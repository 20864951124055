import { Navigate, RouteObject } from "react-router-dom";
import Layout from '@/components/Layout'
import Creation from '@/pages/creation'
import Chat from '@/pages/chat'
import Personal from "@/pages/personal";
import Tools from "@/pages/tools";
import Agents from "@/pages/agents";
import IM from "@/pages/im";
import Home from "@/pages/home";
import Share from "@/pages/share";
import { isDesktop } from "@/utils/electron";
import ClientPrompt from "@/pages/ClientPrompt";

export const router: RouteObject[] = [
  {
    index: true,
    element: <Navigate to={isDesktop() ? '/chat' : '/home'} />,
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/share',
    element: <Share />,
  },
  {
    path: '/clientPrompt',
    element: <ClientPrompt />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: '/chat',
        element: <Chat />,
      },
      {
        path: "/creation",
        element: <Creation />,
      },
      {
        path: "/personal",
        element: <Personal />,
      },
      {
        path: "/tools",
        element: <Tools />,
      },
      {
        path: "/agents",
        element: <Agents />,
      },
      {
        path: "/im",
        element: <IM />,
      },
      {
        path: '*',
        element: <div>404</div>,
      }
    ]
  },
  {
    path: '*',
    element: <div>404</div>,
  }
]