import { CModal } from "@/components/Modal";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { FC, memo, useState } from "react";
import styles from './index.module.scss'
import { ScrollBarBox } from "@/components/ScrollBox";
import { WiseImage } from "@/components/Image";
import { CButton } from "@/components/Button";
import Loading from "@/components/Loading";
import dayjs from "dayjs";
import { copyDataFunc } from "@/utils/common";
import { useMessage } from "@/hooks/useMessage";
import Markdown from "@/components/Markdown";
import { useTranslation } from "react-i18next";
import { SpeechPlayer } from "../SpeechPlayer";
import { useNavigate } from "react-router-dom";
import { AudioRateEnum, AudioPitchEnum, AudioRoleEnum, AudioStyleEnum, AudioSpeekLangeuageEnum, AudioNameEnum } from "@/@types/audio";
import { usernames } from "../../Tools/TextToSpeech";

function getKeyByValue(type: any, value: string): string {
  const entry = Object.entries(type).find(([key, val]) => val === value);
  return entry ? entry[0] : '默认';
}

interface PreviewProps {
  isOpen: boolean
  extendsType?: string
  onClose: () => void
  userAvatar: string
  userName: string
  negativePrompt?: string
  createTime?: string
  onDownload?: (url: string) => Promise<void>
  onSameDraw?: () => void
  showPush?: boolean
  audioSrc: string
  text: string
  audioName: string
  audioCover: string
  speed: number
}
export const CloneAudioPreview: FC<PreviewProps> = memo((props) => {
  const {
    isOpen,
    onClose,
    userAvatar,
    userName,
    onDownload,
    createTime,
    onSameDraw,
    showPush = true,
    audioName,
    audioCover,
    speed,
    audioSrc,
    text
  } = props;
  const { t } = useTranslation();

  return <CModal isOpen={isOpen} onClose={onClose}>
    <Box className={styles.preview}>
      <SpeechPlayer
        audioSrc={audioSrc}
        lyrics={text}
      />
      <Box className={styles.info}>
        <TopCard
          avatar={userAvatar}
          name={userName}
          onClose={onClose}
          onDownload={async () => {
            onDownload && await onDownload(audioSrc);
          }}
          onSameDraw={onSameDraw}
          showPush={showPush}
          cardtitle={'声音克隆'}
        />
        <BottomCard
          time={createTime}
          audioName={audioName}
          audioCover={audioCover}
          audioText={text}
          speed={speed}
        />
      </Box>
    </Box>
  </CModal>
})

interface TopCardProps {
  avatar: string
  name: string
  onClose?: () => void
  onDownload?: () => Promise<void>
  onSameDraw?: () => void
  showPush?: boolean
  cardtitle?: string
}
const TopCard: FC<TopCardProps> = (props) => {
  const { avatar, name, onClose, onDownload, onSameDraw, showPush, cardtitle } = props;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const download = async () => {
    setDownloadLoading(true)
    try {
      onDownload && await onDownload()
    } catch (e) {
      console.error(e)
    } finally {
      setDownloadLoading(false)
    }
  }


  return <Box className={styles.topCard}>
    <Box className={styles.user}>
      <Box className={styles.userInfo}>
        <WiseImage className={styles.avatar} src={avatar} />
        <Text>{name}</Text>
      </Box>
      <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} onClick={onClose} />
    </Box>
    <Text className={styles.type}>{cardtitle}</Text>
    <Box className={styles.btns}>
      <CButton className={`${styles.btn} ${!showPush ? styles.all : ''}`} theme='primary' onClick={onSameDraw}>
        <Icon className={styles.icon} as={require('@/assets/svg/creation_same.svg').ReactComponent} />
        <Text>{t('creation.creationSame')}</Text>
      </CButton>
    </Box>
    <Box className={styles.ops}>
      <Box className={styles.opItem} onClick={download}>
        {
          downloadLoading ? <Loading.Icon className={styles.loading} />
            : <Icon className={styles.icon} as={require('@/assets/svg/creation_download.svg').ReactComponent} />
        }
        <Text>{t('download')}</Text>
      </Box>
      <Box className={styles.opItem} onClick={() => { navigate(`/personal?selectedIndex=${t('report')}`) }}>
        {
          downloadLoading ? <Loading.Icon className={styles.loading} />
            : <Icon className={styles.icon} as={require('@/assets/svg/report.svg').ReactComponent} />
        }
        <Text>{t('report')}</Text>
      </Box>
    </Box>
  </Box>
}

interface BottomCardProps {
  time?: string,
  audioName: string,
  audioCover: string,
  audioText: string,
  speed: number
}
const BottomCard: FC<BottomCardProps> = (props) => {
  const { time, audioName, audioCover, audioText, speed } = props;
  return <Box className={styles.bottomCard}>
    <ScrollBarBox className={styles.bottomScroll}>
      <InfoItem title={'声音选择'} content={audioName ? audioName : ''} iscopy={false} img={audioCover ? audioCover : ''} name={audioName ? audioName : ''} />
      <InfoItem title={'配音文本'} content={audioText ? audioText : ''} iscopy={false} />
      <InfoItem title={'语速'} content={speed ? `${speed.toString()} 倍速` : '1 倍速'} iscopy={false} />
      <InfoItem title={'生成时间'} content={dayjs(time || new Date()).format('YYYY/MM/DD HH:mm:ss')} iscopy={false} />
    </ScrollBarBox>
  </Box>
}

interface InfoItemProps {
  img?: string
  imgSize?: 'small' | 'middle' | 'large'
  title: string
  content: string | string[]
  iscopy: boolean
  name?: string
}
const InfoItem: FC<InfoItemProps> = (props) => {
  const { title, content, iscopy, img, imgSize = 'middle', name } = props;
  const { t } = useTranslation();
  const { copyData } = copyDataFunc();
  const message = useMessage();
  let firstChar = '';
  if (name) {
    firstChar = name.charAt(0);
  }
  const copy = (value: any) => {
    copyData(value);
    message.success({ title: t('CopySuccess') });
  }
  return <Box className={styles.infoItem}>
    <Flex className={styles.titleBox}>
      <Text className={styles.title}>{title}</Text>
      {iscopy &&
        <Icon onClick={() => copy(content)} className={styles.icon} as={require('@/assets/svg/creation_use_prompt.svg').ReactComponent} />
      }
    </Flex>
    <Box className={`${styles.content} ${styles[imgSize]}`}>
      {img && img.length > 0 ? (
        <WiseImage src={img} className={styles.img} />
      ) : (
        name && (
          <Flex
            borderRadius="20%"
            w="40px"
            height="40px"
            justifyContent="center"
            alignItems="center"
            border='1px solid #ccc'
            mr={'10px'}
          >
            <Text fontSize="28px" color="#666" fontWeight="500">
              {firstChar}
            </Text>
          </Flex>
        )
      )}
      <ScrollBarBox className={styles.text} barProps={{ style: { flex: 1 } }}>
        {
          Array.isArray(content) ? content.map((item, index) => <Text key={index}>{item}</Text>)
            : <Markdown source={content} />
        }
      </ScrollBarBox>
    </Box>
  </Box>
}