import { Box, Icon } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { CButton } from "@/components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { getShareDetail } from "@/api/creation";
import { WiseImage } from "@/components/Image";

import styles from './index.module.scss';
import { creationStore, CreationType } from "@/store/creation";
import { SongPreview } from "../creation/Components";
import { SongPlayer } from "../creation/Components/Players";

const Share: FC = () => {
  const [type, setType] = useState<'image' | 'music' | 'video'>('image');
  const [user, setUser] = useState<{ avatar: string, nickName: string }>();
  const [url, setUrl] = useState<string>('');
  const [musicInfo, setMusicInfo] = useState<any>();
  const id = useRef<string>('');
  const location = useLocation();
  const navigate = useNavigate();
  const { setPageType } = creationStore.publicStore;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    id.current = queryParams.get('id') || '';
    const urlType = queryParams.get('type') as 'image' | 'music' | 'video' || 'image'
    setType(urlType);
    getDetail(id.current, urlType);
    setUrl(queryParams.get('url') || '');
    // eslint-disable-next-line
  }, []);

  const getDetail = async (id: string, type: 'image' | 'music' | 'video') => {
    try {
      const res = await getShareDetail(id, type);
      setUser(res.userInfo);
      setMusicInfo(res.info);
      console.log(res)
    } catch (error) {
      console.error(error);
    }
  }


  const handleGoTo = () => {
    switch (type) {
      case 'image':
        setPageType(CreationType.MJ);
        break;
      case 'music':
        setPageType(CreationType.SUNO);
        break;
      case 'video':
        setPageType(CreationType.GEN3);
        break;
    }
    navigate('/creation', { state: { id: id.current, type } });
  };

  let typeText = '';
  switch (type) {
    case 'image':
      typeText = '体验云端SD、MJ绘图';
      break;
    case 'music':
      typeText = '体验云端SUNO生成音乐';
      break;
    case 'video':
      typeText = '体验云端GEN3生成视频';
      break;
  }
  return <Box className={styles.share}>
    <Box className={styles.shareContent}>
      <Box className={styles.header}>
        <Box className={styles.title}>文思逸言-您的一站式AI创作平台</Box>
      </Box>
      <Box className={styles.subTitle}>
        <Box className={styles.userInfo}>
          <WiseImage src={user?.avatar} className={styles.avatar} />
          <Box className={styles.nickName}>由文思用户{user?.nickName}分享</Box>
        </Box>
        <Box className={styles.download}>下载APP</Box>
      </Box>
      {type === 'image' && <WiseImage src={url} className={styles.image} />}
      {
        type === 'music' && musicInfo ? <SongPlayer
          audioSrc={musicInfo.music.clips[0].audio_url}
          lyrics={musicInfo.music.clips[0].metadata.prompt}
          songlyric={musicInfo.music.clips[0].metadata.prompt}
          coverImage={musicInfo.music.clips[0].image_url}
          style={{ width: '700px', height: '500px', margin: '0 auto' }}
        />
          : null
      }
      {type === 'video' && <video src={url} controls className={styles.video} />}
    </Box>
    <Box className={styles.footer}>
      <CButton theme='primary' className={styles.shareBtn} onClick={handleGoTo}>
        去文思逸言官网绘制同款{type === 'image' ? '图片' : type === 'music' ? '音乐' : '视频'}
        <Icon as={require('@/assets/svg/share_to.svg').ReactComponent} className={styles.icon} />
      </CButton>
      <Box className={styles.shareType}>
        {typeText}
      </Box>
    </Box>
  </Box>
}

export default Share;