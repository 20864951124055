import { Box, Flex, Icon, Text, useOutsideClick } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { creationStore, CreationType, DrawImageStatusEnum } from "@/store/creation";
import { observer } from "mobx-react-lite";
import { CreationHeader, CreationPrompt, CreationQuality, CreationScale, CreationStyle, NegativePrompt, CreationReference, CreationInfo, CreationPreview, CreationContent } from "../Components";
import { SliderProps } from "@mui/base/Slider";
import { ScrollBarBox } from "@/components/ScrollBox";
import JSZip from "jszip";
import { getOSSBlobResource, PRICE_SCALE, saveBlobToLocal, SHOW_PRICE_SCALE } from "@/utils/common";
import { useMessage } from "@/hooks/useMessage";
import { userStore } from "@/store/user";
import { globalStore } from "@/store/global";
import { getLangtranslate, postCreateDrawImage, retryDraw, shareToSquare } from "@/api/creation";
import { useQuery } from "@tanstack/react-query";
import { RenderImg } from "../Components/ImageItem";
import { uiStrore } from "@/store/ui";

import styles from './index.module.scss'
import { WisePage } from "@/components/Pagination";
import { PartReDraw } from "../Components/PartReDraw";
import { useTranslation } from "react-i18next";
import CreateBtn from "../Components/CreationBtn";
import { getdate } from "@/pages/tools/components/ContentPreview";
import { cloneDeep } from "lodash";

export const getType = (t: any, extendsType: any) => {
  let extendsTitle = t('textToImage');
  if (extendsType === 'imgToImg') {
    extendsTitle = t('ImageToImage')
  } else if (extendsType === 'magnify') {
    extendsTitle = t('HDZoom')
  } else if (extendsType === 'partReDraw') {
    extendsTitle = t('PartialRepaint')
  } else if (extendsType === 'trans') {
    extendsTitle = t('Transform')
  } else if (extendsType === 'textToVideo') {
    extendsTitle = t('textToVideo')
  } else if (extendsType === 'imgToVideo') {
    extendsTitle = t('imageToVideo')
  } else if (extendsType === 'custom_model') {
    extendsTitle = t('customMode')
  } else if (extendsType === 'simple_gen') {
    extendsTitle = t('InspirationMode')
  }
  return extendsTitle
}

export const WiseMJ: FC = observer(() => {
  const {
    getCreationHistory, setShouldUpdate, init,
    cnPrompt, setCnPrompt, negativeCnPrompt, setNegativeCnPrompt, prompt, negativePrompt,
    styleList, styleConfig, showStyles, setStyleConfig, sizeScal, setSizeScal, setHrLevel, hrLevel, setHrPriceLevel,
    hrPriceLevel, imgToImgUrl, imgToImgWeight, setImgToImgUrl, setImgToImgWeight, crefUrl, setCrefUrl, crefWeight, setCrefWeight
  } = creationStore[CreationType.MJ];
  const { enableKeys } = creationStore.publicStore;
  const [loading, setLoading] = useState(false);

  const { userInfo, updateUserBalance } = userStore;
  const { setLoginIsOpen } = globalStore;
  const { processTaskIds, setProcessTaskIds } = uiStrore;
  const message = useMessage();
  const { t } = useTranslation();

  const marks: SliderProps['marks'] = [
    { value: 0, label: t('low') },
    { value: 3, label: t('high') },
  ]
  const styleTips = {
    title: t('creation.ReferenceStyle'),
    tips: [
      t('creation.MJTips1'),
      t('creation.SDTips2')
    ]
  }
  const crefTips = {
    title: t('creation.CharacterReference'),
    tips: [
      t('creation.MJTips2'),
      t('creation.SDTips2')
    ]
  }

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, [])

  const createTask = async () => {
    if (loading || isDisable) return;
    setLoading(true);
    try {
      if (!userInfo) {
        message.warning({ title: t('LoginFirst') });
        setLoginIsOpen(true);
        return;
      }
      if (!userInfo.monVip && (userInfo.balance * SHOW_PRICE_SCALE) < price) {
        message.warning({ title: t('creation.DrawFaildBalance') });
        return;
      }

      let ePrompt = '';
      if (/[\u4e00-\u9fa5]/.test(cnPrompt + '#' + negativeCnPrompt)) {
        //有中文，则翻译
        ePrompt = (await getLangtranslate(cnPrompt + '#' + negativeCnPrompt)).toString();
      }

      const isImgToImg = !!imgToImgUrl;
      const isCref = !!crefUrl;
      const { userBalance, ret, message: resMessage, newId } = await postCreateDrawImage({
        type: CreationType.MJ,
        hrPriceLevel: hrPriceLevel,
        hrLevel,
        drawType: styleConfig?.name,
        sizeScal,
        prompt: ePrompt.split('#').length > 0 ? (ePrompt.split('#')[0] || cnPrompt) : prompt,
        cnPrompt: cnPrompt,
        negativePrompt: ePrompt.split('#').length > 1 ? ePrompt.split('#')[1] : negativePrompt,
        negativeCnPrompt: negativeCnPrompt,
        vaeName: '',
        group: 'AIDraw',
        ...(isImgToImg && { extendsType: 'imgToImg', imgToImgUrl, imgToImgWeight: imgToImgWeight || 0 }),
        ...(isCref && { crefUrl, crefWeight: crefWeight || 0 }),
      })

      if (ret === 'successd') {
        updateUserBalance(userBalance);
        setShouldUpdate(true);
        message.success({ title: t('creation.DrawTaskSuccess') });
        getCreationHistory();

        const taskIds = cloneDeep(processTaskIds);
        taskIds.push(newId);
        setProcessTaskIds(taskIds);
      } else {
        message.error({ title: resMessage });
      }
    } catch (err: any) {
      console.log(err)
      if (err?.message === 'political_content') {
        message.error({ title: t('creation.InputContainsPolitical') });
      } else {
        message.error({ title: err.message });
      }
    } finally {
      setLoading(false);
    }
  }

  const reset = () => {
    setCnPrompt('');
    setNegativeCnPrompt('');
    setSizeScal('1:1');
    setImgToImgUrl('');
    setImgToImgWeight(0);
    setCrefUrl('');
    setCrefWeight(0);
    const quality = styleConfig?.draw_quality[0];
    if (quality) {
      setHrLevel(quality.name);
      setHrPriceLevel(quality.cost_coef as string);
    } else {
      setHrLevel('');
      setHrPriceLevel('');
    }
  }

  let price = SHOW_PRICE_SCALE;
  const cfg = styleList.find((i) => i.name === styleConfig?.name);
  if (cfg) {
    price = price * cfg.cost_base;
    const cfg_qua = cfg.draw_quality ? cfg.draw_quality.find((ii: any) => ii.name === hrLevel) : null;
    if (cfg_qua) {
      price = price * (Number(cfg_qua.cost_coef) || 0);
    }
    const cfg_scale = cfg.draw_scal ? cfg.draw_scal.find((ii: any) => ii.name === sizeScal) : null
    if (cfg_scale) {
      price = price * (Number(cfg_scale.cost_coef) || 0);
    }
  }

  const isDisable = !cnPrompt || !styleConfig || !hrLevel || !sizeScal;

  return <CreationContent.Content>
    <CreationContent.Inputs>
      <CreationHeader icon='draw_wise_000' text={t('wise') + 'MJ'} />
      <Box className={styles.container}>
        <ScrollBarBox className={styles.content}>
          <CreationStyle styleList={styleList} selectedStyle={styleConfig} onStyleChange={setStyleConfig} showStyles={showStyles} />
          <CreationPrompt onClear={() => { setCnPrompt(''); setNegativeCnPrompt(''); }}
            cnPrompt={cnPrompt}
            onInput={setCnPrompt}
            type={CreationType.MJ}
          />
          <NegativePrompt negativeCNPrompt={negativeCnPrompt} onInput={setNegativeCnPrompt} />
          <CreationScale scaleList={styleConfig?.draw_scal || []} onScaleChange={setSizeScal} value={sizeScal} />
          <CreationQuality qualityList={styleConfig?.draw_quality || []} onQualityChange={(level, priceLevel) => {
            setHrLevel(level);
            setHrPriceLevel(priceLevel);
          }} value={hrLevel} />
          <CreationReference
            title={t('creation.StyleReference')}
            tips={styleTips}
            sliderProps={{ marks, min: 0, max: 3, step: 0.1, onChange: (_, value) => setImgToImgWeight(value as number) }}
            url={imgToImgUrl}
            onUrlChange={setImgToImgUrl}
            filetitle={t('上传图片作为参考，以影响生成图片的构图、风格、色彩')}
            filelist={[`${t('SupportImages')}：JPG、JPEG、PNG、WEBP、BMP、HEIC、HEIF`, t('imgMax20Size')]}
          />
          <CreationReference
            title={t('creation.CharacterReference')}
            tips={crefTips}
            url={crefUrl}
            sliderProps={{ marks: [marks[0], { value: 100, label: '高' }], onChange: (_, value) => setCrefWeight(value as number) }}
            onUrlChange={setCrefUrl}
            filetitle={t('上传图片作为参考，以生成脸部特征、人物相似但动作、场景不同的图片')}
            filelist={[`${t('SupportImages')}：JPG、JPEG、PNG、WEBP、BMP、HEIC、HEIF`, t('imgMax20Size')]}
          />
        </ScrollBarBox>
      </Box>
      <Box className={styles.create}>
        <CreateBtn
          loading={loading}
          isMaintain={!enableKeys['wensimj']}
          disabled={isDisable}
          onClick={createTask}
          price={price}
          isVip={userInfo?.monVip}
          icon={<Icon as={require('@/assets/svg/create_img.svg').ReactComponent} />}
          onReset={reset}
          onVip={() => uiStrore.setOpenVip(true)}
        />
      </Box>
    </CreationContent.Inputs>
    <WiseMJGenerate />
  </CreationContent.Content>
})

const WiseMJGenerate: FC = observer(() => {
  const {
    historyList, styleList, pageNum, pageSize, setPageNum, total, shouldUpdate, faverites,
    setSizeScal, setHrLevel, setHrPriceLevel, setPrompt, setCnPrompt, setNegativePrompt, setNegativeCnPrompt, setSeed,
    setStyleConfig, deleteCreationAction, getCreationHistory, setShouldUpdate, postCollection, setFaverites
  } = creationStore[CreationType.MJ];
  const { userInfo, updateUserBalance } = userStore;
  const { setLoginIsOpen } = globalStore;
  const { openAlert, closeAlert } = uiStrore;
  const { t } = useTranslation();
  const hadCreatingTask = historyList.some(item => (Number(item.status) === DrawImageStatusEnum.PROCESSING || Number(item.status) === DrawImageStatusEnum.WAIT || Number(item.status) === DrawImageStatusEnum.QUEUE));

  const [downloading, setDownloading] = useState<string>();
  const [reDrawing, setReDrawing] = useState('');
  const message = useMessage();
  const moreEdit = useRef<HistoryItem>();
  const editRef = useRef<HTMLDivElement>(null);
  const [morePosition, setMorePosition] = useState<undefined | { x: number, y: number, isFirstEdit: boolean, index: number }>();
  const [viewSwiper, setViewSwiper] = useState(false);
  const viewInfo = useRef<HistoryItem>();
  const [infoIndex, setInfoIndex] = useState(0);
  const [openPartReDraw, setOpenPartReDraw] = useState<{ open: boolean, url: string }>({ open: false, url: '' });

  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdate(false);
    }
    // eslint-disable-next-line
  }, [shouldUpdate])

  useOutsideClick({
    ref: editRef,
    handler: () => {
      setMorePosition(undefined);
    }
  })

  // 存在绘画任务轮询更新列表
  useQuery(
    ['refreshDrawList'],
    () => {
      getCreationHistory();
      return null
    },
    {
      refetchInterval: hadCreatingTask ? 5000 : 30000, //存在绘画任务则5s刷新一次，否则30s刷新一次
      enabled: pageNum === 1 && !!userInfo?._id
    }
  )

  // 重新绘制
  const reDraw = async (item: HistoryItem) => {
    if (reDrawing) {
      message.warning({ title: t('creation.TaskingNotClick') });
      return
    }
    setReDrawing(item._id);
    try {
      if (!userInfo) {
        message.warning({ title: t('LoginFirst') });
        setLoginIsOpen(true);
        setReDrawing('')
        return;
      }
      const { hrLevel, drawType, sizeScal, prompt, hrPriceLevel } = item;
      let basePrice = SHOW_PRICE_SCALE;
      const cfg = styleList.find((i) => i.name === drawType)
      if (cfg) {
        basePrice = basePrice * cfg.cost_base;
        const cfg_qua = cfg.draw_quality.find((ii) => ii.name === hrLevel)
        if (cfg_qua) {
          basePrice = basePrice * (Number(cfg_qua.cost_coef) || 0);
        }
        const cfg_scale = cfg.draw_scal.find((ii) => ii.name === sizeScal)
        if (cfg_scale) {
          basePrice = basePrice * (Number(cfg_scale.cost_coef) || 0);;
        }
      }

      if (!userInfo.monVip && (userInfo.balance * SHOW_PRICE_SCALE) < basePrice) {
        message.warning({ title: t('creation.DrawFaildBalance') });
        setReDrawing('');
        // setGetPointsIsOpen(true);
        return;
      }

      let ePrompt = '';
      if (/[\u4e00-\u9fa5]/.test(prompt.cn + '#' + prompt.negativeCn)) {
        //有中文，则翻译
        ePrompt = (await getLangtranslate(prompt.cn + '#' + prompt.negativeCn)).toString();
      }

      const { userBalance } = await postCreateDrawImage({
        type: CreationType.MJ,
        hrPriceLevel: hrPriceLevel,
        hrLevel,
        drawType,
        sizeScal,
        seed: 0,
        prompt: ePrompt.split('#').length > 0 ? ePrompt.split('#')[0] : prompt.en,
        cnPrompt: prompt.cn,
        negativePrompt: ePrompt.split('#').length > 1 ? ePrompt.split('#')[1] : prompt.negativeEn,
        negativeCnPrompt: prompt.negativeCn,
        vaeName: '',
        group: 'AIDraw',
      })
      message.success({ title: t('creation.DrawTaskSuccess') });
      updateUserBalance(userBalance);
      getCreationHistory();
    } catch (err: any) {
      message.error({ title: err.message })
    }
    setReDrawing('')
  }

  // 复用绘画参数
  const usePrompt = (item: HistoryItem) => {
    let _index = -1;
    const currentStyle = styleList.find((style, index) => {
      _index = index;
      return style.name === item.drawType
    });
    setPrompt(item.prompt.en);
    setCnPrompt(item.prompt.cn);
    setNegativePrompt(item.prompt.negativeEn);
    setNegativeCnPrompt(item.prompt.negativeCn);
    setSizeScal(item.sizeScal);
    setHrLevel(item.hrLevel);
    setHrPriceLevel(item.hrPriceLevel);
    currentStyle && setStyleConfig(currentStyle, _index); // 选中当前风格
    if (item.type === CreationType.SD) {
      item.extends?.sdSeed && setSeed(item.extends.sdSeed)
    }
    message.success({ title: t('creation.ReuseSuccess') })
  }

  // 下载所有图片至压缩包
  const downloadZip = async (drawType: string, urls: string[], id: string, extendsType: any) => {
    if (downloading) return
    let extendsTitle = getType(t, extendsType);
    const date = getdate();
    const zip = new JSZip()
    const fileFolder = zip.folder(drawType)
    const imgList: { name: string, img: Blob }[] = []
    setDownloading(id)

    for (let i = 0; i < urls.length; i++) {
      const name = drawType + i
      try {
        getOSSBlobResource(urls[i]).then(res => {
          imgList.push({ name, img: res })
          if (imgList.length === urls.length && imgList.length) {
            for (let k = 0; k < imgList.length; k++) {
              fileFolder?.file(imgList[k].name + '.png', imgList[k].img, { binary: true })
            }
            zip.generateAsync({ type: 'blob' }).then(async content => {
              try {
                await saveBlobToLocal(content, `创作_文思MJ_${extendsTitle}_${date}.zip`)
                message.success({ title: t('ImgDownloadSuccess') })
              } catch (e) {
                console.error(e);
              }
              setDownloading(undefined)
            })
          }
        })
      } catch (err) {
        setDownloading(undefined)
      }
    }
  }

  // 下载单张图片
  const download = async (url: string, extendsType: any) => {
    if (!url) return;
    let extendsTitle = getType(t, extendsType);
    const date = getdate()
    try {
      const res = await getOSSBlobResource(url);
      await saveBlobToLocal(res, `创作_文思MJ_${extendsTitle}_${date}.png`);
      message.success({ title: t('ImgDownloadSuccess') })
    } catch (err: any) {
      if (err.message !== 'The user aborted a request.') {
        message.error({ title: t('ImgDownloadFaild') })
      }
    }
  }

  const onFavorite = async (id: string, url: string) => {
    const isFavorite = faverites.get(id)?.includes(url) || false;
    try {
      const res = await postCollection(id, url);
      setFaverites(id, res[id] || []);
      if (isFavorite) {
        message.success({ title: t('CancelCollectionSuccess') })
      } else {
        message.success({ title: t('collected') })
      }
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  // 点击显示更多操作
  const onMore = (e: React.MouseEvent<SVGElement, MouseEvent>, url: string, item: HistoryItem) => {
    e.stopPropagation();
    //@ts-ignore
    let { x, y } = e.target.getBoundingClientRect();
    const index = item.imageUrl.findIndex(i => i === url);
    let isFirstEdit = false;
    if (item.imageUrl.filter(i => !!i).length === 1 || index === 0) {
      isFirstEdit = true;
    }
    const _BoxHeigh = isFirstEdit ? 60 : 210, _BoxWidth = 140;
    if (e.nativeEvent.y + _BoxHeigh > window.innerHeight) {
      y = y - _BoxHeigh;
    } else {
      y = y + 15;
    }
    if (e.nativeEvent.x + _BoxWidth > window.innerWidth) {
      x = x - _BoxWidth;
    } else {
      x = x + 15;
    }
    setMorePosition({ x, y, isFirstEdit, index });
    moreEdit.current = item;
  }

  // 高清放大
  const onMagnify = async () => {
    if (!moreEdit.current) return
    try {
      const res = await postCreateDrawImage({
        action: `U${morePosition?.index}`,
        actionId: moreEdit.current.extends.midjourneyID,
        drawType: moreEdit.current.drawType,
        extendsType: 'magnify',
        group: 'AIDraw',
        prompt: `${moreEdit.current.extends.midjourneyID} U${morePosition?.index}`,
        type: CreationType.MJ,
      })
      if (res.ret === 'successd') {
        message.success({ title: t('creation.DrawTaskSuccess') });
        setMorePosition(undefined);
        getCreationHistory();
      } else {
        message.error({ title: res.message });
      }
    } catch (err: any) {
      if (err?.message === 'political_content') {
        message.error({ title: t('creation.InputContainsPolitical') });
      } else {
        message.error({ title: err.message });
      }
    }
  }

  // 变换
  const onTrans = async () => {
    if (!moreEdit.current) return
    let ePrompt = '';
    const cnPrompt = moreEdit.current.prompt.cn;
    const negativeCnPrompt = moreEdit.current.prompt.negativeCn;
    if (/[\u4e00-\u9fa5]/.test(cnPrompt + '#' + negativeCnPrompt)) {
      //有中文，则翻译
      ePrompt = (await getLangtranslate(cnPrompt + '#' + negativeCnPrompt)).toString();
    }
    try {
      const res = await postCreateDrawImage({
        action: `V${morePosition?.index}`,
        actionId: moreEdit.current.extends.midjourneyID,
        drawType: moreEdit.current.drawType,
        extendsType: 'trans',
        group: 'AIDraw',
        negativePrompt: ePrompt.split('#').length > 1 ? ePrompt.split('#')[1] : moreEdit.current.prompt.negativeEn,
        prompt: ePrompt.split('#').length > 0 ? ePrompt.split('#')[0] : moreEdit.current.prompt.en,
        type: CreationType.MJ,
      })
      if (res.ret === 'successd') {
        message.success({ title: t('creation.DrawTaskSuccess') });
        setMorePosition(undefined);
        getCreationHistory();
      } else {
        message.error({ title: res.message });
      }
    } catch (err: any) {
      if (err?.message === 'political_content') {
        message.error({ title: t('creation.InputContainsPolitical') });
      } else {
        message.error({ title: err.message });
      }
    }
  }

  // 预览大图
  const onPreview = (item: HistoryItem, index: number) => {
    viewInfo.current = item;
    setInfoIndex(index);
    setViewSwiper(true);
  }

  const onRetry = async (item: HistoryItem) => {
    try {
      await retryDraw({ _id: item._id, type: item.type });
      getCreationHistory();
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  const onShare = async () => {
    if (!viewInfo.current) return
    try {
      await shareToSquare({
        _id: viewInfo.current._id || '',
        url: viewInfo.current.imageUrl[infoIndex],
        type: CreationType.MJ,
        mediaType: 'image'
      });
      message.success({ title: t('ShareSuccess') });
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  const imgMove = async (url: string) => {
    openAlert({
      title: t('tip'),
      content: <Box className={styles.onlyGen3}>
        <Text>{t('SupportToGen3')}</Text>
        <Box className={styles.model} >
          <Box className={styles.info}>
            <Box className={styles.icon}>
              <Icon as={require('@/assets/svg/draw_video.svg').ReactComponent} />
            </Box>
            <Text className={styles.name}>
              {t('wise') + 'GEN3'}
            </Text>
            <Text className={styles.recommend}>{t('Recommend')}</Text>
          </Box>
          <Text className={styles.price}>
            50 <Icon as={require('@/assets/svg/personalBalanced.svg').ReactComponent} />/{t('times')}
          </Text>
        </Box>
      </Box>,
      status: 'ask',
      onOk: async () => {
        try {
          creationStore[CreationType.GEN3].setImgUrl(url);
          creationStore.publicStore.setPageType(CreationType.GEN3);
        } catch (err: any) {
          message.error({ title: err.message })
        }
        closeAlert();
      }
    })
  }

  return <CreationContent.History>
    <Box className={styles.historyTitle}>
      <Text className={styles.text}>{t('history')}</Text>
    </Box>
    <ScrollBarBox className={styles.listBox}>
      {
        historyList.map((item, _index) => {
          return <Box key={item._id} className={styles.historyItem}>
            <CreationInfo.CreationTitle
              type={CreationType.MJ}
              isDownloading={downloading === item._id}
              isReDrawing={reDrawing === item._id}
              extendsType={item.extends.extendsType}
              onReDraw={() => {
                if (Number(item.status) === DrawImageStatusEnum.FAIL) {
                  onRetry(item)
                } else {
                  reDraw(item)
                }
              }}
              onDelete={() => {
                openAlert({
                  title: t('creation.deleteCreate'),
                  content: t('creation.DeleteCantRecover'),
                  status: 'warning',
                  onOk: async () => {
                    await deleteCreationAction(item._id)
                    closeAlert();
                    message.success({ title: t('deleteSuccess') });
                    getCreationHistory();
                  }
                })
              }}
              {...(Number(item.status) === DrawImageStatusEnum.SUCCESS && { onDownload: () => downloadZip(item.drawType, item.imageUrl, item._id, item.extends.extendsType) })}
              onUsePrompt={() => usePrompt(item)}
            />
            <RenderImg
              type={CreationType.MJ}
              sizeScale={item.sizeScal}
              favorites={faverites.get(item._id)}
              item={item}
              onFavorite={onFavorite}
              onMore={(e, url) => onMore(e, url, item)}
              onView={_index => onPreview(item, _index)}
              onReDraw={() => onRetry(item)}
            />
            <Box className={styles.bottomInfo}>
              <CreationInfo.Calendar time={item.time} />
              <CreationInfo.DrawType drawType={item.drawType} />
              <CreationInfo.Price isVip={item.isVip} price={parseInt((item.extends.costPoint / PRICE_SCALE * SHOW_PRICE_SCALE).toFixed(1))} />
            </Box>
          </Box>
        })
      }
      {
        !historyList.length ? <Flex className={styles.fullbox}>
          <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
          <Text className={styles.prompt}>{t('NoHistory')}</Text>
        </Flex> : null
      }
      {
        total > pageSize && <WisePage pageNum={pageNum} pageSize={pageSize} total={total} onPageChange={setPageNum} />
      }
    </ScrollBarBox>
    {
      morePosition && <Box ref={editRef} className={styles.moreEdit} style={{ left: morePosition.x, top: morePosition.y }}>
        {
          // 是否仅有一张图片或者第一张图片
          morePosition.isFirstEdit ? <Box className={styles.moreItem}
            onClick={() => download(moreEdit.current?.imageUrl[morePosition?.index || 0] || '', moreEdit.current?.extends.extendsType)}>
            <Icon className={styles.icon} as={require('@/assets/svg/creation_download.svg').ReactComponent} />
            {t('creation.DownloadOne')}
          </Box> : <>
            <Box className={`${styles.moreItem} ${styles.moveImg}`}
              onClick={() => imgMove(moreEdit.current?.imageUrl[morePosition?.index || 0] || '')}>
              <Icon className={styles.icon} as={require('@/assets/svg/img_2_video.svg').ReactComponent} />
              {t('creation.img2video')}
            </Box>
            <Box
              className={styles.moreItem}
              onClick={() => download(moreEdit.current?.imageUrl[morePosition?.index || 0] || '', moreEdit.current?.extends.extendsType)}>
              <Icon className={styles.icon} as={require('@/assets/svg/creation_download.svg').ReactComponent} />
              {t('creation.DownloadOne')}
            </Box>
            <Box className={styles.moreItem} onClick={() => setOpenPartReDraw({
              open: true,
              url: moreEdit.current?.imageUrl[morePosition?.index || 0] || ''
            })}>
              <Icon className={styles.icon} as={require('@/assets/svg/creation_wise_redraw.svg').ReactComponent} />
              {t('PartialRepaint')}
            </Box>
            <Box className={styles.moreItem} onClick={() => {
              openAlert({
                title: t('tip'),
                content: t('creation.ConfirmTransRedraw'),
                status: 'warning',
                onOk: async () => {
                  await onTrans();
                  closeAlert();
                }
              })
            }}>
              <Icon className={styles.icon} as={require('@/assets/svg/creation_change.svg').ReactComponent} />
              {t('Transform')}
            </Box>
            <Box className={styles.moreItem} onClick={() => {
              openAlert({
                title: t('tip'),
                content: t('creation.ConfirmMagifyImg'),
                status: 'warning',
                onOk: async () => {
                  await onMagnify();
                  closeAlert();
                }
              })
            }}>
              <Icon className={styles.icon} as={require('@/assets/svg/creation_zoom.svg').ReactComponent} />
              {t('HDZoom')}
            </Box>
          </>
        }
      </Box>
    }
    <CreationPreview
      imgList={viewInfo.current?.imageUrl || []}
      imgIndex={infoIndex || 0}
      isOpen={viewSwiper}
      onClose={() => setViewSwiper(false)}
      userAvatar={userInfo?.avatar || ''}
      userName={userInfo?.nickName || userInfo?.wiseNickName || ''}
      drayInfo={{
        id: viewInfo.current?._id || '',
        type: viewInfo.current?.drawType || '',
        avatar: styleList.find(style => style.name === viewInfo.current?.drawType)?.view_image || '',
      }}
      isFavorite={faverites.get(viewInfo.current?._id || '')?.includes(viewInfo.current?.imageUrl[infoIndex || 0] || '') || false}
      prompt={viewInfo.current?.prompt.cn || viewInfo.current?.prompt.en || ""}
      negativePrompt={viewInfo.current?.prompt.negativeCn || viewInfo.current?.prompt.negativeEn || ""}
      onDownload={download}
      sizeScal={viewInfo.current?.sizeScal || ''}
      hrLevel={viewInfo.current?.hrLevel || ''}
      imgToImg={{ url: viewInfo.current?.extends.imgToImgUrl || '', weight: viewInfo.current?.extends.imgToImgWeight || 0 }}
      createTime={viewInfo.current?.time}
      onChangeIndex={setInfoIndex}
      onFavorite={() => onFavorite(viewInfo.current?._id || '', viewInfo.current?.imageUrl[infoIndex || 0] || '')}
      onSameDraw={() => {
        viewInfo.current && usePrompt(viewInfo.current);
        setViewSwiper(false);
      }}
      onShare={onShare}
      type={CreationType.MJ}
      extendsType={viewInfo.current?.extends.extendsType}
    />
    {
      moreEdit.current && <PartReDraw
        open={openPartReDraw.open}
        onClose={() => setOpenPartReDraw({ open: false, url: '' })}
        item={moreEdit.current}
        partReDrawUrl={openPartReDraw.url}
        styleList={styleList}
        shouldUpdate={() => { setPageNum(1); }}
        isVip={userInfo?.monVip || false}
      />
    }
  </CreationContent.History>
})

