import { agentsStore } from "@/store/agents";
import { Box, Icon, Input, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, FormEventHandler, useEffect, useRef, useState } from "react";
import { WiseImage } from "@/components/Image";
import { uiStrore } from "@/store/ui";
import { ScrollBarBox } from "@/components/ScrollBox";
import { WiseSlider } from "@/pages/creation/Components/Reference";
import { SliderProps } from "@mui/base";
import { CButton } from "@/components/Button";
import { delAgentById, postCreateAgent, putAgentById } from "@/api/agents";
import { useMessage } from "@/hooks/useMessage";
import { chatStore, ChatType } from "@/store/chat";
import { useNavigate } from "react-router";
import { debounce } from "lodash";
import { useSelectFile } from "@/hooks/useSelectFile";
import { uploadFiles } from "@/utils/common";
import Loading from "@/components/Loading";

import styles from './index.module.scss'
import { useTranslation } from "react-i18next";

const _Name_Max = 16;
const _Prompt_Max = 3000;
const _Intro_Max = 100;

const AgentsContent: FC = () => {
  const { activeInfo, fetchList, setActiveInfo, fetchDetail, activeDetail } = agentsStore;
  const { setCurrentChat, setChatType, setChatInfo } = chatStore;
  const [avatar, setAvatar] = useState(activeInfo?.avatar || '')
  const [name, setname] = useState(activeInfo?.name || '');
  const [intro, setIntro] = useState(activeInfo?.intro || '');
  const [prompt, setPrompt] = useState(activeInfo?.systemPrompt ? activeInfo.systemPrompt : activeInfo?.intro ? activeInfo.intro : '');
  const [temperature, setTemperature] = useState(activeInfo?.temperature || 0);
  const [presence_penalty, setPresencePenalty] = useState(activeInfo?.presence_penalty || 0);
  const { openAlert, closeAlert } = uiStrore;
  const editBox = useRef<HTMLDivElement>(null);
  const introBox = useRef<HTMLDivElement>(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const { t } = useTranslation();


  const marks: SliderProps['marks'] = [
    { value: 0, label: t('agent.Determinism') },
    { value: 2, label: t('agent.Randomness') },
  ]

  const marks_1: SliderProps['marks'] = [
    { value: -2, label: t('agent.Conservatism') },
    { value: 2, label: t('agent.Dissemination') },
  ]
  const tips = {
    prompt: {
      title: t('creation.prompt'),
      tip: t('agent.tips1')
    },
    intro: {
      title: t('Introduction'),
      tip: t('agent.tips2')
    }
  }

  const message = useMessage();
  const { onOpen, File } = useSelectFile({ fileType: '.jpeg,.jpg,.png,.heic' });

  useEffect(() => {
    if (activeInfo) {
      setAvatar(activeInfo.avatar)
      setname(activeInfo.name);
      setIntro(activeInfo.intro);
      setPrompt(activeInfo.systemPrompt || '');
      setTemperature(activeInfo.temperature);
      setPresencePenalty(activeInfo.presence_penalty);
      if (editBox) {
        editBox.current!.innerText = activeInfo.systemPrompt.trim() || '';
      }
      if (introBox) {
        introBox.current!.innerText = activeInfo.intro.trim() || '';
      }
      activeInfo._id && fetchDetail(activeInfo._id)
    }
    // eslint-disable-next-line
  }, [activeInfo])

  const changeValue: FormEventHandler<HTMLDivElement> = debounce((e) => {
    if ((e.target as HTMLDivElement).innerText.length > _Prompt_Max) {
      const text = (e.target as HTMLDivElement).innerText.slice(0, _Prompt_Max);
      (e.target as HTMLDivElement).innerText = text;
      setPrompt(text)
      return
    }
    setPrompt((e.target as HTMLDivElement).innerText)
  }, 150)

  const changeIntroValue: FormEventHandler<HTMLDivElement> = debounce((e) => {
    if ((e.target as HTMLDivElement).innerText.length > _Intro_Max) {
      const text = (e.target as HTMLDivElement).innerText.slice(0, _Intro_Max);
      (e.target as HTMLDivElement).innerText = text;
      setIntro(text)
      return
    }
    setIntro((e.target as HTMLDivElement).innerText)
  }, 150)

  const selectImgFile = async (e: File[]) => {
    if (e.length === 0 || uploading) return;
    setUploading(true);
    try {
      const res = await uploadFiles(e[0]);
      setAvatar(res[0])
    } catch (e) {
      console.log(e)
    } finally {
      setUploading(false);
    }
  }

  const openTips = (tip: string, title: string) => {
    openAlert({
      title: title,
      content: <Box className={styles.tipsContent}>
        {tip}
      </Box>,
      status: 'ask',
      footer: <Box className={styles.tipsFooter} onClick={closeAlert}>{t('ISee')}</Box>,
      onclose: closeAlert
    })
  }

  const deleteAgent = async () => {
    openAlert({
      title: t('tip'),
      content: t('agent.deleteConfirm'),
      status: 'warning',
      onOk: async () => {
        await delAgentById(activeInfo?._id || '')
        closeAlert();
        fetchList();
        setActiveInfo();
      }
    })
  }

  const updateAgent = async (toChat = false) => {
    if (saveLoading || disabled) return
    setSaveLoading(true)
    try {
      let new_id = '';
      // 走创建api
      if (!activeInfo?._id) {
        const res = await postCreateAgent({
          name,
          avatar: avatar,
          prompt,
          intro,
          presence_penalty,
          temperature
        });
        new_id = res;
        message.success({ title: t('CreateSuccess') })
        // 跳转到对话
        toChat && goChat(res, avatar, intro, name)
      } else if (activeDetail) {
        await putAgentById(activeInfo?._id || '',
          {
            avatar: avatar,
            chat: { ...activeDetail.chat, temperature, presence_penalty, systemPrompt: prompt },
            share: activeDetail.share,
            intro: intro,
            name
          }
        )
        message.success({ title: t('SaveSuccess') });
        // 跳转到对话
        toChat && goChat(activeDetail._id, avatar, intro, name)
      }
      const list = await fetchList();
      if (!activeInfo?._id && new_id) {
        list.myModels.forEach(item => {
          if (item._id === new_id) {
            setActiveInfo(item)
          }
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      setSaveLoading(false)
    }
  }

  const goChat = (id: string, avatar: string, intro: string, name: string) => {
    setChatType(ChatType.AI);
    setCurrentChat({
      isPlugin: false,
      modelId: id,
      chatId: ''
    })
    setChatInfo({
      info: {
        modelId: id,
        chatId: '',
        history: [],
        model: {
          avatar: avatar,
          intro: intro,
          name: name,
          canUse: false
        }
      }
    })
    navigate('/chat')
  }

  const disabled = !name
  return <Box className={styles.content}>
    {
      activeInfo && <ScrollBarBox className={styles.info}>
        <Box className={styles.avatarBox}>
          <Box className={styles.avatar} onClick={() => { !uploading && onOpen() }}>
            <Box className={styles.img}>
              <WiseImage src={avatar} />
            </Box>
            <Box className={styles.camera}>
              {
                uploading ? <Loading.Dot className={styles.loading} />
                  : <Icon className={styles.icon} as={require('@/assets/svg/camera.svg').ReactComponent} />
              }

            </Box>
          </Box>
        </Box>
        <Box className={styles.params}>
          <Text className={styles.title}>{t('agent.name')}<span className={styles.require}>*</span></Text>
          <Box className={styles.inputBox}>
            <Input value={name} className={styles.input} placeholder='请输入智能体的名称' onChange={e => {
              if (e.target.value.length <= _Name_Max) {
                setname(e.target.value)
              }
            }} />
            <Text className={styles.tips}>{name.length} / {_Name_Max}</Text>
          </Box>
        </Box>
        <Box className={styles.params}>
          <Text className={styles.title}>{t('creation.prompt')}
            <Icon as={require('@/assets/svg/question.svg').ReactComponent} w={'20px'} h='20px'
              onClick={() => openTips(tips.prompt.tip, tips.prompt.title)}
            />
          </Text>
          <Box className={styles.inputBox}>
            <Box className={styles.promptBox}>
              <ScrollBarBox>
                <Box contentEditable
                  className={styles.prompt}
                  ref={editBox}
                  onInput={changeValue}
                  onPaste={e => {
                    e.preventDefault();
                    const text = e.clipboardData.getData('text/plain'); //仅提取文字
                    document.execCommand("insertText", false, text);
                  }}
                  content={t('agent.caseDesc')}
                />
              </ScrollBarBox>
            </Box>
            <Text className={styles.bottomTips}>{prompt ? prompt.length : 0} / {_Prompt_Max}</Text>
          </Box>
        </Box>
        <Box className={styles.params}>
          <Text className={styles.title}>{t('agent.RandomTopic')}</Text>
          <Box className={styles.slider}>
            <WiseSlider min={0} max={2} step={0.1} marks={marks} value={temperature} onChange={(_, value) => setTemperature(value as number)} />
          </Box>
        </Box>
        <Box className={styles.params}>
          <Text className={styles.title}>{t('agent.TopicFreshness')}</Text>
          <Box className={styles.slider}>
            <WiseSlider min={-2} max={2} step={0.1} marks={marks_1} value={presence_penalty} onChange={(_, value) => setPresencePenalty(value as number)} />
          </Box>
        </Box>
        <Box className={styles.params}>
          <Text className={styles.title}>{t('Introduction')}
            <Icon as={require('@/assets/svg/question.svg').ReactComponent} w={'20px'} h='20px'
              onClick={() => openTips(tips.intro.tip, tips.intro.title)}
            />
          </Text>
          <Box className={styles.inputBox}>
            <Box className={styles.promptBox}>
              <ScrollBarBox>
                <Box contentEditable
                  className={styles.prompt}
                  ref={introBox}
                  onInput={changeIntroValue}
                  onPaste={e => {
                    e.preventDefault();
                    const text = e.clipboardData.getData('text/plain'); //仅提取文字
                    document.execCommand("insertText", false, text);
                  }}
                  content={t('agent.caseDesc1')}
                />
              </ScrollBarBox>
            </Box>
            <Text className={styles.bottomTips}>{intro ? intro.length : 0} / {_Intro_Max}</Text>
          </Box>
        </Box>
      </ScrollBarBox>
    }
    {
      !activeInfo && <Box className={styles.empty}>
        <Icon as={require('@/assets/svg/agents_empty.svg').ReactComponent} />
        <Text className={styles.text}>{t('agent.SelectAgent')}</Text>
      </Box>
    }
    {
      activeInfo && <Box className={styles.footer}>
        {
          activeInfo._id ? <CButton theme='outline' className={styles.btn} onClick={deleteAgent}>{t('agent.delete')}</CButton>
            : null
        }
        <CButton theme='primary' disabled={disabled} className={styles.btn} isLoading={saveLoading} onClick={() => updateAgent()}>{t('agent.save')}</CButton>
        <CButton theme='custom' disabled={disabled} className={`${styles.btn} ${styles.exp}`} isLoading={saveLoading} onClick={() => updateAgent(true)}>{t('agent.saveToChat')}</CButton>
      </Box>
    }
    <File onSelect={selectImgFile} />
  </Box>
}

export default observer(AgentsContent)