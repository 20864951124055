import { Box, Text, Textarea, Tooltip, Icon, Flex } from "@chakra-ui/react";
import { FC, useState } from "react";
import { CreationTitle, CreationTitleBox } from "../";
import { useTranslation } from "react-i18next";
import { CModal } from "@/components/Modal";
import { CButton } from "@/components/Button";
import { RobotIcon } from "@/pages/tools/WechatRobot";

import styles from './index.module.scss'

interface CreationPromptProps {
  cnPrompt: string
  onInput: (value: string) => void
  systemPromptTip?: string
}
export const CreationSongName: FC<CreationPromptProps> = (props) => {
  const { cnPrompt, onInput, systemPromptTip } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return <Box className={styles.prompt}>
    <Box className={styles.title}>
      <CreationTitleBox className={styles.text}>
        <CreationTitle title={t('creation.SongName')} />
        <Text className={styles.require}>*</Text>
        <Box w={'5px'}></Box>
        {systemPromptTip &&
          <Tooltip>
            <Icon cursor={'pointer'} onClick={() => setOpen(true)} />
          </Tooltip>
        }
      </CreationTitleBox>
    </Box>
    <Box className={styles.inputs}>
      <Textarea value={cnPrompt} className={styles.area} resize='none' maxLength={500} placeholder={t('creation.inputSongName')} onChange={e => onInput(e.target.value)} />
    </Box>
    <CModal isOpen={open} onClose={() => setOpen(false)}>
      <Flex className={styles.notbalancebox}>
        <Flex className={styles.notbalanceTitleBox}>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <RobotIcon />
            <Text>{t('creation.PureMusic')}</Text>
          </Flex>
          <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setOpen(false)} />
        </Flex>
        <Text className={styles.texttips}>{systemPromptTip}</Text>
        <Flex className={styles.modelbuttonbox}>
          <CButton className={styles.Savebutton} onClick={() => setOpen(false)}>
            {t('ISee')}
          </CButton>
        </Flex>
      </Flex>
    </CModal>
  </Box>
}
