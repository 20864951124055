import { Box, Flex, Icon, Text, Image, Textarea, useOutsideClick } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { creationStore, CreationType } from "@/store/creation";
import { observer } from "mobx-react-lite";
import { CreationHeader, CreationInfo, CreationContent, CreationTitle, CreationTitleBox } from "../Components";
import { CButton } from "@/components/Button";
import { getOSSBlobResource, saveBlobToLocal, SHOW_PRICE_SCALE } from "@/utils/common";
import { useMessage } from "@/hooks/useMessage";
import { userStore } from "@/store/user";
import { globalStore } from "@/store/global";
import { useSelectFile } from "@/hooks/useSelectFile";
import heic2any from 'heic2any';
import axios from "axios";
import styles from './index.module.scss'
import { useTranslation } from "react-i18next";
import { ScrollBarBox } from "@/components/ScrollBox";
import { CreationSongContent } from "../Components/SongContent";
import { creatCloneAudios, creatUserVoice, failCloneAudio, deleteCloneAudio, deleteUserVoice, getCloneAudiosList, getexampleAudio, getPublicVoiceList, getUserVoiceList, updateCloneAudios, readAlreadyAudio } from "@/api/creation";
import { CModal } from "@/components/Modal";
import { SliderProps } from "@mui/base";
import { WiseSlider } from "../Components/Reference";
import { ComAudiosHistory } from "@/pages/tools/components/ComAudiosHistory";
import { CloneAudioPreview } from "../Components/CloneAudioPreview";
import React from "react";
import { ComTextarea } from "@/pages/tools/components/ComTextarea";
import CreateBtn from "../Components/CreationBtn";
import { uiStrore } from "@/store/ui";
import { getdate } from "@/pages/tools/components/ContentPreview";

const pageType = CreationType.SUNO;
export const errorMessages = {
  political: 'riskContent.political_n',
  sexual: 'riskContent.sexual_content',
  violent_horrific_tii: 'riskContent.violent_horrific_tii',
  contraband_drug: 'riskContent.contraband_drug',
  contraband_act: 'riskContent.contraband_act',
  profanity: 'riskContent.profanity',
  contraband: 'riskContent.contraband',
  violence: 'riskContent.violence',
  negative: 'riskContent.negative_content',
  inappropriate: 'riskContent.negative_content'
};

const AudioPlayer = ({ id, onPlay, publicSrc, currentPlayingId }
  : {
    id: string; onPlay: (id: string) => void, publicSrc?: string;
    currentPlayingId: any
  }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioSrc, SetAudioSrc] = useState('');
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const message = useMessage();
  const { t } = useTranslation();

  const getUserPlay = async () => {
    const audios = await getexampleAudio({ audioId: id });
    if (audios.status !== 2) {
      message.warning({ title: (audios.status === 0 || audios.status === 1) ? '正在生成中，稍后再试' : '试听失败，点击重试' })
      return;
    }
    SetAudioSrc(audios.result);
    setTimeout(() => {
      togglePlayPause();
    }, 100);
  }
  const getPublicPlay = () => {
    if (!publicSrc) {
      message.warning({ title: '试听失败，点击重试' })
      return;
    }
    SetAudioSrc(publicSrc);
    setTimeout(() => {
      togglePlayPause();
    }, 100);
  }
  const togglePlayPause = async () => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = 0;
      if (audio.paused) {
        audio.play();
        setIsPlaying(true);
        onPlay(id); // 当播放时，调用 onPlay 更新状态
      } else {
        audio.pause();
        setIsPlaying(false);
        onPlay('-1')
      }
    }
  };
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      if (currentPlayingId === id) {
        const handleEnded = () => {
          setIsPlaying(false);
          onPlay('-1')
        };
        audio.addEventListener('ended', handleEnded);
        return () => {
          audio.removeEventListener('ended', handleEnded);
        };
      } else {
        audio.pause();
        setIsPlaying(false);
      }
    }
  }, [currentPlayingId]);

  return (
    <div>
      <CButton className={styles.listeningBox} onClick={() => { publicSrc ? getPublicPlay() : getUserPlay() }}>
        {isPlaying ? t('Pause') : t('Listen')}
      </CButton>
      <audio ref={audioRef} src={audioSrc}></audio>
    </div>
  );
}

const AudioImage = ({ audioSrc, audioName }: { audioSrc: string, audioName: string }) => {
  const firstChar = audioName.charAt(0);
  return (
    <>
      {audioSrc ?
        <Image borderRadius={'14px'} w={'90px'} height={'90px'} src={audioSrc} />
        :
        <Flex borderRadius={'20px'} w={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'} bg={'#f5f5f5'}>
          <Text fontSize={'38px'} color={'#666'} fontWeight={'500'}>{firstChar}</Text>
        </Flex>
      }
    </>
  )
}

const sampleText = [
  '那个新开的餐厅，我上周去吃了两次呢，菜品新鲜，服务态度也很好',
  '春天来了，又能看到五彩缤纷的花朵了，我真的太喜欢在花园里赏花了。',
  '最近读了几本好书，晚上静静看书，沉浸故事中，思绪飞扬。',
  '美是到处都有的。对于我们的眼睛，不是缺少美，而是缺少发现。',
  '轻轻的我走了，正如我轻轻的来；我轻轻的招手，作别西天的云彩。',
  '落叶飘零，那是它们生命的最后演绎，为大地铺上一层金黄色的华彩。',
  '只那么一瞬，便分出此岸和彼岸，便有了永恒的距离不可跨越。一脚门里，一脚门外。',
  '每个人都有青春，每个青春都有故事，每个故事都有遗憾，每个遗憾都有回味不尽的美。',
  '如果你来访我，我不在，请和门外的花坐一会，它们很温暖，我注视它们很多很多日子了。',
  '明年有明年的雪，明年的雾色，明年的永无休止的阳光，还有明年数不尽的生机。',
  '喜爱雨后，万物洗净铅华，空气中有树叶与泥土混合湿润的味道，让人无比安心。',
]

export const CloneAudios: FC = observer(() => {
  const { init } = creationStore[pageType];
  const [loading, setLoading] = useState(false);
  const [audioText, setAudioText] = useState('');
  const [selectVoice, SetSelectVoice] = useState<any>(null)
  const [modelselectVoice, SetmodelSelectVoice] = useState<any>(true)
  const [isPublicVoice, SetIsPublicVoice] = useState(false);
  const [userVoicelist, SetUserVoicelist] = useState<any[]>([])
  const [publicVoicelist, SetPublicVoicelist] = useState<any[]>([])
  const [addopen, SetAddopen] = useState(false)
  const [fillInopen, SetFillInopen] = useState(false)
  const [listopen, SetListopen] = useState(false)
  const [editopen, SetEditopen] = useState(false)
  const [addinformationopen, SetAddInformationopen] = useState(false)
  const [isVoiceAction, SetIsVoiceAction] = useState(false)
  const [chooseFile, setChooseFile] = useState<any>(null)
  const { t } = useTranslation();
  const [temperature, setTemperature] = useState(1)
  const [audioshis, SetAudiosHis] = useState<any[]>([])
  const [viewSwiper, setViewSwiper] = useState(false);
  const [viewInfo, SetViewInfo] = useState<any>(null!);
  const { enableKeys } = creationStore.publicStore;

  const { userInfo } = userStore;
  const { setLoginIsOpen } = globalStore;
  const message = useMessage();

  const marks: SliderProps['marks'] = [
    { value: 0.6, label: t('Slower') },
    { value: 1.6, label: t('Faster') },
  ]
  const AudioWave = (isplay: boolean) => {
    return (
      <div className={styles.audioWave}>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
        <Box className={styles.bar} bg={isplay ? '#565CFA' : '#fff'}></Box>
      </div>
    );
  };

  const ExampleWave = () => {
    return (
      <div className={styles.ExampleWave}>
        <Box className={styles.bigbar}></Box>
        <Box className={styles.bigbar} ></Box>
        <Box className={styles.bigbar} ></Box>
        <Box className={styles.bigbar} ></Box>
      </div>
    );
  };

  const gethis = async () => {
    const his = await getCloneAudiosList({ pageNum: 1, pageSize: 999 });
    SetAudiosHis(his.data)
  }

  useEffect(() => {
    init();
    const fet = async () => {
      //获取历史
      const uservoice = await getUserVoiceList();
      const publicvoice = await getPublicVoiceList();
      gethis()
      SetPublicVoicelist(publicvoice)
      SetUserVoicelist(uservoice)
    }
    fet();
  }, [])

  // 下载音频
  const download = async (url: string) => {
    if (!url) return;
    try {
      let date = getdate();
      const suggestedName = `创作_声音克隆_${moreEdit.current?.audioName}的声音_${date}.mp3`
      const res = await getOSSBlobResource(url);
      await saveBlobToLocal(res, suggestedName);
      message.success({ title: t('DownloadSuccess') });
    } catch (err: any) {
      if (err.message !== 'The user aborted a request.') {
        message.error({ title: t('DownloadFailed') });
      }
    }
  }

  //上传音频到oss
  const uploadFiles = (e: File) => {
    const formData = new FormData();
    formData.append('file', e)
    return axios.post(
      (window.proxyApi || '') + '/api/system/uploadToAliOss',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      }
    );
  }

  //创建个人声音
  const createVoice = async () => {
    if (loading || !selectVoice) return;
    setLoading(true);
    try {
      if (!userInfo) {
        message.warning({ title: t('LoginFirst') });
        setLoginIsOpen(true);
        return;
      }
      if ((selectVoice && selectVoice.voiceName.length < 1) || (selectVoice && !selectVoice.voiceName)) {
        message.warning({ title: t('fillvoiceName') });
        return;
      }
      if (userVoicelist.length >= 20) {
        message.tips({ title: t('voiceMaxlength') });
        return;
      }
      let auditionFile = null;
      let cover = null;
      if (typeof selectVoice.auditionFile !== 'string') {
        const audition = await uploadFiles(selectVoice.auditionFile);
        auditionFile = audition.data.data[0]
      } else {
        auditionFile = selectVoice.auditionFile
      }
      if (typeof selectVoice.voiceCover !== 'string') {
        const cov = await uploadFiles(selectVoice.voiceCover);
        cover = cov.data.data[0]
      } else {
        cover = selectVoice.voiceCover
      }
      const rets = await creatUserVoice({
        voiceCover: cover,
        voiceText: selectVoice.voiceText,
        voiceName: selectVoice.voiceName,
        description: selectVoice.description,
        auditionFile: auditionFile,
      }) as any;
      if (rets) {
        if (rets.message) {
          message.error({ title: rets.message })
          return;
        }
        message.success({ title: t('CreateSuccess') })
        const uservoice = await getUserVoiceList();
        SetUserVoicelist(uservoice)
      } else {
        message.warning({ title: t('CreateFaild') })
      }
    } catch (err: any) {
      message.error({ title: '创建失败，请检查参数是否正确' });
      console.log(err.message)
    } finally {
      setLoading(false);
    }
  }
  //开始创作
  const createTask = async () => {
    if (loading || isDisable) return;
    setLoading(true);
    try {
      if (!userInfo) {
        message.warning({ title: t('LoginFirst') });
        setLoginIsOpen(true);
        return;
      }
      if (!userInfo.monVip && (userInfo.balance * SHOW_PRICE_SCALE) < 20) {
        message.warning({ title: t('creation.DrawFaildBalance') });
        return;
      }
      const rets = await creatCloneAudios({
        audiosUrl: selectVoice.auditionFile,
        audioPrompt: selectVoice.description,
        audioName: selectVoice.voiceName,
        audioText: audioText,
        speed: temperature,
        audioId: selectVoice._id,
        audioCover: selectVoice.voiceCover,
        voiceText: selectVoice.voiceText,
        notpublic: !isPublicVoice
      }) as any;
      if (rets) {
        if (rets.message) {
          message.error({ title: rets.message })
          return;
        }
        message.success({ title: t('CreateSuccess') })
        Setupdatehis(true)
      } else {
        message.warning({ title: t('CreateFaild') })
      }
    } catch (err: any) {
      for (const [key, messageKey] of Object.entries(errorMessages)) {
        if (err.message.includes(key)) {
          message.error({ title: `您的输入包含${t(messageKey)},请修改后重试` });
          return; // 一旦找到匹配项，立即退出函数
        }
      }
      message.error({ title: err.message || '创建任务失败，请稍后再试！' });
    } finally {
      setLoading(false);
    }
  }

  const userRef = useRef<HTMLDivElement>(null);
  const [userIsLeft, setUserIsLeft] = useState(false);
  const [userIsRight, setUserIsRight] = useState(false);
  const updateButtonVisibility = () => {
    if (userRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = userRef.current;
      setUserIsLeft(scrollLeft > 0);
      setUserIsRight(scrollLeft + clientWidth < scrollWidth - 1);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      updateButtonVisibility();
      window.addEventListener('resize', updateButtonVisibility);
    }, 500);
    return () => window.removeEventListener('resize', updateButtonVisibility);
  }, [isPublicVoice]);

  const scrollLeft = useCallback(() => {
    if (userRef.current) {
      userRef.current.scrollLeft -= 100; // Adjust the scroll amount as needed
      setTimeout(updateButtonVisibility, 300);
    }
  }, []);

  const scrollRight = useCallback(() => {
    if (userRef.current) {
      userRef.current.scrollLeft += 100;
      setTimeout(updateButtonVisibility, 300);
    }
  }, []);

  const systemRef = useRef<HTMLDivElement>(null);
  const [systemIsLeft, setSystemIsLeft] = useState(false);
  const [systemIsRight, setSystemIsRight] = useState(false);
  const updateSystemVisibility = () => {
    if (systemRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = systemRef.current;
      setSystemIsLeft(scrollLeft > 0);
      setSystemIsRight(scrollLeft + clientWidth < scrollWidth - 1);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      updateSystemVisibility();
      window.addEventListener('resize', updateSystemVisibility);
    }, 500);
    return () => window.removeEventListener('resize', updateSystemVisibility);
  }, [isPublicVoice]);

  const systemscrollLeft = useCallback(() => {
    if (systemRef.current) {
      systemRef.current.scrollLeft -= 100; // Adjust the scroll amount as needed
      setTimeout(updateSystemVisibility, 300);
    }
  }, []);

  const systemscrollRight = useCallback(() => {
    if (systemRef.current) {
      systemRef.current.scrollLeft += 100;
      setTimeout(updateSystemVisibility, 300);
    }
  }, []);

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const chunks: Blob[] = [];
      recorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };
      recorder.onstop = async () => {
        const wavBlob = new Blob(chunks, { type: 'audio/wav' });
        setAudioChunks(chunks);
        SetSelectVoice((prevState: any) => ({
          ...prevState,
          auditionFile: wavBlob,
        }));
      };
      recorder.start();
      setMediaRecorder(recorder);
    } catch (error) {
      console.error('Error accessing microphone', error);
    }
  };

  const stopRecording = async () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    SetAddopen(false);
    SetFillInopen(true)
  };

  const { File: FileElement, onOpen: onOpenSelectFile } = useSelectFile({
    fileType: '.jpeg,.jpg,.png,.heic,.bmp,.webp,.heif',
    multiple: false
  });
  const onSelectFile = useCallback(async (e: File[]) => {
    setLoading(true);
    try {
      const file = e[0];
      if (!file) return;
      let blob = file;

      if (file.type === 'image/heic' || file.type === 'image/heif') {
        let convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
        if (Array.isArray(convertedBlob)) {
          convertedBlob = convertedBlob[0];
        }
        const newFileName = `${file.name.split('.')[0]}.heic`;
        blob = new File([convertedBlob], newFileName, {
          type: 'image/heic',
          lastModified: Date.now(), // 或使用file.lastModified保留原时间
        });
      }

      let img = document.createElement('img')
      img.src = URL.createObjectURL(blob)
      img.onload = async () => {
        document.getElementById('chooseFile')?.setAttribute('src', URL.createObjectURL(blob))
        setChooseFile(blob);
        const cov = await uploadFiles(blob);
        const cover = cov.data.data[0]
        SetSelectVoice((prevState: any) => ({
          ...prevState,
          voiceCover: cover
        }));
        setLoading(false)
      };
    } catch (e) {
      console.log(e);
      setLoading(false)
    }
  }, [])

  const [morePosition, setMorePosition] = useState<undefined | { x: number, y: number, isFirstEdit: boolean, index: number }>();
  const moreEdit = useRef<any>();
  const editRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: editRef,
    handler: () => {
      setMorePosition(undefined);
    }
  })
  // 点击显示更多操作
  const onMore = (e: React.MouseEvent<SVGElement, MouseEvent>, index: number, item: any) => {
    e.stopPropagation();
    let isFirstEdit = false;
    if (item.status === 2) {
      isFirstEdit = true
    }
    //@ts-ignore
    let { x, y } = e.target.getBoundingClientRect();
    const _BoxHeigh = 150, _BoxWidth = 140;
    if (e.nativeEvent.y + _BoxHeigh > window.innerHeight) {
      y = y - _BoxHeigh;
    } else {
      y = y + 15;
    }
    if (e.nativeEvent.x + _BoxWidth > window.innerWidth) {
      x = x - _BoxWidth;
    } else {
      x = x + 15;
    }
    setMorePosition({ x, y, isFirstEdit, index });
    moreEdit.current = item;
  }
  const [isPlaying, setIsPlaying] = useState(false);
  const [playAudio, SetPlayAudio] = useState('');
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const togglePlayPause = async (url: string) => {
    SetPlayAudio('')
    const audios = await getexampleAudio({ audioId: url });
    if (audios.status !== 2) {
      message.warning({ title: (audios.status === 0 || audios.status === 1) ? '正在生成中，稍后再试' : '试听失败，点击重试' })
      return;
    }
    SetPlayAudio(audios.result);
  };

  useEffect(() => {
    const audio = audioRef.current;

    if (audio && playAudio) {
      const handleEnded = () => {
        setIsPlaying(false);
      };
      const handlePlay = () => {
        setIsPlaying(true);
      };
      audio.addEventListener('ended', handleEnded);
      audio.addEventListener('play', handlePlay);
      audio.play();
      return () => {
        audio.removeEventListener('ended', handleEnded);
        audio.removeEventListener('play', handlePlay);
      };
    }
  }, [playAudio]);

  const [updatehis, Setupdatehis] = useState(false);
  const intervalIdRef = useRef<any>(null);
  const activeIdsRef = useRef<string[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      const his = await getCloneAudiosList({ pageNum: 1, pageSize: 999 });
      SetAudiosHis(his.data);
      gethis();
      // 保存状态为0或1的ID
      const newActiveIds = his.data
        .filter((item: any) => item.status === 0 || item.status === 1)
        .map((item: any) => item._id);
      if (activeIdsRef.current.length < 1) {
        // 更新activeIdsRef中的ID
        activeIdsRef.current = newActiveIds;
      }
      // 检查这些ID的状态是否变为3
      const hasFailStatus = his.data.some((item: any) => {
        return activeIdsRef.current.includes(item._id) && item.status === 3;
      });
      if (hasFailStatus) {
        await failCloneAudio();
        // 清空已处理的ID
        activeIdsRef.current = [];
      }
      const hasActiveStatus = his.data.some((item: any) => item.status === 1 || item.status === 0);
      if (hasActiveStatus === false && intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        Setupdatehis(false);
      }
    };
    if (updatehis) {
      intervalIdRef.current = setInterval(fetchData, 3000); // 每5秒查询一次
      fetchData(); // 初次加载数据
    }
    return () => clearInterval(intervalIdRef.current); // 清理函数停止轮询
  }, [updatehis])
  const updateAudio = async (item: any) => {
    try {
      const res = await updateCloneAudios({
        voiceCover: item.voiceCover ? item.voiceCover : '',
        description: item.description ? item.description : '',
        voiceName: item.voiceName,
        _id: item._id
      })
      if (res) {
        message.success({ title: '编辑成功！' })
        const uservoice = await getUserVoiceList();
        SetUserVoicelist(uservoice)
      }
    } catch (e) {
      console.log(e);
      setLoading(false)
    }
  }
  //返回历史记录
  const setViewhistory = async (item: any) => {
    SetIsPublicVoice(!item.notpublic)
    if (item.notpublic) {
      userVoicelist.map((it) => {
        if (it._id === item.audioId) { SetSelectVoice(it); }
      })
    } else {
      publicVoicelist.map((it) => {
        if (it._id === item.audioId) { SetSelectVoice(it); }
      })
    }
    setAudioText(item.data.text)
    setTemperature(item.data.speed)
  }
  //删除个人声音
  const deleteAudio = async (voiceid: any) => {
    try {
      const res = await deleteUserVoice({
        _id: voiceid
      })
      if (res) {
        message.success({ title: t('deleteSuccess') })
        const uservoice = await getUserVoiceList();
        SetUserVoicelist(uservoice)
      }
    } catch (e) {
      console.log(e);
      setLoading(false)
    }
  }
  const [randomIndex, SetRandomIndex] = useState(0)
  useEffect(() => {
    const index = Math.floor(Math.random() * sampleText.length);//随机示例文本
    SetRandomIndex(index)
  }, [addopen])
  const [activeAudioId, setActiveAudioId] = useState<string | null>(null); // 用来跟踪正在播放的音频
  const handleAudioPlay = (id: string) => {
    setActiveAudioId(id); // 更新正在播放的音频 ID
  };

  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    let interval: string | number | NodeJS.Timer | undefined;
    if (isVoiceAction) {
      interval = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1);
      }, 1000);

      if (seconds >= 10) {
        clearInterval(interval);
        SetIsVoiceAction(false);
        stopRecording();
        message.warning({ title: '超过最大时长，已自动上传' });
      }
    } else {
      setSeconds(0);
    }
    return () => clearInterval(interval);
  }, [isVoiceAction, seconds]);
  const handleStopRecording = () => {
    if (seconds < 3) {
      SetIsVoiceAction(false);
      message.warning({ title: '录制时间过短，请重新录制' });
    } else {
      SetIsVoiceAction(false);
      SetSelectVoice((prevState: any) => ({
        ...prevState,
        voiceText: sampleText[randomIndex]
      }))
      stopRecording();
    }
  };
  const formatTime = (secs: any) => {
    const minutes = String(Math.floor(secs / 60)).padStart(2, '0');
    const remainingSeconds = String(secs % 60).padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };

  const reset = () => {
    setAudioText('');
    SetSelectVoice(null);
    SetIsPublicVoice(false);
    setTemperature(1);
  }

  const isDisable = !audioText || !selectVoice;

  return <CreationContent.Content>
    <CreationContent.Inputs>
      <CreationHeader icon='draw_clone_000' text={t('cloneAudio')} />
      <Box className={styles.container}>
        <ScrollBarBox className={styles.content}>
          <Flex className={styles.titleBox}>
            <Text className={styles.title}>{t('VoiceSelection')}<span>*</span></Text>
            <Flex w={'100%'} justifyContent={'space-between'} mb={'20px'}>
              <Flex alignItems={'center'}>
                <Box className={isPublicVoice ? styles.leftBox : styles.leftBoxs}
                  onClick={() => {
                    SetIsPublicVoice(false); setActiveAudioId(null); if (systemRef.current) {
                      systemRef.current.scrollLeft = 0;
                    }
                  }}>{t('MyVoice')}</Box>
                <Box className={isPublicVoice ? styles.rightBox : styles.rightBoxs}
                  onClick={() => {
                    SetIsPublicVoice(true); setActiveAudioId(null); if (userRef.current) {
                      userRef.current.scrollLeft = 0;
                    }
                  }}>{t('PublicVoice')}</Box>
              </Flex>
              <Flex justifyContent={'center'} alignItems={'center'} cursor={'pointer'} onClick={() => {
                if (isPublicVoice) {
                  SetmodelSelectVoice(false)
                } else {
                  SetmodelSelectVoice(true)
                }
                SetListopen(true)
              }}>
                <Text>{isPublicVoice ? t('More') : t('ManageVoice')}</Text>
                <Icon as={require('@/assets/svg/nextVoice.svg').ReactComponent} />
              </Flex>
            </Flex>
            <Flex>
              {isPublicVoice ?
                <Flex className={styles.publicBoxWrapper} >
                  <Box className={styles.scrollButton} bottom={'50%'} left={'-35px'} onClick={systemscrollLeft}
                    style={{ display: systemIsLeft ? 'flex' : 'none' }}>
                    <Icon as={require('@/assets/svg/nextVoice.svg').ReactComponent} style={{ transform: 'rotate(180deg)' }} />
                  </Box>
                  <Flex className={styles.publicBox} ref={systemRef}>
                    {publicVoicelist.map((item, index) => (
                      <Box key={index} className={styles.newBox} >
                        <Box border={(selectVoice && selectVoice._id === item._id) ? '2px solid #565CFA ' : '2px solid #fff'}
                          className={styles.createNew} onClick={() => SetSelectVoice(item)}>
                          {activeAudioId === item._id ? ExampleWave() : null}
                          <AudioImage audioSrc={item.voiceCover} audioName={item.voiceName} />
                          {(selectVoice && selectVoice._id === item._id) &&
                            <Icon as={require('@/assets/svg/selectVoice.svg').ReactComponent} />
                          }
                        </Box>
                        <Text className={styles.voiceName}>{item.voiceName}</Text>
                        <AudioPlayer key={index}
                          publicSrc={item.auditionFile}
                          id={item._id}
                          currentPlayingId={activeAudioId}
                          onPlay={handleAudioPlay}
                        />
                      </Box>
                    ))}
                  </Flex>
                  <Box className={styles.scrollButton} bottom={'50%'} right={'-35px'} onClick={systemscrollRight}
                    style={{ display: systemIsRight ? 'flex' : 'none' }}>
                    <Icon as={require('@/assets/svg/nextVoice.svg').ReactComponent} />
                  </Box>
                </Flex>
                :
                <Flex className={styles.publicBoxWrapper} >
                  <Box className={styles.scrollButton} bottom={'50%'} left={'-35px'} onClick={scrollLeft}
                    style={{ display: userIsLeft ? 'flex' : 'none' }}>
                    <Icon as={require('@/assets/svg/nextVoice.svg').ReactComponent} style={{ transform: 'rotate(180deg)' }} />
                  </Box>
                  <Flex className={styles.publicBox} ref={userRef}>
                    <Box className={styles.newBox} onClick={() => { { SetSelectVoice(null); SetAddopen(true) } }}>
                      <Box className={styles.createNew}>
                        <Icon className={styles.createIcon} as={require('@/assets/svg/addVoice.svg').ReactComponent} />
                      </Box>
                      <Text className={styles.voiceName}>{t('CreatingSounds')}</Text>
                      <Text mt={'10px'} className={styles.voiceName}>（{userVoicelist.length}/20）</Text>
                    </Box>
                    {userVoicelist.map((item, index) => (
                      <Box key={index} className={styles.newBox}>
                        <Box border={(selectVoice && selectVoice._id === item._id) ? '2px solid #565CFA ' : '2px solid #fff'}
                          className={styles.createNew} onClick={() => SetSelectVoice(item)}>
                          {activeAudioId === item._id ? ExampleWave() : null}
                          <AudioImage audioSrc={item.voiceCover} audioName={item.voiceName} />
                          {(selectVoice && selectVoice._id === item._id) &&
                            <Icon as={require('@/assets/svg/selectVoice.svg').ReactComponent} />
                          }
                        </Box>
                        <Text className={styles.voiceName}>{item.voiceName}</Text>
                        <AudioPlayer key={index}
                          id={item._id} // 传递音频 ID
                          onPlay={handleAudioPlay} // 传递播放函数
                          currentPlayingId={activeAudioId}
                        />
                      </Box>
                    ))}
                  </Flex>
                  <Box className={styles.scrollButton} bottom={'50%'} right={'-35px'} onClick={scrollRight}
                    style={{ display: userIsRight ? 'flex' : 'none' }}>
                    <Icon as={require('@/assets/svg/nextVoice.svg').ReactComponent} />
                  </Box>
                </Flex>
              }
            </Flex>
          </Flex>
          <CreationSongContent
            title={t('配音文本')} placeholder={t('请输入您想要使用该声音念出的文本')}
            cnPrompt={audioText}
            onInput={setAudioText}
            isRequired={true}
            systemPromptTip={t('语音质量取决于录制声音。为获得最佳效果，请使用高质量声音。严禁合成涉及政治、色情、赌博、毒品等违法违规内容。')}
          />
          <Flex className={styles.progressBox}>
            <Text className={styles.title}>{t('语速调整')}
              {temperature > 1.2 && <span>{t('（生成语音的语速较快）')}</span>}
              {(temperature > 0.8 && temperature < 1.3) && <span>{t('（生成语音的语速正常）')}</span>}
              {temperature < 0.9 && <span>{t('（生成语音的语速缓慢）')}</span>}
            </Text>
            <Box className={styles.slider}>
              <WiseSlider min={0.6} max={1.6} step={0.1} marks={marks} value={temperature} onChange={(_: any, value: any) => setTemperature(value as number)} />
            </Box>
          </Flex>
        </ScrollBarBox>
      </Box>
      <Box className={styles.create}>
        <CreateBtn
          loading={loading}
          isMaintain={!enableKeys['shengyingkeloig']}
          disabled={isDisable}
          onClick={createTask}
          price={50}
          isVip={userInfo?.monVip}
          icon={<Icon as={require('@/assets/svg/create_audio.svg').ReactComponent} />}
          onReset={reset}
          onVip={() => uiStrore.setOpenVip(true)}
        />
      </Box>
    </CreationContent.Inputs>
    <Flex className={styles.previewBox}>
      <ComAudiosHistory
        key={'cloneaudis'}
        historyCache={audioshis}
        onclick={async (item: any) => {
          SetViewInfo(item);
          moreEdit.current = item;
          setViewSwiper(true);
          if (item.status === 2 && item.isNotReading === true) {
            await readAlreadyAudio({ audiotype: 'clone', _id: item._id });
            gethis();
          }
        }}
        onMore={(e: any, index: any, item: any) => { e.stopPropagation(); onMore(e, index, item) }}
      />
    </Flex>
    <CModal isOpen={addopen} onClose={() => { SetIsVoiceAction(false); SetAddopen(false); }}>
      <Flex className={styles.cmodelbox}>
        <Flex className={styles.cmodelTitleBox}>
          <Text>声音录制</Text>
          <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => { SetIsVoiceAction(false); SetAddopen(false); }} />
        </Flex>
        <Flex className={styles.cmodelContents}>
          <Text className={styles.cmodelTitle}>{isVoiceAction ? '录制中，请朗读...' : '请朗读'}</Text>
          <Text className={styles.cmodelContent}>
            {sampleText[randomIndex]}
          </Text>
        </Flex>
        <Flex className={styles.modelbuttonbox}>
          {isVoiceAction &&
            <Text mb={'20px'}>{formatTime(seconds)}</Text>
          }
          {isVoiceAction &&
            <Text className={styles.modeltips}>点击停止录制（3s-10s）</Text>
          }
          {isVoiceAction ?
            <CButton className={styles.Savebutton} onClick={handleStopRecording} >
              {AudioWave(false)}
            </CButton>
            :
            <CButton className={styles.Savebutton} onClick={() => { SetIsVoiceAction(true); startRecording() }} >
              <Icon as={require('@/assets/svg/voiceMic.svg').ReactComponent} />
              点击录制
            </CButton>
          }
          <Text className={styles.modeltips}><span>*</span> 您可以朗读自己的文本或上面的建议文本来录制您的声音，朗读3-10秒即可，请确保录入时环境安静，声音清晰无较多杂音。</Text>
        </Flex>
      </Flex>
    </CModal>
    <CModal isOpen={fillInopen} onClose={() => SetFillInopen(false)}>
      <Flex className={styles.cmodelbox}>
        <Flex className={styles.cmodelTitleBox}>
          <Text>声音录制</Text>
          <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => SetFillInopen(false)} />
        </Flex>
        <Flex className={styles.cmodelFillIn}>
          <Icon className={styles.fillinicon} as={require('@/assets/svg/hdrSucess.svg').ReactComponent} />
          <Text className={styles.fillintext}>录制成功</Text>
        </Flex>
        <Flex className={styles.modelbuttonbox}>
          <Text className={styles.modeltips}>前往填写声音信息后，即可使用</Text>
          <CButton className={styles.Savebutton} onClick={() => { SetFillInopen(false); setChooseFile(null); SetAddInformationopen(true) }} >
            前往填写
          </CButton>
        </Flex>
      </Flex>
    </CModal>
    <CModal isOpen={addinformationopen} onClose={() => SetAddInformationopen(false)}>
      <Flex className={styles.cmodelbox}>
        <Flex className={styles.cmodelTitleBox}>
          <Text>声音信息</Text>
          <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => SetAddInformationopen(false)} />
        </Flex>
        <Flex className={styles.cmodelContents}>
          <Text className={styles.ptitle} w={'556px'}>上传封面</Text>
          <Flex alignItems={'center'} w={'556px'} mb={'10px'}>
            {chooseFile && (
              <Image borderRadius={'20px'} mr={'10px'} w={'90px'} height={'90px'} src={URL.createObjectURL(chooseFile)} />
            )}
            <Flex>
              <Box className={styles.uploadBox} onClick={onOpenSelectFile}>
                <Box>
                  <Icon as={require('@/assets/svg/voiceUpload.svg').ReactComponent} />
                </Box>
              </Box>
            </Flex>
          </Flex>
          <ComTextarea
            title={'声音名称'}
            prompt={''}
            textvalue={(selectVoice && selectVoice.voiceName) ? selectVoice.voiceName : ''}
            textchange={(e: any) => {
              SetSelectVoice((prevState: any) => ({
                ...prevState,
                voiceName: e
              }));
            }}
            maxCount={20}
            rows={1}
            required={true}
          />
          <ComTextarea
            title={'描述'}
            prompt={''}
            textvalue={(selectVoice && selectVoice.description) ? selectVoice.description : ''}
            textchange={(e: any) => {
              SetSelectVoice((prevState: any) => ({
                ...prevState,
                description: e
              }));
            }}
            maxCount={500}
            rows={4}
          />
        </Flex>
        <Flex className={styles.modelbuttonbox}>
          <CButton className={styles.Savebutton} onClick={() => {
            if (!selectVoice || !selectVoice.voiceName || selectVoice.voiceName.trim() === '') {
              message.error({ title: '保存失败，声音名称不可为空' });
              return;
            }
            SetAddInformationopen(false); createVoice();
          }} >
            确定
          </CButton>
        </Flex>
      </Flex>
    </CModal>
    <CModal isOpen={listopen} onClose={() => { setActiveAudioId('-1'); SetListopen(false) }}>
      <Flex className={styles.cmodelbox}>
        <Flex className={styles.cmodelTitleBox}>
          <Flex >
            <Box className={modelselectVoice ? styles.selectVoiceselect : styles.selectVoice} mr={'10px'}
              onClick={() => SetmodelSelectVoice(true)}>
              <Text>声音管理</Text>
              {modelselectVoice &&
                <Icon className={styles.icon} as={require('@/assets/svg/chat_header_active.svg').ReactComponent} />
              }
            </Box>
            <Box className={modelselectVoice ? styles.selectVoice : styles.selectVoiceselect}
              onClick={() => SetmodelSelectVoice(false)}>
              <Text>公开声音</Text>
              {!modelselectVoice &&
                <Icon className={styles.icon} as={require('@/assets/svg/chat_header_active.svg').ReactComponent} />
              }
            </Box>
          </Flex>
          <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => { setActiveAudioId('-1'); SetListopen(false) }} />
        </Flex>
        <Flex className={styles.input_group}>
          {modelselectVoice ?
            userVoicelist.length > 0 ? (userVoicelist.map((item, index) => (
              <Box key={index} className={styles.item}>
                <Box border={(selectVoice && selectVoice._id === item._id) ? '2px solid #565CFA' : '2px solid rgba(255, 255, 255, 0.5)'}
                  className={styles.createItem} onClick={() => SetSelectVoice(item)}>
                  {activeAudioId === item._id ? ExampleWave() : null}
                  <AudioImage audioSrc={item.voiceCover} audioName={item.voiceName} />
                  {(selectVoice && selectVoice._id === item._id) &&
                    <Icon className={styles.selectIcon} as={require('@/assets/svg/selectVoice.svg').ReactComponent} />
                  }
                </Box>
                <Text className={styles.name}>{item.voiceName}</Text>
                <Flex>
                  <AudioPlayer key={index}
                    id={item._id}
                    currentPlayingId={activeAudioId}
                    onPlay={handleAudioPlay}
                  />
                  <CButton ml={'5px'} className={styles.listeningBox} onClick={() => { SetSelectVoice(item); SetListopen(false); SetEditopen(true) }}>编辑</CButton>
                </Flex>
              </Box>
            ))) : (
              <Flex w={'100%'} h={'100%'} justify={'center'} alignItems={'center'} flexDir={'column'}>
                <Image w={'120px'} h={'120px'} src={require('@/assets/png/nullUserVoice.png')} />
                <Text my={'10px'} fontSize={'14px'} color={'#ccc'}>当前暂无可用声音</Text>
                <CButton className={styles.creatbutton} onClick={() => { SetListopen(false); SetAddopen(true) }}>
                  <Icon as={require('@/assets/svg/addUserVoice.svg').ReactComponent} />
                  创建我的声音
                </CButton>
              </Flex>
            ) : publicVoicelist.map((item, index) => (
              <Box key={index} className={styles.item}>
                <Box border={(selectVoice && selectVoice._id === item._id) ? '2px solid #565CFA' : '2px solid rgba(255, 255, 255, 0.5)'}
                  className={styles.createItem} onClick={() => SetSelectVoice(item)}>
                  {activeAudioId === item._id ? ExampleWave() : null}
                  <AudioImage audioSrc={item.voiceCover} audioName={item.voiceName} />
                  {(selectVoice && selectVoice._id === item._id) &&
                    <Icon className={styles.selectIcon} as={require('@/assets/svg/selectVoice.svg').ReactComponent} />
                  }
                </Box>
                <Text className={styles.name}>{item.voiceName}</Text>
                <AudioPlayer key={index}
                  publicSrc={item.auditionFile}
                  id={item._id}
                  currentPlayingId={activeAudioId}
                  onPlay={handleAudioPlay}
                />
              </Box>
            ))
          }
        </Flex>
        {modelselectVoice ?
          userVoicelist.length > 0 ?
            <Flex className={styles.modelbuttonBox}>
              <CButton border={'1px solid #999'} bg={'transparent'} style={{ width: '200px' }} onClick={() => { { setActiveAudioId('-1'); SetListopen(false) } SetAddopen(true) }} >
                <Icon className={styles.addVoiceIcon} as={require('@/assets/svg/addUserVoice.svg').ReactComponent} />
                创建我的声音（{userVoicelist.length}/20）
              </CButton>
              <CButton isDisabled={!listopen} className={styles.Savebutton} onClick={() => { setActiveAudioId('-1'); SetListopen(false) }}>
                确认
              </CButton>
            </Flex>
            :
            null
          :
          <Flex className={styles.modelbuttonBox}>
            <CButton className={styles.Cancelbutton} onClick={() => { setActiveAudioId('-1'); SetListopen(false) }} >
              取消
            </CButton>
            <CButton isDisabled={!listopen} className={styles.Savebutton} onClick={() => { setActiveAudioId('-1'); SetListopen(false) }}>
              确认
            </CButton>
          </Flex>
        }
      </Flex>
    </CModal>
    <CModal isOpen={editopen} onClose={() => SetEditopen(false)}>
      <Flex className={styles.cmodelbox}>
        <Flex className={styles.cmodelTitleBox}>
          <Flex alignItems={'center'} cursor={'pointer'} onClick={() => { SetEditopen(false); SetListopen(true) }}>
            <Icon w={'22px'} h={'22px'} mr={'8px'} as={require('@/assets/svg/personalNavRight.svg').ReactComponent}
              style={{
                transform: 'rotate(180deg)'
              }} />
            <Text>编辑声音</Text>
          </Flex>
          <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => SetEditopen(false)} />
        </Flex>
        <Flex className={styles.cmodelContents} w={'556px'} mx={'auto'}>
          <Text className={styles.ptitle} w={'556px'}>上传封面</Text>
          <Flex alignItems={'center'} w={'556px'} mb={'10px'}>
            <Flex w={'90px'} h={'90px'}>
              {selectVoice ?
                <AudioImage audioSrc={selectVoice.voiceCover} audioName={selectVoice.voiceName} />
                : chooseFile ? (
                  <Image w={'60px'} height={'60px'} src={URL.createObjectURL(chooseFile)} />
                ) : <></>
              }
            </Flex>
            {/* {chooseFile && (
              <Image w={'60px'} height={'60px'} src={URL.createObjectURL(chooseFile)} />
            )} */}
            <Flex ml={'10px'}>
              <Box className={styles.uploadBox} onClick={onOpenSelectFile}>
                <Box>
                  <Icon as={require('@/assets/svg/voiceUpload.svg').ReactComponent} />
                </Box>
              </Box>
            </Flex>
          </Flex>
          <ComTextarea
            title={'声音名称'}
            prompt={''}
            textvalue={(selectVoice && selectVoice.voiceName) ? selectVoice.voiceName : ''}
            textchange={(e: any) => {
              SetSelectVoice((prevState: any) => ({
                ...prevState,
                voiceName: e
              }));
            }}
            maxCount={20}
            rows={1}
            required={true}
          />
          <ComTextarea
            title={'描述'}
            prompt={''}
            textvalue={(selectVoice && selectVoice.description) ? selectVoice.description : ''}
            textchange={(e: any) => {
              SetSelectVoice((prevState: any) => ({
                ...prevState,
                description: e
              }));
            }}
            maxCount={500}
            rows={4}
          />
        </Flex>
        <Flex className={styles.modelcenbutton}>
          <CButton className={styles.editButton} onClick={() => { if (isPlaying) { SetPlayAudio(''); setIsPlaying(false) } else { togglePlayPause(selectVoice._id) } }}>
            {isPlaying ? AudioWave(true) : '点击进行效果试听'}
          </CButton>
          <audio ref={audioRef} src={playAudio}></audio>
        </Flex>
        <Flex className={styles.modelcenbutton} >
          <CButton w={'130px'} className={styles.Cancelbutton} onClick={() => { SetEditopen(false); deleteAudio(selectVoice._id) }} >
            删除
          </CButton>
          <CButton className={styles.Savebutton} onClick={() => {
            if (!selectVoice || !selectVoice.voiceName || selectVoice.voiceName.trim() === '') {
              message.error({ title: '保存失败，声音名称不可为空' });
              return;
            }
            SetEditopen(false);
            updateAudio(selectVoice);
          }}>
            确认
          </CButton>
        </Flex>
      </Flex>
    </CModal>
    <FileElement onSelect={onSelectFile} />
    {
      morePosition && <Box ref={editRef} className={styles.moreEdit} style={{ left: morePosition.x, top: morePosition.y }}>
        {morePosition.isFirstEdit &&
          <Box
            className={styles.moreItem}
            onClick={() => {
              download(moreEdit.current?.result || '');
              setMorePosition(undefined)
            }}
          >
            <Icon as={require('@/assets/svg/creation_download.svg').ReactComponent} />
            <Text>{t('downloadvoice')}</Text>
          </Box>
        }
        <Box className={styles.moreItem} onClick={() => {
          setViewhistory(moreEdit.current);
          setMorePosition(undefined)
        }}>
          <Icon as={require('@/assets/svg/song_regenerate.svg').ReactComponent} />
          <Text>{t('Regenerate')}</Text>
        </Box>
        <Box className={styles.deletemoreItem}
          onClick={async () => {
            setMorePosition(undefined);
            await deleteCloneAudio({ _id: moreEdit.current?._id || '' });
            gethis();
            message.success({ title: t('deleteSuccess') });
          }}>
          <Icon as={require('@/assets/svg/song_delete.svg').ReactComponent} />
          <Text>{t('delete')}</Text>
        </Box>
      </Box>
    }
    <CloneAudioPreview
      isOpen={viewSwiper}
      onClose={() => setViewSwiper(false)}
      userAvatar={userInfo?.avatar || ''}
      userName={userInfo?.nickName || userInfo?.wiseNickName || t('WiseUser')}
      onDownload={download}
      createTime={viewInfo?.create_time}
      onSameDraw={() => { setViewhistory(viewInfo); setViewSwiper(false) }}
      audioSrc={(viewInfo && viewInfo.result) ? viewInfo.result : ''}
      text={viewInfo?.data?.text ?? ''}
      audioName={viewInfo?.audioName ?? ''}
      audioCover={viewInfo?.audioCover ?? ''}
      speed={viewInfo?.data?.speed ?? ''}
    />
  </CreationContent.Content >
})

