import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from './index.module.scss'
import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import { useLocation, useNavigate } from "react-router-dom";
import { WiseImage } from '@/components/Image'
import { globalStore } from "@/store/global";
import { NavAnimation } from "./navAnimation";
import { MonthlyCard } from "../MonthlyCard";
import { SHOW_PRICE_SCALE } from "@/utils/common";
import { uiStrore } from "@/store/ui";
import { observer } from "mobx-react-lite";
import { VIPHYXY } from "@/pages/login/LoginType";
import { NewVersion } from "./NewVersion";
import { ipcRendererInvoke, isDesktop } from "@/utils/electron";
import { userStore } from "@/store/user";
import { imStore } from "@/store/im";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useMessage } from "@/hooks/useMessage";
import { toolStore } from "@/store/tool";
import { getCreationTask } from "@/api/creation";
import { cloneDeep } from "lodash";
import { useTaskMessage } from "@/hooks/useTaskMessage";
import { creationStore, CreationType } from "@/store/creation";

/**
 * icon遵循命名规则
 * 当前路由页面的icon需要加_active
 */

let document_title = document.title;
interface NavProps {
  userInfo?: UserResult
}
export const Nav: FC<NavProps> = observer((props) => {
  const { userInfo } = props;
  const { t } = useTranslation();
  const navs = [
    { icon: 'nav_ai', text: 'AI', route: '/chat' },
    { icon: 'creation', text: t('Creations'), route: '/creation' },
    { icon: 'tools', text: t('Tool'), route: '/tools' },
    { icon: 'agents', text: t('Agents'), route: '/agents' },
    { icon: 'im', text: t('IM'), route: '/im' },
  ]

  const location = useLocation();
  const navigate = useNavigate();
  const [showNewVersion, setShowNewVersion] = useState(false);
  const [hyxyOpen, setHyxyOpen] = useState(false); // 用户协议
  const { updateUserinfo } = userStore
  const { openVip, setOpenVip, updateDownloaded, releaseNotes, updateIsWindow, newsetVersion,
    version, isMustUpdate, processTaskIds, finishTaskInfo
  } = uiStrore;
  const { currentTool, isModify, setLeavaWritingAssistant } = toolStore;
  const { pageType } = creationStore.publicStore;
  const { unread } = imStore;
  const messageNum = useRef(0);
  const timer = useRef<NodeJS.Timer>();
  const audioEle = useRef<HTMLAudioElement>(null);
  const message = useMessage();
  const taskMessage = useTaskMessage();

  const navIcon = useCallback((route: string) => {
    switch (route) {
      case '/chat':
        return <NavAnimation.Chat />;
      case '/creation':
        return <NavAnimation.Creation />;
      case '/tools':
        return <NavAnimation.Tool />;
      case '/agents':
        return <NavAnimation.Agent />;
      case '/im':
        return <NavAnimation.IM />;
      default:
        return <Icon className={styles.svg} as={require(`@/assets/svg/${route}.svg`).ReactComponent} />
    }
    // eslint-disable-next-line
  }, [location.pathname])

  useEffect(() => {
    if (updateDownloaded) {
      setShowNewVersion(true)
    }
  }, [updateDownloaded])

  useEffect(() => {
    timer.current && clearInterval(timer.current);

    if (unread > 0) {
      // 首次打开今日消息提示
      if (unread > messageNum.current) {
        const resp = audioEle.current?.play();
        if (resp !== undefined) {
          resp.then(_ => { }).catch(error => { });
        }
        if (isDesktop()) {
          ipcRendererInvoke('getIM_Message');
        } else {
          if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
            return;
          };
          if (Notification.permission === "granted") {
            // If it's okay let's create a notification
            new Notification("Wisetalker", {
              body: '您收到了一条私聊消息',
              silent: true
            });
          } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
              // 根据用户的回答显示或隐藏按钮
              new Notification("Wisetalker", {
                body: '您收到了一条私聊消息',
                silent: true
              });
            });
          }
        }
      }
      messageNum.current = unread;
      messageChange();
      timer.current = setInterval(() => {
        messageChange();
      }, 3000);
    } else {
      document.title = document_title;
      ipcRendererInvoke('removeIM_Message');
      messageNum.current = 0;
    }
  }, [unread])

  const messageChange = async () => {
    document.title = `您有新的私聊消息`;
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 1500)
    })
    document.title = document_title;
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 1500)
    })
  }


  const currentVersionArr = version?.split('.');
  const newVersionArr = newsetVersion?.split('.');
  let shouldUpdate = false;
  if (currentVersionArr?.length && newVersionArr?.length) {
    for (let i = 0; i < currentVersionArr.length; i++) {
      if (+newVersionArr[i] > +currentVersionArr[i]) {
        shouldUpdate = true;
        break;
      } else if (+newVersionArr[i] < +currentVersionArr[i]) {
        shouldUpdate = false;
        break;
      }
    }
  }

  useQuery(
    ['checkCreationTask'],
    async () => {
      const res = await getCreationTask(processTaskIds.join(','));
      Object.keys(res).forEach(key => {
        if (res[key]) {
          const clonefinishTaskInfo = cloneDeep(finishTaskInfo);
          const cloneProcessTaskIds = [...processTaskIds];

          cloneProcessTaskIds.splice(cloneProcessTaskIds.indexOf(key), 1);
          uiStrore.setFinishTaskInfo({ ...clonefinishTaskInfo, [key]: res[key] });
          uiStrore.setProcessTaskIds(cloneProcessTaskIds);
        }
      });

      return null
    },
    {
      refetchInterval: 5000,
      enabled: processTaskIds.length > 0
    }
  )

  useQuery(
    ['taskMessage'],
    () => {
      if (Object.keys(finishTaskInfo).length === 0) return null;
      const firstKey = Object.keys(finishTaskInfo)[0];
      const taskInfo = finishTaskInfo[firstKey];

      const currentRoute = location.pathname;
      let show = false; //是否需要显示任务成功信息
      switch (taskInfo.type) {
        case 'draw':
          if (currentRoute !== '/creation') {
            show = true;
          } else {
            const taskPageType = taskInfo.page_type;
            if (taskPageType === 'midjourney' && pageType !== CreationType.MJ) {
              show = true;
            } else if (taskPageType === 'gen3' && pageType !== CreationType.GEN3) {
              show = true;
            } else if (taskPageType === 'SD1.5' && pageType !== CreationType.MJ) {
              show = true;
            } else if (taskPageType === 'SD3.0' && pageType !== CreationType.MJ) {
              show = true;
            } else if (taskPageType === 'suno' && pageType !== CreationType.SUNO) {
              show = true;
            } else if (taskPageType === 'hdrestorationsimg' && pageType !== CreationType.MJ) {
              show = true;
            } else if (taskPageType === 'imageremovebg' && pageType !== CreationType.MJ) {
              show = true;
            } else if (taskPageType === 'hdrestorationsimg' && pageType !== CreationType.MJ) {
              show = true;
            } else if (taskPageType === 'e3' && pageType !== CreationType.E3) {
              show = true;
            }
          }
          break;
        case 'tools':
          show = currentRoute !== '/tools';
          break;
        default:
          break;
      }

      show && taskMessage.success({
        title: JSON.stringify(taskInfo),
        isClosable: true,
      });
      finishTaskInfo.hasOwnProperty(firstKey) && delete finishTaskInfo[firstKey];
      uiStrore.setFinishTaskInfo(finishTaskInfo);
      return null
    },
    {
      refetchInterval: 5000,
      enabled: Object.keys(finishTaskInfo).length > 0
    }
  )

  useQuery(
    ['checkVersion'],
    () => {
      ipcRendererInvoke('checkVersion');
      return null
    },
    {
      refetchInterval: 30000,
      enabled: isDesktop()
    }
  )

  const updateVersion = () => {
    if (updateIsWindow) {
      ipcRendererInvoke('openUrl', 'https://www.wisetalker.cn/')
    } else {
      if (!updateDownloaded) {
        message.tips({ title: '下载中,请稍后' })
      } else {
        ipcRendererInvoke('updateVersion')
      }
    }
  }

  const changeNav = (route: string) => {
    if (currentTool === 'writingAssistant' && isModify) {
      setLeavaWritingAssistant({ next: route, type: 'route' });
    } else {
      if (location.pathname === route) return;
      navigate(route);
    }
  }

  return <Box className={styles.nav}>
    <Box className={styles.logo}>
      <WiseImage src={require('@/assets/logo.png')} />
    </Box>
    <Box className={styles.navs}>
      {
        navs.map(item => {
          const isActive = location.pathname === item.route;
          return <Box className={`${styles.navItem} ${isActive ? styles.active : ''}`} key={item.route}
            onClick={() => changeNav(item.route)}>
            {
              isActive ? navIcon(item.route)
                : <Box className={styles.svgContent}>
                  <Icon className={styles.svg} as={require(`@/assets/svg/${item.icon}${isActive ? '_active' : ''}.svg`).ReactComponent} />
                </Box>
            }
            {
              item.route === '/im' && unread > 0 && <Box className={styles.unread}>{unread}</Box>
            }
            <Box className={styles.text} style={{ visibility: isActive ? 'hidden' : 'visible' }}>{item.text}</Box>
          </Box>
        })
      }
    </Box>
    <Box className={styles.bottomNavs}>
      {
        (updateDownloaded || shouldUpdate) && <Box className={styles.newVersion} onClick={() => setShowNewVersion(true)}>
          <Icon className={styles.icon} as={require(`@/assets/svg/new_version.svg`).ReactComponent} />
          <Text className={styles.text}>{t('NewVersion')}</Text>
        </Box>
      }
      {/* <Box className={styles.feedback}>
        <Icon className={styles.svg} as={require(`@/assets/svg/feedback.svg`).ReactComponent} />
        <Box className={styles.text}>反馈</Box>
      </Box> */}
      <Flex className={styles.vipbox} onClick={(e) => {
        e.stopPropagation();
        setOpenVip(true);
      }}>
        <Icon as={userInfo?.monVip ? require(`@/assets/svg/navVIP.svg`).ReactComponent
          : require(`@/assets/svg/navNoVIP.svg`).ReactComponent} />
        <Flex className={userInfo?.monVip ? styles.vipuserbox : styles.notvipuserbox}>
          <Icon as={userInfo?.monVip ? require(`@/assets/svg/navisVip.svg`).ReactComponent
            : require(`@/assets/svg/navnotVip.svg`).ReactComponent} />
          <Text>{userInfo?.monVip ? t('Availabled') : t('NotAvailable')}</Text>
        </Flex>
        <Box className={styles.vipBoxModal}>
          <Box className={userInfo?.monVip ? styles.vipmodel : styles.notvipmodel}>
            <Flex className={styles.contentbox}>
              <Text className={styles.title}>{t('WSVip')}</Text>
              <Text className={styles.date}>
                {userInfo?.monVip ?
                  `${new Date(userInfo?.limit.vipPayList[userInfo?.limit.vipPayList.length - 1]?.endTime).toLocaleDateString()}${t('Expiration')}`
                  : t('BenefitsNotActivated')
                }
              </Text>
              <Flex>
                <Text className={styles.points}>{t('Balance')}：<span>{userInfo?.balance ? (userInfo.balance * SHOW_PRICE_SCALE).toFixed(2) : '0.00'}</span></Text>
                <Icon className={styles.pointssvg} as={userInfo?.monVip ?
                  require('@/assets/svg/vipbalance.svg').ReactComponent
                  : require('@/assets/svg/notvipbalance.svg').ReactComponent} />
              </Flex>
            </Flex>
            <Flex className={styles.goOpenbox}
              onClick={(e) => {
                e.stopPropagation();
                setOpenVip(true);
              }}>
              <Text>{userInfo?.monVip ? t('Renew') : t('activation')}</Text>
            </Flex>
          </Box>
        </Box>
      </Flex>
      {
        userInfo ? <Box className={styles.userInfo} onClick={() => { updateUserinfo(); changeNav('/personal') }}>
          {userInfo.isWhiteList === 1 &&
            <Flex className={styles.whiteUser}>白名单</Flex>
          }
          <WiseImage className={styles.userAvatar} src={userInfo.avatar} />
        </Box>
          : <Box className={styles.login} onClick={() => globalStore.setLoginIsOpen(true)}>
            <Icon className={styles.svg} as={require(`@/assets/svg/login.svg`).ReactComponent} />
            <Box className={styles.text}>{t('Login')}</Box>
          </Box>
      }
    </Box>
    <MonthlyCard isOpen={openVip} onClose={() => setOpenVip(false)} onOpen={() => { setHyxyOpen(true); setOpenVip(false) }} />
    <VIPHYXY isOpen={hyxyOpen} onClose={() => { setHyxyOpen(false); setOpenVip(true) }} />
    <NewVersion open={showNewVersion || isMustUpdate}
      isDownloaded={updateDownloaded}
      isMustUpdate={isMustUpdate}
      releaseNotes={releaseNotes}
      onClose={() => setShowNewVersion(false)}
      onUpdate={updateVersion}
      version={newsetVersion}
      isWindow={updateIsWindow}
    />
    <audio src={require('@/assets/audio/tips.mp3')} ref={audioEle} style={{ display: 'none' }} />
  </Box>
})