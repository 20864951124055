import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { FC, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from './index.module.scss'
import { CButton } from "../Button";
import { creationStore, CreationType } from "@/store/creation";
import { useTranslation } from "react-i18next";

interface TaskMessageProps {
  status?: 'warning' | 'tips' | 'success' | 'error'
  title?: React.ReactNode
  content?: React.ReactNode
  onClose?: () => void
}
const TaskMeesage: FC<TaskMessageProps> = (props) => {
  const { status, title, content, onClose } = props;

  const statusText = useRef<string>('生成完毕');
  const navigate = useNavigate();
  const { t } = useTranslation();

  let realTitle = '';
  const params = JSON.parse((title as string) || '{}');
  switch (params.page_type) {
    case 'midjourney':
      realTitle = t('wise') + 'MJ';
      break;
    case 'SD1.5':
      realTitle = t('wise') + 'SD1.5';
      break;
    case 'SD3.0':
      realTitle = t('wise') + 'SD3.0';
      break;
    case 'gen3':
      realTitle = t('wise') + 'GEN3';
      break;
    case 'e3':
      realTitle = t('wise') + 'E3';
      break;
    case 'suno':
      realTitle = t('wise') + 'SUNO';
      break;
    case 'hdrestorationsimg':
      realTitle = t('PictureMagify');
      break;
    case 'imageremovebg':
      realTitle = t('PictureRemoveBG');
      break;
    default:
      realTitle = '生成完毕';
      break;
  }

  const toPage = () => {
    const { page_type, type } = params;
    switch (type) {
      case 'draw':
        if (page_type === 'midjourney') {
          creationStore.publicStore.setPageType(CreationType.MJ);
        } else if (page_type === 'gen3') {
          creationStore.publicStore.setPageType(CreationType.GEN3);
        } else if (page_type === 'SD1.5') {
          creationStore.publicStore.setPageType(CreationType.SD);
        } else if (page_type === 'SD3.0') {
          creationStore.publicStore.setPageType(CreationType.SD3);
        } else if (page_type === 'suno') {
          creationStore.publicStore.setPageType(CreationType.SUNO);
        } else if (page_type === 'hdrestorationsimg') {
          creationStore.publicStore.setPageType(CreationType.ENLARGE);
        } else if (page_type === 'imageremovebg') {
          creationStore.publicStore.setPageType(CreationType.BG);
        } else if (page_type === 'hdrestorationsimg') {
          creationStore.publicStore.setPageType(CreationType.ENLARGE);
        }
        navigate('/creation');
        onClose && onClose();
        break;
      case 'tools':
        console.log('去工具')
        break;
    }
  }

  const StatusIcon = useCallback(() => {
    switch (status) {
      case 'error':
        return <Icon as={require('@/assets/toasticon/blackerror.svg').ReactComponent} className={styles.icon} />
      case 'success':
        statusText.current = '生成完毕';
        return <Icon as={require('@/assets/svg/task_success.svg').ReactComponent} className={styles.icon} />
      case 'tips':
        return <Icon as={require('@/assets/toasticon/blacksuccess.svg').ReactComponent} className={styles.icon} />
      case 'warning':
        return <Icon as={require('@/assets/toasticon/blackwarnning.svg').ReactComponent} className={styles.icon} />
      default:
        return <Icon as={require('@/assets/svg/task_success.svg').ReactComponent} className={styles.icon} />
    }
    // 黑夜模式下的图标
    // switch (status) {
    //   case 'error':
    //     return <Icon as={require('@/assets/toasticon/error.svg').ReactComponent} className={styles.icon} />
    //   case 'success':
    //     return <Icon as={require('@/assets/toasticon/success.svg').ReactComponent} className={styles.icon} />
    //   case 'tips':
    //     return <Icon as={require('@/assets/toasticon/tips.svg').ReactComponent} className={styles.icon} />
    //   case 'warning':
    //     return <Icon as={require('@/assets/toasticon/warning.svg').ReactComponent} className={styles.icon} />
    //   default:
    //     return <Icon as={require('@/assets/toasticon/warning.svg').ReactComponent} className={styles.icon} />
    // }
  }, [status])

  const cls = `${styles.message} ${styles[props?.status || '']}`

  return <Box className={cls}>
    <Flex alignItems='center'>
      <Box className={styles.title}>
        <StatusIcon />{realTitle}<Text className={styles.statusText}>({statusText.current})</Text>
      </Box>
      <Box>
        <CButton className={styles.btn} size='sm' onClick={toPage}>
          去查看
        </CButton>
      </Box>
    </Flex>
    <Box className={styles.close} onClick={onClose} >
      <Icon as={require('@/assets/svg/close_circle_1.svg').ReactComponent} />
    </Box>
    {content}
  </Box>
}

export default TaskMeesage