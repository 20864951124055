import { Box, Flex, Icon, Text, useOutsideClick } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { creationStore, CreationType } from "@/store/creation";
import { observer } from "mobx-react-lite";
import { CreationHeader, CreationInfo, CreationContent } from "../Components";
import { CButton } from "@/components/Button";
import { ScrollBarBox } from "@/components/ScrollBox";
import { getOSSBlobResource, saveBlobToLocal, SHOW_PRICE_SCALE } from "@/utils/common";
import { useMessage } from "@/hooks/useMessage";
import { userStore } from "@/store/user";
import { globalStore } from "@/store/global";
import { creatSuno, creatSunoLyric, deleteDrawSuno, getDrawSunoList, getDrawSunoLyric, getDrawSunoStyleList, retryDraw, shareToSquare } from "@/api/creation";
import { uiStrore } from "@/store/ui";

import styles from './index.module.scss'
import { WisePage } from "@/components/Pagination";
import { CreationSwitch } from "../Components/Switch";
import { CreationSongName } from "../Components/SongName";
import { CreationSongContent } from "../Components/SongContent";
import { CreationSingStyle } from "../Components/SongStyle";
import { RenderSong } from "../Components/SongItem";
import { CModal } from "@/components/Modal";
import Markdown from "@/components/Markdown";
import { SongPreview } from "../Components/SongPreview";
import RotatingCircles from "@/components/RotatingCircles";
import { useTranslation } from "react-i18next";
import CreateBtn from "../Components/CreationBtn";
import { getdate } from "@/pages/tools/components/ContentPreview";
import { errorMessages } from "../CloneAudios";

const pageType = CreationType.SUNO;
const priceMap: { [key: string]: number } = {
  'chirp-v2-0': 20,
  'chirp-v3-0': 20,
  'chirp-v3.5-0': 40,
  'chirp-v4-0': 50
};
const getPrice = (musicModel: string): number => {
  return priceMap[musicModel] || 20; // 默认返回0或其他默认值
};

export const WiseSUNO: FC = observer(() => {
  const { init } = creationStore[pageType];
  const [loading, setLoading] = useState(false);
  const [isAbsolute, setIsAbsolute] = useState(false);
  const [music_style, setMusic_style] = useState('');
  const [lyric, setLyric] = useState('');
  const [songName, setSongName] = useState('');
  const [songPrompt, setSongPrompt] = useState('');
  const [visible, setVisible] = useState(false);
  const [music_styleHis, setMusic_styleHis] = useState<any[]>([]);
  const [islyric, Setislyric] = useState(false);
  const [updatehis, Setupdatehis] = useState(false);
  const [selectedValues, setSelectedValues] = useState<any[string]>([]);
  const { t } = useTranslation();
  const { enableKeys } = creationStore.publicStore;
  const sunoStyles = [
    'simple_gen',
    'custom_model'
  ]
  const [sunoStyle, setSunoStyle] = useState(sunoStyles[0]);
  const songStyleList = [
    // { name: 'V2（老款模型，最长生成1分钟左右）', value: 'chirp-v2-0' },
    // { name: t('creation.SunoV3Desc'), value: 'chirp-v3-0' },
    { name: t('creation.SunoV35Desc'), value: 'chirp-v3.5-0' },
    { name: t('creation.SunoV4Desc'), value: 'chirp-v4-0' }
  ]
  const [songStyle, SetSongStyle] = useState('')

  const { userInfo, updateUserBalance } = userStore;
  const { setLoginIsOpen } = globalStore;
  const message = useMessage();

  useEffect(() => {
    init();
    const fet = async () => {
      const his = await getDrawSunoStyleList()
      setMusic_styleHis(his)
    }
    fet();
  }, [])

  //开始创作
  const createTask = async () => {
    if (loading || isDisable) return;
    setLoading(true);
    try {
      if (!userInfo) {
        message.warning({ title: t('LoginFirst') });
        setLoginIsOpen(true);
        return;
      }
      if (!userInfo.monVip && (userInfo.balance * SHOW_PRICE_SCALE) < price) {
        message.warning({ title: t('creation.DrawFaildBalance') });
        return;
      }
      const value = (songStyleList.find(item => item.name === songStyle) || {}).value || '';
      const convertToString = (styles: any) => {
        return styles.join(' ');
      };
      const musicStyleStr = music_style ? String(music_style) : '';
      const result = music_style.length > 0 ? convertToString(musicStyleStr.split(/,|，/)) : '';
      const rets = await creatSuno({
        draw_type: sunoStyle,
        instrumental_music: isAbsolute,
        music_style: result,
        music_model: value,
        title: songName,
        text: isAbsolute ? '' : lyric,
        prompt: songPrompt,
      }) as any;
      if (rets) {
        if (rets.message) {
          message.error({ title: rets.message })
          return;
        }
        message.success({ title: t('CreateSuccess') })
        Setupdatehis(true)
      } else {
        message.warning({ title: t('CreateFaild') })
      }
    } catch (err: any) {
      for (const [key, messageKey] of Object.entries(errorMessages)) {
        if (err.message.includes(key)) {
          message.error({ title: `您的输入包含${t(messageKey)},请修改后重试` });
          return; // 一旦找到匹配项，立即退出函数
        }
      }
      message.error({ title: err.message });
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  let price = priceMap[songStyleList[0].value];
  if (songStyle === songStyleList[0].name) {
    price = priceMap[songStyleList[0].value]
  } else if (songStyle === songStyleList[1].name) {
    price = priceMap[songStyleList[1].value]
  }

  const creatlyric = async () => {
    if (songName.length < 1) {
      message.warning({ title: t('creation.SongNameFirst') })
      return;
    }
    const res: any = await creatSunoLyric({ title: songName });
    if (res) {
      checkLyricStatus(res._id)
    }
    Setislyric(true)
  }
  const [newlyric, setNewLyric] = useState('');
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const checkLyricStatus = (_id: any) => {
    const checkStatus = async () => {
      try {
        const response = await getDrawSunoLyric({ _id: _id });
        const music = response[0].music
        if (music && music.lyrics && music.lyrics.text) { // 假设 status 决定是否完成
          setNewLyric(music.lyrics.text); // 存储歌词数据
          if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
          } // 停止轮询
          setLoading(false); // 更新加载状态
        }
      } catch (error) {
        console.error("Error fetching lyric status:", error);
      }
    };

    intervalRef.current = setInterval(checkStatus, 1000);
    setLoading(true); // 开始轮询，设置加载状态
  };
  const lyricboxonClose = () => {
    setNewLyric('');
    setLoading(false);
    Setislyric(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }

  const songRegenerate = (item: any) => {
    if (item.draw_type === 'simple_gen') {
      setSunoStyle(sunoStyles[0])
      if (item.music.prompt) {
        setSongPrompt(item.music.prompt)
      } else if (item.music.clips[0].metadata.gpt_description_prompt) {
        setSongPrompt(item.music.clips[0].metadata.gpt_description_prompt)
      } else (
        setSongPrompt('')
      )
    } else {
      setSunoStyle(sunoStyles[1])
      if (item.music.clips[0].title) {
        setSongName(item.music.clips[0].title)
      }
      if (item.music.clips[0].metadata.prompt) {
        setLyric(item.music.clips[0].metadata.prompt)
      }
      if (item.music.clips[0].metadata.tags) {
        const formattedTags = item.music.clips[0].metadata.tags.replace(/\s+/g, ',');
        const tagsArray = item.music.clips[0].metadata.tags.split(' ').filter((tag: any) => tag !== '');
        setSelectedValues(tagsArray)
        setMusic_style(formattedTags)
      }
    }
    if (item.instrumental_music) {
      setIsAbsolute(true)
    } else {
      setIsAbsolute(false)
    }
    if (item.music_model) {
      const foundStyle = songStyleList.find(style => style.value === item.music_model);
      SetSongStyle(foundStyle ? foundStyle?.name : songStyleList[1].name);
    }
  }

  const reset = () => {
    setSunoStyle(sunoStyles[0]);
    setIsAbsolute(false);
    setMusic_style('');
    setLyric('');
    setSongName('');
    setSongPrompt('');
    setSelectedValues([]);
    SetSongStyle(songStyleList[1].name);
  }

  const isDisable = sunoStyle === sunoStyles[0] ? !songPrompt : !songName || !music_style;

  return <CreationContent.Content>
    <CreationContent.Inputs>
      <CreationHeader icon='draw_suno_000' text={t('wise') + 'SUNO'} />
      <Box className={styles.container}>
        <ScrollBarBox className={styles.content}>
          <Box className={styles.type}>
            <Box className={`${styles.chatType} ${sunoStyle === sunoStyles[0] ? styles.active : ''}`}
              onClick={() => setSunoStyle(sunoStyles[0])}>
              <Box>
                {t('InspirationMode')}
              </Box>
            </Box>
            <Box className={`${styles.chatType} ${sunoStyle === sunoStyles[1] ? styles.active : ''}`}
              onClick={() => setSunoStyle(sunoStyles[1])}>
              <Box>
                {t('customMode')}
              </Box>
            </Box>
          </Box>
          <CreationSwitch isOn={isAbsolute} setIsOn={setIsAbsolute} systemPromptTip={t('creation.PureMusicDesc')} />
          {sunoStyle === sunoStyles[1] &&
            <CreationSongName
              cnPrompt={songName}
              onInput={setSongName}
              systemPromptTip={t('creation.SongNameDesc')}
            />
          }
          {sunoStyle === sunoStyles[0] &&
            <CreationSongContent
              title={t('creation.SongDesc')} placeholder={t('creation.PlsEnterMusicDesc')}
              onClear={() => setSongPrompt('')}
              cnPrompt={songPrompt}
              onInput={setSongPrompt}
              isRequired={true}
              systemPromptTip={t('creation.PlsEnterMusicStyleDesc')}
            />
          }
          {sunoStyle === sunoStyles[1] && !isAbsolute &&
            <CreationSongContent
              title={t('creation.lyrics')} placeholder={t('creation.EnterSongLyrics')}
              onClear={() => setLyric('')}
              cnPrompt={lyric}
              onInput={setLyric}
              isRequired={false}
              icon={require(`@/assets/svg/sunolyric.svg`).ReactComponent}
              modeltext={t('creation.AIGeneratedLyrics')}
              onclick={creatlyric}
              systemPromptTip={t('creation.lyricsDesc')}
            />
          }
          {sunoStyle === sunoStyles[1] &&
            <CreationSongContent
              title={t('creation.MusicStyle')} placeholder={t('creation.UWantMusicStyle')}
              onClear={() => { setSelectedValues([]); setMusic_style('') }}
              cnPrompt={music_style}
              onInput={setMusic_style}
              isRequired={true}
              icon={require(`@/assets/svg/sunomusic_style.svg`).ReactComponent}
              modeltext={t('creation.ChooseMusicStyle')}
              onclick={() => setVisible(true)}
              systemPromptTip={t('creation.MusicStyleDesc')}
            />
          }
          <CreationSingStyle
            selectLanguage={songStyle}
            title={t('creation.MusicModel')}
            langs={songStyleList}
            setlang_key={SetSongStyle}
            SetSelectLanguage={(value: string) => SetSongStyle(value)}
            systemPromptTip={t('creation.SunoOfficePoints')}
          />
        </ScrollBarBox>
      </Box>
      <Box className={styles.create}>
        <CreateBtn
          loading={loading}
          isMaintain={!enableKeys['wensisuno']}
          disabled={isDisable}
          onClick={createTask}
          price={price}
          isVip={userInfo?.monVip}
          icon={<Icon as={require('@/assets/svg/create_audio.svg').ReactComponent} />}
          onReset={reset}
          onVip={() => uiStrore.setOpenVip(true)}
        />
      </Box>
    </CreationContent.Inputs>
    <WiseSUNOGenerate updatehis={updatehis} setUpdatehis={Setupdatehis} songRegenerate={songRegenerate} />
    <CModal isOpen={islyric} onClose={lyricboxonClose}>
      <Flex className={styles.balancebox}>
        <Flex className={styles.notbalanceTitleBox}>
          <Flex justifyContent={'center'} alignItems={'center'}>
            <Text>{t('creation.LyricsGenerating')}</Text>
            {lyric.length > 0 && <span style={{ color: '#ff7b00', marginLeft: '5px' }}>**{t('creation.LyricsOverwrite')}</span>}
          </Flex>
          <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={lyricboxonClose} />
        </Flex>
        <Flex className={styles.lyricBox}>
          {loading ?
            <Flex w={'100%'} justifyContent={'center'} alignItems={'center'} flexDir={'column'}>
              <RotatingCircles />
              <Text mt={'5px'} color={'#09AD19'}>{t('creation.LyricsGenerating')}...</Text>
            </Flex>
            :
            <ScrollBarBox>
              <Markdown source={newlyric}></Markdown>
            </ScrollBarBox>
          }
        </Flex>
        <Flex className={styles.modelbuttonbox}>
          <CButton className={styles.Cancelbutton} onClick={lyricboxonClose} >
            {t('cancel')}
          </CButton>
          <CButton className={styles.Savebutton} isDisabled={loading} onClick={() => { setLyric(newlyric); Setislyric(false) }}>
            {t('Use')}
          </CButton>
        </Flex>
      </Flex>
    </CModal>
    <MusicStyleSelector music_style={music_style} data={music_styleHis} visible={visible} onClose={() => setVisible(false)}
      onClick={(value: any) => setMusic_style(value)} selectedValues={selectedValues} setSelectedValues={setSelectedValues} />
  </CreationContent.Content>
})

const MusicStyleSelector = (
  { music_style, data, visible, onClose, onClick, selectedValues, setSelectedValues }:
    { music_style: any, data: any[], visible: any, onClose: any, onClick: any, selectedValues: any[], setSelectedValues: any }
) => {

  const [selectedKeys, setSelectedKeys] = useState<{ [groupIndex: number]: string | null }>({});
  const message = useMessage();
  const { t } = useTranslation();

  const toggleKey = (groupIndex: number, key: string) => {
    setSelectedKeys(prev => ({
      ...prev,
      [groupIndex]: key,
    }));
  };

  // 方法: 处理选中值的状态
  const handleCheck = (checkedValue: any) => {
    if (selectedValues.length >= 5) {
      message.warning({ title: t('creation.MaxFiveStyle') });
      return;
    }
    setSelectedValues((prev: any) =>
      prev.includes(checkedValue)
        ? prev.filter((v: any) => v !== checkedValue)
        : [...prev, checkedValue]
    );
  };
  const handleRemove = (valueToRemove: string) => {
    setSelectedValues((prev: any) => prev.filter((item: any) => item !== valueToRemove));
  };
  const handleOk = () => {
    let formattedMusicStyle;
    if (Array.isArray(music_style)) {
      formattedMusicStyle = music_style;
    } else {
      const normalizedString = music_style.replace(/、/g, ','); // 替换中文逗号为英文逗号
      formattedMusicStyle = normalizedString
        .split(',') // 按英文逗号分割字符串
        .map((item: any) => item.trim()) // 去掉每个项的前后空格
        .filter((item: any) => item !== ''); // 过滤掉空字符串
    }
    let combinedArray = [...formattedMusicStyle, ...selectedValues];
    onClick(combinedArray);
    setSelectedValues([])
    onClose();
  };

  useEffect(() => {
    const initialSelectedKeys = data.reduce((acc: { [groupIndex: number]: string | null }, group, index) => {
      const firstKey = Object.keys(group.categories)[0]; // 获取每组的第一个键
      acc[index] = firstKey;
      return acc;
    }, {});

    setSelectedKeys(initialSelectedKeys); // 使用 setState 初始化 selectedKeys
  }, [data]);
  return (
    <CModal isOpen={visible} onClose={onClose}>
      <Flex className={styles.notbalancebox}>
        <Flex className={styles.notbalanceTitleBox}>
          <Text>{t('creation.ChooseMusicStyle')}</Text>
          <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={onClose} />
        </Flex>
        {data.map((group, index) => (
          <div key={index} style={{ position: 'relative', borderBottom: index === 1 ? '1px solid #eee' : '' }}>
            <Text className={styles.typeText}>{t('According')}{group.type}{t('Choose')}</Text>
            <Flex className={styles.groupBox}>
              {Object.entries(group.categories).map(([key, values]) => (
                <div key={key} className={styles.titleContainer}>
                  <Box className={selectedKeys[index] === key ? `${styles.title} ${styles.active}` : styles.title}
                    onClick={() => toggleKey(index, key)} cursor={'pointer'}>{key}</Box>
                  <Flex className={styles.valuesBox}>
                    {selectedKeys[index] === key && (
                      (values as string[]).map((value) => (
                        <Box key={value} className={selectedValues.includes(value) ? styles.selectedvalue : styles.value} onClick={() => handleCheck(value)}>{value}</Box>
                      ))
                    )}
                  </Flex>
                </div>
              ))}
            </Flex>
          </div>
        ))}
        <Flex className={styles.selectValuesBox}>
          <Text>{t('CurrentSelected')}：</Text>
          {selectedValues.map((item: string) => (
            <Box key={item} className={styles.selectValueBox}>
              <Text>{item}</Text>
              <Flex onClick={() => handleRemove(item)}>x</Flex>
            </Box>
          ))}
        </Flex>
        <Flex className={styles.modelbuttonbox}>
          <CButton className={styles.Cancelbutton} onClick={onClose} >
            {t('cancel')}
          </CButton>
          <CButton className={styles.Savebutton} onClick={handleOk}>
            {t('Use')}
          </CButton>
        </Flex>
      </Flex>
    </CModal>
  );
};

const WiseSUNOGenerate: FC<any> = observer(({ updatehis, setUpdatehis, songRegenerate }) => {
  const {
    pageNum, pageSize, setPageNum, total, shouldUpdate, faverites, getCreationHistory, setShouldUpdate, postCollection, setFaverites
  } = creationStore[pageType];
  const { userInfo } = userStore;
  const { openAlert, closeAlert } = uiStrore;
  const [historyList, SetHistoryList] = useState<any[]>([])
  const message = useMessage();
  const moreEdit = useRef<DrawSunoSchema>();
  const editRef = useRef<HTMLDivElement>(null);
  const [morePosition, setMorePosition] = useState<undefined | { x: number, y: number, isFirstEdit: boolean, index: number }>();
  const [viewSwiper, setViewSwiper] = useState(false);
  const viewInfo = useRef<DrawSunoSchema>(null!);
  const [infoIndex, setInfoIndex] = useState(0);
  const [openPartReDraw, setOpenPartReDraw] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      const his = await getDrawSunoList();
      SetHistoryList(his)
    }
    fetch();
  }, [])
  const intervalIdRef = useRef<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      const his = await getDrawSunoList();
      SetHistoryList(his);

      const hasActiveStatus = his.some(item => item.status === 1 || item.status === 0);
      if (hasActiveStatus === false && intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        setUpdatehis(false);
      }
    };
    if (updatehis) {
      intervalIdRef.current = setInterval(fetchData, 3000); // 每5秒查询一次
      fetchData(); // 初次加载数据
    }
    return () => clearInterval(intervalIdRef.current); // 清理函数停止轮询
  }, [updatehis])
  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdate(false);
    }
    // eslint-disable-next-line
  }, [shouldUpdate])

  useOutsideClick({
    ref: editRef,
    handler: () => {
      setMorePosition(undefined);
    }
  })

  const getType = (extendsType: any) => {
    let extendsTitle = t('customMode');
    if (extendsType === 'custom_model') {
      extendsTitle = t('customMode');
    } else if (extendsType === 'simple_gen') {
      extendsTitle = t('InspirationMode');
    }
    return extendsTitle
  }

  // 下载单张图片
  const download = async (url: string, extendsType: any) => {
    if (!url) return;
    let extendsTitle = getType(extendsType);
    const date = getdate()
    try {
      const res = await getOSSBlobResource(url);
      await saveBlobToLocal(res, `创作_文思SUNO_${extendsTitle}_${date}.mp3`);
      message.success({ title: t('DownloadSuccess') })
    } catch (err: any) {
      if (err.message !== 'The user aborted a request.') {
        message.error({ title: t('DownloadFailed') })
      }
    }
  }

  const onFavorite = async (id: string, url: string) => {
    const isFavorite = faverites.get(id)?.includes(url) || false;
    try {
      const res = await postCollection(id, url);
      setFaverites(id, res[id] || []);
      if (isFavorite) {
        message.success({ title: t('CancelCollectionSuccess') })
      } else {
        message.success({ title: t('collected') })
      }
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  // 点击显示更多操作
  const onMore = (e: React.MouseEvent<SVGElement, MouseEvent>, url: string, item: any) => {
    e.stopPropagation();
    const index = item.music.clips.findIndex((m: any) => m.image_url === url);
    let isFirstEdit = false;
    //@ts-ignore
    let { x, y } = e.target.getBoundingClientRect();
    const _BoxHeigh = 150, _BoxWidth = 140;
    if (e.nativeEvent.y + _BoxHeigh > window.innerHeight) {
      y = y - _BoxHeigh;
    } else {
      y = y + 15;
    }
    if (e.nativeEvent.x + _BoxWidth > window.innerWidth) {
      x = x - _BoxWidth;
    } else {
      x = x + 15;
    }
    setMorePosition({ x, y, isFirstEdit, index });
    moreEdit.current = item;
  }

  // 预览大图
  const onPreview = (item: DrawSunoSchema, index: number) => {
    viewInfo.current = item;
    setInfoIndex(index);
    setViewSwiper(true);
  }

  const onDelete = async (id: string) => {
    openAlert({
      title: t('IsDeleteRecord'),
      content: t('creation.DeleteCantRecover'),
      status: 'warning',
      onOk: async () => {
        await deleteDrawSuno({ _id: id || '' })
        closeAlert();
        message.success({ title: t('deleteSuccess') });
        const his = await getDrawSunoList();
        SetHistoryList(his)
      }
    })
  }

  const onRetry = async (item: HistoryItem) => {
    try {
      await retryDraw({ _id: item._id, type: item.type });
      getCreationHistory();
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  const onShare = async () => {
    if (!viewInfo.current) return
    try {
      await shareToSquare({
        _id: viewInfo.current._id || '',
        url: viewInfo.current.music.clips[0].audio_url || '',
        type: CreationType.SUNO,
        mediaType: 'audio',
      });
      message.success({ title: t('ShareSuccess') });
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  return <CreationContent.History>
    <Box className={styles.historyTitle}>
      <Text className={styles.text}>{t('history')}</Text>
    </Box>
    <ScrollBarBox className={styles.listBox}>
      {
        historyList.map((item, _index) => {
          const price = getPrice(item.music_model);
          return <Box key={item._id} className={styles.historyItem}>
            <CreationInfo.CreationTitle
              type={pageType}
              extendsType={item.draw_type}
            />
            <RenderSong
              type={pageType}
              sizeScale={item.sizeScal}
              favorites={faverites.get(item._id)}
              item={item}
              onFavorite={onFavorite}
              onMore={(e, url) => { e.stopPropagation(); onMore(e, url, item) }}
              onView={_index => onPreview(item, _index)}
              onDelete={() => onDelete(item._id)}
              onReDraw={() => onRetry(item)}
            />
            <Box className={styles.bottomInfo}>
              <CreationInfo.Calendar time={item.submitAt} />
              <CreationInfo.DrawType drawType={item.music_model} />
              <Flex className={styles.priceBox}>
                <CreationInfo.Price isVip={item.isvip} price={item.status > 10 ? 0 : price} />
                <Box className={styles.aimark}>
                  {t('AIGEN')}
                </Box>
              </Flex>
            </Box>
          </Box>
        })
      }
      {
        historyList.length % 3 !== 0 && <Box w='160px' visibility='hidden'></Box>
      }
      {
        !historyList.length ? <Flex className={styles.fullbox}>
          <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
          <Text className={styles.prompt}>{t('NoHistory')}</Text>
        </Flex> : null
      }
      {
        total > pageSize && <WisePage pageNum={pageNum} pageSize={pageSize} total={total} onPageChange={setPageNum} />
      }
    </ScrollBarBox>
    {
      morePosition && <Box ref={editRef} className={styles.moreEdit} style={{ left: morePosition.x, top: morePosition.y }}>
        <Box
          className={styles.moreItem}
          onClick={() => download(moreEdit.current?.music.clips[0].video_url || '', moreEdit.current?.draw_type)}
        >
          <Icon as={require('@/assets/svg/creation_download.svg').ReactComponent} />
          <Text>{t('download')}</Text>
        </Box>
        <Box className={styles.moreItem} onClick={() => {
          songRegenerate(moreEdit.current); setMorePosition(undefined)
        }}>
          <Icon as={require('@/assets/svg/creation_redraw.svg').ReactComponent} />
          <Text>{t('Regenerate')}</Text>
        </Box>
        {/* <Box
          className={styles.moreItem}
        >
          <Icon as={require('@/assets/svg/song_share.svg').ReactComponent} />
          <Text>分享</Text>
        </Box> */}
        <Box className={`${styles.moreItem} ${styles.del}`} onClick={() => onDelete(moreEdit.current?._id || '')}>
          <Icon className={styles.icon} as={require('@/assets/svg/creation_delete.svg').ReactComponent} />
          <Text>{t('delete')}</Text>
        </Box>
      </Box>
    }
    <SongPreview
      imgList={viewInfo.current?.music.clips[0].image_large_url || ''}
      imgIndex={infoIndex || 0}
      isOpen={viewSwiper}
      onClose={() => setViewSwiper(false)}
      userAvatar={userInfo?.avatar || ''}
      userName={userInfo?.nickName || userInfo?.wiseNickName || t('WiseUser')}
      onDownload={download}
      createTime={viewInfo.current?.submitAt}
      onChangeIndex={setInfoIndex}
      onSameDraw={() => { songRegenerate(viewInfo.current); setViewSwiper(false) }}
      type={pageType}
      extendsType={viewInfo.current ? viewInfo.current.draw_type : 'custom_model'}
      songname={viewInfo.current?.music.clips[0].title}
      prompt={viewInfo.current?.music.clips[0].metadata.gpt_description_prompt}
      songlyric={viewInfo.current?.instrumental_music}
      music_model={viewInfo.current?.music_model}
      music_style={viewInfo.current?.music_style}
      audioSrc={viewInfo.current?.music.clips[0].video_url}
      lyrics={viewInfo.current?.music.clips[0].metadata.prompt}
      coverImage={viewInfo.current?.music.clips[0].image_large_url}
      onShare={onShare}
    />
  </CreationContent.History>
})

