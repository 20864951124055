import { Box, Flex, Tabs, TabList, Tab, TabPanels, TabPanel, Text, Icon, useOutsideClick } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import { useMessage } from "@/hooks/useMessage";
import { toolStore } from "@/store/tool";
import { AudioSchema, ToolSchema } from "@/@types/tools";
import style from "./index.module.scss"
import { ScrollBarBox } from "@/components/ScrollBox";
import { deleteTextAudio, getAllAudios, getTextAudios, postCreateTextAudio } from "@/api/tools";
import { ComToSpeechHistory } from "../../../tools/components/ComToSpeechHistory";
import { ComTextarea } from "../../../tools/components/ComTextarea";
import { SpeechBoBox } from "../../../tools/components/SpeechBoBox";
import { AudioNameEnum, AudioPitchEnum, AudioRateEnum, AudioRoleEnum, AudioSpeekLangeuageEnum, AudioStyleEnum } from "@/@types/audio";
import { userStore } from "@/store/user";
import { useTranslation } from "react-i18next";
import { franc } from 'franc-min';
import { SpeechPreview } from "../../Components/SpeechPreview";
import { creationStore } from "@/store/creation";
import { TranslationTitle } from "@/pages/tools/components/ComTarnsTitle";
import { readAlreadyAudio } from "@/api/creation";
import { getOSSBlobResource, saveBlobToLocal } from "@/utils/common";
import CreateBtn from "../../Components/CreationBtn";
import { uiStrore } from "@/store/ui";
import { getdate } from "@/pages/tools/components/ContentPreview";
import { errorMessages } from "../../CloneAudios";

export const usernames = [
  { name: '浩宇（男）', key: 'alloy', tips: '我是浩宇，探索星空，无畏是我的信仰', url: require('@/assets/audio/alloy.mp3') },
  { name: '文博（男）', key: 'echo', tips: '我是文博，书海中遨游，知识使我自由', url: require('@/assets/audio/echo.mp3') },
  { name: '星辰（男）', key: 'fable', tips: '我是星辰，夜空中最亮的那颗，指引着迷途的方向', url: require('@/assets/audio/fable.mp3') },
  { name: '路遥（男）', key: 'onyx', tips: '我是路遥，每一步都踏实而坚定，前方即是我的梦想', url: require('@/assets/audio/onyx.mp3') },
  { name: '佳琪（女）', key: 'nova', tips: '我是佳琪，如同春天的花朵，温柔且充满生机', url: require('@/assets/audio/nova.mp3') },
  { name: '欣怡（女）', key: 'shimmer', tips: '我是欣怡，笑容可以融化冰山，带来无尽的温暖和希望', url: require('@/assets/audio/shimmer.mp3') },
]

function selectRate(option: any) {
  const match = option.match(/^[0-9.]+/);
  return match ? match[0] : '1';
}

export function getKeyByName(selectName: any) {
  const user = usernames.find(u => u.name === selectName);
  return user ? user.key : null;
}
export function getKeyByValue(type: any, value: string) {
  for (const [key, enumValue] of Object.entries(type)) {
    if (enumValue === value) {
      return key;
    }
  }
}

export const TextToSpeech: FC = observer(() => {

  const [tool, SetTool] = useState<ToolSchema>();
  const { userInfo } = userStore;
  const { t } = useTranslation();
  const { language } = uiStrore;
  const [isLoading, setIsLoading] = useState(true);
  const [historyCache, setHistoryCache] = useState<AudioSchema[]>([]);
  const [choosesModel, setChoosesModel] = useState('wisetalker');
  const [textvalue, setTextvalue] = useState('');
  const [audioPitch, setAudioPitch] = useState<any>([]);
  const [audioRate, setAudioRate] = useState<any>([]);
  const [selectAudioPitch, setSelectAudioPitch] = useState<any>([]);
  const [selectAudioRate, setSelectAudioRate] = useState<any>([]);
  const [audiotype, SetAudiotype] = useState('MP3');
  const [speechModel, setSpeechModel] = useState<any>([]);
  const [viewSwiper, setViewSwiper] = useState(false);
  const [viewInfo, SetViewInfo] = useState<AudioSchema>(null!);
  const [quality, SetQuality] = useState('normal')
  const tabRef = useRef<any>(null);
  const tabsRef = useRef<any>(null);

  const [names, setNames] = useState<any>([]);
  const [languages, setLanguages] = useState<any>([]);
  const [styles, setStyles] = useState<any>([]);
  const [imitates, setImitates] = useState<any>([]);
  const [selectnames, setSelectNames] = useState<any>(['']);
  const [selectlanguages, setSelectLanguages] = useState<any>(['']);
  const [selectstyles, setSelectStyles] = useState<any>([]);
  const [selectimitates, setSelectImitates] = useState<any>([]);
  const { enableKeys } = creationStore.publicStore;

  const { getToolByLink } = toolStore;

  const [loading, setLoading] = useState(false);
  const message = useMessage();

  const gethis = async () => {
    const hisdata = await getTextAudios();
    setHistoryCache(hisdata.data);
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = await getToolByLink({ toollink: 'texttospeech' });
      const datas = await getAllAudios();
      const speechModels = datas.expand.speechModel;
      setSpeechModel(speechModels);
      const uniqueLanguage = Array.from(new Set(speechModels.map((model: any) => model.language)));
      setLanguages(uniqueLanguage);
      const uniqueName = Array.from(new Set(speechModels.map((model: any) => model.name)));
      setNames(uniqueName)
      const uniqueStyles = Array.from(new Set(speechModels.flatMap((model: any) => model.style)));
      setStyles(uniqueStyles)
      const uniqueImitate = Array.from(new Set(speechModels.flatMap((model: any) => model.imitate)));
      setImitates(uniqueImitate)
      const audioPitchArray = Object.entries(AudioPitchEnum).map(([key, value]) => (key));
      setAudioPitch(audioPitchArray)
      const audioRateArray = Object.entries(AudioRateEnum).map(([key, value]) => (key));
      setAudioRate(audioRateArray)
      SetTool(data[0]);
      gethis();
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleUpdateOptions = (selectedLanguage: any, selectname: any) => {
      const filteredModel = speechModel.filter((model: any) => model.language === selectedLanguage);
      const updatedNames = filteredModel.map((model: any) => model.name);
      setNames(updatedNames);
      if (!updatedNames.includes(selectname)) {
        setSelectNames(updatedNames[0]);
      } else {
        setSelectNames(selectname);
      }
      const filteredModels = filteredModel.filter((model: any) => model.name === selectname);
      setImitates([
        ...new Set(
          filteredModels.flatMap((model: any) => model.imitate)
        )
      ]);
      setSelectImitates(imitates[0])
      setStyles([
        ...new Set(
          filteredModels.flatMap((model: any) => model.style)
        )
      ]);
      setSelectStyles(styles[0])
    };
    if (choosesModel === 'wisetalker') { return; }
    handleUpdateOptions(selectlanguages, selectnames);
  }, [selectlanguages, selectnames]);
  useEffect(() => {
    if (choosesModel === 'wisetalker') {
      setSelectNames(usernames[0].name)
    } else {
      setSelectNames(names[0])
    }
  }, [choosesModel])
  useEffect(() => {
    setSelectImitates(imitates[0])
  }, [imitates])
  useEffect(() => {
    setSelectStyles(styles[0])
  }, [styles])
  useEffect(() => {
    if (textvalue.length >= 1) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [textvalue])

  //验证是否非空
  const [isDisabled, setIsDisabled] = useState(true);

  // 生成按钮
  const startTextTospeech = async () => {
    if (textvalue.length < 1) {
      return
    }
    if (userInfo && !userInfo.monVip && userInfo.balance <= 0) {
      message.warning({ title: t('ToolinsufficientBalance') })
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      let speaks = [] as any;
      if (choosesModel === 'microsoft') {
        let totalLen = 0;
        let text_contains_result = '';
        totalLen += textvalue.length;
        speaks.push({
          text: textvalue,
          langeuage: AudioSpeekLangeuageEnum[selectlanguages as keyof typeof AudioSpeekLangeuageEnum],
          rate: AudioRateEnum[selectAudioRate as keyof typeof AudioRateEnum],
          pitch: AudioPitchEnum[selectAudioPitch as keyof typeof AudioPitchEnum],
          role: AudioRoleEnum[selectimitates as keyof typeof AudioRoleEnum],
          style: AudioStyleEnum[selectstyles as keyof typeof AudioStyleEnum],
          name: AudioNameEnum[selectnames as keyof typeof AudioNameEnum]
        });
        console.log('franc:', franc(textvalue));
        if (
          franc(textvalue) === 'cmn' &&
          selectlanguages != "中文（普通话,简体）" &&
          selectlanguages != "中文（中原官话陕西,简体）" &&
          selectlanguages != "中文（粤语,简体）" &&
          selectlanguages != "中文（中原官话河南,简体）" &&
          selectlanguages != "中文（台湾普通话）" &&
          selectlanguages != "中文（东北官话,简体）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容包含中文，不能转为非中文相关的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) === 'jpn' &&
          selectlanguages != "日语（日本）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容包含日语，不能转为非日语相关的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) === 'kor' &&
          selectlanguages != "韩语（韩国）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容包含韩语，不能转为非韩语相关的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) === 'rus' &&
          selectlanguages != "俄语（俄国）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容包含俄语，不能转为非俄语相关的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) === 'tha' &&
          selectlanguages != "泰语（泰国）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容包含泰语，不能转为非泰语相关的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) === 'fra' &&
          selectlanguages != "法语（法国）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容包含法语，不能转为非法语相关的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) === 'deu' &&
          selectlanguages != "德语（德国）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容包含德语，不能转为非德语相关的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) !== 'cmn' && franc(textvalue) != 'und' &&
          (selectlanguages == "中文（普通话,简体）" ||
            selectlanguages == "中文（中原官话陕西,简体）" ||
            selectlanguages == "中文（粤语,简体）" ||
            selectlanguages == "中文（中原官话河南,简体）" ||
            selectlanguages == "中文（台湾普通话）" ||
            selectlanguages == "中文（东北官话,简体）") &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容不包含中文，不能转为中文相关的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) !== 'jpn' &&
          selectlanguages == "日语（日本）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容不包含日语，不能转为日语的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) !== 'kor' &&
          selectlanguages == "韩语（韩国）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容不包含韩语，不能转为韩语的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) !== 'rus' &&
          selectlanguages == "俄语（俄国）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容不包含俄语，不能转为俄语的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) !== 'tha' &&
          selectlanguages == "泰语（泰国）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容不包含泰语，不能转为泰语的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) !== 'fra' &&
          selectlanguages == "法语（法国）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容不包含法语，不能转为法语的语音，请修改配置再试！';
        }
        if (
          franc(textvalue) !== 'deu' &&
          selectlanguages == "德语（德国）" &&
          text_contains_result === ''
        ) {
          text_contains_result = '文本内容不包含德语，不能转为德语的语音，请修改配置再试！';
        }
        if (totalLen <= 0) {
          message.warning({ title: '文本内容为空，请输入需要转换的文字内容！' })
          setLoading(false);
          return;
        }
        if (text_contains_result != '') {
          message.warning({ title: text_contains_result })
          setLoading(false);
          return;
        }
      } else {
        speaks.push({
          text: textvalue,
          rate: selectRate(selectAudioRate),
          name: getKeyByName(selectnames)
        });
      }
      const ret = await postCreateTextAudio({
        speaks: speaks,
        defaultLangeuage: 'zh-CN',
        extends: {
          fileFormat: audiotype
        },
        toolid: tool ? tool._id : '',
        modeltype: choosesModel,
        quality: choosesModel === 'wisetalker' ? quality : ''
      });
      message.success({ title: '创建任务完成，请点击查看记录' })
      Setupdatehis(true);
    } catch (error: any) {
      for (const [key, messageKey] of Object.entries(errorMessages)) {
        if (error.message.includes(key)) {
          message.error({ title: `您的输入包含${t(messageKey)},请修改后重试` });
          setLoading(false);
          return; // 一旦找到匹配项，立即退出函数
        }
      }
      message.error({ title: error.message || '创建任务失败，请稍后再试！' });
    }
    setLoading(false);
  };
  //返回历史记录
  const setViewhistory = async (item: any) => {

    setChoosesModel(item.apiType)
    if (item.apiType === 'wisetalker') {
      if (tabsRef.current) {
        tabsRef.current.click();
      }
      const user = usernames.find(user => user.key === item.speaks[0].name);
      setSelectNames(user ? user.name : usernames[0].name)
      setTextvalue(item.speaks[0].text)
      setSelectAudioRate(item.speaks[0].rate ? `${item.speaks[0].rate}倍语速` : '默认(1倍语速)')
      SetQuality(item.quality)
      SetAudiotype(item.extends.fileFormat)
    } else {
      setChoosesModel(item.apiType)
      if (tabRef.current) {
        tabRef.current.click();
      }
      setTextvalue(item.speaks[0].text)
      setSelectLanguages(getKeyByValue(AudioSpeekLangeuageEnum, item.speaks[0].langeuage))
      setSelectAudioPitch(getKeyByValue(AudioPitchEnum, item.speaks[0].pitch))
      setSelectAudioRate(getKeyByValue(AudioRateEnum, item.speaks[0].rate))
      setTimeout(() => {
        setSelectNames(getKeyByValue(AudioNameEnum, item.speaks[0].name))
        setSelectImitates(getKeyByValue(AudioRoleEnum, item.speaks[0].role))
        setSelectStyles(getKeyByValue(AudioStyleEnum, item.speaks[0].style))
      }, 100);
      SetAudiotype(item.extends.fileFormat)
    }
  };

  const getType = (extendsType: any) => {
    let extendsTitle = t('Microsoft');
    if (extendsType === 'microsoft') {
      extendsTitle = t('Microsoft');
    } else if (extendsType === 'wisetalker') {
      extendsTitle = t('Wisetalker');
    }
    return extendsTitle
  }
  // 下载音频
  const download = async (url: string, extendsType: any) => {
    if (!url) return;
    const type = getType(extendsType)
    const date = getdate();
    try {
      const res = await getOSSBlobResource(url);
      const suggestedName = `创作_文本转语音_${type}_${date}.mp3`
      await saveBlobToLocal(res, suggestedName);
      message.success({ title: t('DownloadSuccess') });
    } catch (err: any) {
      if (err.message !== 'The user aborted a request.') {
        message.error({ title: t('DownloadFailed') });
      }
    }
  }


  const [morePosition, setMorePosition] = useState<undefined | { x: number, y: number, isFirstEdit: boolean, index: number }>();
  const moreEdit = useRef<AudioSchema>();
  const editRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: editRef,
    handler: () => {
      setMorePosition(undefined);
    }
  })
  // 点击显示更多操作
  const onMore = (e: React.MouseEvent<SVGElement, MouseEvent>, index: number, item: any) => {
    e.stopPropagation();
    let isFirstEdit = false;
    if (item.status === 2) {
      isFirstEdit = true
    }
    //@ts-ignore
    let { x, y } = e.target.getBoundingClientRect();
    const _BoxHeigh = 150, _BoxWidth = 140;
    if (e.nativeEvent.y + _BoxHeigh > window.innerHeight) {
      y = y - _BoxHeigh;
    } else {
      y = y + 15;
    }
    if (e.nativeEvent.x + _BoxWidth > window.innerWidth) {
      x = x - _BoxWidth;
    } else {
      x = x + 15;
    }
    setMorePosition({ x, y, isFirstEdit, index });
    moreEdit.current = item;
  }

  const [updatehis, Setupdatehis] = useState(false);
  const intervalIdRef = useRef<any>(null);
  useEffect(() => {
    const fetchData = async () => {
      const hisdata = await getTextAudios();
      setHistoryCache(hisdata.data);

      const hasActiveStatus = hisdata.data.some((item: any) => item.status === 1 || item.status === 0);
      if (hasActiveStatus === false && intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        Setupdatehis(false);
      }
    };
    if (updatehis) {
      intervalIdRef.current = setInterval(fetchData, 3000); // 每5秒查询一次
      fetchData(); // 初次加载数据
    }
    return () => clearInterval(intervalIdRef.current); // 清理函数停止轮询
  }, [updatehis])

  if (isLoading) {
    return <div>Loading...</div>; // 或者你的加载指示器
  }

  const reset = () => {
    setTextvalue('');
    setSelectNames(usernames[0].name);
    setSelectAudioRate('默认(1倍语速)');
    SetQuality('normal');
    SetAudiotype('MP3');
    setChoosesModel('wisetalker');
    tabsRef.current.click();
  }
  const { showNav } = creationStore.publicStore
  return (
    <Flex key={'langtranslations'} w={!showNav ? '100%' : 'calc(100% - 340px)'} className={style.toollistBox}>
      <Flex className={style.listBox}>
        <TranslationTitle
          icon={tool?.icon}
          title={language === 'zh' ? tool ? tool.name.toString() : '' : (tool && tool.name_more_language) ? tool.name_more_language.en.toString() : ''}
        />
        <ScrollBarBox display={'flex'} flexDir={'column'} height={'100%'}>
          <ComTextarea
            title={'请输入文本*'}
            prompt={'请输入文本内容'}
            textvalue={textvalue}
            textchange={setTextvalue}
            maxCount={500}
            rows={5}
            required={true}
          />
          <Text w={'556px'} m={'30px auto 10px'} className={style.title}>模型选择</Text>
          <Tabs className={style.tabs}>
            <TabList className={style.tabList}>
              <Tab className={style.tab} onClick={() => setChoosesModel('wisetalker')} ref={tabsRef}>
                <Box className={choosesModel === 'wisetalker' ? style.modeliconboxs : style.modeliconbox} >
                  <Icon as={require('@/assets/svg/speechWise.svg').ReactComponent} />
                </Box>
                <Text>文思GPT</Text>
                {choosesModel === 'wisetalker' &&
                  <Icon className={style.selectSpeechModel} as={require('@/assets/svg/selectSpeechModel.svg').ReactComponent} />
                }
              </Tab>
              <Tab className={style.tab} onClick={() => setChoosesModel('microsoft')} ref={tabRef}>
                <Box className={choosesModel === 'microsoft' ? style.modeliconboxs : style.modeliconbox} >
                  <Icon as={require('@/assets/svg/speechMic.svg').ReactComponent} />
                </Box>
                <Text>微软语音</Text>
                {choosesModel === 'microsoft' &&
                  < Icon className={style.selectSpeechModel} as={require('@/assets/svg/selectSpeechModel.svg').ReactComponent} />
                }
              </Tab>
            </TabList>
            <TabPanels className={style.tabPanels}>
              <TabPanel className={style.tabPanel}>
                <Box className={style.titlebox}>
                  <Text className={style.title}>
                    参数设置
                  </Text>
                </Box>
                <SpeechBoBox
                  selectLanguage={'自动生成语言进行生成'}
                  title={'语言'}
                  langs={['自动生成语言进行生成']}
                  SetSelectLanguage={() => { }}
                  width={'456px'}
                  readonly={'true'}
                  tipscontents={'当选择文思GPT作为模型时，其具备自动识别转语音文本中不同语种的能力，能够对各种语言实现兼容和转化为对应的语音。'}
                ></SpeechBoBox>
                <SpeechBoBox
                  selectLanguage={selectnames}
                  title={'语言角色'}
                  langs={usernames}
                  SetSelectLanguage={(value: any) => setSelectNames(value)}
                  type={'wise'}
                  width={'456px'}
                ></SpeechBoBox>
                <SpeechBoBox
                  selectLanguage={selectAudioRate}
                  title={'语速'}
                  langs={audioRate}
                  SetSelectLanguage={(value: any) => setSelectAudioRate(value)}
                  width={'456px'}
                ></SpeechBoBox>
                <Flex className={style.allbox}>
                  <Box className={style.titlebox}>
                    <Text className={style.title}>
                      生成质量
                    </Text>
                  </Box>
                  <Flex className={style.listboxs}>
                    <Box display={'flex'} alignItems={'center'} mr={'15px'} cursor={'pointer'} onClick={() => { SetQuality('normal') }}>
                      <Box bg={quality === 'normal' ? '#565CFA' : '#ccc'} className={style.selectBox}>
                        <Box className={style.selectbox}></Box>
                      </Box>
                      普通
                    </Box>
                    <Box display={'flex'} alignItems={'center'} cursor={'pointer'} onClick={() => { SetQuality('hd') }}>
                      <Box bg={quality === 'hd' ? '#565CFA' : '#ccc'} className={style.selectBox}>
                        <Box className={style.selectbox}></Box>
                      </Box>
                      高级版（富有感情）
                    </Box>
                  </Flex>
                </Flex>
              </TabPanel>
              <TabPanel className={style.tabPanel}>
                <Box className={style.titlebox}>
                  <Text className={style.title}>
                    参数设置
                  </Text>
                </Box>
                <Flex flexDirection={'column'} w={'100%'}>
                  <SpeechBoBox
                    selectLanguage={selectlanguages}
                    title={'语言'}
                    langs={languages}
                    SetSelectLanguage={(value: any) => setSelectLanguages(value)}
                    tipscontents={'在选择微软语音作为模型时，为了所有文本都能被正确朗读，请确保选择与文本内容相匹配的语言选项 。'}
                    width={'456px'}
                  ></SpeechBoBox>
                  <SpeechBoBox
                    selectLanguage={selectnames}
                    title={'语言角色'}
                    langs={names}
                    SetSelectLanguage={(value: any) => setSelectNames(value)}
                    width={'456px'}
                    isaction={true}
                  ></SpeechBoBox>
                  <Flex>
                    <SpeechBoBox
                      selectLanguage={selectAudioRate}
                      title={'选择语速'}
                      langs={audioRate}
                      SetSelectLanguage={(value: any) => setSelectAudioRate(value)}
                      width={'156px'}
                    ></SpeechBoBox>
                    <Box w={'20px'}></Box>
                    <SpeechBoBox
                      selectLanguage={selectAudioPitch}
                      title={'选择语调'}
                      langs={audioPitch}
                      SetSelectLanguage={(value: any) => setSelectAudioPitch(value)}
                      width={'156px'}
                    ></SpeechBoBox>
                  </Flex>
                  <Flex>
                    <SpeechBoBox
                      selectLanguage={selectstyles}
                      title={'选择风格'}
                      langs={styles}
                      SetSelectLanguage={(value: any) => setSelectStyles(value)}
                      width={'156px'}
                      boxwidth={'193px'}
                    ></SpeechBoBox>
                    <Box w={imitates.length > 1 ? '20px' : '0px'}></Box>
                    {imitates.length > 1 &&
                      <SpeechBoBox
                        selectLanguage={selectimitates}
                        title={'声音模仿'}
                        langs={imitates}
                        SetSelectLanguage={(value: any) => setSelectImitates(value)}
                        width={'156px'}
                      ></SpeechBoBox>
                    }
                  </Flex>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Flex className={style.allbox}>
            <Box className={style.titlebox}>
              <Text className={style.title}>
                生成格式
              </Text>
            </Box>
            <Flex className={style.listboxs}>
              <Box className={`${audiotype === 'MP3' ? style.selbox : style.sexbox}`} onClick={() => { SetAudiotype('MP3') }}>
                MP3
              </Box>
              <Box className={`${audiotype === 'WAV' ? style.selbox : style.sexbox}`} onClick={() => { SetAudiotype('WAV') }}>
                WAV
              </Box>
            </Flex>
          </Flex>
        </ScrollBarBox>
        <Flex className={style.footbutton}>
          <CreateBtn
            loading={loading}
            isMaintain={!enableKeys['wenbenzhuanyuyin']}
            disabled={isDisabled}
            onClick={startTextTospeech}
            price={(choosesModel === 'wisetalker' && quality === 'hd') ? 40 : 20}
            isVip={userInfo?.monVip}
            icon={<Icon as={require('@/assets/svg/create_audio.svg').ReactComponent} />}
            onReset={reset}
            onVip={() => uiStrore.setOpenVip(true)}
          />
        </Flex>
      </Flex>
      <Flex className={style.previewBox}>
        <ComToSpeechHistory
          key={tool?.name}
          type={'txt'}
          setViewhistory={setViewhistory}
          setHistoryCache={setHistoryCache}
          historytitle='language'
          historycontent='title'
          historyCache={historyCache}
          onclick={async (item: any) => {
            SetViewInfo(item);
            setViewSwiper(true)
            if (item.status === 2 && item.isNotReading === true) {
              await readAlreadyAudio({ audiotype: 'text', _id: item._id });
              gethis()
            }
          }}
          onMore={(e: any, index: any, item: any) => { e.stopPropagation(); onMore(e, index, item) }}
        />
      </Flex>
      {
        morePosition && <Box ref={editRef} className={style.moreEdit} style={{ left: morePosition.x, top: morePosition.y }}>
          {morePosition.isFirstEdit &&
            <Box
              className={style.moreItem}
              onClick={() => {
                download(moreEdit.current?.audioUrl || '', moreEdit.current?.apiType);
                setMorePosition(undefined)
              }}
            >
              <Icon as={require('@/assets/svg/creation_download.svg').ReactComponent} />
              <Text>{t('downloadvoice')}</Text>
            </Box>
          }
          <Box className={style.moreItem} onClick={() => {
            setViewhistory(moreEdit.current); setMorePosition(undefined)
          }}>
            <Icon as={require('@/assets/svg/song_regenerate.svg').ReactComponent} />
            <Text>{t('Regenerate')}</Text>
          </Box>
          <Box className={style.deletemoreItem}
            onClick={async () => {
              setMorePosition(undefined);
              await deleteTextAudio({ _id: moreEdit.current?._id || '' });
              const res = await getTextAudios()
              setHistoryCache(res.data);
              message.success({ title: t('deleteSuccess') });
            }}>
            <Icon as={require('@/assets/svg/song_delete.svg').ReactComponent} />
            <Text>{t('delete')}</Text>
          </Box>
        </Box>
      }
      <SpeechPreview
        isOpen={viewSwiper}
        onClose={() => setViewSwiper(false)}
        userAvatar={userInfo?.avatar || ''}
        userName={userInfo?.nickName || userInfo?.wiseNickName || t('WiseUser')}
        onDownload={download}
        createTime={viewInfo?.time}
        onSameDraw={() => { setViewSwiper(false) }}
        extendsType={viewInfo ? viewInfo.apiType : 'microsoft'}
        username={viewInfo?.speaks[0]?.name ?? ''}
        audioSrc={(viewInfo && viewInfo.audioUrl) ? viewInfo.audioUrl : ''}
        text={viewInfo?.speaks[0]?.text ?? ''}
        audioRate={viewInfo?.speaks[0]?.rate ?? ''}
        audioPitch={viewInfo?.speaks[0]?.pitch ?? ''}
        imitates={viewInfo?.speaks[0]?.role ?? ''}
        speeckstyles={viewInfo?.speaks[0]?.style ?? ''}
        format={viewInfo?.extends?.fileFormat ?? ''}
        quality={viewInfo?.quality ?? ''}
        langeuage={viewInfo?.speaks[0]?.langeuage ?? ''}
      />
    </Flex>
  )
})