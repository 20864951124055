import TaskMeesage from '@/components/TaskMessage';
import { useToast as uToast, UseToastOptions } from '@chakra-ui/react';

export const useTaskMessage = (props?: UseToastOptions) => {
  const sameToast = (type: 'warning' | 'tips' | 'success' | 'error') => {
    return uToast({
      containerStyle: {
        border: '10px'
      },
      position: 'top',
      duration: 5000,
      isClosable: true,
      render: (props) => {
        return <TaskMeesage
          status={type}
          title={props?.title}
          onClose={props.onClose}
        />
      },
      ...props,
    });
  }

  const success = sameToast('success')
  const warning = sameToast('warning')
  const tips = sameToast('tips')
  const error = sameToast('error')

  return {
    success,
    warning,
    tips,
    error
  };
};
