import { FC, useMemo } from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { WiseMJ } from './Wise';
import { SubNav } from './Components';
import { creationStore, CreationType } from '@/store/creation';
import { WiseSD } from './SD';
import { observer } from 'mobx-react-lite';
import { CreationSquare } from './Square';
import { CreationDallE3 } from './DallE3';
import { WiseSD3 } from './SD3';
import { WiseRunway } from './Runway';
import { Enlarge } from './Tools/Enlarge';
import { BackGround } from './Tools/Bg';
import { WiseImage } from '@/components/Image';
import { WiseSUNO } from './Suno';
import { TextToSpeech } from './Tools/TextToSpeech';
import { CloneAudios } from './CloneAudios';
import { OldPhoto } from './Tools/OldPhoto';


import styles from './index.module.scss';
const Creation: FC = observer(() => {
  const { pageType } = creationStore.publicStore;
  const { showNav, setShowNav } = creationStore.publicStore

  const renderPage = useMemo(() => {
    switch (pageType) {
      case 'Square':
        return <CreationSquare />
      case CreationType.MJ:
        return <WiseMJ />;
      case CreationType.E3:
        return <CreationDallE3 />
      case CreationType.SD3:
        return <WiseSD3 />
      case CreationType.GEN3:
        return <WiseRunway />
      case CreationType.ENLARGE:
        return <Enlarge />
      case CreationType.BG:
        return <BackGround />
      case CreationType.TextToSpeech:
        return <TextToSpeech />
      case CreationType.SUNO:
        return <WiseSUNO />
      case CreationType.CloneAudio:
        return <CloneAudios />
      case CreationType.OldPhoto:
        return <OldPhoto />
      default:
        return <WiseSD />
    }
  }, [pageType])
  return <Box className={styles.creation}>
    <SubNav />
    <Box className={`${styles.nav}  ${!showNav ? styles.hide : ''}`} onClick={() => setShowNav(!showNav)}>
      <Box className={styles.navBox}>
        <WiseImage className={styles.navOp} src={require('@/assets/png/nav_op_bg.png')} />
        <Icon className={styles.navOpIcon} as={require('@/assets/svg/right.svg').ReactComponent} />
      </Box>
    </Box>
    {renderPage}
  </Box>
})

export default Creation

