import { Flex, Box, Text, Icon, Image } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import styles from "./index.module.scss";
import { ScrollBarBox } from "@/components/ScrollBox";
import { useTranslation } from "react-i18next";
import RotatingCircles from "@/components/RotatingCircles";


export type ExportChatType = 'md' | 'pdf' | 'html' | 'word';
export const ComAIpptHistory: FC<any> = observer(({
  historyCache,
  onclick,
  onMore,
  actionLoading
}: {
  historyCache: any[];
  onclick: (item: any) => void;
  onMore: any;
  actionLoading: boolean
}) => {
  const { t } = useTranslation();
  return (
    <Flex className={styles.hisFlex}>
      <Box className={styles.centerItemsbox}>
        <Text className={styles.title}>{t('history')}</Text>
      </Box>
      <ScrollBarBox className={styles.hisBox}>
        {(historyCache.length > 0 && !actionLoading) && historyCache.map((item, index) => {
          const statusDesc = (() => {
            switch (item.status.toString()) {
              case '0':
                return t('TobeStarted');
              case '1':
                return t('大纲生成中...');
              case '2':
                return t('大纲已生成，立即前往PPT制作');
              case '3':
                return t('PPT生成中...');
              case '4':
                return t('有新PPT已生成');
              case '5':
                return t('大纲生成失败（消耗点数已为您返还）');
              case '6':
                return t('PPT生成失败（消耗点数已为您返还）');
              default:
                return t('BuildFailure');
            }
          })();
          const statusColor = (() => {
            switch (item.status.toString()) {
              case '0':
              case '1':
              case '3':
                return '#FF951A';
              case '2':
              case '4':
                return '#689F38';
              case '5':
              case '6':
                return '#F76560';
              default:
                return '#FF951A';
            }
          })();
          return (
            <Flex key={item._id} className={styles.historyValue} cursor={item.status === 2 ? 'pointer' : ''}
              onClick={async () => { if (item.status !== 3) { onclick(item) } }}>
              {item.status === 1 && <Flex className={styles.prohibitBox} onClick={(e) => e.stopPropagation()}></Flex>}
              <Flex className={styles.historyValuebox}>
                <Box className={styles.hisTitleBox}>
                  {(item.isReading && item.status === 2) ?
                    <Box className={styles.hisTitleBox}>
                      <Text>
                        {item.outline ? item.outline.title : ''}
                      </Text>
                    </Box>
                    : (item.isReading && item.status === 4) ?
                      <Box className={styles.hisTitleBox}>
                        <Text>
                          {item.outline ? item.outline.title : ''}
                        </Text>
                      </Box>
                      : item.status === 1 ?
                        <Flex justify={'center'} alignItems={'center'}>
                          <RotatingCircles />
                          <Text ml={'5px'}>{t('大纲生成中...')}</Text>
                        </Flex>
                        : item.status === 3 ?
                          <Flex justify={'center'} alignItems={'center'}>
                            <RotatingCircles />
                            <Text ml={'5px'}>{t('PPT生成中...')}</Text>
                          </Flex>
                          :
                          <Flex color={statusColor}>
                            {statusDesc}
                          </Flex>
                  }
                  <Icon as={require('@/assets/svg/speechMore.svg').ReactComponent} className={styles.more}
                    onClick={(e: any) => { e.stopPropagation(); onMore && onMore(e, index, item) }} />
                </Box>
                {((!item.isReading && item.status === 4) || item.status === 2 || item.status === 6) &&
                  <Box className={styles.hisTitleBox}>
                    <Text>
                      {item.outline ? item.outline.title : ''}
                    </Text>
                  </Box>
                }
                {(item.outline && item.status !== 3) &&
                  <Text className={styles.hisContentsBox}>
                    {item.outline.subTitle}
                  </Text>
                }
                {item.status === 4 &&
                  <Image cursor={'pointer'} w={'190px'} h={'107px'} borderRadius={'10px'} mb={'10px'} src={item.coverImgSrc} />
                }
                <Flex className={styles.hisDateBox}>
                  <Text>
                    {item.creatdate}
                  </Text>
                  <Flex >
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )
        })}
        {(historyCache.length <= 0 && !actionLoading) &&
          <Flex className={styles.fullbox}>
            <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
            <Text className={styles.prompt}>{t('NoHistory')}</Text>
          </Flex>
        }
        {actionLoading &&
          Array.from({ length: 4 }).map((_, index) => (
            <Box key={index} className={styles.skeletonCard}>
              <Box className={styles.skeletonContent}>
                <Box className={styles.skeletonTitle}></Box>
                <Box className={styles.skeletonDescription}></Box>
                <Box className={styles.skeletonIcon}></Box>
              </Box>
              <Box className={styles.skeletonHeart}></Box>
            </Box>
          ))}
      </ScrollBarBox>
    </Flex>
  );
})

