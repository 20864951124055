import { CModal } from "@/components/Modal"
import { CreationType } from "@/store/creation"
import { ObjectId } from "@/utils/chat"
import { streamFetch } from "@/utils/stream"
import { Box, Icon, Text } from "@chakra-ui/react"
import { FC, useEffect, useRef, useState } from "react"

import styles from './index.module.scss'
import { CButton } from "@/components/Button"
import { ScrollBarBox } from "@/components/ScrollBox"
import { useTranslation } from "react-i18next"

interface CreationPromptOptimizationProps {
  type: CreationType
  originPrompt: string
  open: boolean
  onClose: () => void
  onOk: (prompt: string) => void
}
export const CreationPromptOptimization: FC<CreationPromptOptimizationProps> = (props) => {

  const { type, originPrompt, open, onOk, onClose } = props;
  const generateRef = useRef<HTMLDivElement>(null)
  const controller = useRef(new AbortController());
  const [isFetch, setIsFetch] = useState<false | number>(false)
  const [fetchloading, setFecthLoading] = useState(false);
  const [finishedPrompt, setFinishedPrompt] = useState('');
  const { t } = useTranslation()

  useEffect(() => {
    if (!generateRef.current) {
      (async () => {
        await new Promise((resolve: (val: boolean) => void) => {
          setTimeout(() => {
            resolve(true)
          }, 100);
        })
        setIsFetch(Math.random())
      })()
    } else {
      chatGptPrompt()
    }
    return () => {
      controller.current.abort();
    }
    // eslint-disable-next-line
  }, [originPrompt, isFetch])


  const chatGptPrompt = async () => {
    const abortSignal = new AbortController();
    controller.current = abortSignal

    try {
      generateRef.current!.innerText = ''
      setFecthLoading(true)

      const mj_system_prompt = `mid journey是一款AI绘画工具，只要输入你想要的文字，就能通过人工智能产出相对应的图片，我希望你作为Mid.Jourey程序的提示词(prompt)生成器。你的工作是提供详细和有创意的描述，以激发人工智能的独特和有趣的图像。请记住，人工智能能够理解广泛的语言，并能解释抽象的概念，所以请自由发挥想象力和描述力，尽可能地发挥。例如，你可以描述一个未来城市的场景，或一个充满奇怪生物的超现实景观，你的描述越详细、越有想象力，产生的图像就越有趣。mid journey的提示词标准公式为:(image we're prompting).(5 descriptive keywords).(camera tepy).(camera lensteyp).(time of day).(style of photograph).(type of film)请记住这个公式，后续统一使用该公式进行prompt生成，接下来我会给你发一句话，使用以上公式重新描述以上场景，只输出英文的提示词`
      const sd_system_prompt = `我正在使用一个叫做SD的AI图像生成工具。我想让你充当关键词生成器。我将在我想生成的主题之前添加"/"你会生成各种关键词。例如，如果我输入"/跑车图像"，你将生成关键词，如"Realistic true details photography of Sports car,laction shots, speed motion blur, racing tracks, urban environments, scenic roads, dramatic skies"。接下来我会给你一个主题，请按照以上的格式返回答给我`
      await streamFetch({
        data: {
          toolId: new ObjectId().toString(),
          stream: true,
          model: 'gpt-4o-mini',
          messages: [
            {
              _id: new ObjectId().toString(),
              role: 'system',
              content: type === CreationType.MJ ? mj_system_prompt : sd_system_prompt
            },
            {
              _id: new ObjectId().toString(),
              role: 'user',
              content: originPrompt,
            },
            {
              _id: new ObjectId().toString(),
              role: 'assistant',
              content: '',
            }
          ]
        },
        onMessage: text => {
          generateRef.current!.innerText += text
        },
        abortSignal
      })
      if (!generateRef || !generateRef.current) return;
      const prompt: string = generateRef.current!.innerText.replaceAll('#', ' ').replaceAll('\n', ' ')
      setFinishedPrompt(prompt)
    } catch (err: any) {
      generateRef.current!.innerText = originPrompt
    }
    setFecthLoading(false)
  }

  return <CModal isOpen={open} onClose={onClose}>
    <Box className={styles.optimization}>
      <Box className={styles.title}>
        <Box className={styles.iconText}>
          <Icon as={require('@/assets/svg/feiji_orange.svg').ReactComponent} className={styles.close} />
          <Text fontSize="lg" fontWeight="bold">{t('creation.OptimizationPrompt')}</Text>
        </Box>
        <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} className={styles.close} onClick={onClose} />
      </Box>
      <Box className={styles.generateArea}>
        <ScrollBarBox>
          <Box ref={generateRef}>
            {originPrompt}
          </Box>
        </ScrollBarBox>
      </Box>
      <Box className={styles.btns}>
        <CButton size='md' theme='outline' className={styles.cancel} onClick={onClose}>{t('cancel')}</CButton>
        {
          fetchloading ? <CButton size='md' theme='primary' className={styles.ok} onClick={() => { controller.current.abort() }}>
            <Box className={styles.loading}></Box>
          </CButton>
            : <CButton size='md' theme='primary' className={styles.ok} onClick={() => {
              onOk(finishedPrompt);
              onClose()
            }}>{t('confirm')} </CButton>
        }
      </Box>
    </Box>
  </CModal>
}