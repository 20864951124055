import { Flex, Box, Text, Textarea, Image, MenuList, Menu, MenuItem, useDisclosure, Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { useMessage } from "@/hooks/useMessage";
import { CButton } from "@/components/Button";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { chatStore } from "@/store/chat";
import { voiceBroadcast } from "@/utils/chat";
import { userStore } from "@/store/user";
import { CModal } from "@/components/Modal";
import Markdown from "@/components/Markdown";
import { ScrollBarBox } from "@/components/ScrollBox";
import RotatingCircles from "@/components/RotatingCircles";
import i18n from '@/utils/i18n';
import { useTranslation } from "react-i18next";
import { marked } from 'marked';

export type ExportChatType = 'md' | 'pdf' | 'html' | 'word';
export const getdate = () => {
  let today = new Date();
  let month = String(today.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
  let day = String(today.getDate()).padStart(2, '0');
  let hour = String(today.getHours()).padStart(2, '0');
  let minute = String(today.getMinutes()).padStart(2, '0');
  let second = String(today.getSeconds()).padStart(2, '0');
  let millisecond = String(today.getMilliseconds()).padStart(2, '0');
  return `${month}${day}${hour}${minute}${second}${millisecond}`
}
export const Contentpreview: FC<any> = observer(({
  title,
  titlePrompt,
  bindprompt,
  download,
  downloadtitle,
  loading,
  webPageUrl,
  onclose,
  isContent,
  buttonpd,
  isPlugin,
  riskInfo,
  model
}: {
  title: string;
  bindprompt: any;
  download?: boolean;
  downloadtitle?: string,
  loading?: boolean;
  titlePrompt?: string;
  webPageUrl?: string;
  onclose?: () => void;
  isContent: boolean;
  buttonpd: boolean;
  isPlugin?: boolean
  riskInfo?: ChatInfoHistory['contentCheck']
  model: string
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userInfo } = userStore;
  const message = useMessage();
  const { setPlayingId, playingId } = chatStore;
  const androidRef = useRef<{ ttsWS: WebSocket; audioPlayer: any; }>();
  const {
    isOpen: isOpenDownload,
    onClose: onCloseDownload,
    onOpen: onOpenDownload
  } = useDisclosure();
  const { t } = useTranslation();

  const icons = [
    {
      key: '清空',
      enkey: 'Clear',
      icon: require('@/assets/svg/toolsicon/delete.svg').ReactComponent,
      hovericon: require('@/assets/svg/toolsicon/deletenot.svg').ReactComponent,
      selecticon: require('@/assets/svg/toolsicon/deleteed.svg').ReactComponent,
    },
    {
      key: '复制',
      enkey: 'Copy',
      icon: require('@/assets/svg/toolsicon/copy.svg').ReactComponent,
      hovericon: require('@/assets/svg/toolsicon/copynot.svg').ReactComponent,
      selecticon: require('@/assets/svg/toolsicon/copyed.svg').ReactComponent,
    },
    {
      key: '朗读',
      enkey: 'Read',
      icon: require('@/assets/svg/toolsicon/play.svg').ReactComponent,
      hovericon: require('@/assets/svg/toolsicon/playnot.svg').ReactComponent,
      selecticon: require('@/assets/svg/toolsicon/played.svg').ReactComponent
    },
    {
      key: '下载',
      enkey: 'Download',
      icon: require('@/assets/svg/toolsicon/download.svg').ReactComponent,
      hovericon: require('@/assets/svg/toolsicon/downloadnot.svg').ReactComponent,
      selecticon: require('@/assets/svg/toolsicon/downloaded.svg').ReactComponent
    }
  ];
  // 复制内容
  const onclickCopy = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      message.success({ title: t('CopySuccess') })
    } catch (err) {
      message.error({ title: t('CopyFaild') })
    }
  };
  const play = (id: any) => {
    // console.log('playid', playingId)
    if (playingId === id) {
      if (window['speechSynthesis'] === undefined) {
        androidRef.current?.ttsWS.close()
        androidRef.current?.audioPlayer.reset()
      } else {
        window.speechSynthesis.cancel();
      }
      setPlayingId('');
    } else {
      if (window['speechSynthesis'] === undefined) {
        // androidPlayAudio(value) // 安卓播放音频 暂时不支持
      } else {
        voiceBroadcast({
          text: bindprompt.current.value,
          onEndCallback: () => {
            setPlayingId(''); // 朗读结束时更新状态
          }
        });
      }
      setPlayingId(id);
    }
  }
  //删除内容
  const delChatRecord = () => {
    if (bindprompt.current !== null) {
      bindprompt.current.value = '';
    }
    setIsModalOpen(false)
  };

  // export chat data
  const onclickExportChat = useCallback(
    (type: ExportChatType) => {
      const getHistoryHtml = () => {
        const historyText = bindprompt.current.value;
        if (!historyText) return '';

        const encodedText = historyText.replace(/ /g, '&nbsp;').replace(/\n/g, '<br>');

        return encodedText;
      };

      const getHistoryPlainText = () => {
        const historyText = bindprompt.current.value;
        if (!historyText) return '';
        const plainText = historyText.replace(/<[^>]+>/g, ''); // 去除所有 HTML 标签
        return plainText;
      };

      let date = getdate();
      const anyWindow: any = window;
      const map: Record<ExportChatType, () => void> = {
        md: async () => {
          const blob = new Blob([bindprompt.current.value], { type: 'text/markdown' });
          try {
            const fileName = `工具_${downloadtitle}_${model}_${date}.md`;
            if (anyWindow.showSaveFilePicker) {
              //@ts-ignore
              const handle = await window.showSaveFilePicker({
                suggestedName: fileName,
                types: [{
                  description: 'Markdown file',
                  accept: { 'text/markdown': ['.md'] }
                }]
              });
              const writableStream = await (handle as any).createWritable();
              await writableStream.write(blob);
              await writableStream.close();
            } else {
              // Safari 浏览器不支持 showSaveFilePicker，使用 a 元素替代
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }, 0);
            }
            message.success({ title: t('SaveSuccess') })
          } catch (err) {
            console.log('下载错误', err);
            message.warning({ title: t('CancelExport') })
          }
        },
        html: async () => {
          let htmlContent = getHistoryHtml();
          if (htmlContent === undefined) {
            return; // 终止操作
          }
          if (!htmlContent.includes('<meta charset="UTF-8">')) {
            htmlContent = '<meta charset="UTF-8">' + htmlContent;
          }
          const blob = new Blob([htmlContent], { type: 'text/html;charset=utf-8' });
          const fileName = `工具_${downloadtitle}_${model}_${date}.html`
          try {
            if (anyWindow.showSaveFilePicker) {
              // @ts-ignore
              const handle = await window.showSaveFilePicker({
                suggestedName: fileName,
                types: [{
                  description: 'HTML file',
                  accept: { 'text/html': ['.html'] },
                }],
              });
              const writableStream = await (handle as any).createWritable();
              await writableStream.write(blob);
              await writableStream.close();
            } else {
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }, 0);
            }
            message.success({ title: t('SaveSuccess') })
          } catch (err) {
            message.warning({ title: t('CancelExport') })
          }
        },
        pdf: async () => {
          const pdfContent = getHistoryHtml();
          const text = marked(pdfContent)
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          document.body.appendChild(iframe);
          const doc = iframe.contentWindow?.document;
          if (!doc) return;
          doc.body.innerHTML = text.toString();
          const win = iframe.contentWindow;
          win.print();
          document.body.removeChild(iframe);
        },
        word: async () => {
          const wordContent = getHistoryPlainText();
          if (wordContent === undefined) {
            return; // 终止操作
          }
          const blob = new Blob([wordContent], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          const fileName = `工具_${downloadtitle}_${model}_${date}.docx`
          try {
            if (anyWindow.showSaveFilePicker) {
              // @ts-ignore
              const handle = await window.showSaveFilePicker({
                suggestedName: fileName,
                types: [{
                  description: 'Microsoft Word document',
                  accept: { 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] },
                }],
              });
              const writableStream = await (handle as any).createWritable();
              await writableStream.write(blob);
              await writableStream.close();
            } else {
              const url = URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();
              setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }, 0);
            }
            message.success({ title: t('SaveSuccess') })
          } catch (err) {
            message.warning({ title: t('CancelExport') })
          }
        },
      };
      map[type]();

    },
    [bindprompt]
  );
  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleOutsideClick(event: any) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onCloseDownload();
      }
    }

    if (isOpenDownload) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    // 在组件卸载时移除事件监听器
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpenDownload]);

  const ButtonWithIcon = ({ keyName, onClick, isDisabled }: { keyName: any, onClick: any, isDisabled: any }) => {
    const [isHovered, setIsHovered] = useState(false);

    const iconData = icons.find(icon => {
      if (icon.key === keyName || icon.enkey === keyName) {
        return icon
      }
    })!;

    let IconComponent = iconData.icon;
    if (isDisabled) {
      IconComponent = iconData.hovericon;
    } else if (isHovered) {
      IconComponent = iconData.selecticon;
    }
    return (
      <Flex
        className={`${styles.button} ${isDisabled ? styles.disabled : ''}`}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <IconComponent />
        <Text whiteSpace="nowrap">{t(keyName)}</Text>
      </Flex>
    );
  };

  const value = bindprompt.current ? bindprompt.current.value : ''
  const isFunctionCall = Array.isArray(value);
  let valStr = ''
  if (isFunctionCall) {
    value.forEach(str => {
      const noText = str.startsWith('\n\n```function_call') || str.endsWith('```\n\n') || str.startsWith('\n\n```finish_reason')
      if (!noText) {
        valStr = str;
      }
    })
  }
  const val = (isPlugin || isFunctionCall) ? valStr : value;
  const riskValue = riskInfo?.status !== false ? val : (`${t("ContentMayContains")}:${riskInfo.labels?.split(',').map(str => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')} ` || t('ContentRiskBlocked'))
  return (
    <Flex className={styles.flexColumn}>
      <Box className={styles.centerItemsbox}>
        <Text className={styles.title}>{title}</Text>
        <Text className={styles.prompt}>{titlePrompt ? `（ ${titlePrompt} ）` : ''}</Text>
      </Box>
      <Box className={styles.fullHeightbox}>
        <Flex className={buttonpd ? styles.fullbox : styles.fullnobox}>
          <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
          <Text className={styles.prompt}>{t('NoGenResult')}</Text>
        </Flex>
        <Textarea ref={bindprompt} className={buttonpd ? styles.notextarea : loading ? isContent ? styles.notextarea : styles.textarea : styles.notextarea} />
        {!loading &&
          <ScrollBarBox className={buttonpd ? styles.nomarkdownBox : styles.markdownBox}>
            <Flex>
              {(riskValue.includes(t("creation.TextMayContain")) || riskValue.includes(t("ContentMayContains"))) &&
                <Icon as={require('@/assets/svg/toolHisError.svg').ReactComponent} />
              }
              {/* <Markdown source={(bindprompt.current && bindprompt.current.value.length > 1) ? bindprompt.current.value : ''} /> */}
              <Box color={(riskValue.includes(t("creation.TextMayContain")) || riskValue.includes(t("ContentMayContains"))) ? '#FF5852' : '#333'}>
                <Markdown source={riskValue} />
              </Box>
            </Flex>
          </ScrollBarBox>
        }
        {(loading && !buttonpd && isContent) &&
          <Flex className={styles.loadingBox}>
            <RotatingCircles />
            <Text>{t('AIGenerating')}...</Text>
          </Flex>
        }
      </Box>
      {webPageUrl || (
        <Flex className={styles.footBox}>
          <Flex className={styles.footTextBox}>
            {/* <Text>本次生成消耗100.00点</Text> */}
          </Flex>
          <Box className={styles.buttonBox}>
            <ButtonWithIcon
              keyName="Clear"
              onClick={() => setIsModalOpen(true)}
              // onClick={() => delChatRecord()}
              isDisabled={buttonpd}
            />
            <ButtonWithIcon
              keyName="Copy"
              onClick={() => onclickCopy(bindprompt.current.value)}
              isDisabled={buttonpd}
            />
            <ButtonWithIcon
              keyName="Read"
              onClick={() => { play(userInfo?._id) }}
              isDisabled={buttonpd}
            />
            <CModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
              <Flex className={styles.avatarcmodelbox}>
                <Flex className={styles.cmodelTitleBox}>
                  <Text>{t('清空内容')}</Text>
                  <Icon as={require('@/assets/svg/cModelClose.svg').ReactComponent} onClick={() => setIsModalOpen(false)} />
                </Flex>
                <Flex className={styles.avatar_group}>
                  {t('您确定要清空当前内容吗？')}
                </Flex>
                <Flex className={styles.modelbuttonbox}>
                  <CButton className={styles.cancelButton} mr={3} onClick={() => setIsModalOpen(false)}>
                    {t('cancel')}
                  </CButton>
                  <CButton className={styles.confirmButton} onClick={() => delChatRecord()}>
                    {t('confirm')}
                  </CButton>
                </Flex>
              </Flex>
            </CModal>
            {download && (
              <Box position="relative">
                <ButtonWithIcon
                  keyName="Download"
                  onClick={onOpenDownload}
                  isDisabled={buttonpd}
                />
                <Box className={styles.download} ref={menuRef}>
                  <Menu isOpen={isOpenDownload} >
                    <MenuList className={styles.downloadlist}>
                      <MenuItem className={styles.op} onClick={() => { onCloseDownload(); onclickExportChat('html') }}>{t(('ExportHtml'))}</MenuItem>
                      <MenuItem className={styles.op} onClick={() => { onCloseDownload(); onclickExportChat('pdf') }}>{t('ExportPDF')}</MenuItem>
                      <MenuItem className={styles.op} onClick={() => { onCloseDownload(); onclickExportChat('md') }}>{t('ExportMD')}</MenuItem>
                      <MenuItem className={styles.op} onClick={() => { onCloseDownload(); onclickExportChat('word') }}>{t('ExportWord')}</MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
            )}
            {(!buttonpd && !loading) &&
              <Text className={styles.aitext}>{t('AIGEN')}</Text>
            }
          </Box>
        </Flex>
      )}
    </Flex>
  )
})

