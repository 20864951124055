import { Box, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, MenuOptionGroup, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss"
import { ScrollBarBox } from "@/components/ScrollBox";
import { uiStrore } from "@/store/ui";

export const PPTlistBox: FC<any> = observer(({
  title,
  langs,
  selectLanguage,
  setlang_key,
  SetSelectLanguage,
  setall
}: {
  title: string;
  langs: any[];
  selectLanguage: any;
  setlang_key?: (e: string) => void;
  SetSelectLanguage?: (e: string) => void;
  setall?: (e: string) => void;
}) => {

  const { language } = uiStrore;
  useEffect(() => {
    if (SetSelectLanguage) {
      SetSelectLanguage(language === 'zh' ? langs[0].text : langs[0].entext);
    }
  }, [])
  const parts = title.split('*');
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleMenuToggle = (isOpen: any) => {
    setIsOpen(isOpen);
  };
  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        {parts.map((part: any, index: any) => (
          <Text key={index} className={styles.title}>
            {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
          </Text>
        ))}
      </Box>
      <Box >
        <Menu onOpen={() => handleMenuToggle(true)} onClose={() => handleMenuToggle(false)}>
          <MenuButton ref={menuButtonRef} className={styles.selectmenu}>
            <Flex>
              <Box>{selectLanguage}</Box>
              <Icon className={isOpen ? styles.top : styles.bottom} as={require(`@/assets/svg/personalRight.svg`).ReactComponent} />
            </Flex>
          </MenuButton>
          <MenuList zIndex={999} className={styles.menuList}>
            <MenuOptionGroup defaultValue={selectLanguage} type="radio">
              <ScrollBarBox className={styles.scrollbar}>
                {langs.map((it, index) => {
                  return (<MenuItem
                    className={styles.menuitem}
                    key={it.text + index}
                    value={it.text}
                    onClick={() => {
                      if (SetSelectLanguage) {
                        SetSelectLanguage(it.text);
                      }
                      if (setlang_key) {
                        setlang_key(it.lang_key)
                      }
                      if (setall) {
                        setall(it)
                      }
                    }}
                  >
                    <Box>
                      <Text className={styles.ftitle} mb={'10px'}>{language === 'zh' ? it.text : it.entext}</Text>
                      {it.subtitle &&
                        <Text className={styles.stitle}>{language === 'zh' ? it.subtitle : it.ensubtitle}</Text>
                      }
                    </Box>
                    {selectLanguage === it.text &&
                      <Icon as={require('@/assets/svg/Comselected.svg').ReactComponent} className={styles.uploadIcon} />
                    }
                  </MenuItem>)
                })}
              </ScrollBarBox>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
})