import { CModal } from "@/components/Modal";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { FC, memo, useState } from "react";
import { CreationSwiper } from "../Swiper";
import styles from './index.module.scss'
import { ScrollBarBox } from "@/components/ScrollBox";
import { WiseImage } from "@/components/Image";
import { CButton } from "@/components/Button";
import Loading from "@/components/Loading";
import dayjs from "dayjs";
import { CreationType } from "@/store/creation";
import { SongPlayer } from "../Players";
import { copyDataFunc } from "@/utils/common";
import { useMessage } from "@/hooks/useMessage";
import Markdown from "@/components/Markdown";
import { useTranslation } from "react-i18next";

interface PreviewProps {
  isOpen: boolean
  type: CreationType
  extendsType?: string
  prompt: string,
  songname: string,
  songlyric: boolean,
  music_model: string,
  music_style: string,
  onClose: () => void
  imgList: string
  imgIndex?: number
  userAvatar: string
  userName: string
  negativePrompt?: string
  imgToImg?: {
    url: string
    weight: number
  }
  cerfToImg?: {
    url: string
    weight: number
  }
  sizeScal?: string
  hrLevel?: string
  createTime?: string
  onDownload?: (url: string, extendsType: any) => void
  onChangeIndex?: (index: number) => void
  onFavorite?: () => void
  onSameDraw?: () => void
  onShare?: () => Promise<void>
  showPush?: boolean
  isVideo?: boolean
  audioSrc: string
  lyrics: string
  coverImage: string
}
export const SongPreview: FC<PreviewProps> = memo((props) => {
  const {
    isOpen,
    onClose,
    imgList,
    imgIndex,
    userAvatar,
    userName,
    onDownload,
    prompt,
    songname,
    songlyric,
    music_model,
    music_style,
    createTime,
    onChangeIndex,
    onFavorite,
    onSameDraw,
    showPush = true,
    onShare,
    type,
    extendsType,
    isVideo,
    audioSrc,
    lyrics,
    coverImage
  } = props;
  const { t } = useTranslation();

  return <CModal isOpen={isOpen} onClose={onClose}>
    <Box className={styles.preview}>
      <SongPlayer
        audioSrc={audioSrc}
        lyrics={lyrics}
        songlyric={songlyric}
        coverImage={coverImage}
      />
      <Box className={styles.info}>
        <TopCard
          avatar={userAvatar}
          name={userName}
          onClose={onClose}
          url={imgList[imgIndex || 0]}
          drawId={""}
          isFavorite={false}
          onDownload={async () => {
            onDownload && await onDownload(audioSrc, extendsType);
          }}
          onFavorite={onFavorite}
          onSameDraw={onSameDraw}
          showPush={showPush}
          onShare={onShare}
          type={type}
          extendsType={extendsType}
        />
        <BottomCard
          prompt={prompt}
          songname={songname}
          songlyric={songlyric ? '[' + t('creation.InstrumentalMusic') + ']' : lyrics}
          music_model={music_model}
          music_style={extendsType === 'custom_model' ? music_style : ''}
          time={createTime}
        />
      </Box>
    </Box>
  </CModal>
})

interface TopCardProps {
  avatar: string
  name: string
  drawId: string
  onClose?: () => void
  url: string
  isFavorite: boolean
  onFavorite?: () => void
  onDownload?: () => Promise<void>
  onSameDraw?: () => void
  onShare?: () => Promise<void>
  type: CreationType
  showPush?: boolean
  extendsType?: string
}
const TopCard: FC<TopCardProps> = (props) => {
  const { avatar, name, onClose, onDownload, onSameDraw, showPush, onShare, type, extendsType } = props;//isFavorite, onFavorite,
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);

  const { t } = useTranslation();
  const download = async () => {
    setDownloadLoading(true)
    try {
      onDownload && await onDownload()
    } catch (e) {
      console.error(e)
    } finally {
      setDownloadLoading(false)
    }
  }

  const shareAudio = async () => {
    setShareLoading(true)
    try {
      onShare && await onShare()
    } catch (e) {
      console.error(e)
    } finally {
      setShareLoading(false)
    }
  }

  let title = t('wise') + 'Suno·';
  let extendsTitle = t('customMode');

  if (extendsType === 'custom_model') {
    extendsTitle = t('customMode');
  } else if (extendsType === 'simple_gen') {
    extendsTitle = t('InspirationMode');
  }
  return <Box className={styles.topCard}>
    <Box className={styles.user}>
      <Box className={styles.userInfo}>
        <WiseImage className={styles.avatar} src={avatar} />
        <Text>{name}</Text>
      </Box>
      <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} onClick={onClose} />
    </Box>
    <Text className={styles.type}>{title}{extendsTitle}</Text>
    <Box className={styles.btns}>
      <CButton className={`${styles.btn} ${!showPush ? styles.all : ''}`} theme='primary' onClick={onSameDraw}>
        <Icon className={styles.icon} as={require('@/assets/svg/creation_same.svg').ReactComponent} />
        <Text>{t('creation.creationSame')}</Text>
      </CButton>
      {
        showPush && <CButton isLoading={shareLoading} className={`${styles.btn} ${styles.expBtn}`} theme='custom' onClick={shareAudio}>
          <Icon className={styles.icon} as={require('@/assets/svg/push_square.svg').ReactComponent} />
          <Text>{t('creation.PublishToWork')}</Text>
        </CButton>
      }
    </Box>
    <Box className={styles.ops}>
      {/* <Box className={styles.opItem} onClick={download}>
        {
          downloadLoading ? <Loading.Icon className={styles.loading} />
            : <Icon className={styles.icon} as={require('@/assets/svg/song_share.svg').ReactComponent} />
        }
        <Text>分享</Text>
      </Box> */}
      <Box className={styles.opItem} onClick={download}>
        {
          downloadLoading ? <Loading.Icon className={styles.loading} />
            : <Icon className={styles.icon} as={require('@/assets/svg/creation_download.svg').ReactComponent} />
        }
        <Text>{t('download')}</Text>
      </Box>
      {/* <Box className={styles.opItem} onClick={download}>
        {
          downloadLoading ? <Loading.Icon className={styles.loading} />
            : <Icon className={styles.icon} as={require('@/assets/svg/song_collection.svg').ReactComponent} />
        }
        <Text>收藏</Text>
      </Box> */}
      {/* <Box className={styles.opItem} onClick={download}>
        {
          downloadLoading ? <Loading.Icon className={styles.loading} />
            : <Icon className={styles.icon} as={require('@/assets/svg/song_report.svg').ReactComponent} />
        }
        <Text>举报</Text>
      </Box> */}
    </Box>
  </Box>
}

interface BottomCardProps {
  prompt: string
  songname: string
  songlyric?: string
  music_model?: string
  music_style?: string
  time?: string
}
const BottomCard: FC<BottomCardProps> = (props) => {
  const { prompt, songlyric, music_model, time, songname, music_style } = props;
  const { t } = useTranslation();
  return <Box className={styles.bottomCard}>
    <ScrollBarBox className={styles.bottomScroll}>
      {
        prompt ? <InfoItem title={t('creation.SongDesc')} content={prompt} iscopy={true} />
          : <InfoItem title={t('creation.SongName')} content={songname} iscopy={true} />
      }
      <InfoItem title={t('creation.lyrics')} content={songlyric ? songlyric : ''} iscopy={true} />
      {
        music_style && <InfoItem title={t('creation.MusicStyle')} content={music_style} iscopy={true} />
      }
      {
        music_model && <InfoItem title={t('creation.MusicModel')} content={music_model} iscopy={false} />
      }
      <InfoItem title={t('creatTime')} content={dayjs(time || new Date()).format('YYYY/MM/DD HH:mm:ss')} iscopy={false} />
    </ScrollBarBox>
  </Box>
}

interface InfoItemProps {
  title: string
  content: string | string[]
  iscopy: boolean
}
const InfoItem: FC<InfoItemProps> = (props) => {
  const { title, content, iscopy } = props;
  const { t } = useTranslation();
  const { copyData } = copyDataFunc();
  const message = useMessage();
  const copy = (value: any) => {
    copyData(value);
    message.success({ title: t('CopySuccess') });
  }
  return <Box className={styles.infoItem}>
    <Flex className={styles.titleBox}>
      <Text className={styles.title}>{title}</Text>
      {iscopy &&
        <Icon onClick={() => copy(content)} className={styles.icon} as={require('@/assets/svg/creation_use_prompt.svg').ReactComponent} />
      }
    </Flex>
    <Box className={`${styles.content}`}>
      <ScrollBarBox className={styles.text} barProps={{ style: { flex: 1 } }}>
        {
          Array.isArray(content) ? content.map((item, index) => <Text key={index}>{item}</Text>)
            : <Markdown source={content} />
        }
      </ScrollBarBox>
    </Box>
  </Box>
}