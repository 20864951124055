import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss"
import { CButton } from "@/components/Button";
import { uiStrore } from "@/store/ui";

export const ComDoubleTags: FC<any> = observer(({
  title,
  array,
  emoji_content,
  SetEmoji_content,
  needzdy,
  zdy,
  setZdy,
  selectedzdy,
  setSelectedZdy
}: {
  title: string;
  array: any[];
  emoji_content: string[];
  SetEmoji_content: (text: string[]) => void;
  needzdy?: boolean;
  zdy?: any;
  setZdy?: any;
  selectedzdy?: any;
  setSelectedZdy?: any;
}) => {

  const scrollableRef = useRef<HTMLDivElement>(null);
  const { language } = uiStrore;

  const parts = title.split('*');
  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        {parts.map((part: any, index: any) => (
          <Text key={index} className={styles.title}>
            {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
          </Text>
        ))}
      </Box>
      <Box
        ref={scrollableRef}
        className={styles.scrollStyle}
      >
        {array.map((item) => (
          <CButton className={emoji_content && emoji_content.includes(item.text) ? styles.selectButton : styles.button}
            key={item.text}
            onClick={() => { SetEmoji_content(item.text) }}
          >
            {language === 'zh' ? item.text : item.text_more_language ? item.text_more_language.en : ''}
          </CButton>
        ))}
      </Box>
      {needzdy && (
        <Box mt={1} display={'flex'} flexDirection={'row'} h={'32px'} alignItems={'center'}>
          <Text style={{ whiteSpace: 'nowrap', marginRight: '10px', color: '#ccc' }}>
            自定义
          </Text>
          <Input
            value={zdy}
            onClick={() => setSelectedZdy(!selectedzdy)}
            onChange={(event) => setZdy(event.target.value)}
          />
        </Box>
      )}
    </Flex>
  );
})