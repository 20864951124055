import React, { useRef, useCallback } from 'react';
import { Box } from '@chakra-ui/react';

export const useSelectFile = (props?: { fileType?: string; multiple?: boolean }) => {
  const { fileType = '*', multiple = false } = props || {};
  const SelectFileDom = useRef<HTMLInputElement>(null);

  const FileBox = useCallback(
    ({ onSelect }: { onSelect: (e: File[]) => void }) => (
      <Box position={'absolute'} w={0} h={0} overflow={'hidden'}>
        <input
          ref={SelectFileDom}
          type="file"
          accept={fileType}
          multiple={multiple}
          onChange={(e) => {
            if (!e.target.files || e.target.files?.length === 0) return;
            const files = Array.from(e.target.files).map(file => {
              const type = file.type;
              const isHeicOrHeif = file.name.toUpperCase().endsWith('.HEIC') || file.name.toUpperCase().endsWith('.HEIF');
              if (type === '' && isHeicOrHeif) {
                const isHeic = file.name.toUpperCase().endsWith('.HEIC');
                return new File([file], file.name, { type: isHeic ? 'image/heic' : 'image/heif' });
              }
              return file;
            })
            onSelect(files);
            e.target.value = '' // 选择后将input的值清空
          }}
        />
      </Box>
    ),
    [fileType, multiple]
  );

  const onOpen = useCallback(() => {
    SelectFileDom.current && SelectFileDom.current.click();
  }, []);

  return {
    File: FileBox,
    onOpen
  };
};