import { Box, Flex, Text, Icon, Image } from "@chakra-ui/react";
import { FC, useEffect } from "react";
import styles from './index.module.scss';
import { CButton } from "@/components/Button";
import { useTranslation } from "react-i18next";
import { ScrollBarBox } from "@/components/ScrollBox";
import { personalStore } from "@/store/personal";
import { userStore } from "@/store/user";
import { observer } from "mobx-react-lite";
import { useMessage } from "@/hooks/useMessage";

export const Invite: FC = observer(() => {
  const { t } = useTranslation();
  const { getPromotionData, getPromotions, promotionData, promotions } = personalStore;
  const message = useMessage();
  const { userInfo } = userStore;
  useEffect(() => {
    getPromotionData();
    getPromotions();
  }, [])
  const copyToClipboard = async () => {
    try {
      if (userInfo) {
        const text = `https://www.wisetalker.cn//number?inviterId=${userInfo._id}`;
        await navigator.clipboard.writeText(text);
        message.success({ title: t('CopySuccess') })
      }
    } catch (err) {
      message.error({ title: t('CopyFaild') })
    }
  };
  return (
    <Box className={styles.parent}>
      <Box className={styles.child}>
        <Box className={styles.allbox}>
          <Text className={styles.boxtext}>{t('Share_to_earn_money')}</Text >
          <Text className={styles.boxtext}>{t('Invite_friends')}</Text>
        </Box>
        <Image src={require('@/assets/png/personalinvite.png')} className={styles.signInboxpng} />
        <Flex className={styles.signInbox}>
          <Flex className={styles.signIntitlebox}>
            <Text className={styles.signInboxtext}>{t('Accrued_commissions')}</Text>
          </Flex>
          <Flex className={styles.signIntitlebox}>
            <Text className={styles.invitetext}>{promotionData.historyAmount}</Text>
          </Flex>
          <Flex className={styles.signIncontentsbox}>
            <Box >
              <Text className={styles.signInboxtext}>{t('Number_invitees')}</Text>
              <Text className={styles.signInboxvalue}>{promotionData.invitedAmount}{t('people')}</Text>
            </Box>
            <Box>
              <Text className={styles.signInboxtext}>{t('Available_commissions')}</Text>
              <Text className={styles.signInboxvalue}>￥{promotionData.residueAmount}</Text>
            </Box>
            <Box>
              <Text className={styles.signInboxtext}>{t('Commission_Percentage')}</Text>
              <Text className={styles.signInboxvalue}>15%</Text>
            </Box>
          </Flex>
          <CButton className={styles.signInbutton} onClick={() => {
            message.warning({ title: '您的累计佣金未达50元！' })
          }}>
            <Text className={styles.signInten}>{t('Payouts')}</Text>
          </CButton>
          <Flex className={styles.signInbuttonbox}>
            <Text className={styles.signInbuttontext}>{t('PayoutsTips')}</Text>
          </Flex>
        </Flex>
        <Flex className={styles.fatherbox}>
          <Text className={styles.boxtitletext}>{t('Invitation_link')}</Text>
          <Flex className={styles.contentbox}>
            <Text className={styles.balancetext}>{userInfo?._id}</Text>
          </Flex>
        </Flex>
        <Flex className={styles.rightbox}>
          <Text className={styles.signInbuttontext}>{t('InvitationTips')}</Text>
        </Flex>
        <Flex className={styles.rightbox}>
          <CButton className={styles.balancebutton} onClick={copyToClipboard}>
            <Icon as={require('@/assets/svg/invcopy.svg').ReactComponent} />
            {t('Copy_link')}
          </CButton>
          {/* <CButton className={styles.sharebutton} onClick={() => { }}>
            <Icon as={require('@/assets/svg/invshare.svg').ReactComponent} />
            分享至微信
          </CButton> */}
        </Flex>
        <Flex className={styles.fatherbox}>
          <Flex className={styles.fathertitlebox}>
            <Text className={styles.boxtitletext}>{t('Commission_history')}</Text>
            <Flex className={styles.fathericonbox}>
              <Icon as={require(`@/assets/svg/datetime.svg`).ReactComponent} className={styles.icon} />
              <Text>{t('date')}</Text>
            </Flex>
          </Flex>
          <ScrollBarBox className={styles.listbox}>
            <Flex className={styles.boxContents}>
              {promotions.length > 0 ? promotions.map((item, index) => {
                return (
                  <Flex className={styles.commissionItem} key={index}>
                    <Text>{t('Get_commissioned')}：￥{item.amount}</Text>
                    <Text>{t('type')}：{item.type}</Text>
                    <Text>{t('Friend_topup_amount')}：{item.amount / 0.15}</Text>
                    <Text>{t('Invite_friends')}{item.objUId}</Text>
                    <Text>{t('Time')}：{item.createTime}</Text>
                  </Flex>
                )
              }) : (
                <Box className={styles.unInvitbox}>
                  <Image src={require('@/assets/png/Uncommission.png')} className={styles.unInvitimg} />
                  <Text className={styles.unInvittext}>暂无佣金记录</Text>
                </Box>
              )}

            </Flex>
          </ScrollBarBox>
        </Flex>
      </Box>
    </Box>
  )
})