import { WiseImage } from "@/components/Image";
import { Box, Icon, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CModal } from "@/components/Modal";
import { CButton } from "@/components/Button";
import { useMessage } from "@/hooks/useMessage";
import { CreationTitle } from "../Title";
import { ScrollBarBox } from "@/components/ScrollBox";

import styles from './index.module.scss'
import { useTranslation } from "react-i18next";

interface CreationStyleProps<T> {
  styleList: T[]
  showStyles: T[]
  onStyleChange: (style: T, index?: number) => void
  selectedStyle?: T
  title?: string
}
export const CreationStyle = <T extends StyleConfig | Gen3StyleConfig>(props: CreationStyleProps<T>) => {
  const { t } = useTranslation();
  const { styleList, selectedStyle, showStyles, onStyleChange, title = t('creation.style') || '绘画风格' } = props;
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [chooseTag, setChooseTag] = useState<string | 'all' | 'used'>('all');
  const [filterList, setFilterList] = useState<Array<T>>([]);
  const [chooseStyle, setChooseStyle] = useState<T | null>(null);
  const message = useMessage();

  useEffect(() => {
    if (selectedStyle && open) {
      setChooseStyle(selectedStyle)
    }
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    let _tags: string[] = [];
    styleList.forEach(style => {
      _tags = [..._tags, ...style.tags]
    })
    setTags(Array.from(new Set(_tags)));
  }, [styleList])

  useEffect(() => {
    if (styleList) {
      let _filterList = styleList;
      if (chooseTag !== 'all') {
        _filterList = styleList.filter(style => style.tags.includes(chooseTag))
      }
      setChooseStyle(null);
      setFilterList(_filterList);
    }
    // eslint-disable-next-line
  }, [chooseTag, styleList])

  const selectConfirm = () => {
    if (chooseStyle) {
      const index = styleList.findIndex(style => style.key === chooseStyle.key)
      onStyleChange(chooseStyle, index)
      setOpen(false)
    } else {
      message.warning({ title: t('creation.ChooseStyle') })
    }
  }

  return <Box className={styles.styles}>
    <Box className={styles.title}>
      <CreationTitle title={title} />
    </Box>
    <Box className={styles.styleList}>
      {
        showStyles.map(style => {
          return <Box
            className={`${styles.item} ${selectedStyle?.key === style.key ? styles.active : ''}`}
            key={style.key}
            onClick={() => onStyleChange(style)}
          >
            <Box className={styles.img}>
              <WiseImage src={style.view_image} />
              {
                selectedStyle?.key === style.key ? <Box className={styles.selected}>
                  <Icon as={require('@/assets/svg/gou.svg').ReactComponent} />
                </Box> : null
              }
            </Box>
            <Box className={styles.name}>{t(style.name)}</Box>
          </Box>
        })
      }
      <Box className={styles.more} onClick={() => setOpen(true)}>
        <Box className={styles.Text}>{t('SeeMore')}</Box>
      </Box>
    </Box>
    <CModal isOpen={open} onClose={() => setOpen(false)}>
      <Box className={styles.allList}>
        <Box className={styles.title}>
          <Text>{title}</Text>
          <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} onClick={() => setOpen(false)} />
        </Box>
        <ScrollBarBox barProps={{ style: { height: 'auto' }, suppressScrollY: true }} className={styles.tags} >
          <Box className={`${styles.tag} ${chooseTag === 'used' ? styles.active : ''}`} onClick={() => setChooseTag('used')}>{t('Recently')}</Box>
          <Box className={`${styles.tag} ${chooseTag === 'all' ? styles.active : ''}`} onClick={() => setChooseTag('all')}>{t('all')}</Box>
          {
            tags.map(tag => {
              return <Box key={tag} className={`${styles.tag} ${chooseTag === tag ? styles.active : ''}`} onClick={() => setChooseTag(tag)}>{t(tag)}</Box>
            })
          }
        </ScrollBarBox>
        <ScrollBarBox className={styles.styleBox} barProps={{ style: { height: 'auto' } }}>
          <Box className={styles.allStyle}>
            {
              filterList.map((style, index) => {
                return <Box key={style.key}
                  className={`${styles.item} ${chooseStyle?.key === style.key ? styles.active : ''}`}
                >
                  <Box className={styles.img} onClick={() => setChooseStyle(style)}>
                    <WiseImage src={style.view_image} />
                    {
                      chooseStyle?.key === style.key ? <Box className={styles.selected}>
                        <Icon as={require('@/assets/svg/gou.svg').ReactComponent} />
                      </Box> : null
                    }
                  </Box>
                  <Box className={styles.name}>{t(style.name)}</Box>
                </Box>
              })
            }
          </Box>
        </ScrollBarBox>
        <Box className={styles.btns}>
          <CButton className={styles.btn} theme='outline' onClick={() => setOpen(false)}>{t('cancel')}</CButton>
          <CButton className={styles.btn} theme='primary' onClick={selectConfirm}>{t('confirm')}</CButton>
        </Box>
      </Box>
    </CModal>
  </Box>
}