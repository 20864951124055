import { CButton } from "@/components/Button";
import { Box, Flex, Icon, Radio, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useRef, useState } from "react";
import styles from "./index.module.scss"
import { _Models } from "../..";
import { userStore } from "@/store/user";
import { uiStrore } from "@/store/ui";
import { useMessage } from "@/hooks/useMessage";
import { RobotYHXY } from "@/pages/login/LoginType";
import { useTranslation } from "react-i18next";

export const ComRobotButton: FC<any> = observer(({
  loading,
  saveDataOnClick,
  genQRcodeOnClick,
  loadDefaulOnClick,
  ginOutOnClick,
  redius,
  logined
}: {
  loading: any;
  saveDataOnClick: () => void;
  genQRcodeOnClick: () => void;
  loadDefaulOnClick: () => void;
  ginOutOnClick: () => void;
  redius?: string;
  logined?: boolean
}) => {
  const { t } = useTranslation();
  const { openAlert, closeAlert } = uiStrore;
  const [isChecked, setIsChecked] = useState(true);
  const [isopen, setisopen] = useState(false)
  const message = useMessage();
  const signOuthandleConfirm = async () => {
    ginOutOnClick();
    closeAlert()
  }
  const handleConfirm = async () => {
    saveDataOnClick();
    closeAlert()
  }
  return (
    <Flex className={styles.allbox}>
      <Box className={styles.textbox}>
        <Flex className={isChecked ? styles.checked : styles.ischeck}
          onClick={() => { setIsChecked(!isChecked) }}>
          {isChecked ? '✓' : ''}
        </Flex>
        <Text>{t('已阅读')}<span onClick={() => setisopen(true)}>{t('《微信AI机器人用户使用协议》')}</span> </Text>
      </Box>
      <Flex className={styles.buttonBox}>
        {/* 插入示例按钮 */}
        <CButton className={styles.casebutton} onClick={() => {
          if (!isChecked) {
            message.warning({ title: t('请先阅读使用协议') })
            return;
          }; loadDefaulOnClick()
        }}>
          <Icon as={require('@/assets/svg/robotDefault.svg').ReactComponent} />
          {t('恢复默认')}
        </CButton>
        <CButton className={styles.casebutton} onClick={() => {
          if (!isChecked) {
            message.warning({ title: t('请先阅读使用协议') })
            return;
          }
          openAlert({
            title: `${t('保存配置')}？`,
            onclose: closeAlert,
            footer: <Box className={styles.detailbox}>
              <Text>{t('保存配置后需要重新扫码登录才能生效，要保存吗？')}</Text>
              <Flex className={styles.detailbox_butbox}>
                <CButton className={styles.detailbox_calbut} onClick={closeAlert}>{t('cancel')}</CButton>
                <CButton className={styles.detailbox_okbut} onClick={handleConfirm}>{t('Sure')}</CButton>
              </Flex>
            </Box>
          })
        }}>
          <Icon as={require('@/assets/svg/robotSave.svg').ReactComponent} />
          {t('保存配置')}
        </CButton>
        {logined ?
          <CButton className={styles.wxoutbutton} onClick={() => {
            openAlert({
              title: t('tip'),
              content: t('您确定要退出当前登录微信吗？'),
              onOk: () => {
                signOuthandleConfirm();
              }
            })
          }}>
            <Icon as={require('@/assets/svg/robotWx.svg').ReactComponent} />
            {t('退出登录')}
          </CButton>
          :
          <CButton className={styles.wxbutton} onClick={() => {
            if (!isChecked) {
              message.warning({ title: t('请先阅读使用协议') })
              return;
            }; genQRcodeOnClick()
          }}>
            <Icon as={require('@/assets/svg/robotWx.svg').ReactComponent} />
            {t('微信扫码')}
          </CButton>
        }
      </Flex>
      <RobotYHXY isOpen={isopen} onClose={() => { setisopen(false) }} />
    </Flex>
  );
})