import { Flex, Box, Text, Icon } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import styles from "./index.module.scss";
import { ScrollBarBox } from "@/components/ScrollBox";
import { ProgressBar } from "@/components/ProgressBar";
import { toolStore } from "@/store/tool";
import { AudioNameEnum } from "@/@types/audio";
import { getKeyByName, getKeyByValue, usernames } from "@/pages/creation/Tools/TextToSpeech";
import { useTranslation } from "react-i18next";


export type ExportChatType = 'md' | 'pdf' | 'html' | 'word';
export const ComToSpeechHistory: FC<any> = observer(({
  historyCache,
  type,
  setHistoryCache,
  setViewhistory,
  onclick,
  historytitle,
  historycontent,
  onMore
}: {
  historyCache: any[];
  type: string,
  setHistoryCache: (data: any) => void;
  setViewhistory: (...args: any[]) => void;
  onclick: (item: any) => void;
  historytitle?: string;
  historycontent?: string;
  isVIP?: boolean
  onMore: any;
}) => {
  const { t } = useTranslation();
  const { getTranslations } = toolStore;
  const updateHistoryCache = async () => {
    const hisdata = await getTranslations({ types: type });
    setHistoryCache(hisdata);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      const itemWithStatusOne = sortedList.find(item => item.status === 1);
      if (itemWithStatusOne) {
        updateHistoryCache();
      }
    }, 3000);

    return () => clearInterval(interval); // 清理定时器
  }, []);
  let parentField = '';
  let childField = '';
  if (historytitle && historytitle.includes('.')) {
    const fields = historytitle.split('.');
    parentField = fields[0];
    childField = fields[1];
  } else {
    parentField = historytitle!
  }

  const sortedList = historyCache.slice().sort((a, b) => new Date(b.createdate).getTime() - new Date(a.createdate).getTime());
  return (
    <Flex className={styles.hisFlex}>
      <Box className={styles.centerItemsbox}>
        <Text className={styles.title}>{t('history')}</Text>
      </Box>
      <ScrollBarBox className={styles.hisBox}>
        {sortedList.map((item, index) => {
          const statusDesc = (() => {
            switch (item.status.toString()) {
              case '0':
                return t('TobeStarted');
              case '8':
                return t('IntheQueue');
              case '1':
                return '正在生成中，请稍等....';
              case '2':
                return '有新声音已生成';
              case '3':
                return t('BuildFailure');
              default:
                return t('BuildFailure');
            }
          })();
          const statusColor = (() => {
            switch (item.status.toString()) {
              case '0':
              case '8':
              case '1':
                return '#FF951A';
              case '2':
                return '#689F38';
              case '3':
                return '#F76560';
              default:
                return '#FF951A';
            }
          })();
          let names;
          if (item.apiType === 'microsoft') {
            names = getKeyByValue(AudioNameEnum, item.speaks[0].name)
          } else {
            const user = usernames.find(user => user.key === item.speaks[0].name)
            names = user ? user.name : ''
          }
          return (
            <Flex key={item._id} className={styles.historyValue} cursor={item.status === 2 ? 'pointer' : ''}
              onClick={async () => { if (item.status !== 3) { onclick(item) } }}>
              {item.status === 1 && <Flex className={styles.prohibitBox} onClick={(e) => e.stopPropagation()}></Flex>}
              <Flex className={styles.historyValuebox}>
                <Box className={styles.hisTitleBox}>
                  {(!item.isNotReading && item.status === 2) ?
                    <Box className={styles.hisTitleBox}>
                      <Text>
                        文本转语音 · {item.apiType === 'microsoft' ? '微软语音' : '文思GPT'}
                      </Text>
                    </Box>
                    :
                    <Flex color={statusColor}>
                      {statusDesc}
                    </Flex>
                  }
                  <Icon as={require('@/assets/svg/speechMore.svg').ReactComponent} className={styles.more}
                    onClick={(e: any) => { e.stopPropagation(); onMore && onMore(e, index, item) }} />
                </Box>
                {(item.isNotReading || item.status !== 2) &&
                  <Box className={styles.hisTitleBox}>
                    <Text>
                      文本转语音 · {item.apiType === 'microsoft' ? '微软语音' : '文思GPT'}
                    </Text>
                  </Box>
                }
                {
                  item?.textCheck.status ? <Text className={styles.hisContentsBox}>
                    {item.speaks[0].text}
                  </Text> : <Text className={styles.riskContentsBox}>
                    <Icon as={require('@/assets/svg/risk_red.svg').ReactComponent} />
                    {t('creation.TextMayContain')}{t('riskContent.' + item?.textCheck?.labels.split(',')[0])} {t('creation.TemporarilyUnavailable')} {t('creation.NoConsume')}
                  </Text>
                }
                {item.status === 1 && <ProgressBar value={item.extends.process ? item.extends.process : '0'} />}
                <Flex className={styles.hisDateBox}>
                  <Flex>
                    <Icon w={'18px'} height={'18px'} as={require(`@/assets/svg/datetime.svg`).ReactComponent} className={styles.icon} />
                    <Text>
                      {item.time ? (
                        item.time.toLocaleString()
                          .replace('Z', '')
                          .replace('T', ' ')
                          .substring(0, 16)
                      ) : (
                        ''
                      )}
                    </Text>
                  </Flex>
                  <Flex mb={'3px'}>
                    <Icon w={'18px'} height={'18px'} as={require(`@/assets/svg/imgmodel.svg`).ReactComponent} />
                    <Text>
                      {names}
                    </Text>
                  </Flex>
                  <Flex w={'120px'}>
                    <Icon w={'15px'} height={'15px'} as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                    <Text textDecorationLine={(item.status === 2 && item.isvip) ? 'line-through' : ''}>{item.price}</Text>
                    {(item.status === 2 && item.isvip) && <Flex className={styles.viptext}>
                      <Icon as={require(`@/assets/svg/navisVip.svg`).ReactComponent} />
                      {t('vipFree')}
                    </Flex>
                    }
                  </Flex>
                  <Flex>
                    <Box className={styles.aimark}>
                      {t('AIGEN')}
                    </Box>
                  </Flex>
                  <Flex >
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )
        })}
        {
          !sortedList.length ? <Flex className={styles.nullBox}>
            <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
            <Text className={styles.prompt}>{t('NoHistory')}</Text>
          </Flex> : null
        }
      </ScrollBarBox >
    </Flex >
  );
})

