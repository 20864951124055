import { ipcRendererInvoke } from "./electron";

export enum sseResponseEventEnum {
  error = 'error',
  answer = 'answer',
  chatResponse = 'chatResponse'
}

export const streamFetch = ({ data, onMessage, abortSignal, onError }: StreamFetchProps) =>
  new Promise<ChatResponseType & { responseText: string; errMsg: string | any }>(
    async (resolve, reject) => {
      const cookie = await ipcRendererInvoke('getCookie');
      document.cookie = cookie?.split(';')[0]; //待修改
      try {
        const { shareId, ...others } = data
        const headers: HeadersInit = {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + shareId,
          'Cookie': cookie
        }
        if (!shareId) {
          delete headers.authorization
        }

        const url = (window.proxyApi || '') + '/api/openapi/v1/chat/completions';
        const response = await window.fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: headers,
          signal: abortSignal.signal,
          body: JSON.stringify({
            ...others,
            stream: true
          })
        });

        if (response.status !== 200) {
          const err = await response.json();
          return reject(err);
        }

        if (!response?.body) {
          throw new Error('Request Error');
        }

        const reader = response.body?.getReader();

        // response data
        let responseText = '';
        let newChatId = '';
        let quoteLen = 0;
        let errMsg = '';

        const read = async () => {
          try {
            const { done, value } = await reader.read();
            if (done) {
              if (response.status === 200) {
                return resolve({
                  responseText,
                  newChatId,
                  quoteLen,
                  errMsg
                });
              } else {
                return reject('响应过程出现异常~');
              }
            }
            if (others.voice) {
              onMessage(value.buffer as any)
            } else {
              const chunkResponse = parseStreamChunk(value);

              chunkResponse.forEach((item) => {
                // parse json data
                const data = (() => {
                  try {
                    return JSON.parse(item.data);
                  } catch (error) {

                    return item.data;
                  }
                })();
                if (item.event === sseResponseEventEnum.answer && data !== '[DONE]') {
                  const answer: string = data?.choices?.[0].delta.content || '';
                  onMessage(answer);
                  responseText += answer;
                } else if (item.event === sseResponseEventEnum.chatResponse) {
                  const chatResponse = data as ChatResponseType;
                  newChatId = chatResponse.newChatId;
                  quoteLen = chatResponse.quoteLen || 0;
                } else if (item.event === sseResponseEventEnum.error) {
                  // errMsg = getErrText(data, '流响应错误');
                  onError && onError(data);
                } else if (item.event === '' && Number(data.code) === 501) {
                  errMsg = JSON.stringify(data);
                }
              });
            }

            read();
          } catch (err: any) {
            console.log('error:', err?.message)
            if (err?.message === 'The user aborted a request.'
              || err?.message === 'BodyStreamBuffer was aborted'
              || err?.message === 'Fetch is aborted') {
              return resolve({
                responseText,
                newChatId,
                quoteLen,
                errMsg: errMsg || err
              });
            }
            // reject(getErrText(err, '请求异常'));
          }
        };
        read();
      } catch (err: any) {
        console.log(err);
        // reject(getErrText(err, '请求异常'));
      }
    }
  );

const decoder = new TextDecoder();
export const parseStreamChunk = (value: BufferSource) => {
  const chunk = decoder.decode(value);
  const chunkLines = chunk.split('\n\n').filter((item) => item);
  const chunkResponse = chunkLines.map((item) => {
    const splitEvent = item.split('\n');
    if (splitEvent.length === 2) {
      return {
        event: splitEvent[0].replace('event: ', ''),
        data: splitEvent[1].replace('data: ', '')
      };
    }
    return {
      event: '',
      data: splitEvent[0].replace('data: ', '')
    };
  });

  return chunkResponse;
};


interface StreamFetchPluginProps {
  data: PluginChatParamsProps;
  onMessage: (text: string) => void;
  abortSignal: AbortController;
}
export const streamPluginFetch = ({ data, onMessage, abortSignal }: StreamFetchPluginProps) => {
  return new Promise<ChatResponseType & { responseText: string; errMsg: string | any }>(
    async (resolve, reject) => {
      const cookie = await ipcRendererInvoke('getCookie');
      document.cookie = cookie?.split(';')[0]; //待修改
      try {
        const { shareId, ...others } = data;
        const headers: HeadersInit = {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + shareId,
          'Cookie': cookie
        }
        if (!shareId) {
          delete headers.authorization
        }
        const url = (window.proxyApi || '') + '/api/openapi/v1/chat/plugin';
        const response = await window.fetch(url, {
          method: 'POST',
          headers: headers,
          signal: abortSignal.signal,
          body: JSON.stringify({
            ...others,
            stream: true
          })
        });

        if (response.status !== 200) {
          const err = await response.json();
          return reject(err);
        }

        if (!response?.body) {
          throw new Error('Request Error');
        }

        const reader = response.body?.getReader();

        // response data
        let responseText = '';
        let newChatId = '';
        let quoteLen = 0;
        let errMsg = '';
        let noParseString = '';

        const read = async () => {
          try {
            const { done, value } = await reader.read();
            if (done) {
              if (response.status === 200) {
                return resolve({
                  responseText,
                  newChatId,
                  quoteLen,
                  errMsg
                });
              } else {
                return reject('响应过程出现异常~');
              }
            }
            const chunkResponse = parseStreamChunk(value);

            chunkResponse.forEach((item) => {
              // parse json data
              const data = (() => {
                try {
                  return JSON.parse(item.data);
                } catch (error) {
                  if (!noParseString) {
                    noParseString = item.data;
                  } else {
                    noParseString += item.data;
                  }
                  try {
                    let josnObj = JSON.parse(noParseString);
                    item.event = sseResponseEventEnum.answer;
                    noParseString = '';
                    return josnObj;
                  } catch (error) {
                    return item.data;
                  }
                }
              })();

              if (item.event === sseResponseEventEnum.answer && data !== '[DONE]') {
                const answer: string = data?.choices?.[0].delta.content || '';
                onMessage(answer);
                responseText += answer;
              } else if (item.event === sseResponseEventEnum.chatResponse) {
                const chatResponse = data as ChatResponseType;
                newChatId = chatResponse.newChatId;
                quoteLen = chatResponse.quoteLen || 0;
              } else if (item.event === sseResponseEventEnum.error) {
                errMsg = getErrText(data, '流响应错误');
              } else if (item.event === '' && Number(data.code) === 501) {
                errMsg = JSON.stringify(data);
              }
            });
            read();
          } catch (err: any) {
            console.log('error:', err)
            if (err?.message === 'The user aborted a request.'
              || err?.message === 'BodyStreamBuffer was aborted'
              || err?.message === 'Fetch is aborted') {
              return resolve({
                responseText,
                newChatId,
                quoteLen,
                errMsg: errMsg || err
              });
            }
            reject(getErrText(err, '请求异常'));
          }
        };
        read();
      } catch (err: any) {
        console.log(err);

        reject(getErrText(err, '请求异常'));
      }
    });
}
const getErrText = (err: any, def = '') => {
  const msg: string = typeof err === 'string' ? err : err?.message || def || '';
  msg && console.log('error =>', msg);
  return msg;
};