import { Box, Flex, Grid, GridItem, Icon, Image, Text, Textarea, Tooltip } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './index.module.scss'
import style from '../../../recharge/components/Content/index.module.scss'
import { chatStore, ChatType } from "@/store/chat";
import { observer } from "mobx-react-lite";
import { ScrollBarBox } from "@/components/ScrollBox";
import { CButton } from "@/components/Button";
import { CProgress } from "@/components/Progress";
import { useMessage } from "@/hooks/useMessage";
import { WiseImage } from "@/components/Image";
import { personalStore } from "@/store/personal";
import { CModal } from "@/components/Modal";
import DatePicker from "@/components/DatePicker";
import dayjs from "dayjs";
import { Recharge } from "../../../recharge";
import { BalanceTop_up } from "../../../BalanceTop_up";

interface Bill {
  price: number;
  modelName: string;
  contents: string;
  id: string;
  textLen: number;
  time: string;
  tokenLen: number;
  type: string;
  arraytype: 'bill';
  notVipPrice: number;
  isVip: boolean;
}

interface PayOrder {
  points: number;
  formattedCreateTime: string;
  _id: string;
  payType: string;
  orderType: string;
  createTime: string;
  status: string;
  orderId: string;
  price: number;
  vipType: string;
  time: string;
  arraytype: 'order';
}
const BillsContent: FC = () => {
  const { currentType } = chatStore
  const { t } = useTranslation();
  const message = useMessage();
  const [open, setOpen] = useState(false);
  const { models, getModels } = chatStore;
  const { getBills, bills } = personalStore;
  const [range, setRange] = useState<[Date | null, Date | null]>([null, null]);
  const padZero = (num: any) => num.toString().padStart(2, '0');
  const { payOrders, getPayOrders, checkPayResult } = personalStore;
  const formatDates = (type: any, dateString: any) => {
    const date = new Date(dateString);
    if (type !== "doc-translation") {
      date.setUTCHours(date.getUTCHours() + 8);
    }
    const year = date.getUTCFullYear();
    const month = padZero(date.getUTCMonth() + 1); // 月份从0计数，需要+1
    const day = padZero(date.getUTCDate());
    const hours = padZero(date.getUTCHours());
    const minutes = padZero(date.getUTCMinutes());
    const seconds = padZero(date.getUTCSeconds());
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const formatDate = (date: Date) => {
    return dayjs(date).format('YYYY-MM-DD');
  };
  useEffect(() => {
    const fetchData = async () => {
      getModels();
      if (range[0] !== null && range[1] !== null) {
        const time1 = formatDate(range[0])
        const time2 = formatDate(range[1])
        await getBills(time1, time2);
        await getPayOrders(time1, time2);
      } else {
        await getBills();
        await getPayOrders();
      }
    }
    fetchData();
  }, [range]);

  const handleRefreshPayOrder = useCallback(
    async (payId: string) => {
      try {
        const data = await checkPayResult(payId);
        message.warning({ title: data === null ? "订单已关闭" : data })
      } catch (error: any) {
        message.warning({ title: error?.message })
        console.log(error);
      }
    },
    [message]
  );
  const onclickCopy = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      message.success({ title: t('CopySuccess') })
    } catch (err) {
      message.error({ title: t('CopyFaild') })
    }
  };

  const newbills = bills.map(bill => {
    let newPrice: number | string = (bill.price * 100).toFixed(2);
    const allModelsArray = Object.values(models).flatMap(group => group.models);
    if (newPrice.endsWith('.00')) {
      newPrice = parseInt(newPrice, 10);
    } else {
      newPrice = parseFloat(newPrice);
    }
    newPrice = -newPrice;
    let matchedModel = allModelsArray.find(model => model.key === bill.modelName);
    return {
      ...bill,
      price: newPrice,
      notVipPrice: bill.notVipPrice / 10000,
      modelName: matchedModel ? matchedModel.name : bill.modelName
    };
  });
  const newPayOrders = payOrders.map(order => ({
    ...order,
    points: order.price * 100,
    formattedCreateTime: dayjs(order.createTime).format('YYYY-MM-DD HH:mm:ss')
  }));
  const combinedData: Array<Bill | PayOrder> = [
    ...newbills.map(bill => ({ ...bill, arraytype: 'bill' as const, time: bill.time })),
    ...newPayOrders.map(order => ({ ...order, arraytype: 'order' as const, time: order.createTime }))
  ];
  combinedData.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());

  const CombinedComponent: React.FC<{ data: Array<Bill | PayOrder> }> = ({ data }) => {
    return (
      <>
        {data.length > 0 ? (
          data.map((item, index) => {
            if (item.arraytype === 'order') {
              const statusDesc = (() => {
                switch (item.status.toString()) {
                  case 'NOTPAY':
                    return t('Wait_for_payment');
                  case 'CLOSED':
                    return t('Closed');
                  case 'SUCCESS':
                    return t('Points_increase');
                  default:
                    return t('Unknown_status');
                }
              })();
              const statusColor = (() => {
                switch (item.status.toString()) {
                  case 'NOTPAY':
                    return 'billsboxawait';
                  case 'CLOSED':
                    return 'billsboxfail';
                  case 'SUCCESS':
                    return 'billsboxsucceed';
                  default:
                    return 'billsboxfail';
                }
              })();
              return (
                <Box className={style.itmebox} key={index}>
                  <Flex justify={'space-between'} pos={'relative'}>
                    <Flex className={style.billbox}>
                      <Icon
                        as={require(`@/assets/svg/viphistory.svg`).ReactComponent}
                        className={style.billsboxicon}
                      />
                      <Text className={style.billstitle}>点数充值</Text>
                    </Flex>
                    <Flex>
                      <Text
                        color={item.status === 'SUCCESS' ? '#333' : 'rgba(153, 153, 153, 0.5)'}
                        className={style.billsboxprices}
                      >
                        +{item.points}
                      </Text>
                      <Text className={style[statusColor]}>{statusDesc}</Text>
                      {item.status === 'NOTPAY' && (
                        <Flex className={style.paysbutton} onClick={() => handleRefreshPayOrder(item._id)}>
                          更新
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                  <Flex className={style.textbox}>
                    <Text className={styles.fasttext}>{t('type')}：个人中心</Text>
                    <Text className={styles.endtext}>{t('Time')}：{item.formattedCreateTime}</Text>
                  </Flex>
                  <Flex className={style.textbox}>
                    <Text className={styles.fasttext}>金额：{item.price}</Text>
                    <Text className={styles.endtext}>{t('Order_number')}：{item.orderId}</Text>
                    <Icon
                      as={require(`@/assets/svg/vipOrderCopy.svg`).ReactComponent}
                      onClick={() => onclickCopy(item.orderId)}
                    />
                  </Flex>
                </Box>
              );
            } else { // item.type === 'bill'
              const truncatedPrice = parseFloat(item.notVipPrice.toString()).toFixed(0);
              return (
                <Box className={styles.itmebox} key={index}>
                  <Flex className={styles.billbox}>
                    <Flex>
                      <Icon
                        className={styles.billsboxicon}
                        as={require(`@/assets/svg/${item.price > 0 ? "addpoints" : "depletepoints"}.svg`).ReactComponent}
                      />
                      <Text className={styles.billsboxadd}>
                        {item.price > 0 ? '点数增加' : t('Points_are_consumed')}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text className={styles.billsboxadd} textDecoration={item.isVip ? 'line-through' : ''} color={item.isVip ? 'rgba(153,153,153,0.5)' : '#333'}>
                        {item.notVipPrice ? `-${truncatedPrice}` : item.price > 0 ? `+${item.price}` : item.price === 0 ? `${item.price}` : `${item.price}`}
                      </Text>
                      <Icon className={item.isVip ? styles.vippointsicon : styles.pointsicon} as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                    </Flex>
                    {item.isVip &&
                      <Flex pos={'absolute'} right={'0px'} top={'20px'} justify={'center'} align={'center'}>
                        <Icon as={require(`@/assets/svg/personPointsVip.svg`).ReactComponent} mr={'2px'} />
                        <Text className={styles.vipbillsboxadd}>
                          会员免费
                        </Text>
                      </Flex>
                    }
                  </Flex>
                  <Flex className={styles.textbox}>
                    <Text className={styles.fasttext}>{t('type')}：{item.type}</Text>
                    <Text className={styles.endtext}>{t('Time')}：{formatDates(item.type, item.time)}</Text>
                  </Flex>
                  {item.type === '对话' ?
                    <Flex className={styles.textbox}>
                      <Text className={styles.endtext}>详情：
                        {item && item.modelName && (() => {
                          const modelName = item.modelName;
                          const dashCount = (modelName.match(/-/g) || []).length;
                          if (modelName === '-') {
                            return modelName;
                          }
                          if (dashCount === 1) {
                            return modelName.replace('-', ' ');
                          } else {
                            if (modelName.includes('文思逸言')) {
                              return `文思GPT ${modelName.slice(modelName.indexOf('-') + 1)}`;
                            } else if (modelName.includes('文件翻译')) {
                              return item.type;
                            } else {
                              return modelName.slice(modelName.indexOf('-') + 1);
                            }
                          }
                        })()}
                      </Text>
                    </Flex>
                    :
                    <Flex className={styles.textbox}>
                      <Text className={styles.fasttext}>内容：{item.contents}</Text>
                      <Text className={styles.endtext}>{t('Details_Models')}：
                        {item && item.modelName && (() => {
                          const modelName = item.modelName;
                          const dashCount = (modelName.match(/-/g) || []).length;
                          if (modelName === '-') {
                            return modelName;
                          }
                          if (dashCount === 1) {
                            return modelName.replace('-', ' ');
                          } else {
                            if (modelName.includes('文思逸言')) {
                              return `文思GPT ${modelName.slice(modelName.indexOf('-') + 1)}`;
                            } else if (modelName.includes('文件翻译')) {
                              return item.type;
                            } else {
                              return modelName.slice(modelName.indexOf('-') + 1);
                            }
                          }
                        })()}
                      </Text>
                    </Flex>
                  }
                </Box>
              );
            }
          })
        ) : (
          <Box className={styles.noChat}>
            <Image src={require('@/assets/png/no_chat.png')} />
            <Text>暂无记录</Text>
          </Box>
        )}
      </>
    );
  };
  return (
    <Box className={styles.content}>
      {currentType === ChatType.AI ?
        <BalanceTop_up></BalanceTop_up>
        :
        <Box className={styles.parent}>
          <Box className={styles.child}>
            <Flex className={styles.selectDate} onClick={() => setOpen(true)}>
              <Text>{range[0] ? formatDate(range[0]) : '开始时间'}</Text>
              <Text mx={2}> —— </Text>
              <Text>{range[1] ? formatDate(range[1]) : '结束时间'}</Text>
            </Flex>
            <ScrollBarBox className={styles.listbox}>
              <CombinedComponent data={combinedData}></CombinedComponent>
            </ScrollBarBox>
          </Box>
          <CModal isOpen={open} onClose={() => setOpen(false)}>
            <DatePicker range={range} setRange={setRange} onClose={() => setOpen(false)} />
          </CModal>
        </Box>}
    </Box>
  );
};


export default observer(BillsContent);