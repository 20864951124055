import { observer } from "mobx-react-lite";
import { Box, Flex } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import styles from './index.module.scss'
import { ComTitle } from "../components/ComTitle";
import { ScrollBarBox } from "@/components/ScrollBox";
import { ComButton } from "../components/ComButton";
import { Contentpreview } from "../components/ContentPreview";
import { NamingSchema, ToolSchema } from "@/@types/tools";
import { toolStore } from "@/store/tool";
import { throttle } from "lodash";
import { useMessage } from "@/hooks/useMessage";
import { streamFetch } from "@/utils/stream";
import { deleteNaming, getNaming, postCreateNaming } from "@/api/tools";
import { _Models } from "..";
import { userStore } from "@/store/user";
import { ComBgTextarea } from "../components/ComBgTextarea";
import { ComSex } from "../components/ComSex";
import { useTranslation } from "react-i18next";
import { uiStrore } from "@/store/ui";

export const NaMing: FC = observer(() => {

  const { t } = useTranslation();
  const { language } = uiStrore;
  const [tool, SetTool] = useState<ToolSchema>()
  const bindGenPrompt = useRef<HTMLTextAreaElement>(null);
  const textareabindGenPrompt = useRef<HTMLTextAreaElement>(null);
  const [historyCache, setHistoryCache] = useState<NamingSchema[]>([]);
  const [choosesModel, setChoosesModel] = useState(false);
  const [modelkey, setModelsKey] = useState(_Models[1])
  const [caseStop, SetcaseStop] = useState(true);
  const message = useMessage();
  const { userInfo } = userStore;
  const { getToolByLink, getToolModel } = toolStore;
  const [buttonpd, setButtonpd] = useState(true);
  const [riskInfo, SetRiskInfo] = useState<any>();
  const [surname, SetSurname] = useState('');
  const [dateBirth, SetDateBirth] = useState('');
  const [timeBirth, SetTimeBirth] = useState('');
  const [generations, SetGenerations] = useState('');
  const [nameType, SetNameType] = useState(t('namingdouble'))
  const [sex, SetSex] = useState('男');

  const setViewhistory = (item: any) => {
    setIsContent(!isContent)
    SetSurname(item.surname)
    SetDateBirth(item.dateBirth)
    SetTimeBirth(item.timeBirth)
    SetGenerations(item.generations)
    if (item.nameType) {
      SetNameType(item.nameType === '2' ? t('namingdouble') : t('namingsingle'))
    }
    SetSex(item.sex === '男' ? t('namingmale') : t('namingfemale'))
    if (bindGenPrompt.current !== null) {
      bindGenPrompt.current.value = item.content;
      setButtonpd(bindGenPrompt.current.value.length < 1);
    }
    SetcaseStop(true);
    if (item.contentCheck) {
      SetRiskInfo(item.contentCheck);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await getToolByLink({ toollink: 'naMing' });
      SetTool(data[0])
      const res = await getNaming()
      setHistoryCache(res);
    };
    fetchData();
  }, [])

  const [loading, setLoading] = useState(false);
  const [isContent, setIsContent] = useState(false);

  const scrollToBottom = useCallback((behavior: 'smooth' | 'auto' = 'smooth') => {
    if (!bindGenPrompt.current) return;
    bindGenPrompt.current.scrollTo({
      top: bindGenPrompt.current.scrollHeight,
      behavior
    });
  }, []);
  const generatingMessage = useCallback(
    throttle(() => {
      if (!bindGenPrompt.current) return;
      const isBottom =
        bindGenPrompt.current.scrollTop + bindGenPrompt.current.clientHeight + 150 >=
        bindGenPrompt.current.scrollHeight;

      isBottom && scrollToBottom('auto');
    }, 100),
    []
  );
  const handleChange = () => {
    if (!bindGenPrompt.current || loading) return;
  };
  const delay = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve('');
      }, ms);
    });
  // 中断请求
  const controller = useRef(new AbortController());
  const handleGPTGenInfo = async () => {
    setButtonpd(false);
    setIsContent(true);
    if (bindGenPrompt.current !== null) {
      bindGenPrompt.current.value = '';
    }
    if (userInfo && !userInfo.monVip && userInfo.balance <= 0) {
      message.warning({ title: t('ToolinsufficientBalance') })
      // onOpenPayModal()
      setLoading(false);
      return;
    }
    const aiwritemodel = modelkey.key;
    const year = parseInt(dateBirth.slice(0, 4), 10);
    const month = parseInt(dateBirth.slice(5, 7), 10);
    const day = parseInt(dateBirth.slice(8), 10);
    const hour = parseInt(timeBirth.slice(0, 2), 10);
    const result = await postCreateNaming({ year, month, day, hour });
    setLoading(true);
    const template = `The number of characters in the "suffix" of the "name" I want should be: 【${nameType}】 character. The "name" should be provided in the format of: "surname" + "generation name" + "suffix".
The child's "surname" is: 【${surname}】. The child's "generation name" is: 【${generations}】. Gender:${sex}.
The Eight Characters (Ba Zi) and Five Elements (Wu Xing) information I provided includes:${result}；`
    const abortSignal = new AbortController();
    controller.current = abortSignal;
    // onOpenView();
    // 流请求，获取数据
    try {
      const ret = await streamFetch({
        data: {
          messages: [
            {
              content:
                'You are a master proficient in the Chinese Five Elements and Eight Trigrams, and you excel at combining these elements to create "names."【Rules】About "names": A "name" = "surname" + "generation name" + "suffix."Before answering, please ensure that the number of characters in the "suffix" matches the number I specified.Provide 5 "name" options at a time.The "names" you create should not be too repetitive, and provide an explanation and pinyin for each "name" to facilitate pronunciation.Now, analyze the Eight Characters information I provided, and then create "names" based on my requirements. Finally, please make sure to respond to me in Chinese.',
              role: 'system'
            },
            {
              content: template,
              role: 'user'
            }
          ] as any[],
          chatId: '',
          stream: true,
          appId: await getToolModel(''),
          model: aiwritemodel,
          toolParams: {
            surname: surname,
            dateBirth: dateBirth,
            timeBirth: timeBirth,
            generations: generations,
            nameType: nameType === t('namingdouble') ? '2' : '1',
            sex: sex === t('namingmale') ? '男' : '女',
            result: result,
            model: modelkey.key,
            template: template,
            toolsname: tool ? tool.name : '',
            toolsid: tool ? tool._id : ''
          }
        },
        onMessage: (text: string) => {
          if (!bindGenPrompt.current) return;
          bindGenPrompt.current.value = bindGenPrompt.current.value + text;
          setIsContent(false);
          generatingMessage();
          if (!textareabindGenPrompt.current) return;
          textareabindGenPrompt.current.value = bindGenPrompt.current.value;
        },
        abortSignal
      });
      // 等待输出内容结束
      abortSignal.signal.aborted && (await delay(500));
      const res = await getNaming()
      if (res) {
        SetRiskInfo(res[0].contentCheck)
      }
    } catch (err: any) {
      if (!(err == 'The user aborted a request.'
        || err == 'BodyStreamBuffer was aborted'
        || err == 'Fetch is aborted')) {
        message.warning({ title: typeof err === 'string' ? err : err?.message || '聊天出错了~' })
      } else {
        message.warning({ title: t('BuildFailure') })
      }
      if (err.message && err.message.includes('不足')) {
        // onOpenPayModal()
      }
      setLoading(false);
    }
    handleChange();
    const data = await getNaming();
    setHistoryCache(data);
    setLoading(false);
  };

  let isnullcontent = !surname || !dateBirth || !timeBirth

  return (<Flex key={'naMing'} className={styles.toollistBox}>
    <Flex className={styles.listBox}>
      <Box className={styles.bgbox}></Box>
      <Box zIndex={2} className={styles.topbox}>
        <ComTitle
          key={tool?.name.toString()}
          color={true}
          setViewhistory={setViewhistory}
          icon={tool ? tool.icon.toString() : ''}
          title={language === 'zh' ? tool ? tool.name.toString() : '' : (tool && tool.name_more_language) ? tool.name_more_language.en.toString() : ''}
          historyCache={historyCache}
          bindprompt={bindGenPrompt}
          historytitle='surname'
          historycontent='content'
          naming={true}
          onDelete={async (id: string) => {
            await deleteNaming({ _id: id });
            const data = await getNaming();
            setHistoryCache(data);
            if (bindGenPrompt.current !== null) {
              bindGenPrompt.current.value = '';
            }
          }} />
      </Box>
      <Box className={styles.contentsBox}>
        <ScrollBarBox>
          <Box>
            <Flex className={styles.allbox}>
              <ComBgTextarea
                title={t('namingname')}
                prompt={t('namingenterName')}
                textvalue={surname}
                textchange={SetSurname}
                maxCount={2}
                rows={1}
                required={true}
              />
              <ComBgTextarea
                title={t('namingDate')}
                prompt={t('namingSelectDatte')}
                textvalue={dateBirth}
                textchange={SetDateBirth}
                maxCount={30}
                rows={1}
                required={true}
                systemPromptTip={t('namingSelectDattetips')}
                isdatecl={true}
              />
              <ComBgTextarea
                title={t('namingTime')}
                prompt={t('namingselectTime')}
                textvalue={timeBirth}
                textchange={SetTimeBirth}
                maxCount={30}
                rows={1}
                required={true}
                systemPromptTip={t('namingselectTimetips')}
                istimecl={true}
              />
              <ComBgTextarea
                title={t('namingFamilyName')}
                prompt={t('namingenterFamilyName')}
                textvalue={generations}
                textchange={SetGenerations}
                maxCount={2}
                rows={1}
              />
              {/* <ComBgTextarea
                title={'名字总数'}
                prompt={'请输入'}
                textvalue={nameTotal}
                textchange={SetNameTotal}
                maxCount={10}
                rows={1}
              /> */}
              <ComSex sex={nameType} title={t('namingsNUmber')} SetSex={SetNameType} itema={t('namingdouble')} itemb={t('namingsingle')} issex={false} />
              <ComSex sex={sex} title={t('namingGender')} SetSex={SetSex} itema={t('namingmale')} itemb={t('namingfemale')} issex={true} />
            </Flex>
          </Box>
        </ScrollBarBox>
      </Box>
      <Flex className={styles.footbutton}>
        <ComButton
          isnull={isnullcontent}
          loading={loading}
          color={true}
          setLoading={setLoading}
          casebut={t('ToolExample')}
          startbut={t('Toolgenerate')}
          stopbut={t('toolstop')}
          stopClick={() => { controller.current?.abort() }}
          startClick={handleGPTGenInfo}
          caseStop={true}
          // caseButtonClick={caseButtonClick}
          chooseModel={choosesModel}
          setChooseModel={setChoosesModel}
          modelkey={modelkey}
          setModelsKey={setModelsKey}
          _Models={_Models}
        ></ComButton>
      </Flex>
    </Flex>
    <Flex className={styles.previewBox}>
      <Contentpreview
        title={t('ContentPreview')}
        titlePrompt={t('namingPreviewTips')}
        bindprompt={bindGenPrompt}
        loading={loading}
        buttonpd={buttonpd}
        isContent={isContent}
        riskInfo={riskInfo}
        model={modelkey.key}
      />
    </Flex>
  </Flex>)
})