import { Box, Flex, Icon, Image } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { userStore } from "@/store/user";
import { useMessage } from "@/hooks/useMessage";
import { toolStore } from "@/store/tool";
import { OldPhotoItem, ToolSchema } from "@/@types/tools";
import styles from "../Bg/index.module.scss"
import { ScrollBarBox } from "@/components/ScrollBox";
import { TranslationTitle } from "@/pages/tools/components/ComTarnsTitle";
import { ComFile } from "../Components/ComFile";
import { ImgsHistory } from "../Components/history";
import { getOldPhotoFix, oldPhotoFix } from "@/api/tools";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { creationStore } from "@/store/creation";
import { isDesktop } from "@/utils/electron";
import { useTranslation } from "react-i18next";
import CreateBtn from "../../Components/CreationBtn";
import { uiStrore } from "@/store/ui";
import OldPhotoAnimation from "../Components/oldPhotoAnimation";

export const OldPhoto: FC = observer(() => {
  const [tool, SetTool] = useState<ToolSchema>()
  const { getToolByLink } = toolStore;
  const { t } = useTranslation();
  const { userInfo } = userStore;
  const { enableKeys } = creationStore.publicStore;
  const message = useMessage();
  const [list, setList] = useState<OldPhotoItem[]>([])
  const [chooseFile, setChooseFile] = useState<File>();
  const [showFile, setShowFile] = useState<File>();
  const [loading, setLoading] = useState(false)
  const { language } = uiStrore;
  useEffect(() => {
    const fetchData = async () => {
      const data = await getToolByLink({ toollink: 'oldPhotos' });
      SetTool(data[0]);
      // const res = await getHdrestoration('oldPhotos')
      // setList(res || [])
    };
    fetchData();
  }, []);
  const [isHovered, setIsHovered] = useState(false);

  const uploadFile = async (file: File) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    try {
      const response = await axios.post(
        (isDesktop() ? (window.proxyApi || '') : '') + '/api/system/heicToAliOss',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent: any) => {
            if (progressEvent && progressEvent.total && progressEvent.total > 0) {
              const perCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              console.log('Upload progress:', perCompleted);
            }
          }
        }
      );
      await oldPhotoFix(response.data?.data[0])
      setChooseFile(undefined)
      setShowFile(undefined);
      setLoading(false)
      document.getElementById('chooseFile')?.setAttribute('src', '')
      if (response.data?.code === 200) {
        await getMyImgs()
      } else if (response.data?.message.indexOf('余额不足') > -1) {
        message.warning({ title: t('creation.ImgFixFaild') })
      }
    } catch (err) {
      setChooseFile(undefined)
      document.getElementById('chooseFile')?.setAttribute('src', '')
      setLoading(false)
      console.log(err)
    }
  }
  const getMyImgs = async () => {
    try {
      const res = await getOldPhotoFix()
      setList(res.data || [])
    } catch (err) {
      console.log(err)
    }
  }

  const startGenerate = () => {
    if (loading) return
    if (!userInfo?.monVip && (userInfo?.balance && userInfo?.balance < 1)) {
      message.warning({ title: t('PlsRecharge') })
      return;
    }
    if (chooseFile) {
      uploadFile(chooseFile)
    }
  }

  useQuery(['refreshList'], () => { getMyImgs(); return null }, {
    refetchInterval: 10000,
  })

  const reset = () => {
    setChooseFile(undefined);
    setShowFile(undefined);
    document.getElementById('chooseFile')?.setAttribute('src', '')
  }

  const { showNav } = creationStore.publicStore

  return (
    <Flex key={'oldphoto'} w={!showNav ? '100%' : 'calc(100% - 340px)'} className={styles.toollistBox}>
      <Flex className={styles.listBox}>
        <TranslationTitle
          icon={tool ? tool.icon.toString() : ''}
          title={language === 'zh' ? tool ? tool.name.toString() : '' : (tool && tool.name_more_language) ? tool.name_more_language.en.toString() : ''}
          historytitle='language'
        />
        <Box className={styles.contentsBox}>
          <ScrollBarBox>
            <Box className={styles.imageBox}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}>
              <Box className={`${styles.fade} ${isHovered ? styles.visible : styles.hidden}`}>
                <OldPhotoAnimation />
              </Box>
              <Box borderRadius='38px' overflow='hidden' className={`${styles.fade} ${isHovered ? styles.hidden : styles.visible}`}>
                <Image className={styles.image} src={require('@/assets/lottie/oldPhoto_0.jpg')} />
              </Box>
            </Box>
            <Flex>
              <ComFile
                title={t('creation.UploadOldPhoto')}
                prompt={t('creation.RMBgDesc')}
                chooseFile={showFile}
                setChooseFile={setShowFile}
                setOriginalFile={setChooseFile}
                filetitle={t('上传一张黑白老照片，进行上色和画质增强的处理')}
                filelist={[`${t('SupportImages')}：JPG、JPEG、PNG、WEBP、BMP、HEIC、HEIF`, t('imgMax20Size')]}
              />
            </Flex>
          </ScrollBarBox>
        </Box>
        <Flex className={styles.footbutton}>
          <CreateBtn
            loading={loading}
            isMaintain={!enableKeys['oldPhoto']}
            disabled={!chooseFile}
            onClick={() => { if (chooseFile) { startGenerate() } }}
            price={10}
            isVip={userInfo?.monVip}
            icon={<Icon as={require('@/assets/svg/create_img.svg').ReactComponent} />}
            onReset={reset}
            onVip={() => uiStrore.setOpenVip(true)}
          />
        </Flex>
      </Flex>
      <Flex className={styles.previewBox}>
        <ImgsHistory
          key={tool?.name.toString()}
          history={list}
          type='oldPhotos'
        />
      </Flex>
    </Flex>
  )
})