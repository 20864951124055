import { CModal } from "@/components/Modal";
import { Box, Icon, Text, Image as ShowImage, Textarea } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { WiseSelect } from "@/components/WiseSelect";
import { Option, Slider } from "@mui/base";
import { v4 as uuidv4 } from 'uuid';
import { getLangtranslate, postCreateDrawImage } from "@/api/creation";
import { useMessage } from "@/hooks/useMessage";
import { CreationType } from "@/store/creation";
import { CButton } from "@/components/Button";
import { isChromeHighVersion, PRICE_SCALE, SHOW_PRICE_SCALE, uploadFiles } from "@/utils/common";
import { uiStrore } from "@/store/ui";
import { useTranslation } from "react-i18next";

import styles from './index.module.scss'
import CreateBtn from "../CreationBtn";

interface PartReDrawProps {
  open: boolean;
  onClose: () => void;
  item: HistoryItem
  partReDrawUrl: string
  styleList: StyleConfig[]
  shouldUpdate?: () => void
  isVip?: boolean
}
const _MAX_LENGTH = 500;
export const PartReDraw: FC<PartReDrawProps> = (props) => {
  const { open, onClose, item, partReDrawUrl, styleList, shouldUpdate, isVip } = props;

  const [canvasScale, setCanvasScale] = useState({ width: 1, height: 1 })
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const lineHistory = useRef<Path2D[]>([])
  const [imgNode, setImgNode] = useState<HTMLImageElement>();
  const [cnPrompt, setCNPrompt] = useState('');
  const lineSize = useRef(10)
  const ctx = useRef<CanvasRenderingContext2D | null>(null)
  const isDown = useRef(false);
  const [chooseStyle, setChooseStyle] = useState<string>('')
  const [upLoading, setUploading] = useState(false);
  const path = useRef(new Path2D()); // 画笔路径
  const { zoom = 1 } = uiStrore;
  const sliderRef = useRef<HTMLSpanElement>(null);
  const { t } = useTranslation();

  const message = useMessage();

  useEffect(() => {
    if (imgNode && partReDrawUrl) {
      renderImg()
    }

    if (sliderRef.current && open) {
      const originalGetBoundingClientRect = sliderRef.current.getBoundingClientRect;
      if (!isChromeHighVersion()) {
        return;
      }
      // @ts-ignore
      const zoomNum = zoom || Number(document.body.style.zoom) || 1;
      sliderRef.current.getBoundingClientRect = function () {
        const rect = originalGetBoundingClientRect.call(this);
        const newRect = new DOMRect(
          rect.x * zoomNum,
          rect.y * zoomNum,
          rect.width * zoomNum,
          rect.height * zoomNum
        );
        return newRect;
      };
    }

    return () => {
      lineHistory.current = []
    }
    // eslint-disable-next-line
  }, [imgNode, partReDrawUrl])

  useEffect(() => {
    if (canvasScale && canvasRef.current) {
      ctx.current = canvasRef.current.getContext('2d')
      ctx.current?.scale(canvasScale.width, canvasScale.height)
    }
  }, [canvasScale])

  const refCallback = useCallback((node: HTMLImageElement) => {
    setImgNode(node)
  }, [])

  const renderImg = () => {
    if (!imgNode || !partReDrawUrl) return
    imgNode.src = partReDrawUrl || ''
    imgNode.onload = () => {
      const canvas = canvasRef.current as HTMLCanvasElement
      // const dpr = window.devicePixelRatio;
      const { naturalWidth, naturalHeight } = imgNode;
      //   // 重新设置 canvas 自身宽高大小和 css 大小。放大 canvas；css 保持不变，因为我们需要那么多的点
      canvas.width = Math.round(naturalWidth); // * dpr
      canvas.height = Math.round(naturalHeight);
      canvas.style.width = imgNode.width + 'px';
      canvas.style.height = imgNode.height + 'px';
      canvas.style.top = imgNode.offsetTop + 'px';
      canvas.style.left = imgNode.offsetLeft + 'px';
      setCanvasScale({ width: naturalWidth / imgNode.width, height: naturalHeight / imgNode.height })
    }
  }

  const startDraw = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    isDown.current = true
    ctx.current = (canvasRef.current as HTMLCanvasElement).getContext('2d')

    ctx.current?.beginPath();
    let x: number = 0, y: number = 0;
    if (event.type === 'touchstart') {
      const canvasEle = canvasRef.current as HTMLCanvasElement
      x = (event as React.TouchEvent<HTMLCanvasElement>).touches[0].pageX - canvasEle?.getBoundingClientRect().x;
      y = (event as React.TouchEvent<HTMLCanvasElement>).touches[0].pageY - canvasEle?.getBoundingClientRect().y;
    } else {
      const { offsetX, offsetY } = (event as React.MouseEvent<HTMLCanvasElement>).nativeEvent
      x = offsetX; y = offsetY;
    }
    ctx.current?.moveTo(x / zoom, y / zoom);
    path.current.moveTo(x / zoom, y / zoom);
    draw(event)
  }

  const draw = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    event.stopPropagation();

    if (!isDown.current) return

    if (ctx.current) {
      ctx.current.strokeStyle = 'rgba(255,41,34)';
      ctx.current.lineWidth = lineSize.current;
      ctx.current.lineJoin = 'round'
      ctx.current.lineCap = 'round'
      let x: number = 0, y: number = 0;
      if (event.type === 'touchmove') {
        const canvasEle = canvasRef.current as HTMLCanvasElement
        x = (event as React.TouchEvent<HTMLCanvasElement>).touches[0].pageX - canvasEle?.getBoundingClientRect().x;
        y = (event as React.TouchEvent<HTMLCanvasElement>).touches[0].pageY - canvasEle?.getBoundingClientRect().y;
      } else {
        const { offsetX, offsetY } = (event as React.MouseEvent<HTMLCanvasElement>).nativeEvent
        x = offsetX; y = offsetY;
      }
      ctx.current.lineTo(x / zoom, y / zoom);
      path.current.lineTo(x / zoom, y / zoom);
      lineHistory.current.push(path.current);
      ctx.current.stroke()
    }
  }

  const stopDraw = () => {
    isDown.current = false;
    path.current = new Path2D();
    ctx.current?.closePath();
  }

  const postBeforeFillWhite = async () => {
    if (!ctx.current || upLoading) return
    ctx.current.clearRect(0, 0, ctx.current.canvas.width, ctx.current.canvas.height);
    setUploading(true);
    lineHistory.current.forEach((item) => {
      ctx.current!.strokeStyle = 'white';
      ctx.current!.stroke(item);
    });
    confirmPartReDraw();
  }

  const confirmPartReDraw = async () => {
    const newCanvas = document.createElement('canvas')
    const newContext = newCanvas.getContext('2d')

    newCanvas.width = ctx.current?.canvas.width as number;
    newCanvas.height = ctx.current?.canvas.height as number;
    newContext!.fillStyle = 'black';
    newContext!.fillRect(0, 0, newCanvas.width, newCanvas.height);
    newContext?.drawImage(ctx.current?.canvas as HTMLCanvasElement, 0, 0);

    /**
     * 生成涂鸦图并创建绘画任务
     */
    newCanvas.toBlob(async (blob) => {
      if (upLoading || !blob) return

      if (!chooseStyle && item.drawType === '') {
        setUploading(false);
        message.tips({ title: t('creation.SelectStyle') })
        return
      }
      if (lineHistory.current.length === 0) {
        message.tips({ title: t('creation.PaintingFirst') })
        setUploading(false);
        return
      }
      let uPrompt = cnPrompt;
      if (/[\u4e00-\u9fa5]/.test(cnPrompt)) {
        //有中文，则翻译
        const ePrompt = (await getLangtranslate(uPrompt)).toString();
        uPrompt = ePrompt;
      }

      if (uPrompt === '' || uPrompt.trim().length <= 2) {
        setUploading(false);
        message.tips({ title: t('PlsInputPrompt') })
        return
      }

      const index = item?.imageUrl.indexOf(partReDrawUrl) || 0;
      const file = new File([blob], uuidv4() + '.png', { type: blob.type });
      try {
        const res = await uploadFiles(file);
        const reDrawTaskRes = await postCreateDrawImage({
          drawType: chooseStyle || item.drawType,
          group: 'AIDraw',
          cnPrompt: cnPrompt,
          hrPriceLevel: item?.hrPriceLevel,
          prompt: uPrompt,
          type: item?.type!,
          extendsType: 'partReDraw',
          originUrl: partReDrawUrl,
          maskUrl: res[0],
          imgToImgWeight: 0.75,
          ...(item?.type === CreationType.MJ && {
            action: 'R' + index,
            actionId: item?.extends.midjourneyID
          }),
          ...(item?.type === CreationType.SD3 && {
            sd3DrawParam: item.extends.sd3DrawParam,
          })
        });
        message.success({ title: reDrawTaskRes.message });
        shouldUpdate && shouldUpdate()
        onClose()
      } catch (err: any) {
        message.error({ title: err.message })
      } finally {
        setUploading(false);
      }
    }, 'image/png')
  }

  return <CModal isOpen={open} onClose={onClose}>
    <Box className={styles.partReDraw}>
      <Box className={styles.title}>
        <Box className={styles.titleText}>
          <Text className={styles.name}>{t('PartialRepaint')}</Text>
          <Text className={styles.tips}>{t('creation.partRedrawTips')}</Text>
        </Box>
        <Icon className={styles.close} as={require('@/assets/svg/close_x.svg').ReactComponent} onClick={onClose} />
      </Box>
      <Box className={styles.content}>
        <Box className={styles.imgBox} bg={`url(${require('@/assets/png/transparent_bg.png')})`}>
          <ShowImage ref={refCallback} src={partReDrawUrl} />
          <canvas ref={canvasRef} className={styles.canvas}
            onMouseDown={startDraw}
            onMouseUp={stopDraw}
            onMouseMove={draw}
          />
        </Box>
        <Box className={styles.tools}>
          <Box className={styles.style}>
            {/* <Icon as={require('@/assets/svg/brush.svg').ReactComponent} /> */}
            <Text className={styles.title}>{t('creation.RepaintStyle')}</Text>
            <WiseSelect defaultValue={item.drawType} onChange={setChooseStyle}>
              {styleList.map((item, index) => {
                // console.log(item.name)
                return item.enable && <Option key={item.key + index} value={item.name}>{item.name}</Option>
              })}
            </WiseSelect>
          </Box>
          <Box className={styles.brush}>
            <Text className={styles.title}>{t('creation.BrushSize')}</Text>
            <Slider
              ref={sliderRef}
              className={styles.sliderStyle}
              min={10} max={40}
              defaultValue={lineSize.current}
              onChange={(_, val) => { lineSize.current = val as number }}
              slotProps={{
                rail: { className: styles.sliderRail },
                mark: { className: styles.sliderMark },
                markLabel: { className: styles.sliderMarkLabel },
                thumb: { className: styles.sliderThumb },
                track: { className: styles.sliderTrack },
              }}
            />
          </Box>
          <Box className={styles.prompt}>
            <Text className={styles.title}>{t('creation.prompt')}</Text>
            <Box className={styles.inputs}>
              <Textarea value={cnPrompt} className={styles.area} resize='none' maxLength={500} placeholder={t('PlsInputPrompt')} onChange={e => setCNPrompt(e.target.value)} />
              <Text className={styles.length}>{cnPrompt.length}/{_MAX_LENGTH}</Text>
            </Box>
          </Box>
          <CreateBtn
            isVip={isVip}
            price={Math.floor(item.extends.costPoint / PRICE_SCALE * SHOW_PRICE_SCALE)}
            showReset={false}
            onClick={postBeforeFillWhite}
            icon={<Icon as={require('@/assets/svg/create_img.svg').ReactComponent} />}
            loading={upLoading}
          />
        </Box>
      </Box>
    </Box>
  </CModal>
}