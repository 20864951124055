import { Box, Icon } from "@chakra-ui/react"

import styles from './index.module.scss'
import { CodeInput, PhoneInput } from "./LoginInput"
import { useEffect, useRef, useState } from "react"
import { useMessage } from "@/hooks/useMessage"
import { userStore } from "@/store/user"
import { CButton } from "@/components/Button"
import { globalStore } from "@/store/global"
import { postWxBindPhone } from "@/api/user"
import { ipcRendererInvoke } from "@/utils/electron"
import { observer } from "mobx-react-lite"
import { useLocation } from "react-router-dom"

interface WxLoginBindPhoneProps {
  onBack: () => void
}
export const WxLoginBindPhone: React.FC<WxLoginBindPhoneProps> = observer((props) => {
  const { onBack } = props;

  const [phone, setPhone] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [codeLoading, setCodeLoading] = useState<boolean>(false);
  const message = useMessage();
  const { getCode, codeCountdown, wxUnbindUserInfo, setUserInfo } = userStore;
  const { setCanCloseLoginModal, setLoginIsOpen, loginSuccess } = globalStore;
  const [loading, setLoading] = useState<boolean>(false);
  const isBind = useRef(false);
  const location = useLocation();

  // 绑定手机号禁止关闭登录弹窗
  useEffect(() => {
    setCanCloseLoginModal(false);

    return () => {
      setCanCloseLoginModal(true);
      if (!isBind.current) {
        ipcRendererInvoke('clearCookie');
      }
    }
    // eslint-disable-next-line
  }, [])

  const sendGetCode = async () => {
    let errMsg = '';
    if (!phone) {
      errMsg = '请输入手机号';
    } else if (!/(^1[3456789]\d{9}$)/.test(phone)) {
      errMsg = '手机号格式错误';
    }
    if (errMsg) {
      message.error({ title: errMsg })
      return
    }

    setCodeLoading(true);
    try {
      await getCode(phone, 'bindPhone');
      setCodeLoading(false);
    } catch (err: any) {
      console.log(err);
      message.error({ title: err.message })
      setCodeLoading(false);
      // setError((err as ErrorResponse).message)
    }
  }

  const onBind = async () => {
    if (!wxUnbindUserInfo) return;
    let errMsg = '';
    if (!phone) {
      errMsg = '请输入手机号';
    } else if (!/(^1[3456789]\d{9}$)/.test(phone)) {
      errMsg = '手机号格式错误';
    } else if (!code) {
      errMsg = '请输入验证码';
    }
    if (errMsg) {
      message.error({ title: errMsg })
      return
    }

    setLoading(true);
    try {
      const openId = wxUnbindUserInfo.wxOpenId || wxUnbindUserInfo.appData.appWxopenId;
      const res = await postWxBindPhone({ username: phone, code, wxopenid: openId, password: openId });
      setUserInfo(res.user);
      setLoginIsOpen(false);
      loginSuccess(location.pathname);
      isBind.current = true;
    } catch (err: any) {
      message.error({ title: err.message })
      console.log(err)
    } finally {
      setLoading(false);
    }
  }

  const isDisabled = phone === '' || !wxUnbindUserInfo || code === '';
  return <Box className={styles.bindPhone}>
    <Box className={styles.bindTitle}>
      <Icon className={styles.icon} as={require('@/assets/svg/close_x.svg').ReactComponent} onClick={onBack} />
      <Box className={styles.title}>绑定手机号</Box>
    </Box>
    <Box className={styles.bindDesc}>
      <Box className={styles.freePoints}>新注册用户免费领取500
        <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
        点数
      </Box>
      <Box className={styles.desc}>
        如果您填写的手机号已存在且未绑定微信，我们将自动为您进行关联。
      </Box>
    </Box>
    <PhoneInput onChange={value => {
      setPhone(value);
    }} />
    <CodeInput loading={codeLoading} cutdown={codeCountdown} onChange={setCode} onGetCode={sendGetCode} />
    <CButton className={styles.bindBtn}
      isLoading={loading}
      disabled={isDisabled}
      theme='primary'
      onClick={onBind}
    >保存</CButton>
  </Box>
})