import { Flex, Box, Text, Icon, Image, BoxProps } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useState } from "react";
import styles from "./index.module.scss";
import { ScrollBarBox } from "@/components/ScrollBox";
import { HdrestorationUpStatusEnum } from "@/store/tool";
import RotatingCircles from "@/components/RotatingCircles";
import { useTranslation } from "react-i18next";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { getdate } from "@/pages/tools/components/ContentPreview";
import { getOSSBlobResource, saveBlobToLocal } from "@/utils/common";
import { useMessage } from "@/hooks/useMessage";

interface ListBox extends BoxProps {
  item: any
  history: any
}

export const ImgsHistory: FC<any> = observer(({
  history,
  type = 'enlarge'
}: {
  history: any[]
  type: 'enlarge' | 'bg' | 'oldPhotos'
}) => {
  const { t } = useTranslation();

  let title = '';
  let beforeText = '';
  let afterText = '';
  if (type === 'enlarge') {
    title = '图片高清放大';
    beforeText = t('creation.BeforeZoomin');
    afterText = t('creation.AfterZoomin');
  } else if (type === 'bg') {
    title = '图片背景移除';
    beforeText = t('creation.BeforeRemoval');
    afterText = t('creation.AfterRemoval');
  } else if (type === 'oldPhotos') {
    title = '老照片修复';
    beforeText = t('creation.BeforeInpaint');
    afterText = t('creation.AfterInpaint');
  }

  const message = useMessage();
  const handleDownload = async (url: string) => {
    if (!url) return;
    try {
      let date = getdate();
      const suggestedName = `创作_${title}_${date}.png`;
      const res = await getOSSBlobResource(url);
      await saveBlobToLocal(res, suggestedName);
      message.success({ title: t('DownloadSuccess') });
    } catch (err: any) {
      if (err.message !== 'The user aborted a request.') {
        message.error({ title: t('DownloadFailed') });
      }
    }
  }

  const ListBox: FC<ListBox> = useCallback((props: any) => {
    const { item, history } = props;
    let statusInfo = {
      color: '',
      text: ''
    }

    if (type === 'enlarge') {
      switch (Number(item.status)) {
        case HdrestorationUpStatusEnum.PROCESSING:
          statusInfo = { text: t('creation.Zoomingin') + '...', color: '#565CFA' }
          break;
        case HdrestorationUpStatusEnum.COMPLETED:
          statusInfo = { text: t('creation.AmplifySuccess'), color: '#09AD19' }
          break;
        case HdrestorationUpStatusEnum.FAIL:
          statusInfo = { text: t('creation.ZoomFailed'), color: '#F76560' }
          break;
        default:
          statusInfo = { text: t('creation.Zoomingin') + '...', color: '#565CFA' }
          break;
      }
    } else if (type === 'bg') {
      switch (Number(item.status)) {
        case HdrestorationUpStatusEnum.PROCESSING:
          statusInfo = { text: t('creation.BGRemovaling') + '...', color: '#565CFA' }
          break;
        case HdrestorationUpStatusEnum.COMPLETED:
          statusInfo = { text: t('creation.RemovalSuccess'), color: '#09AD19' }
          break;
        case HdrestorationUpStatusEnum.FAIL:
          statusInfo = { text: t('creation.RemovalFailed'), color: '#F76560' }
          break;
        default:
          statusInfo = { text: t('creation.BGRemovaling') + '...', color: '#565CFA' }
          break;
      }
    } else {
      statusInfo = { text: t('creation.photoFixFinish'), color: '#09AD19' }
    }
    const date = new Date(item.uploadTime);
    date.setHours(date.getHours());
    const month = String(date.getMonth() + 1).padStart(2, '0 ')
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const [size, setSize] = useState<{ before: { w: number, h: number }, after: { w: number, h: number } }>()
    const formattedDate = `${month}-${day} ${hours}:${minutes}`;
    return (
      <Box key={item._id} className={styles.hisbox}>
        <Flex className={styles.headbox}>
          <Text className={styles.filename}>{item.fileName}</Text>
          <Flex color={statusInfo.color}>
            {Number(item.status) === HdrestorationUpStatusEnum.COMPLETED &&
              <Icon as={require('@/assets/svg/hdrSucess.svg').ReactComponent} />}
            {Number(item.status) === HdrestorationUpStatusEnum.FAIL &&
              <Icon as={require('@/assets/svg/hdrError.svg').ReactComponent} />}
            {statusInfo.text}
          </Flex>
        </Flex>
        <Flex className={styles.imgboxs}>
          {type === 'enlarge' ?
            <Box className={styles.imgbox}>
              <Box w={((size?.before?.w || 0) / (size?.after?.w || 0) * 100) + '%'}
                h={((size?.before?.h || 0) / (size?.after?.h || 0) * 100) + '%'} alignItems={'center'}>
                <PhotoProvider>
                  <PhotoView src={item.beforeUrl}>
                    <Image onLoad={e => {
                      const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
                      setSize(pre => ({
                        ...pre!,
                        before: { w: naturalWidth, h: naturalHeight }
                      }))
                    }} className={styles.brforeimg} src={item.beforeUrl} />
                  </PhotoView>
                </PhotoProvider>
              </Box>
              <Flex className={styles.tipstext}>{beforeText}</Flex>
              <Box id='see-detail' className={styles.downloadicon} onClick={() => { handleDownload(item.beforeUrl) }}>
                <Icon as={require('@/assets/svg/toolsicon/downloadnot.svg').ReactComponent} />{t('download')}
              </Box>
            </Box>
            : null
          }
          {
            type === 'bg' ? <Box className={styles.imgbox}>
              <PhotoProvider>
                <PhotoView src={item.beforeUrl}>
                  <Image className={styles.brforeimg} src={item.beforeUrl} />
                </PhotoView>
              </PhotoProvider>
              <Flex className={styles.tipstext}>{beforeText}</Flex>
              <Box id='see-detail' className={styles.downloadicon} onClick={() => { handleDownload(item.beforeUrl) }}>
                <Icon as={require('@/assets/svg/toolsicon/downloadnot.svg').ReactComponent} />{t('download')}
              </Box>
            </Box> : null
          }
          {
            type === 'oldPhotos' ? <Box className={styles.imgbox}>
              <PhotoProvider>
                <PhotoView src={item.beforeUrl}>
                  <Image className={styles.brforeimg} src={item.beforeUrl} />
                </PhotoView>
              </PhotoProvider>
              <Flex className={styles.tipstext}>{beforeText}</Flex>
              <Box id='see-detail' className={styles.downloadicon} onClick={() => { handleDownload(item.beforeUrl) }}>
                <Icon as={require('@/assets/svg/toolsicon/downloadnot.svg').ReactComponent} />{t('download')}
              </Box>
            </Box> : null
          }
          {Number(item.status) === HdrestorationUpStatusEnum.COMPLETED ? (
            <Box className={styles.imgbox} _hover={{ ' #see-detail': { display: 'flex' } }}>
              <PhotoProvider>
                <PhotoView src={item.afterUrl}>
                  <Image onLoad={e => {
                    const { naturalWidth, naturalHeight } = e.target as HTMLImageElement;
                    setSize(pre => ({
                      ...pre!,
                      after: { w: naturalWidth, h: naturalHeight }
                    }))
                  }} src={item.afterUrl} className={styles.afterimg}
                  />
                </PhotoView>
              </PhotoProvider>
              <Box id='see-detail' className={styles.downloadicon} onClick={() => { handleDownload(item.afterUrl) }}>
                <Icon as={require('@/assets/svg/toolsicon/downloadnot.svg').ReactComponent} />{t('download')}
              </Box>
              <Flex className={styles.tipstext}>{afterText}</Flex>
            </Box>
          ) : Number(item.status) === HdrestorationUpStatusEnum.FAIL ? (
            <Box className={styles.errorbox}>
              <Icon as={require('@/assets/svg/creation_fail.svg').ReactComponent} />
              <Text>{item.message ? item.message : t('creation.ImageFixFailed')}</Text>
              <Flex className={styles.tipstext}>{afterText}</Flex>
            </Box>
          ) : (
            <Box className={styles.loadingbox}>
              <RotatingCircles />
              <Text>{t('creation.PlsWait3Min') + '~'}</Text>
              <Flex className={styles.tipstext}>{afterText}</Flex>
            </Box>
          )}
        </Flex>
        <Flex className={styles.footbox}>
          {type === 'enlarge' &&
            <>
              <Icon as={require(`@/assets/svg/imgmodel.svg`).ReactComponent} className={styles.icon} />
              <Text>{item.elements.model}</Text>
            </>
          }
          <Icon as={require(`@/assets/svg/datetime.svg`).ReactComponent} className={styles.icon} />
          <Text>{formattedDate}</Text>
          {Number(item.status) === HdrestorationUpStatusEnum.COMPLETED && (
            <Flex>
              <Flex textDecoration={item.isuservip ? 'line-through' : ''}>
                <Icon className={styles.points} as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                {item.price} {t('points')}
              </Flex>
              {item.isuservip && <Flex className={styles.viptext}>
                <Icon as={require(`@/assets/svg/navisVip.svg`).ReactComponent} />
                {t('vipFree')}
              </Flex>}
            </Flex>
          )}
        </Flex>
      </Box>
    )
  }, []);

  return (
    <Flex className={styles.hisFlex}>
      <Box className={styles.centerItemsbox}>
        <Text className={styles.title}>{t('history')}</Text>
      </Box>
      <ScrollBarBox className={styles.hisBoxs}>
        {history.length > 0 ?
          history.map((item, index) => { return <ListBox key={index} item={item} history={history} /> })
          :
          <Flex className={styles.fullbox}>
            <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
            <Text className={styles.prompt}>{t('NoHistory')}</Text>
          </Flex>
        }
      </ScrollBarBox>
    </Flex>
  );
})

