import { getdate } from "@/pages/tools/components/ContentPreview";

class SaveUtils {
  /**
   * 该方法生成一个能触发下载的Blob URL。
   * @param data 要下载的数据。
   * @param type 数据类型，例如'image/svg+xml'。
   */
  createObjectUrl(data: any, type: string) {
    // 创建一个Blob对象
    const blob = new Blob(data, { type: type });
    // 生成一个指向该Blob的URL
    const url = URL.createObjectURL(blob);
    return url;
  }

  /**
   * 触发实际的下载操作
   * @param url Blob URL或者任何有效的URL指向资源。
   * @param fileName 下载文件的名称。
   */
  download(url: any, fileName: any) {
    // 创建一个<a>元素用于触发下载
    const a = document.createElement('a');
    // 设置下载链接
    a.href = url;
    // 设置下载文件名
    a.download = fileName;
    // 触发点击事件以开始下载
    document.body.appendChild(a);
    a.click();
    // 清理创建的临时元素
    document.body.removeChild(a);
  }

  downloadSvg(svgSelection: any, name: string) {
    // 首先获取真实的SVG DOM元素
    // 因为_selection._groups[0]是包含了我们目标节点的数组
    const svgElement = svgSelection.node();
    if (!svgElement) return; // 如果没有找到元素，直接返回
    // 获取outerHTML，并确保所有的<br>都变成自闭合标签以兼容SVG规范
    let text = svgElement.outerHTML.replaceAll('<br>', '<br/>');
    // 调用下载功能
    this.download(this.createObjectUrl([text], 'image/svg+xml'), name);
  }

  downloadSvgAsPng(svgSelection: any, name: any, model: string) {
    return new Promise(async (resolve, reject) => {
      try {
        // 获取SVG DOM元素
        const svgElement = svgSelection.node();
        if (!svgElement) throw new Error("SVG element not found");
        // 首先，我们需要将SVG元素转换为XML字符串
        const serializer = new XMLSerializer();
        let source = serializer.serializeToString(svgElement);
        // 修正潜在的命名空间问题
        if (!source.match(/^<svg[^>]+xmlns="http:\/\/www\.w3\.org\/2000\/svg"/)) {
          source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
        }
        if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
          source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }
        // 添加XML声明在开头
        source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
        // 将字符串转换成Blob
        const blob = new Blob([source], { type: 'image/svg+xml;charset=utf-8' });
        // 使用FileReader将Blob转换为Base64编码的图像
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          // 创建Image对象，用于载入Base64编码的SVG
          const image = new Image();
          if (typeof reader.result === 'string') {
            image.src = reader.result;
          } else {
            console.error('Expected a string, but received a different type');
          }
          image.onload = () => {
            // 创建Canvas
            const canvas = document.createElement('canvas');
            canvas.width = svgElement.clientWidth || svgElement.viewBox.baseVal.width;
            canvas.height = svgElement.clientHeight || svgElement.viewBox.baseVal.height;
            const context = canvas.getContext('2d');
            if (!context) {
              console.error('Unable to get the canvas context');
              return;
            }
            // 在绘制SVG之前，先为背景填充白色
            context.fillStyle = '#FFFFFF'; // 设置填充颜色为白色
            context.fillRect(0, 0, canvas.width, canvas.height); // 填充整个Canvas区域
            // 绘制图像到Canvas
            context.drawImage(image, 0, 0);
            const date = getdate();
            // 导出Canvas内容为PNG
            canvas.toBlob(async (blob) => {
              if (blob) {
                // const pngUrl = URL.createObjectURL(blob);
                // this.download(pngUrl, `${name}.png`);
                try {
                  // @ts-ignore
                  const handle = await window.showSaveFilePicker({
                    suggestedName: `工具_${name}_${model}_${date}.png`,  // 建议的文件名
                    types: [{
                      description: 'PNG Image',
                      accept: { 'image/png': ['.png'] },
                    }],
                  });
                  const writableStream = await (handle as any).createWritable();
                  await writableStream.write(blob);
                  await writableStream.close();
                  resolve(true);
                } catch (err) {
                  reject(`File Saving Error: ${err}`);
                }
              } else {
                reject("Failed to convert canvas to Blob.");
              }
            }, 'image/png');
          };
        };
      } catch (error: any) {
        reject(error.toString());
      }
    })
  }
}

export default new SaveUtils();
