export const OldPhotoAnimationJson = {
  "v": "5.12.2",
  "fr": 60,
  "ip": 0,
  "op": 345,
  "w": 556,
  "h": 300,
  "nm": "有圆角老照片",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 556,
      "h": 300,
      "p": require('@/assets/lottie/oldPhoto_0.jpg'),
      "e": 0
    },
    {
      "id": "image_1",
      "w": 556,
      "h": 300,
      "u": "images/",
      "p": "img_1.jpg",
      "e": 0
    },
    {
      "id": "image_2",
      "w": 556,
      "h": 300,
      "p": require('@/assets/lottie/oldPhoto_1.jpg'),
      "e": 0
    },
    {
      "id": "comp_0",
      "nm": "没圆角老照片",
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "形状图层 1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                159.512,
                117.109,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                -118.488,
                -32.891,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [],
          "ip": 0,
          "op": 600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 0,
          "nm": "竖线",
          "refId": "comp_1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": 0.475,
                    "y": 0.986
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0
                  },
                  "t": 0,
                  "s": [
                    556,
                    150,
                    0
                  ],
                  "to": [
                    -37.728,
                    0,
                    0
                  ],
                  "ti": [
                    27.106,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.667,
                    "y": 0.667
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0.333
                  },
                  "t": 120,
                  "s": [
                    167,
                    150,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.367,
                    "y": 1
                  },
                  "o": {
                    "x": 0.252,
                    "y": 0
                  },
                  "t": 135,
                  "s": [
                    167,
                    150,
                    0
                  ],
                  "to": [
                    27.667,
                    0,
                    0
                  ],
                  "ti": [
                    -27.667,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.507,
                    "y": 0.507
                  },
                  "o": {
                    "x": 0.333,
                    "y": 0.333
                  },
                  "t": 240,
                  "s": [
                    333,
                    150,
                    0
                  ],
                  "to": [
                    0,
                    0,
                    0
                  ],
                  "ti": [
                    0,
                    0,
                    0
                  ]
                },
                {
                  "i": {
                    "x": 0.693,
                    "y": 0.983
                  },
                  "o": {
                    "x": 0.58,
                    "y": 0.014
                  },
                  "t": 255,
                  "s": [
                    333,
                    150,
                    0
                  ],
                  "to": [
                    -9.979,
                    0,
                    0
                  ],
                  "ti": [
                    4.854,
                    0,
                    0
                  ]
                },
                {
                  "t": 345,
                  "s": [
                    -11.5,
                    150,
                    0
                  ]
                }
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                15,
                150,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "w": 30,
          "h": 300,
          "ip": 0,
          "op": 600,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 2,
          "nm": "生成后.png",
          "cl": "png",
          "tt": 1,
          "tp": 7,
          "refId": "image_0",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                278,
                150,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                278,
                150,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 600,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 4,
          "ty": 2,
          "nm": "修复后.png",
          "cl": "png",
          "tt": 1,
          "tp": 7,
          "refId": "image_1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                278,
                150,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                278,
                150,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 600,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 7,
          "ty": 4,
          "nm": "遮罩",
          "td": 1,
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                556,
                149.5,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                219.943,
                -9.791,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 1,
              "k": [
                {
                  "i": {
                    "x": [
                      0.715,
                      0.667,
                      0.667
                    ],
                    "y": [
                      0.466,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.408,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0.016,
                      0,
                      0
                    ]
                  },
                  "t": 0,
                  "s": [
                    0,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.668,
                      0.667,
                      0.667
                    ],
                    "y": [
                      0.636,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.339,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0.354,
                      0,
                      0
                    ]
                  },
                  "t": 37,
                  "s": [
                    20.226,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.518,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1.025,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.151,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0.3,
                      0,
                      0
                    ]
                  },
                  "t": 48,
                  "s": [
                    31,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.667,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 120,
                  "s": [
                    70,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.803,
                      0.667,
                      0.667
                    ],
                    "y": [
                      0.662,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.538,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0.224,
                      0,
                      0
                    ]
                  },
                  "t": 135,
                  "s": [
                    70,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.663,
                      0.667,
                      0.667
                    ],
                    "y": [
                      0.726,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.596,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0.528,
                      0,
                      0
                    ]
                  },
                  "t": 151,
                  "s": [
                    66,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.615,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1.038,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.291,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0.628,
                      0,
                      0
                    ]
                  },
                  "t": 180,
                  "s": [
                    51.5,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.615,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.333,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0,
                      0,
                      0
                    ]
                  },
                  "t": 240,
                  "s": [
                    40,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.713,
                      0.667,
                      0.667
                    ],
                    "y": [
                      0.35,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.384,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0.015,
                      0,
                      0
                    ]
                  },
                  "t": 255,
                  "s": [
                    40,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.696,
                      0.667,
                      0.667
                    ],
                    "y": [
                      0.663,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.366,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0.269,
                      0,
                      0
                    ]
                  },
                  "t": 286,
                  "s": [
                    48.885,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.694,
                      0.667,
                      0.667
                    ],
                    "y": [
                      0.7,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.364,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0.302,
                      0,
                      0
                    ]
                  },
                  "t": 302,
                  "s": [
                    63,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.742,
                      0.667,
                      0.667
                    ],
                    "y": [
                      0.807,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.437,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0.493,
                      0,
                      0
                    ]
                  },
                  "t": 316,
                  "s": [
                    79.5,
                    100,
                    100
                  ]
                },
                {
                  "i": {
                    "x": [
                      0.634,
                      0.667,
                      0.667
                    ],
                    "y": [
                      1.006,
                      1,
                      1
                    ]
                  },
                  "o": {
                    "x": [
                      0.356,
                      0.333,
                      0.333
                    ],
                    "y": [
                      0.862,
                      0,
                      0
                    ]
                  },
                  "t": 331,
                  "s": [
                    96.1,
                    100,
                    100
                  ]
                },
                {
                  "t": 345,
                  "s": [
                    100.9,
                    100,
                    100
                  ]
                }
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "rc",
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      556,
                      300
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "nm": "矩形路径 1",
                  "mn": "ADBE Vector Shape - Rect",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.337254901961,
                      0.360784313725,
                      0.980392156863,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "填充 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -58.057,
                      -9.791
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "矩形 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 10,
          "ty": 2,
          "nm": "生成前.png",
          "cl": "png",
          "refId": "image_2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                278,
                150,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                278,
                150,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 600,
          "st": 0,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_1",
      "nm": "竖线",
      "fr": 60,
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 4,
          "nm": "形状图层 1",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                15,
                150,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                -134.709,
                -280.836,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "d": 1,
                  "ty": "el",
                  "s": {
                    "a": 0,
                    "k": [
                      20,
                      20
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "nm": "椭圆路径 1",
                  "mn": "ADBE Vector Shape - Ellipse",
                  "hd": false
                },
                {
                  "ty": "st",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 3
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 4
                  },
                  "w": {
                    "a": 0,
                    "k": 3,
                    "ix": 5
                  },
                  "lc": 1,
                  "lj": 1,
                  "ml": 4,
                  "bm": 0,
                  "nm": "描边 1",
                  "mn": "ADBE Vector Graphic - Stroke",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      0.337254901961,
                      0.360784313725,
                      0.980392156863,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "填充 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -134.709,
                      -280.836
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "椭圆 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 600,
          "st": 0,
          "ct": 1,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 4,
          "nm": "形状图层 2",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                15,
                150,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                -82.371,
                -280,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "shapes": [
            {
              "ty": "gr",
              "it": [
                {
                  "ty": "rc",
                  "d": 1,
                  "s": {
                    "a": 0,
                    "k": [
                      4,
                      300
                    ],
                    "ix": 2
                  },
                  "p": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "nm": "矩形路径 1",
                  "mn": "ADBE Vector Shape - Rect",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": {
                    "a": 0,
                    "k": [
                      1,
                      1,
                      1,
                      1
                    ],
                    "ix": 4
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 5
                  },
                  "r": 1,
                  "bm": 0,
                  "nm": "填充 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": {
                    "a": 0,
                    "k": [
                      -82.371,
                      -280
                    ],
                    "ix": 2
                  },
                  "a": {
                    "a": 0,
                    "k": [
                      0,
                      0
                    ],
                    "ix": 1
                  },
                  "s": {
                    "a": 0,
                    "k": [
                      100,
                      100
                    ],
                    "ix": 3
                  },
                  "r": {
                    "a": 0,
                    "k": 0,
                    "ix": 6
                  },
                  "o": {
                    "a": 0,
                    "k": 100,
                    "ix": 7
                  },
                  "sk": {
                    "a": 0,
                    "k": 0,
                    "ix": 4
                  },
                  "sa": {
                    "a": 0,
                    "k": 0,
                    "ix": 5
                  },
                  "nm": "变换"
                }
              ],
              "nm": "矩形 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            }
          ],
          "ip": 0,
          "op": 600,
          "st": 0,
          "ct": 1,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 2,
      "ty": 0,
      "nm": "没圆角老照片",
      "tt": 1,
      "tp": 3,
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            278,
            150,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            278,
            150,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 556,
      "h": 300,
      "ip": 0,
      "op": 600,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "遮罩 2",
      "td": 1,
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            556,
            150,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            219.943,
            -9.791,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "rc",
              "d": 1,
              "s": {
                "a": 0,
                "k": [
                  556,
                  300
                ],
                "ix": 2
              },
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 30,
                "ix": 4
              },
              "nm": "矩形路径 1",
              "mn": "ADBE Vector Shape - Rect",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0.337254901961,
                  0.360784313725,
                  0.980392156863,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "填充 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  -58.057,
                  -9.791
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "变换"
            }
          ],
          "nm": "矩形 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 600,
      "st": 0,
      "ct": 1,
      "bm": 0
    }
  ],
  "markers": [],
  "props": {}
}