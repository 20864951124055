import { Box, Flex, Image, Text, Icon, useOutsideClick } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useMessage } from "@/hooks/useMessage";
import { toolStore } from "@/store/tool";
import { ToolSchema } from "@/@types/tools";
import styles from "./index.module.scss"
import { ScrollBarBox } from "@/components/ScrollBox";
import { deletePPT, getAIppts, getAllAudios, postCreateOutline, postCreatePPT, readAlready, updateOutline } from "@/api/tools";
import { userStore } from "@/store/user";
import { useTranslation } from "react-i18next";
import { TranslationTitle } from "@/pages/tools/components/ComTarnsTitle";
import { ComTextarea } from "../components/ComTextarea";
import { ToolSwitch } from "../components/Switch";
import { UploadFileComponent } from "../VideoSummary";
import { useSelectFile } from "@/hooks/useSelectFile";
import axios from "axios";
import { truncateText } from "@/pages/creation/Tools/Components/ComFile";
import { CButton } from "@/components/Button";
import { ComAIpptHistory } from "../components/ComAIpptHistory";
import { ComTags } from "../components/ComTags";
import { PPTlistBox } from "../components/CompptList";
import { getOSSBlobResource, saveBlobToLocal } from "@/utils/common";
import { CModal } from "@/components/Modal";
import { getdate } from "../components/ContentPreview";
import RotatingCircles from "@/components/RotatingCircles";
import { uiStrore } from "@/store/ui";
import HorizontalBox from "@/components/HorizontalBox";
import { useNavigate } from "react-router";
import React from "react";
import { errorMessages } from "@/pages/creation/CloneAudios";

const creates = [
  {
    svg: require('@/assets/svg/ppttitle.svg').ReactComponent,
    title: '标题生成',
    entitle: 'Title Generation',
    contonts: '输入标题，AI自动生成大纲，并可编辑与修改',
    encontonts: 'Enter a title and let AI automatically generate an outline which you can edit and modify.',
    tag: '推荐',
    entag: 'Top',
    num: 1
  },
  {
    svg: require('@/assets/svg/pptlongtext.svg').ReactComponent,
    title: '长文本生成',
    entitle: 'Long Text Generation',
    contonts: '输入文本，AI总结提炼，完成PPT的编写',
    encontonts: 'Input text and let AI summarize and refine to complete the PPT writing.',
    num: 2
  },
  {
    svg: require('@/assets/svg/pptfile.svg').ReactComponent,
    title: '上传文档生成',
    entitle: 'Document Upload Generation',
    contonts: '上传文档，AI提炼关键信息，生成优质PPT',
    encontonts: 'Upload a document and let AI extract key information to create a high-quality PPT.',
    num: 3
  },
  {
    svg: require('@/assets/svg/pptcustom.svg').ReactComponent,
    title: '自定义生成',
    entitle: 'Custom Generation',
    contonts: '输入标题或上传文档，AI自动生成PPT',
    encontonts: 'Input a title or upload a document, and let AI automatically generate the PPT.',
    num: 4
  }
];

const languages = [
  {
    text: "中文",
    lang_key: "cn",
    entext: "Chinese"
  },
  {
    text: "英文",
    lang_key: "en",
    entext: "English"
  },
  {
    text: "日语",
    lang_key: "ja",
    entext: "Japanese"
  },
  {
    text: "俄语",
    lang_key: "ru",
    entext: "Russian"
  },
  {
    text: "韩语",
    lang_key: "ko",
    entext: "Korean"
  },
  {
    text: "德语",
    lang_key: "de",
    entext: "German"
  },
  {
    text: "法语",
    lang_key: "fr",
    entext: "French"
  },
  {
    text: "葡萄牙语",
    lang_key: "pt",
    entext: "Portuguese"
  },
  {
    text: "西班牙语",
    lang_key: "es",
    entext: "Spanish"
  },
  {
    text: "意大利语",
    lang_key: "it",
    entext: "Italian"
  },
  {
    text: "泰语",
    lang_key: "th",
    entext: "Thai"
  }
];

const models = [
  {
    text: '无需AI配图',
    entext: 'No AI Illustrations',
    subtitle: '所有内容均为文字排版。无AI图片',
    ensubtitle: 'All content is text-based. No AI illustrations.',
    bol: false,
    val: ''
  },
  {
    text: '普通AI图片模型',
    entext: 'Standard AI Image Model',
    subtitle: '少量AI图文排版，内容更美观，排版更灵活',
    ensubtitle: 'Minimal AI graphic layouts, for more attractive content and flexible formatting.',
    bol: true,
    val: 'normal'
  },
  {
    text: '高级AI图片模型',
    entext: 'Advanced AI Image Model',
    subtitle: '丰富的AI图片排版，图片质量更高，生成速度更快',
    ensubtitle: 'Rich AI image layouts, with higher quality images and faster generation.',
    bol: true,
    val: 'advanced'
  }
];

interface IconMap {
  [key: string]: React.ComponentType<any>;
}
const icon_map: IconMap = {
  txt: require('@/assets/svg/txt.svg').ReactComponent,
  pdf: require('@/assets/svg/pdf.svg').ReactComponent,
  doc: require('@/assets/svg/docx.svg').ReactComponent,
  docx: require('@/assets/svg/docx.svg').ReactComponent, // 添加 `docx` 类型
  srt: require('@/assets/svg/srt.svg').ReactComponent,
};
const color = [
  { text: '全部', text_more_language: { en: 'All' } },
  { text: '蓝色', text_more_language: { en: 'Blue' } },
  { text: '绿色', text_more_language: { en: 'Green' } },
  { text: '红色', text_more_language: { en: 'Red' } },
  { text: '紫色', text_more_language: { en: 'Purple' } },
  { text: '黑色', text_more_language: { en: 'Black' } },
  { text: '灰色', text_more_language: { en: 'Gray' } },
  { text: '黄色', text_more_language: { en: 'Yellow' } },
  { text: '粉色', text_more_language: { en: 'Pink' } },
  { text: '橙色', text_more_language: { en: 'Orange' } },
];
const industry = [
  { text: '全部', text_more_language: { en: 'All' } },
  { text: '科技互联网', text_more_language: { en: 'Technology & Internet' } },
  { text: '教育培训', text_more_language: { en: 'Education & Training' } },
  { text: '政务', text_more_language: { en: 'Government Affairs' } },
  { text: '学院', text_more_language: { en: 'Academia' } },
  { text: '电子商务', text_more_language: { en: 'E-commerce' } },
  { text: '金融战略', text_more_language: { en: 'Finance & Strategy' } },
  { text: '法律', text_more_language: { en: 'Law' } },
  { text: '医疗健康', text_more_language: { en: 'Healthcare' } },
  { text: '文旅体育', text_more_language: { en: 'Culture, Travel & Sports' } },
  { text: '艺术广告', text_more_language: { en: 'Arts & Advertising' } },
  { text: '人力资源', text_more_language: { en: 'Human Resources' } },
  { text: '游戏娱乐', text_more_language: { en: 'Gaming & Entertainment' } },
];
const style = [
  { text: '全部', text_more_language: { en: 'All' } },
  { text: '简约', text_more_language: { en: 'Simple' } },
  { text: '卡通', text_more_language: { en: 'Cartoon' } },
  { text: '商务', text_more_language: { en: 'Business' } },
  { text: '创意', text_more_language: { en: 'Creative' } },
  { text: '国风', text_more_language: { en: 'Traditional Chinese' } },
  { text: '清新', text_more_language: { en: 'Fresh' } },
  { text: '扁平', text_more_language: { en: 'Flat' } },
  { text: '插画', text_more_language: { en: 'Illustration' } },
  { text: '节日', text_more_language: { en: 'Festival' } },
];
const getPriceByType = (dataArray: any, type: any) => {
  const item = dataArray.find((item: any) => item.type === type);
  return item ? item.price : '100'; // Return price if found, else null
};

export const AIppts: FC = observer(() => {

  const [tool, SetTool] = useState<ToolSchema>();
  const [pagenum, SetPageNum] = useState(0)
  const { userInfo } = userStore;
  const { t } = useTranslation();
  const { language, openAlert, closeAlert, zoom = 1 } = uiStrore;
  const { isPPTModify, setIsPPTModify, leavaAIppt, setLeavaAIppt, SetSelectTool } = toolStore;
  const navigate = useNavigate();
  const [ison, setIsOn] = useState(false);
  const [isspeech, setIsSpeech] = useState(false);
  const [title, SetTitle] = useState('')
  const [contents, SetContents] = useState('')
  const [langang, SetLangang] = useState('')
  const [langkey, SetLangkey] = useState('cn')
  const [historyCache, setHistoryCache] = useState<any[]>([]);
  const [pptlist, setPPTlist] = useState<any[]>([]);
  const [selectppt, SetSelectppt] = useState('');
  const [selectpptImg, SetSelectpptImg] = useState<any[]>([]);
  const [videoFile, setVideoFile] = useState<ChatFileType[]>();
  const [outline, SetOutline] = useState<any>(null)
  const [outlineId, SetOutlineId] = useState<any>(null)
  const [aimodel, SetaiModel] = useState(models[0]);
  const [selectIndustry, SetSelectIndustry] = useState('')
  const [selectStyle, SetSelectStyle] = useState('')
  const [selectColor, SetSelectColor] = useState('')
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [isShowPPT, setIsShowPPT] = useState(false);
  const [intervalId, setIntervalId] = useState('');
  const [showPPT, setShowPPT] = useState<any[]>([]);
  const [downloadppt, setDownloadppt] = useState('');
  const modalRef = useRef<HTMLDivElement>(null);
  const fileMaxSize = 1 * 1024 * 1024 // 500MB
  const supportedExtensions = ['.pdf', '.doc', '.docx', '.txt', '.md'];
  const [actionLoading, setActionLoading] = useState(true);
  const [outlinepirce, Setoutlinepirce] = useState(15);
  const [pptpirce, Setpptpirce] = useState(50);
  const [outlinePirce, setOutlinePirce] = useState(0);//生成大纲
  const [networkingPirce, setNetworkingPirce] = useState(0);//联网搜索
  const [notChinesePirce, setNotChinesePirce] = useState(0);//非中文
  const [speechPirce, setSpeechPirce] = useState(0);//演讲备注
  const [pptPirce, setpptPirce] = useState(0);//生成PPT
  const [ordinaryPirce, setOrdinaryPirce] = useState(0);//普通AI配图
  const [advancedPirce, setAdvancedPirce] = useState(0);//高级AI配图
  const { onOpen, File } = useSelectFile({
    fileType: '.pdf,.doc,.docx,.txt,.md',
    multiple: true
  });
  const selectFiles = useCallback(async (files: File[]) => {
    if (files && files.length) {
      setIsSpeaking(true);
      const filesInfo: ChatFileType[] = [];
      files.forEach(file => {
        if (file.size > fileMaxSize) {
          message.error({ title: `${file.name}${t('FileTooLarge10')}` })
          return false
        }
        if (supportedExtensions.some(ext => file.name.toLowerCase().endsWith(ext))) {
          filesInfo.push({
            type: 'file_url',
            name: file.name,
            file,
            url: ''
          })
        } else {
          message.error({ title: `${t('NotSupported')} ${file.name} ${t('FileType')}` });
          return false
        }
      })

      if (filesInfo.length) {
        const beforeFiles = videoFile || []
        setVideoFile(beforeFiles.concat(filesInfo))
        // setUploading(true)
        const afterInfo: ChatFileType[] = []
        for (const item of filesInfo) {
          if (item.file) {
            try {
              const res = await uploadFiles(item.file)
              afterInfo.push({
                type: item.type,
                name: item.name,
                url: res.data.data[0]
              })
              setIsSpeaking(false)
            } catch (err) {
              message.error({ title: `${item.name}上传失败，请重试` });
              setIsSpeaking(false)
            }
          }
        }
        setVideoFile(beforeFiles.concat(afterInfo))
      }
    }
  }, [videoFile])

  const uploadFiles = (e: File) => {
    const formData = new FormData();
    formData.append('file', e)
    return axios.post(
      (window.proxyApi || '') + '/api/system/uploadToAliOss',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      }
    );
  }
  const deleteFile = (index: number) => {
    const new_file = videoFile?.filter((_, i) => i !== index) || [];
    setVideoFile(new_file)
  }
  const [uploadError, setUploadError] = useState('');

  const { getToolByLink } = toolStore;

  const [loading, setLoading] = useState(false);
  const message = useMessage();

  useEffect(() => {
    const fetchData = async () => {
      const data = await getToolByLink({ toollink: 'GeneratePPT' });
      SetTool(data[0]);
      const list = await getAllAudios();
      setPPTlist(list.expand.pptlist)
      startPolling();
      startPPTPolling();
      const datas = await getAllAudios();
      setOutlinePirce(getPriceByType(datas.expand.toolPrice, 'ppt_outline'))//生成大纲
      setNetworkingPirce(getPriceByType(datas.expand.toolPrice, 'ppt_networking'))//联网搜索
      setNotChinesePirce(getPriceByType(datas.expand.toolPrice, 'ppt_notChinese'))//非中文
      setSpeechPirce(getPriceByType(datas.expand.toolPrice, 'ppt_speech'))//演讲备注
      setpptPirce(getPriceByType(datas.expand.toolPrice, 'ppt_ppt'))//生成PPT
      setOrdinaryPirce(getPriceByType(datas.expand.toolPrice, 'ppt_ordinary'))//普通AI配图
      setAdvancedPirce(getPriceByType(datas.expand.toolPrice, 'ppt_advanced'))//高级AI配图
    };
    fetchData();
  }, []);

  useEffect(() => {
    SetTitle('')
    SetContents('')
    setVideoFile(undefined);
    SetLangkey('cn');
  }, [pagenum])

  useEffect(() => {
    let adjustedPrice = outlinePirce;
    let pptjustedPrice = pptPirce;
    if (langkey !== 'cn') {
      adjustedPrice += notChinesePirce
    }
    if (ison) {
      adjustedPrice += networkingPirce
      pptjustedPrice += networkingPirce
    }
    if (aimodel.val === 'normal') {
      pptjustedPrice += ordinaryPirce
    }
    if (aimodel.val === 'advanced') {
      pptjustedPrice += advancedPirce
    }
    if (isspeech) {
      pptjustedPrice += speechPirce
    }
    Setoutlinepirce(adjustedPrice);
    Setpptpirce(pptjustedPrice)
  }, [langkey, ison, aimodel, isspeech, outlinePirce])

  const gethis = async () => {
    const hisdata = await getAIppts();
    setHistoryCache(hisdata);
  }

  const createOutline = async () => {
    try {
      setLoading(true)
      const filename = videoFile ? videoFile[0].name : '';
      const fileurl = videoFile ? videoFile[0].url : '';
      const res = await postCreateOutline({
        title: title,
        content: contents,
        fileName: filename ? filename : '',
        fileUrl: fileurl,
        language: langkey,
        search: ison
      });
      if (res) {
        setLoading(false)
        startPolling();
        message.success({ title: t('生成大纲任务创建成功！') });
      } else {
        setLoading(false)
        startPolling();
        message.error({ title: t('创建任务失败！') });
      }
    } catch (e: any) {
      setLoading(false)
      startPolling();
      for (const [key, messageKey] of Object.entries(errorMessages)) {
        if (e.message.includes(key)) {
          message.error({ title: language === 'zh' ? `您的输入包含${t(messageKey)},请修改后重试` : e.message });
          return; // 一旦找到匹配项，立即退出函数
        }
      }
      message.error({ title: e.message });
    }
  }
  const startPolling = () => {
    const interval = setInterval(async () => {
      const hisdata = await getAIppts();
      setHistoryCache(hisdata);
      setActionLoading(false);

      // 检查是否有 status 为 1 的数据
      const hasStatusOne = hisdata.some((item: any) => item.status === 0 || item.status === 1);

      if (!hasStatusOne) {
        // 如果没有 status 为 1 的数据，停止轮询
        clearInterval(interval);
      }
    }, 3000); // 每隔3秒钟轮询一次，可以根据需要调整时间间隔
  };

  const createPPT = async () => {
    try {
      setLoading(true)
      const res = await postCreatePPT({
        outlineSid: outlineId,
        templateId: selectppt,
        language: langkey,
        search: ison,
        isCardNote: isspeech,
        aiImage: aimodel.val,
        isFigure: aimodel.bol
      });
      if (res) {
        setLoading(false)
        startPPTPolling();
        message.success({ title: t('生成PPT任务创建成功！') });
        SetPageNum(0)
      } else {
        setLoading(false)
        startPPTPolling();
        message.error({ title: t('创建任务失败！') });
      }
    } catch (e: any) {
      setLoading(false)
      startPPTPolling();
      for (const [key, messageKey] of Object.entries(errorMessages)) {
        if (e.message.includes(key)) {
          message.error({ title: language === 'zh' ? `您的输入包含${t(messageKey)},请修改后重试` : e.message });
          return; // 一旦找到匹配项，立即退出函数
        }
      }
      message.error({ title: e.message });
    }
  }
  const startPPTPolling = () => {
    const interval = setInterval(async () => {
      const hisdata = await getAIppts();
      setHistoryCache(hisdata);
      setActionLoading(false);
      const hasStatusOne = hisdata.some((item: any) => item.status === 3);
      if (!hasStatusOne) {
        clearInterval(interval);
      }
    }, 3000); // 每隔3秒钟轮询一次，可以根据需要调整时间间隔
  };

  const getFileIcon = (filename: any) => {
    const ext = filename.split('.').pop().toLowerCase(); // 提取文件扩展名并转为小写
    return icon_map[ext] || icon_map['default']; // 返回相应的图标组件或默认图标
  };
  const handleEdit = (type: any, value: any, chapterIndex: any, contentIndex: any) => {
    const newOutline = { ...outline };

    if (type === 'title') {
      newOutline.title = value;
    } else if (type === 'subTitle') {
      newOutline.subTitle = value;
    } else if (type === 'chapterTitle') {
      newOutline.chapters[chapterIndex].chapterTitle = value;
    } else if (type === 'chapterContent') {
      newOutline.chapters[chapterIndex].chapterContents[contentIndex].chapterTitle = value;
    }
    setIsPPTModify(true);
    SetOutline(newOutline);
  };

  const [morePosition, setMorePosition] = useState<undefined | { x: number, y: number, isFirstEdit: boolean, index: number }>();
  const moreEdit = useRef<any>();
  const editRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: editRef,
    handler: () => {
      setMorePosition(undefined);
    }
  })
  // 点击显示更多操作
  const onMore = (e: React.MouseEvent<SVGElement, MouseEvent>, index: number, item: any) => {
    e.stopPropagation();
    let isFirstEdit = false;
    if (item.status === 4) {
      isFirstEdit = true
    }
    //@ts-ignore
    let { x, y } = e.target.getBoundingClientRect();
    const _BoxHeigh = 90, _BoxWidth = 140;
    if (e.nativeEvent.y + _BoxHeigh > window.innerHeight) {
      y = y - _BoxHeigh;
    } else {
      y = y + 15;
    }
    if (e.nativeEvent.x + _BoxWidth > window.innerWidth) {
      x = x - _BoxWidth;
    } else {
      x = x + 15;
    }
    setMorePosition({ x, y, isFirstEdit, index });
    moreEdit.current = item;
  }

  const [outlinePosition, setOutlinePosition] = useState<undefined | { x: number, y: number, isFirstEdit: boolean, index: number, item: any, contentIndex?: number }>();
  const outlineRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: outlineRef,
    handler: () => {
      setOutlinePosition(undefined);
    }
  })
  // 点击显示更多操作
  const onOutline = (e: React.MouseEvent<SVGElement, MouseEvent>, index: number, item: any, contentIndex?: number) => {
    e.stopPropagation();
    let isFirstEdit = false;
    //@ts-ignore
    let { x, y } = e.target.getBoundingClientRect();
    const _BoxHeigh = 200, _BoxWidth = 140;
    if (e.nativeEvent.y + _BoxHeigh > window.innerHeight) {
      y = y - _BoxHeigh;
    } else {
      y = y + 15;
    }
    if (e.nativeEvent.x + _BoxWidth > window.innerWidth) {
      x = x - _BoxWidth;
    } else {
      x = x + 15;
    }
    if (item.chapterContents) {
      isFirstEdit = true
    }
    if (contentIndex !== undefined) {
      setOutlinePosition({ x, y, isFirstEdit, index, item, contentIndex });
    } else {
      setOutlinePosition({ x, y, isFirstEdit, index, item });
    }
  }

  const download = async (url: string) => {
    if (!url) return;
    try {
      const res = await getOSSBlobResource(url);
      let date = getdate();
      const suggestedName = `工具_人工智能PPT_生成PPT_${date}.pptx`
      await saveBlobToLocal(res, suggestedName);
      message.success({ title: t('DownloadSuccess') });
    } catch (err: any) {
      if (err.message !== 'The user aborted a request.') {
        message.error({ title: t('DownloadFailed') });
      }
    }
  }


  useEffect(() => {
    if (leavaAIppt && isPPTModify) {
      const reset = async (should_save = false) => {
        if (should_save) {
          await updateOutline({
            sid: outlineId,
            outline: outline
          })
        }
        closeAlert();
        setIsPPTModify(false);
        setLeavaAIppt(null)
        localStorage.setItem('GeneratePPT', JSON.stringify({ type: 'list', spend: 0 }));
        if (leavaAIppt.type === 'route') {
          navigate(leavaAIppt.next)
        } else {
          SetSelectTool(leavaAIppt.next)
        }
      }
      openAlert({
        status: 'warning',
        title: t('tip'),
        content: t('您有未保存的内容，是否保存修改?'),
        footer: <HorizontalBox justifyContent='flex-end' mt='20px'>
          <CButton theme='outline' size='md' mr='10px' onClick={_ => { reset(false) }}>{t('放弃')}</CButton>
          <CButton theme='primary' size='md' w='80px' onClick={() => { reset(true) }}>保存</CButton>
        </HorizontalBox>
      })
    }
  }, [leavaAIppt])

  const filteredItems: any = pptlist.filter((item: any) => {
    const colorMatch = selectColor === '全部' || item.color === selectColor;
    const industryMatch = selectIndustry === '全部' || item.industry === selectIndustry;
    const styleMatch = selectStyle === '全部' || item.style === selectStyle;
    return colorMatch && industryMatch && styleMatch;
  });

  useEffect(() => {
    const intervalid = setInterval(async () => {
      const hisdata = await getAIppts();
      setHistoryCache(hisdata);
      const item = hisdata.find((entry: any) => entry._id === intervalId);
      if (isShowPPT && item && item.pptimages && item.pptimages.length > 0) {
        setShowPPT(item.pptimages);
        setDownloadppt(item.pptUrl);
        clearInterval(intervalid);
      }
    }, 3000);
    if (!intervalId) { clearInterval(intervalid); }
    return () => clearInterval(intervalid);
  }, [intervalId])

  const isDisabled =
    (pagenum === 1 || pagenum === 2 || pagenum === 3 || pagenum === 4)
    && !title && !contents && !videoFile;
  const invalidPattern = /^[\s!@#$%^&*()_+\-=$$ undefined $${};':"\\|,.<>\/?]*$/;

  return (
    <Flex key={'langtranslations'} className={styles.toollistBox}>
      <Flex className={styles.listBox} h={pagenum === 6 ? '100%' : ' calc(100% - 120px)'}
        pb={pagenum === 6 ? '0px' : '120px'}>
        <TranslationTitle
          icon={tool?.icon}
          title={language === 'zh' ? tool ? tool.name : '' : (tool && tool.name_more_language) ? tool.name_more_language.en : ''}
        />
        <Flex h={'100%'} overflowY={'auto'}>
          <ScrollBarBox display={'flex'} flexDir={'column'}>
            {pagenum === 0 && //初始选择模式
              <Flex className={styles.createBox}>
                <Text className={styles.createText} mb={'50px'}>{t('选择PPT创建模式')}</Text>
                {creates.map((item: any) => (
                  <Flex key={item.num} className={styles.modelBox} onClick={() => SetPageNum(item.num)}>
                    {item.tag &&
                      <Flex className={styles.tagBox}>
                        {language === 'zh' ? item.tag : item.entag}
                      </Flex>
                    }
                    <Icon as={item.svg} />
                    <Box>
                      <Text className={styles.titletext}>{language === 'zh' ? item.title : item.entitle}</Text>
                      <Text className={styles.contentstext}>{language === 'zh' ? item.contonts : item.encontonts}</Text>
                    </Box>
                  </Flex>
                ))}
              </Flex>
            }
            {pagenum === 1 && //按标题生成
              <Flex className={styles.createBox}>
                <Flex justify={'center'} alignItems={'center'} cursor={'pointer'} onClick={() => SetPageNum(0)}>
                  <Icon w={'24pxp'} h={'24px'} mr={'5px'} as={require('@/assets/svg/pptback.svg').ReactComponent} />
                  <Text className={styles.createText}>{t('标题生成PPT')}</Text>
                </Flex>
                <ComTextarea
                  title={t('输入标题生成*')}
                  prompt={t('例如：年度工作回顾与未来规划')}
                  textvalue={title}
                  textchange={(e: any) => { SetTitle(e) }}
                  maxCount={50}
                  rows={1}
                  required={true}
                />
                <PPTlistBox
                  selectLanguage={langang} //选中的语言
                  title={t('选择生成语言')}  //标题
                  langs={languages}  //下拉列表
                  setlang_key={(value: any) => SetLangkey(value)}
                  SetSelectLanguage={(value: any) => SetLangang(value)} //切换的方法
                ></PPTlistBox>
                <ToolSwitch isOn={ison} setIsOn={setIsOn} title={t('是否使用联网搜索')} price={networkingPirce.toString()} tips={t('联网搜素')}
                  systemPromptTip={t('联网搜索开启后，利用搜索引擎帮助用户丰富和完善内容，丰富大纲或PPT的信息量。')} />
              </Flex>
            }
            {pagenum === 2 && //按长文本生成
              <Flex className={styles.createBox}>
                <Flex justify={'center'} alignItems={'center'} cursor={'pointer'} onClick={() => SetPageNum(0)}>
                  <Icon w={'24pxp'} h={'24px'} mr={'5px'} as={require('@/assets/svg/pptback.svg').ReactComponent} />
                  <Text className={styles.createText}>{t('文本生成PPT')}</Text>
                </Flex>
                <ComTextarea
                  title={t('输入长文本生成*')}
                  prompt={t('输入长文本生成tips')}
                  textvalue={contents}
                  textchange={(e: any) => { SetContents(e) }}
                  maxCount={8000}
                  rows={10}
                  required={true}
                />
                <PPTlistBox
                  selectLanguage={langang} //选中的语言
                  title={t('选择生成语言')}  //标题
                  langs={languages}  //下拉列表
                  setlang_key={(value: any) => SetLangkey(value)}
                  SetSelectLanguage={(value: any) => SetLangang(value)} //切换的方法
                ></PPTlistBox>
                <ToolSwitch isOn={ison} setIsOn={setIsOn} title={t('是否使用联网搜索')} price={networkingPirce.toString()} tips={t('联网搜素')}
                  systemPromptTip={t('联网搜索开启后，利用搜索引擎帮助用户丰富和完善内容，丰富大纲或PPT的信息量。')} />
              </Flex>
            }
            {pagenum === 3 && //按文件生成
              <Flex className={styles.createBox}>
                <Flex justify={'center'} alignItems={'center'} cursor={'pointer'} onClick={() => SetPageNum(0)}>
                  <Icon w={'24pxp'} h={'24px'} mr={'5px'} as={require('@/assets/svg/pptback.svg').ReactComponent} />
                  <Text className={styles.createText}>{t('上传文档生成PPT')}</Text>
                </Flex>
                <Text mt={'20px'} w={'556px'} fontSize={'18px'} fontWeight={'bold'} color={'#333'}>{t('上传文档生成')}<span>*</span></Text>
                {(videoFile && videoFile.length) ? (
                  videoFile.map((file, index) => {
                    return <Box className={styles.uploadBoxx} key={index}>
                      {isSpeaking ?
                        // <Icon className={styles.loadicon} as={require('@/assets/svg/creation_loading.svg').ReactComponent} />
                        <Box className={styles.loader}></Box>
                        :
                        <Icon as={getFileIcon(file.name)} className={styles.previewVideo} />
                      }
                      <Box>
                        <Text className={styles.textWithEllipsis}>{truncateText(file.name)}</Text>
                        {!isSpeaking &&
                          <Flex className={styles.SucessBox}>
                            <Icon as={require('@/assets/svg/hdrSucess.svg').ReactComponent} />
                            <Text className={styles.uploadSucess}>{t('UploadSuccess')}</Text>
                          </Flex>
                        }
                      </Box>
                      {!isSpeaking && <Icon className={styles.uploadBoxicon} onClick={() => deleteFile(index)} as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} />}
                    </Box>
                  })
                ) : (uploadError ?
                  <Box className={styles.uploadBoxx}>
                    <Flex className={styles.defaultbox}>
                      <Icon as={require('@/assets/svg/errorVideo.svg').ReactComponent} className={styles.uploadIcon} />
                      <Text className={styles.errplusIcon} >{t('UploadFailed')}</Text>
                    </Flex>
                    <Flex className={styles.SucessBox}>
                      <Icon className={styles.errsvg} as={require('@/assets/svg/toolFileRegenerate.svg').ReactComponent} />
                      <Text className={styles.againText} onClick={() => { onOpen(); setUploadError('') }}>{t('ReUpload')}</Text>
                    </Flex>
                    <Icon className={styles.uploadBoxicon} onClick={() => setUploadError('')} as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} />
                  </Box>
                  :
                  <Box w={'556px'} mt={'20px'}>
                    <UploadFileComponent
                      onOpen={onOpen}
                      title={''}
                      list={[t('上传后，AI会自动提取关键信息，生成贴合文档内容的大纲及PPT'), t('支持PDF、DOC、DOCX、TXT、MD格式的文档'), `${t('DocumentsSize')}10M`]}
                      filetype={t('上传文档')}
                      img={require('@/assets/svg/uploadVideo.svg').ReactComponent}
                      color={'#999'}
                    />
                  </Box>
                )}
                <PPTlistBox
                  selectLanguage={langang} //选中的语言
                  title={t('选择生成语言')}  //标题
                  langs={languages}  //下拉列表
                  setlang_key={(value: any) => SetLangkey(value)}
                  SetSelectLanguage={(value: any) => SetLangang(value)} //切换的方法
                ></PPTlistBox>
                <ToolSwitch isOn={ison} setIsOn={setIsOn} title={t('是否使用联网搜索')} price={networkingPirce.toString()} tips={t('联网搜素')}
                  systemPromptTip={t('联网搜索开启后，利用搜索引擎帮助用户丰富和完善内容，丰富大纲或PPT的信息量。')} />
              </Flex>
            }
            {pagenum === 4 && //自定义生成
              <Flex className={styles.createBox}>
                <Flex justify={'center'} alignItems={'center'} cursor={'pointer'} onClick={() => SetPageNum(0)}>
                  <Icon w={'24pxp'} h={'24px'} mr={'5px'} as={require('@/assets/svg/pptback.svg').ReactComponent} />
                  <Text className={styles.createText}>{t('自定义生成PPT')}</Text>
                </Flex>
                <ComTextarea
                  title={t('输入标题生成*')}
                  prompt={t('例如：年度工作回顾与未来规划')}
                  textvalue={title}
                  textchange={(e: any) => { SetTitle(e) }}
                  maxCount={50}
                  rows={1}
                  required={true}
                />
                <ComTextarea
                  title={t('文本补充')}
                  prompt={t('输入长文本生成tips')}
                  textvalue={contents}
                  textchange={(e: any) => { SetContents(e) }}
                  maxCount={8000}
                  rows={10}
                />
                <Text mt={'10px'} w={'556px'} fontSize={'18px'} fontWeight={'bold'} color={'#333'}>{t('文档补充')}</Text>
                {(videoFile && videoFile.length) ? (
                  videoFile.map((file, index) => {
                    return <Box className={styles.uploadBoxx} key={index}>
                      {isSpeaking ?
                        // <Icon className={styles.loadicon} as={require('@/assets/svg/creation_loading.svg').ReactComponent} />
                        <Box className={styles.loader}></Box>
                        :
                        <Icon as={getFileIcon(file.name)} className={styles.previewVideo} />
                      }
                      <Box>
                        <Text className={styles.textWithEllipsis}>{truncateText(file.name)}</Text>
                        {!isSpeaking &&
                          <Flex className={styles.SucessBox}>
                            <Icon as={require('@/assets/svg/hdrSucess.svg').ReactComponent} />
                            <Text className={styles.uploadSucess}>{t('UploadSuccess')}</Text>
                          </Flex>
                        }
                      </Box>
                      {!isSpeaking && <Icon className={styles.uploadBoxicon} onClick={() => deleteFile(index)} as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} />}
                    </Box>
                  })
                ) : (uploadError ?
                  <Box className={styles.uploadBoxx}>
                    <Flex className={styles.defaultbox}>
                      <Icon as={require('@/assets/svg/errorVideo.svg').ReactComponent} className={styles.uploadIcon} />
                      <Text className={styles.errplusIcon} >{t('UploadFailed')}</Text>
                    </Flex>
                    <Flex className={styles.SucessBox}>
                      <Icon className={styles.errsvg} as={require('@/assets/svg/toolFileRegenerate.svg').ReactComponent} />
                      <Text className={styles.againText} onClick={() => { onOpen(); setUploadError('') }}>{t('ReUpload')}</Text>
                    </Flex>
                    <Icon className={styles.uploadBoxicon} onClick={() => setUploadError('')} as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} />
                  </Box>
                  :
                  <Box w={'556px'} mt={'20px'}>
                    <UploadFileComponent
                      onOpen={onOpen}
                      title={''}
                      list={[t('上传后，AI会自动提取关键信息，生成贴合文档内容的大纲及PPT'), t('支持PDF、DOC、DOCX、TXT、MD格式的文档'), `${t('DocumentsSize')}10M`]}
                      filetype={t('UploadFiles')}
                      img={require('@/assets/svg/uploadVideo.svg').ReactComponent}
                      color={'#999'}
                    />
                  </Box>
                )}
                <PPTlistBox
                  selectLanguage={langang} //选中的语言
                  title={t('选择生成语言')}  //标题
                  langs={languages}  //下拉列表
                  setlang_key={(value: any) => SetLangkey(value)}
                  SetSelectLanguage={(value: any) => SetLangang(value)} //切换的方法
                ></PPTlistBox>
                <ToolSwitch isOn={ison} setIsOn={setIsOn} title={t('是否使用联网搜索')} price={networkingPirce.toString()} tips={t('联网搜素')}
                  systemPromptTip={t('联网搜索开启后，利用搜索引擎帮助用户丰富和完善内容，丰富大纲或PPT的信息量。')} />
              </Flex>
            }
            {pagenum === 5 && //生成大纲
              <Flex className={styles.outlineBox}>
                <Flex justify={'center'} alignItems={'center'} cursor={'pointer'} my={'30px'} onClick={() => {
                  if (isPPTModify) {
                    openAlert({
                      status: 'warning',
                      title: t('tip'),
                      content: t('您有未保存的内容，是否保存修改?'),
                      okBtnText: t('Save'),
                      cancelBtnText: t('放弃'),
                      onOk: async () => {
                        setIsPPTModify(false);
                        await updateOutline({
                          sid: outlineId,
                          outline: outline
                        })
                        closeAlert();
                        SetPageNum(0)
                      },
                      onCancel: async () => {
                        setIsPPTModify(false);
                        gethis()
                        closeAlert();
                        SetPageNum(0)
                      }
                    })
                  } else {
                    SetPageNum(0)
                  }
                }}>
                  <Icon w={'24pxp'} h={'24px'} mr={'5px'} as={require('@/assets/svg/pptback.svg').ReactComponent} />
                  <Text className={styles.createText}>{t('PPT大纲')}</Text>
                </Flex>
                <Flex className={styles.textbox}>
                  {t('主标题')}
                  <Text contentEditable suppressContentEditableWarning={true}
                    onBlur={(e) => handleEdit('title', e.target.innerText, 0, 0)}>{outline.title}</Text>
                </Flex>
                <Flex className={styles.textbox}>
                  {t('副标题')}
                  <Text contentEditable suppressContentEditableWarning={true}
                    onBlur={(e) => handleEdit('subTitle', e.target.innerText, 0, 0)}>{outline.subTitle}</Text>
                </Flex>
                <Box>
                  {outline.chapters.map((chapter: any, chapterIndex: any) => (
                    <Box key={chapterIndex} w={'556px'} mb={'20px'}>
                      <Flex w={'100%'} justify={'space-between'} alignItems={'center'}>
                        <Flex className={styles.chapterBox}>
                          <Text className={styles.chaptertitle}>{t('章节')} {chapterIndex + 1}：</Text>
                          <Text
                            className={styles.chaptertitle}
                            contentEditable
                            suppressContentEditableWarning={true}
                            onBlur={(e) => {
                              const text_clean = e.target.innerText.replace(/[ \n]/g, "");
                              if (text_clean.length <= 0) {
                                openAlert({
                                  title: t('tip'),
                                  content: t('章节名称不能为空，请重新输入章节名称'),
                                  footer: <HorizontalBox justifyContent='flex-end' mt='20px'>
                                    <CButton theme='primary' size='md' w='80px' onClick={() => {
                                      closeAlert();
                                      const target = e.target as HTMLElement;
                                      target.innerText = chapter.chapterTitle;
                                      setIsPPTModify(false);
                                      return
                                    }}>{t('Sure')}</CButton>
                                  </HorizontalBox>
                                })
                                return
                              } else {
                                handleEdit('chapterTitle', e.target.innerText, chapterIndex, 0)
                              }
                            }}
                          >
                            {chapter.chapterTitle}
                          </Text>
                        </Flex>
                        <Icon as={require('@/assets/svg/pptMore.svg').ReactComponent} className={styles.more}
                          onClick={(e: any) => {
                            e.stopPropagation(); onOutline(e, chapterIndex, chapter)
                          }} />
                      </Flex>
                      <ul>
                        {chapter.chapterContents.map((content: any, contentIndex: any) => (
                          <Flex key={contentIndex} className={styles.libox} >
                            <li>
                              <p
                                contentEditable
                                suppressContentEditableWarning={true}
                                onBlur={(e) => {
                                  const text_clean = e.target.innerText.replace(/[ \n]/g, "");
                                  if (text_clean.length <= 0) {
                                    openAlert({
                                      title: t('tip'),
                                      content: t('小节名称不能为空，请重新输入小节名称'),
                                      footer: <HorizontalBox justifyContent='flex-end' mt='20px'>
                                        <CButton theme='primary' size='md' w='80px' onClick={() => {
                                          closeAlert();
                                          const target = e.target as HTMLElement;
                                          target.innerText = chapter.chapterContents[contentIndex].chapterTitle;
                                          setIsPPTModify(false);
                                          return
                                        }}>{t('Sure')}</CButton>
                                      </HorizontalBox>
                                    })
                                    return
                                  } else {
                                    handleEdit('chapterContent', e.target.innerText, chapterIndex, contentIndex)
                                  }
                                }}
                              >
                                {content.chapterTitle}
                              </p>
                            </li>
                            <Icon as={require('@/assets/svg/pptMore.svg').ReactComponent} className={styles.contentMore}
                              onClick={(e: any) => {
                                e.stopPropagation(); onOutline(e, chapterIndex, content, contentIndex)
                              }} />
                          </Flex>
                        ))}
                      </ul>
                    </Box>
                  ))}
                </Box>
              </Flex>
            }
            {pagenum === 6 && //选择模板
              <Flex className={styles.outlineBox}>
                <Flex justify={'center'} alignItems={'center'} cursor={'pointer'} my={'30px'} onClick={() => SetPageNum(5)}>
                  <Icon w={'24pxp'} h={'24px'} mr={'5px'} as={require('@/assets/svg/pptback.svg').ReactComponent} />
                  <Text className={styles.createText}>{t('模板选择')}</Text>
                </Flex>
                <ComTags
                  title={t('行业选择')}
                  array={industry}
                  style={selectIndustry}
                  SetStyle={(value: any) => SetSelectIndustry(value)}
                  isselectvalue={true}
                />
                <ComTags
                  title={t('风格选择')}
                  array={style}
                  style={selectStyle}
                  SetStyle={(value: any) => SetSelectStyle(value)}
                  isselectvalue={true}
                />
                <ComTags
                  title={t('颜色选择')}
                  array={color}
                  style={selectColor}
                  SetStyle={(value: any) => SetSelectColor(value)}
                  isselectvalue={true}
                />
                <Flex w={'556px'} h={'340px'} wrap="wrap" borderTop={'1px solid #eee'} pt={'30px'}>
                  {(filteredItems && filteredItems.length > 0) ? (
                    filteredItems.map((item: any, index: any) => {
                      const detailImageObj = JSON.parse(item.detailImage);
                      const firstImageUrl = String(Object.values(detailImageObj).find(url => url !== ''));
                      const imageUrls = Object.values(detailImageObj)
                        .filter(url => url !== '' && url !== null && url !== undefined);
                      const uniqueImageUrls = imageUrls.slice(1);

                      return <Flex key={index} cursor={'pointer'} mx={'10px'} mb={'25px'} width="calc(33.33% - 20px)" onClick={() => {
                        SetPageNum(7);
                        SetSelectpptImg(uniqueImageUrls)
                        SetSelectppt(item.templateIndexId)
                      }}>
                        <Image src={firstImageUrl} w={'160px'} h={'90px'} borderRadius={'12px'} />
                      </Flex>
                    })
                  ) : (
                    <Flex w={'100%'} h={'100%'} justify={'center'} alignItems={'center'} flexDir={'column'}>
                      <Icon w={'120px'} h={'120px'} as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
                      <Text m="20px" fontSize={'16px'} color={'#999'}>{t('暂无对应模板')}</Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            }
            {pagenum === 7 && //信息确认
              <Flex className={styles.outlineBox}>
                <Flex justify={'center'} alignItems={'center'} cursor={'pointer'} my={'30px'} onClick={() => SetPageNum(6)}>
                  <Icon w={'24pxp'} h={'24px'} mr={'5px'} as={require('@/assets/svg/pptback.svg').ReactComponent} />
                  <Text className={styles.createText}>{t('信息确认')}</Text>
                </Flex>
                <PPTlistBox
                  selectLanguage={language === 'zh' ? aimodel.text : aimodel.entext}
                  title={t('配图模型')}
                  langs={models}
                  setall={(value: any) => SetaiModel(value)}
                ></PPTlistBox>
                <ToolSwitch isOn={ison} setIsOn={setIsOn} title={t('是否使用联网搜索')} price={networkingPirce.toString()} tips={t('联网搜素')}
                  systemPromptTip={t('联网搜索开启后，利用搜索引擎帮助用户丰富和完善内容，丰富大纲或PPT的信息量。')} />
                <ToolSwitch isOn={isspeech} setIsOn={setIsSpeech} title={t('是否生成演讲备注')} price={speechPirce.toString()} tips={t('演讲备注')}
                  systemPromptTip={t('是否生成演讲备注tips')} />
                <Box w={'556px'}>
                  <Text color={'#333'} fontWeight={'bold'} fontSize={'18px'} my={'30px'}>{t('模板样式')}</Text>
                  {selectpptImg.map((item, index) => {
                    return (
                      <Image key={index} mb={'10px'} w={'400px'} h={'225px'} borderRadius={'14px'} src={item} />
                    )
                  })}
                </Box>
              </Flex>
            }
          </ScrollBarBox>
        </Flex>
        {(pagenum !== 6 && pagenum !== 0) &&
          <Flex className={styles.footbutton}>
            {pagenum === 7 ?
              <Box className={styles.create}>
                {userInfo?.monVip ?
                  <Flex className={styles.notvipbut}>
                    <CButton className={styles.vipbtn} theme='primary' isLoading={loading} disabled={isDisabled}
                      onClick={() => { createPPT() }}>
                      <Box className={styles.vipprice}>
                        <Icon as={loading ? require('@/assets/svg/navisVipNull.svg').ReactComponent : require('@/assets/svg/navisVip.svg').ReactComponent} />
                        <Text>{t('免费生成')}</Text>
                      </Box>
                    </CButton>
                    <Box className={styles.vprice}>
                      <Text>{t('Consume')}：</Text>
                      <span>{pptpirce}</span>
                      <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                    </Box>
                  </Flex>
                  :
                  <Flex className={styles.notvipbut}>
                    <CButton className={styles.btn} theme='primary' isLoading={loading} disabled={isDisabled}
                      onClick={() => { createPPT() }}>
                      <Text className={styles.start}>{t('开始生成')}</Text>
                    </CButton>
                    <Box className={styles.price}>
                      <Text>{t('Consume')}：</Text>
                      <span>{pptpirce}</span>
                      <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                    </Box>
                  </Flex>
                }
              </Box>
              :
              <Box className={styles.create}>
                {userInfo?.monVip ?
                  <Flex className={styles.notvipbut}>
                    <CButton className={styles.vipbtn} theme='primary' isLoading={loading} disabled={isDisabled}
                      onClick={async () => {
                        if (pagenum === 5) {
                          if (invalidPattern.test(outline.title) || invalidPattern.test(outline.subTitle)) {
                            message.warning({ title: t('主标题和副标题不能为空！') })
                            return
                          }
                          if (invalidPattern.test(outline.chapters[0].chapterTitle)) {
                            message.warning({ title: t('章节名称不能为空！') })
                            return
                          }
                          const res = await updateOutline({
                            sid: outlineId,
                            outline: outline
                          })
                          if (res) {
                            setIsPPTModify(false)
                            message.success({ title: t('SaveSuccess') })
                            gethis();
                            SetPageNum(6);
                          } else {
                            message.success({ title: t('保存失败') })
                          }
                        } else {
                          if (!title && !contents && !videoFile) {
                            message.warning({ title: t('请填写完整内容！') })
                            return;
                          }
                          if (!title && pagenum === 4) {
                            message.warning({ title: t('请填写完整内容！') })
                            return;
                          }
                          createOutline()
                        }
                      }}>
                      <Box className={styles.vipprice}>
                        <Icon as={loading ? require('@/assets/svg/navisVipNull.svg').ReactComponent : require('@/assets/svg/navisVip.svg').ReactComponent} />
                        <Text>{pagenum === 5 ? t('选择模板') : t('生成大纲')}</Text>
                      </Box>
                    </CButton>
                    {[1, 2, 3, 4].includes(pagenum) ?
                      <Box className={styles.vprice}>
                        <Text>{t('Consume')}：</Text>
                        <span>{outlinepirce}</span>
                        <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                      </Box>
                      :
                      <Box h={'20px'}></Box>
                    }
                  </Flex>
                  :
                  <Flex className={styles.notvipbut}>
                    <CButton className={styles.btn} theme='primary' isLoading={loading} disabled={isDisabled}
                      onClick={async () => {
                        if (pagenum === 5) {
                          if (invalidPattern.test(outline.title) || invalidPattern.test(outline.subTitle)) {
                            message.warning({ title: t('主标题和副标题不能为空！') })
                            return
                          }
                          const res = await updateOutline({
                            sid: outlineId,
                            outline: outline
                          })
                          if (res) {
                            message.success({ title: t('SaveSuccess') })
                            gethis();
                            SetPageNum(6);
                          } else {
                            message.success({ title: t('保存失败') })
                          }
                        } else {
                          if (!title && !contents && !videoFile) {
                            message.warning({ title: t('请填写完整内容！') })
                            return;
                          }
                          if (!title && pagenum === 4) {
                            message.warning({ title: t('请填写完整内容！') })
                            return;
                          }
                          createOutline()
                        }
                      }}>
                      <Text className={styles.start}>{pagenum === 5 ? t('选择模板') : t('生成大纲')}</Text>
                      <Icon w={'22px'} h={'22px'} as={require('@/assets/svg/pptnext.svg').ReactComponent} />
                    </CButton>
                    {[1, 2, 3, 4].includes(pagenum) ?
                      <Box className={styles.price}>
                        <Text>{t('Consume')}：</Text>
                        <span>{outlinepirce}</span>
                        <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                      </Box>
                      :
                      <Box h={'20px'}></Box>
                    }
                  </Flex>
                }
              </Box>
            }
          </Flex>
        }
      </Flex>
      <Flex className={styles.previewBox}>
        <ComAIpptHistory
          key={tool?.name}
          historyCache={historyCache}
          onclick={async (item: any) => {
            if (item.status === 2) {
              if (isPPTModify) {
                //选择取消时，因为缓存的原因还是会出现更新的数据
                openAlert({
                  status: 'warning',
                  title: t('tip'),
                  content: t('您有未保存的内容，是否保存修改?'),
                  okBtnText: t('Save'),
                  cancelBtnText: t('放弃'),
                  onOk: async () => {
                    setIsPPTModify(false);
                    await updateOutline({
                      sid: outlineId,
                      outline: outline
                    })
                    closeAlert();
                    SetOutline(item.outline)
                    SetOutlineId(item.sid)
                  },
                  onCancel: async () => {
                    setIsPPTModify(false);
                    gethis()
                    closeAlert();
                    SetOutline(item.outline)
                    SetOutlineId(item.sid)
                  }
                })
              } else {
                SetPageNum(5)
                SetOutline(item.outline)
                setIsPPTModify(false)
                SetOutlineId(item.sid)
              }
            } else if (item.status === 4) {
              await readAlready({ _id: item._id });
              setIntervalId(item._id)
              setShowPPT(item.pptimages);
              setDownloadppt(item.pptUrl)
              setIsShowPPT(true)
            }
          }}
          onMore={(e: any, index: any, item: any) => { e.stopPropagation(); onMore(e, index, item) }}
          actionLoading={actionLoading}
        />
      </Flex>
      <CModal isOpen={isShowPPT} onClose={() => setIsShowPPT(false)}>
        <Box className={styles.editTitle} ref={modalRef}>
          <Box className={styles.title}>
            <Text>{t('PPT预览')}</Text>
            <Icon className={styles.icon} as={require('@/assets/svg/close_x.svg').ReactComponent} onClick={() => setIsShowPPT(false)} />
          </Box>
          <Flex className={styles.scrollContainer}>
            {showPPT.length > 0 ?
              showPPT.map((it: any, idx: any) => (
                <Image key={idx} src={it} />
              ))
              :
              <Flex my={'250px'} flexDir={'column'} justify={'center'} alignItems={'center'}>
                <RotatingCircles />
                <Text color={'#999'} fontSize={'18px'}>{t('PPT生成完毕，在线预览图正在生成中…')}</Text>
                <Text color={'#999'} fontSize={'18px'}>{t('您可尝试点击下载，在本地进行查看')}</Text>
              </Flex>
            }
          </Flex>
          <Box className={styles.btns} >
            <Flex onClick={() => { setIsShowPPT(false); download(downloadppt) }}>
              <Icon as={require('@/assets/svg/pptDownload.svg').ReactComponent} />
              {t('下载')}
            </Flex>
          </Box>
        </Box>
      </CModal>
      <File onSelect={selectFiles} />
      {
        morePosition && <Box ref={editRef} className={styles.moreEdit} style={{ left: morePosition.x, top: morePosition.y }}>
          {morePosition.isFirstEdit &&
            <Box
              className={styles.moreItem}
              onClick={() => {
                download(moreEdit.current?.pptUrl || '');
                setMorePosition(undefined)
              }}
            >
              <Icon as={require('@/assets/svg/creation_download.svg').ReactComponent} />
              <Text>{t('下载')}PPT</Text>
            </Box>
          }
          <Box className={styles.deletemoreItem}
            onClick={async () => {
              setMorePosition(undefined);
              await deletePPT({ _id: moreEdit.current?._id || '' });
              gethis();
              message.success({ title: t('deleteSuccess') });
            }}>
            <Icon as={require('@/assets/svg/song_delete.svg').ReactComponent} />
            <Text>{t('delete')}</Text>
          </Box>
        </Box>
      }
      {
        outlinePosition && <Box ref={outlineRef} className={styles.moreEdit} style={{ left: outlinePosition.x, top: outlinePosition.y }}>
          {(outlinePosition.isFirstEdit && outlinePosition.index !== 0) &&
            <Box
              className={styles.outlineItem}
              onClick={() => {
                const selectedChapter = outline.chapters[outlinePosition.index];
                const targetChapter = outline.chapters[outlinePosition.index - 1];
                targetChapter.chapterContents.push(
                  { chapterTitle: selectedChapter.chapterTitle }, // 先加上章节标题
                  ...selectedChapter.chapterContents // 然后加上原有小节
                );
                outline.chapters.splice(outlinePosition.index, 1);
                setIsPPTModify(true);
                setOutlinePosition(undefined)
              }}
            >
              <Text>{t('降级为小节')}</Text>
            </Box>
          }
          {!outlinePosition.isFirstEdit &&
            <Box
              className={styles.outlineItem}
              onClick={() => {
                if (outlinePosition.contentIndex === undefined) { return }
                const chapters = outline.chapters;
                const currentChapter = chapters[outlinePosition.index];
                const subsectionToPromote = currentChapter.chapterContents.slice(outlinePosition.contentIndex);
                const newChapter = {
                  chapterTitle: subsectionToPromote[0].chapterTitle,
                  chapterContents: subsectionToPromote.slice(1),
                };
                chapters.splice(outlinePosition.index + 1, 0, newChapter);
                currentChapter.chapterContents.splice(outlinePosition.contentIndex);
                setIsPPTModify(true);
                setOutlinePosition(undefined)
              }}
            >
              <Text>{t('升级为章节')}</Text>
            </Box>
          }
          {outlinePosition.isFirstEdit &&
            <Box
              className={styles.outlineItem}
              onClick={() => {
                if (outline.chapters.length >= 20) {
                  message.warning({ title: t('添加失败，章节数上限为20个') })
                  return;
                }
                const newChapter = {
                  chapterTitle: t('章节'),
                  chapterContents: [
                    { chapterTitle: t('小节') } // 默认小节
                  ],
                };
                outline.chapters.splice(outlinePosition.index + 1, 0, newChapter);
                setIsPPTModify(true);
                setOutlinePosition(undefined)
              }}
            >
              <Text>{t('在下方新增章节')}</Text>
            </Box>
          }
          {!outlinePosition.isFirstEdit &&
            <Box
              className={styles.outlineItem}
              onClick={() => {
                if (outlinePosition.contentIndex === undefined) { return }
                const newSubsection = { chapterTitle: t('小节') };
                const chapters = outline.chapters;
                const currentChapter = chapters[outlinePosition.index];
                if (currentChapter.chapterContents.length >= 5) {
                  message.warning({ title: t('添加失败，小节数上限为5个') })
                  setOutlinePosition(undefined)
                  return;
                }
                currentChapter.chapterContents.splice(outlinePosition.contentIndex + 1, 0, newSubsection);
                setIsPPTModify(true);
                setOutlinePosition(undefined)
              }}
            >
              <Text>{t('在下方新增小节')}</Text>
            </Box>
          }
          {(outlinePosition.isFirstEdit && outlinePosition.index !== 0) &&
            <Box
              className={styles.outlineItem}
              onClick={() => {
                const chapters = outline.chapters;
                [chapters[outlinePosition.index - 1], chapters[outlinePosition.index]] =
                  [chapters[outlinePosition.index], chapters[outlinePosition.index - 1]];
                setIsPPTModify(true);
                setOutlinePosition(undefined)
              }}
            >
              <Text>{t('章节上移')}</Text>
            </Box>
          }
          {(outlinePosition.isFirstEdit && outlinePosition.index !== outline.chapters.length - 1) &&
            <Box
              className={styles.outlineItem}
              onClick={() => {
                const chapters = outline.chapters;
                [chapters[outlinePosition.index], chapters[outlinePosition.index + 1]] =
                  [chapters[outlinePosition.index + 1], chapters[outlinePosition.index]];
                setIsPPTModify(true);
                setOutlinePosition(undefined)
              }}
            >
              <Text>{t('章节下移')}</Text>
            </Box>
          }
          {(!outlinePosition.isFirstEdit &&
            (outlinePosition.index !== 0 || outlinePosition.contentIndex !== 0)) &&
            <Box
              className={styles.outlineItem}
              onClick={() => {
                if (outlinePosition.contentIndex === undefined) { return }
                const chapters = outline.chapters;
                if (outlinePosition.index > 0 && outlinePosition.contentIndex === 0) {
                  const previousChapter = chapters[outlinePosition.index - 1];
                  if (previousChapter.chapterContents.length >= 5) {
                    message.warning({ title: t('小节上移失败，小节数上限为5个') })
                    return;
                  }
                  const [movedSection] = chapters[outlinePosition.index].chapterContents.splice(0, 1);
                  previousChapter.chapterContents.push(movedSection);
                } else {
                  const currentChapter = chapters[outlinePosition.index];
                  // 交换小节的位置
                  [currentChapter.chapterContents[outlinePosition.contentIndex - 1], currentChapter.chapterContents[outlinePosition.contentIndex]] =
                    [currentChapter.chapterContents[outlinePosition.contentIndex], currentChapter.chapterContents[outlinePosition.contentIndex - 1]];
                }
                setIsPPTModify(true);
                setOutlinePosition(undefined)
              }}
            >
              <Text>{t('小节上移')}</Text>
            </Box>
          }
          {(!outlinePosition.isFirstEdit &&
            (outlinePosition.index !== outline.chapters.length - 1
              || outlinePosition.contentIndex !== outline.chapters[outlinePosition.index].chapterContents.length - 1)) &&
            <Box
              className={styles.outlineItem}
              onClick={() => {
                if (outlinePosition.contentIndex === undefined) { return }
                const chapters = outline.chapters;
                const currentChapter = chapters[outlinePosition.index];
                // 检查是否是最后一个小节
                if (outlinePosition.contentIndex === currentChapter.chapterContents.length - 1) {
                  const nextChapter = chapters[outlinePosition.index + 1];
                  if (nextChapter.chapterContents.length >= 5) {
                    message.warning({ title: t('小节下移失败，小节数上限为5个') })
                    return;
                  }
                  const [movedSection] = currentChapter.chapterContents.splice(outlinePosition.contentIndex, 1);
                  nextChapter.chapterContents.unshift(movedSection);
                } else {
                  // 交换小节的位置
                  [currentChapter.chapterContents[outlinePosition.contentIndex], currentChapter.chapterContents[outlinePosition.contentIndex + 1]] =
                    [currentChapter.chapterContents[outlinePosition.contentIndex + 1], currentChapter.chapterContents[outlinePosition.contentIndex]];
                }
                setIsPPTModify(true);
                setOutlinePosition(undefined)
              }}
            >
              <Text>{t('小节下移')}</Text>
            </Box>
          }
          <Box className={styles.deletemoreItem}
            onClick={async () => {
              if (outlinePosition.isFirstEdit) {
                const chapters = outline.chapters;
                if (outlinePosition.index === 0) {
                  message.warning({ title: t('删除失败！请至少保留1条章节') });
                  return
                }
                if (outlinePosition.index >= 0 && outlinePosition.index < chapters.length) {
                  chapters.splice(outlinePosition.index, 1);
                }
              } else {
                if (outlinePosition.contentIndex === undefined) { return }
                const chapters = outline.chapters;
                const currentChapter = chapters[outlinePosition.index];
                if (outlinePosition.contentIndex >= 0 && outlinePosition.contentIndex < currentChapter.chapterContents.length) {
                  currentChapter.chapterContents.splice(outlinePosition.contentIndex, 1);
                }
              }
              setIsPPTModify(true);
              setOutlinePosition(undefined);
              message.success({ title: t('deleteSuccess') });
            }}>
            <Icon as={require('@/assets/svg/song_delete.svg').ReactComponent} />
            <Text>{t('delete')}</Text>
          </Box>
        </Box>
      }
    </Flex >
  )
})