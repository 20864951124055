import { Flex, Box, Text, Icon } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import styles from "./index.module.scss";
import { ScrollBarBox } from "@/components/ScrollBox";
import { useTranslation } from "react-i18next";


export const ComAudiosHistory: FC<any> = observer(({
  historyCache,
  onclick,
  onMore
}: {
  historyCache: any[];
  onclick: (item: any) => void;
  isVIP?: boolean
  onMore: any;
}) => {
  const { t } = useTranslation();

  return (
    <Flex className={styles.hisFlex}>
      <Box className={styles.centerItemsbox}>
        <Text className={styles.title}>{t('history')}</Text>
      </Box>
      <ScrollBarBox className={styles.hisBox}>
        {historyCache.length > 0 ? historyCache.map((item, index) => {
          const statusDesc = (() => {
            switch (item.status.toString()) {
              case '0':
                return t('TobeStarted');
              case '8':
                return t('IntheQueue');
              case '1':
                return '正在生成中，请稍等....';
              case '2':
                return '有新声音已生成';
              case '3':
                return t('BuildFailure');
              default:
                return t('BuildFailure');
            }
          })();
          const statusColor = (() => {
            switch (item.status.toString()) {
              case '0':
              case '8':
              case '1':
                return '#FF951A';
              case '2':
                return '#689F38';
              case '3':
                return '#F76560';
              default:
                return '#FF951A';
            }
          })();
          return (
            <Flex key={item._id} className={styles.historyValue} cursor={item.status === 2 ? 'pointer' : ''}
              onClick={async () => { if (item.status !== 3) { onclick(item) } }}>
              {(item.status === 0 || item.status === 1) && <Flex className={styles.prohibitBox} onClick={(e) => e.stopPropagation()}></Flex>}
              <Flex className={styles.historyValuebox}>
                <Box className={styles.hisTitleBox}>
                  {(!item.isNotReading && item.status === 2) ?
                    <Box className={styles.hisTitleBox}>
                      <Text>
                        {item.audioName}的声音
                      </Text>
                    </Box>
                    :
                    <Flex color={statusColor}>
                      {statusDesc}
                    </Flex>
                  }
                  <Icon as={require('@/assets/svg/speechMore.svg').ReactComponent} className={styles.more}
                    onClick={(e: any) => { e.stopPropagation(); onMore && onMore(e, index, item) }} />
                </Box>
                {(item.isNotReading || item.status !== 2) &&
                  <Box className={styles.hisTitleBox}>
                    <Text>
                      {item.audioName}的声音
                    </Text>
                  </Box>
                }
                <Text className={styles.hisContentsBox}>
                  {item.data.text}
                </Text>
                <Flex className={styles.hisDateBox}>
                  <Flex mr={'20px'}>
                    <Icon w={'18px'} height={'18px'} as={require(`@/assets/svg/datetime.svg`).ReactComponent} className={styles.icon} />
                    <Text>
                      {item.create_time ? (
                        item.create_time.toLocaleString()
                          .replace('Z', '')
                          .replace('T', ' ')
                          .substring(0, 16)
                      ) : (
                        ''
                      )}
                    </Text>
                  </Flex>
                  <Flex>
                    <Icon w={'15px'} height={'15px'} as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
                    {item.status === 3 ?
                      <Text>生成失败，消耗点数已为您返还</Text>
                      :
                      <Text textDecorationLine={item.isVip ? 'line-through' : ''}>{item.price}</Text>
                    }
                    {(item.status === 2 && item.isVip) && <Flex className={styles.viptext}>
                      <Icon as={require(`@/assets/svg/navisVip.svg`).ReactComponent} />
                      {t('vipFree')}
                    </Flex>
                    }
                  </Flex>
                  <Flex>
                    <Box className={styles.aimark}>
                      {t('AIGEN')}
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          )
        })
          :
          <Flex className={styles.nullBox}>
            <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
            <Text>{t('currentlyNoContent')}</Text>
          </Flex>
        }
      </ScrollBarBox>
    </Flex>
  );
})

