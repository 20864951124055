import { FC, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Virtual } from 'swiper/modules';
import { WiseImage } from "@/components/Image";
import { Box, Icon, Text } from "@chakra-ui/react";
import { throttle } from "lodash";
import { Slider } from "@mui/base/Slider";

import styles from './index.module.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import { isChromeHighVersion } from "@/utils/common";
import { uiStrore } from "@/store/ui";
import { useTranslation } from "react-i18next";

interface CreationSwiperProps {
  list: string[]
  defaultIndex?: number
  onExit?: () => void
  onIndexChange?: (index: number) => void
  isVideo?: boolean
}

interface ImgScale {
  w: number
  h: number
  scale: number
}
export const CreationSwiper: FC<CreationSwiperProps> = (props) => {
  const { list, defaultIndex, onIndexChange, onExit, isVideo } = props;

  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [imgScale, setImgScale] = useState<Map<number, ImgScale>>(new Map());
  const videoRef = useRef<HTMLVideoElement>(null);
  const [initIsLoaded, setInitIsLoaded] = useState(false);
  const canMove = useRef({ can: false, x: 0, y: 0, l: 0, t: 0 }); // 拖动图片
  const content_ref = useRef<HTMLDivElement>(null);
  const isInit = useRef(false);
  const { zoom = 1 } = uiStrore;
  const sliderRef = useRef<HTMLSpanElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (sliderRef.current) {
      const originalGetBoundingClientRect = sliderRef.current.getBoundingClientRect;
      if (!isChromeHighVersion()) {
        return;
      }
      // @ts-ignore
      const zoomNum = zoom || Number(document.body.style.zoom) || 1;
      sliderRef.current.getBoundingClientRect = function () {
        const rect = originalGetBoundingClientRect.call(this);
        const newRect = new DOMRect(
          rect.x * zoomNum,
          rect.y * zoomNum,
          rect.width * zoomNum,
          rect.height * zoomNum
        );
        return newRect;
      };
    }
    return () => {
      setInitIsLoaded(false);
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (swiperRef && !isInit.current) {
      swiperRef.once('slideChangeTransitionEnd', () => {
        setInitIsLoaded(true);
      });
      swiperRef?.slideTo(defaultIndex || 0, 200, true);
      setTimeout(() => {
        setInitIsLoaded(true);
      }, 300)
      isInit.current = true;
    }

    // eslint-disable-next-line
  }, [defaultIndex, swiperRef])

  useEffect(() => {
    if (content_ref.current) {
      const container = content_ref.current as HTMLDivElement;
      container.addEventListener('mousedown', mousedown)
      container.addEventListener('mouseup', mouseup)
      container.addEventListener('mousemove', move)
      return () => {
        container.removeEventListener('mousedown', mousedown)
        container.removeEventListener('mouseup', mouseup)
        container.removeEventListener('mousemove', move)
      }
    }
    // eslint-disable-next-line
  }, [])

  const mousedown = (e: MouseEvent) => {
    if (!(e.target as HTMLElement).classList.contains('previewImg')) return
    e.preventDefault();
    e.stopPropagation();
    canMove.current = {
      can: true,
      x: e.clientX,
      y: e.clientY,
      l: (e.target as HTMLImageElement).offsetLeft,
      t: (e.target as HTMLImageElement).offsetTop
    }
  }

  const mouseup = () => {
    canMove.current = { can: false, x: 0, y: 0, l: 0, t: 0 }
  }

  const move = throttle((e) => {
    const { x, y, l, t, can } = canMove.current
    if (!can || !(e.target as HTMLElement).classList.contains('previewImg')) return
    const { clientX, clientY } = e;
    let nl = clientX - (x - l);
    let nt = clientY - (y - t);
    e.target.style.left = nl + 'px'
    e.target.style.top = nt + 'px'

  }, 10)

  // 图片加载完成
  const imgLoaded = (e: SyntheticEvent<HTMLImageElement, Event>, index: number) => {
    setImgScale(map => new Map(map).set(index, {
      w: (e.target as HTMLImageElement).width,
      h: (e.target as HTMLImageElement).height,
      scale: 1
    }));
  }

  // 适应屏幕
  const onAutoScrren = () => {
    const activeSlide = document.querySelector('.swiper-slide-active') as HTMLImageElement;
    const activeImg = activeSlide.querySelector('.previewImg') as HTMLImageElement;
    if (!activeImg) return;
    activeImg.style.left = '50%'
    activeImg.style.top = '50%';
    setImgScale(map => new Map(map).set(swiperRef!.activeIndex, {
      ...map.get(swiperRef!.activeIndex)!,
      scale: 1
    }))
  }

  return <Box className={styles.swiper} ref={content_ref}>
    <Swiper className={styles.mySwiper}
      pagination={{
        dynamicBullets: list.length > 10,
        clickable: true,
      }}
      modules={[Virtual, Navigation, Pagination]}
      onSwiper={setSwiperRef}
      onSlideChange={(swiper: SwiperClass) => {
        onIndexChange && onIndexChange(swiper.activeIndex);
      }}
      allowTouchMove={false}
      touchStartPreventDefault={false}
      virtual={list.length > 10}
    >
      {
        list?.map((item, index) => {
          const _scale = imgScale.get(index);
          return <SwiperSlide key={index} virtualIndex={index} className={styles.swiperSlide}>
            {
              isVideo ? <video src={item} controls className={styles.previewImg} id={`video_${index}`} /> :
                <WiseImage
                  style={{ visibility: initIsLoaded ? 'visible' : 'hidden' }}
                  src={item}
                  onLoad={e => imgLoaded(e, index)}
                  className={`${styles.previewImg} previewImg`}
                  maxW={_scale ? 'none !important' : '100%'}
                  maxH={_scale ? 'none !important' : '100%'}
                  w={_scale ? `${_scale.w * _scale.scale}px` : 'auto'}
                  h={_scale ? `${_scale.h * _scale.scale}px` : 'auto'}
                />
            }
          </SwiperSlide>
        })
      }
    </Swiper>
    {
      swiperRef?.activeIndex !== 0 && <Icon as={require('@/assets/svg/big_arrow.svg').ReactComponent} className={styles.pre} onClick={() => {
        if (list.length > 10) {
          onAutoScrren(); // 虚拟滚动时，需要先适应屏幕
        }
        console.log(swiperRef?.activeIndex)
        const ele = document.getElementById(`video_${swiperRef?.activeIndex}`) as HTMLVideoElement;
        console.log(ele)
        ele?.pause();
        swiperRef?.slidePrev();
      }} />
    }
    {
      swiperRef?.activeIndex !== list.length - 1 && <Icon as={require('@/assets/svg/big_arrow.svg').ReactComponent} className={styles.next} onClick={() => {
        if (list.length > 10) {
          onAutoScrren(); // 虚拟滚动时，需要先适应屏幕
        }
        console.log(swiperRef?.activeIndex)
        const ele = document.getElementById(`video_${swiperRef?.activeIndex}`) as HTMLVideoElement;
        console.log(ele)
        ele?.pause();
        swiperRef?.slideNext();
      }} />
    }
    {
      !isVideo && <Box className={styles.ops}>
        <Box className={styles.opItem}>
          <Icon as={require('@/assets/svg/sub.svg').ReactComponent} onClick={() => {
            const activeIndex = swiperRef?.activeIndex;
            if (activeIndex === undefined) return;
            const scaleInfo = imgScale.get(activeIndex);
            if (!scaleInfo) return;
            const num = scaleInfo.scale - 0.1 || 0;
            setImgScale(map => new Map(map).set(swiperRef!.activeIndex, {
              ...map.get(swiperRef!.activeIndex)!,
              scale: num < 0.5 ? 0.5 : num
            }))
          }} />
          <Slider ref={sliderRef} className={styles.slider} min={0.5} max={2} step={0.1}
            value={imgScale.get(swiperRef?.activeIndex || 0)?.scale || 1}
            slotProps={{
              rail: { className: styles.sliderRail },
              thumb: { className: styles.sliderThumb },
              track: { className: styles.sliderTrack },
            }}
            onChange={(_, value) => {
              throttle(() => {
                setImgScale(map => new Map(map).set(swiperRef!.activeIndex, {
                  ...map.get(swiperRef!.activeIndex)!,
                  scale: value as number
                }))
              }, 100)()
            }}
          >
          </Slider>
          <Icon as={require('@/assets/svg/add.svg').ReactComponent} onClick={() => {
            const activeIndex = swiperRef?.activeIndex;
            if (activeIndex === undefined) return;
            const scaleInfo = imgScale.get(activeIndex);
            if (!scaleInfo) return;
            const num = scaleInfo.scale + 0.1 || 0;
            setImgScale(map => new Map(map).set(swiperRef!.activeIndex, {
              ...map.get(swiperRef!.activeIndex)!,
              scale: num > 2 ? 2 : num
            }))
          }} />
        </Box>
        <Box className={styles.opItem} onClick={onAutoScrren}>
          <Icon as={require('@/assets/svg/screen.svg').ReactComponent} />
          <Text>{t("AdaptScreen")}</Text>
        </Box>
        <Box className={styles.opItem} onClick={onExit}>
          <Icon as={require('@/assets/svg/exit.svg').ReactComponent} />
          <Text>{t('exited')}</Text>
        </Box>
      </Box>
    }
  </Box >
}
