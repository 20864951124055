import { Box, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, MenuOptionGroup, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss"
import { ScrollBarBox } from "@/components/ScrollBox";
import { uiStrore } from "@/store/ui";

export const ComComboBox: FC<any> = observer(({
  title,
  langs,
  selectLanguage,
  setlang_key,
  SetSelectLanguage
}: {
  title: string;
  langs: any[];
  selectLanguage: any;
  setlang_key?: (e: string) => void;
  SetSelectLanguage: (e: string) => void;
}) => {

  const { language } = uiStrore;
  useEffect(() => {
    SetSelectLanguage(language === 'zh' ? langs[0].text : langs[0].text_more_language ? langs[0].text_more_language.en : '');
  }, [])
  const parts = title.split('*');
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const handleMenuToggle = (isOpen: any) => {
    setIsOpen(isOpen);
  };
  // const [menuPosition, setMenuPosition] = useState({ top: 0, maxHeight: '330px' });
  // useEffect(() => {
  //   const handleResize = () => {
  //     if (menuButtonRef.current) {
  //       const buttonRect = menuButtonRef.current.getBoundingClientRect();
  //       const windowHeight = window.innerHeight - 52;
  //       const idealBottom = buttonRect.top + 330;
  //       let newTop = buttonRect.top;
  //       let newMaxHeight = '330px';
  //       if (idealBottom > windowHeight) {
  //         newTop = windowHeight - idealBottom;
  //       } else {
  //         newTop = 0
  //       }

  //       setMenuPosition({
  //         top: newTop,
  //         maxHeight: newMaxHeight
  //       });
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);
  //   window.addEventListener('scroll', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //     window.removeEventListener('scroll', handleResize);
  //   };
  // }, []);
  return (
    <Flex className={styles.allbox}>
      <Box className={styles.titlebox}>
        {parts.map((part: any, index: any) => (
          <Text key={index} className={styles.title}>
            {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
          </Text>
        ))}
      </Box>
      <Box >
        <Menu onOpen={() => handleMenuToggle(true)} onClose={() => handleMenuToggle(false)}>
          <MenuButton ref={menuButtonRef} className={styles.selectmenu}>
            <Flex>
              <Box>{selectLanguage}</Box>
              <Icon className={isOpen ? styles.top : styles.bottom} as={require(`@/assets/svg/personalRight.svg`).ReactComponent} />
            </Flex>
          </MenuButton>
          <MenuList zIndex={999} className={styles.menuList}>
            <MenuOptionGroup defaultValue={selectLanguage} type="radio">
              <ScrollBarBox className={styles.scrollbar}>
                {langs.map((it, index) => {
                  return (<MenuItem
                    className={styles.menuitem}
                    key={it.text + index}
                    value={it.text}
                    onClick={() => {
                      SetSelectLanguage(language === 'zh' ? it.text : it.text_more_language ? it.text_more_language.en : '');
                      if (setlang_key) {
                        setlang_key(it.lang_key)
                      }
                    }}
                  >
                    {/* <Icon name={it.icon ? it.icon : ''} /> */}
                    <Text>{language === 'zh' ? it.text : it.text_more_language ? it.text_more_language.en : ''}</Text>
                    {selectLanguage === (language === 'zh' ? it.text : it.text_more_language ? it.text_more_language.en : '') &&
                      <Icon as={require('@/assets/svg/Comselected.svg').ReactComponent} className={styles.uploadIcon} />
                    }
                  </MenuItem>)
                })}
              </ScrollBarBox>
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
})