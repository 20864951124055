import { CModal } from "@/components/Modal";
import { Box, Icon, Text, useOutsideClick } from "@chakra-ui/react";
import { FC, memo, useRef, useState } from "react";
import { CreationSwiper } from "../Swiper";

import styles from './index.module.scss'
import { ScrollBarBox } from "@/components/ScrollBox";
import { WiseImage } from "@/components/Image";
import { CButton } from "@/components/Button";
import Loading from "@/components/Loading";
import dayjs from "dayjs";
import { CreationType } from "@/store/creation";
import { useTranslation } from "react-i18next";
import qrcode from 'qrcode'
import { copyDataFunc } from "@/utils/common";
import { useMessage } from "@/hooks/useMessage";

interface PreviewProps {
  isOpen: boolean
  type: CreationType
  extendsType?: ExtendsType
  onClose: () => void
  imgList: string[]
  imgIndex?: number
  userAvatar: string
  userName: string
  drayInfo: {
    id: string
    type: string
    avatar: string
  }
  isFavorite: boolean
  prompt: string | string[]
  negativePrompt?: string
  imgToImg?: {
    url: string
    weight: number
  }
  cerfToImg?: {
    url: string
    weight: number
  }
  sizeScal?: string
  hrLevel?: string
  createTime?: Date
  onDownload?: (url: string, extendsType: any) => void
  onChangeIndex?: (index: number) => void
  onFavorite?: () => void
  onSameDraw?: () => void
  onShare?: () => Promise<void>
  showPush?: boolean
  isVideo?: boolean
  audio?: {
    name: string
    lyrics: string
    style: string
    model_name: string
  }
}
export const CreationPreview: FC<PreviewProps> = memo((props) => {
  const {
    isOpen,
    onClose,
    imgList,
    imgIndex,
    userAvatar,
    userName,
    drayInfo,
    onDownload,
    prompt,
    negativePrompt,
    sizeScal,
    hrLevel,
    imgToImg,
    isFavorite,
    createTime,
    onChangeIndex,
    onFavorite,
    onSameDraw,
    showPush = true,
    onShare,
    type,
    extendsType,
    isVideo,
    audio
  } = props;

  return <CModal isOpen={isOpen} onClose={onClose}>
    <Box className={styles.preview}>
      <CreationSwiper
        defaultIndex={imgIndex}
        list={imgList}
        onExit={onClose}
        onIndexChange={onChangeIndex}
        isVideo={isVideo}
      />
      <Box className={styles.info}>
        <TopCard
          avatar={userAvatar}
          name={userName}
          onClose={onClose}
          url={imgList[imgIndex || 0]}
          drawId={""}
          isFavorite={isFavorite}
          onDownload={async () => {
            onDownload && await onDownload(imgList[imgIndex || 0], extendsType);
          }}
          onFavorite={onFavorite}
          onSameDraw={onSameDraw}
          showPush={showPush}
          onShare={onShare}
          type={type}
          extendsType={extendsType}
          drawType={drayInfo.type}
          id={drayInfo.id}
        />
        <BottomCard
          prompt={prompt}
          negativePrompt={negativePrompt}
          sizeScal={sizeScal}
          hrLevel={hrLevel}
          time={createTime}
          imgToImg={imgToImg}
          drawAvatar={drayInfo.avatar}
          drawType={drayInfo.type}
          audio={audio}
        />
      </Box>
    </Box>
  </CModal>
})

interface TopCardProps {
  avatar: string
  name: string
  drawId: string
  onClose?: () => void
  url: string
  isFavorite: boolean
  onFavorite?: () => void
  onDownload?: () => Promise<void>
  onSameDraw?: () => void
  onShare?: () => Promise<void>
  type: CreationType
  showPush?: boolean
  extendsType?: ExtendsType
  drawType?: string
  id: string
}
const TopCard: FC<TopCardProps> = (props) => {
  const { avatar, name, onClose, onDownload, onSameDraw, showPush, onShare, type, extendsType, drawType, url, id } = props;//isFavorite, onFavorite,
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const QRCodeRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation()
  const { copyData } = copyDataFunc();
  const message = useMessage();

  let shareType = '';
  if (type === CreationType.SD || type === CreationType.SD3 || type === CreationType.MJ) {
    shareType = 'image'
  } else if (type === CreationType.GEN3) {
    shareType = 'video'
  } else if (type === CreationType.SUNO) {
    shareType = 'music'
  }
  const shareUrl = `https://www.wisetalker.cn/#/share?id=${id}&type=${shareType}&url=${url}`;

  useOutsideClick({
    ref: QRCodeRef,
    handler: e => {
      setShowQRCode(false)
    }
  })

  const download = async () => {
    setDownloadLoading(true)
    try {
      onDownload && await onDownload()
    } catch (e) {
      console.error(e)
    } finally {
      setDownloadLoading(false)
    }
  }

  const shareImg = async () => {
    setShareLoading(true)
    try {
      onShare && await onShare()
    } catch (e) {
      console.error(e)
    } finally {
      setShareLoading(false)
    }
  }

  const shareForQRCode = () => {
    const element = document.getElementById('qrcode')
    qrcode.toCanvas(element, shareUrl, {
      width: 130,
      margin: 0
    }, function (error) {
      if (error) console.error(error)
      console.log('success!')
    })
    setShowQRCode(true)
  }

  const onCopy = () => {
    copyData(shareUrl);
    message.success({ title: t('CopySuccess') });
  }

  let title = t('wise') + 'MJ·';
  let extendsTitle = t('textToImage');

  if (type === CreationType.MJ) {
    title = t('wise') + 'MJ·'
  } else if (type === CreationType.SD) {
    title = t('wise') + 'SD1.5·'
  } else if (type === CreationType.SD3) {
    title = t('wise') + 'SD3·'
  } else if (type === CreationType.GEN3) {
    title = t('wise') + 'GEN3·'
  } else if (type === CreationType.SUNO) {
    title = t('wise') + 'SUNO·'
  }

  if (type === CreationType.MJ || type === CreationType.SD || type === CreationType.SD3) {
    if (extendsType === 'imgToImg') {
      extendsTitle = t('ImageToImage')
    } else if (extendsType === 'magnify') {
      extendsTitle = t('HDZoom')
    } else if (extendsType === 'partReDraw') {
      extendsTitle = t('PartialRepaint')
    } else if (extendsType === 'trans') {
      extendsTitle = t('Transform')
    } else if (extendsType === 'textToVideo') {
      extendsTitle = t('textToVideo')
    } else if (extendsType === 'imgToVideo') {
      extendsTitle = t('imageToVideo')
    }
  } else if (type === CreationType.GEN3) {
    extendsTitle = t('imageToVideo')
  } else if (type === CreationType.SUNO) {
    if (drawType === 'custom_model') {
      extendsTitle = t('customMode')
    } else {
      extendsTitle = t('InspirationMode')
    }
  }

  return <Box className={styles.topCard}>
    <Box className={styles.user}>
      <Box className={styles.userInfo}>
        <WiseImage className={styles.avatar} src={avatar} />
        <Text className={styles.name}>{name}</Text>
      </Box>
      <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} onClick={onClose} />
    </Box>
    <Text className={styles.type}>{title}{extendsTitle}</Text>
    <Box className={styles.btns}>
      <CButton className={`${styles.btn} ${!showPush ? styles.all : ''}`} theme='primary' onClick={onSameDraw}>
        <Icon className={styles.icon} as={require('@/assets/svg/creation_same.svg').ReactComponent} />
        <Text>{t('creation.creationSame')}</Text>
      </CButton>
      {
        showPush && <CButton isLoading={shareLoading} className={`${styles.btn} ${styles.expBtn}`} theme='custom' onClick={shareImg}>
          <Icon className={styles.icon} as={require('@/assets/svg/push_square.svg').ReactComponent} />
          <Text>{t('creation.PublishToWork')}</Text>
        </CButton>
      }
    </Box>
    <Box className={styles.ops}>
      {/* <Box className={styles.opItem} onClick={onFavorite}>

        {
          isFavorite ? <Icon className={styles.icon} as={require('@/assets/svg/heart_red.svg').ReactComponent} />
            : <Icon className={styles.icon} as={require('@/assets/svg/heart_hollow_black.svg').ReactComponent} />
        }
        <Text>收藏</Text>
      </Box> */}
      <Box className={styles.opItem} onClick={shareForQRCode}>
        <Icon className={styles.icon} as={require('@/assets/svg/share.svg').ReactComponent} />
        <Text>{t('Share')}</Text>
      </Box>
      <Box className={styles.opItem} onClick={download}>
        {
          downloadLoading ? <Loading.Icon className={styles.loading} />
            : <Icon className={styles.icon} as={require('@/assets/svg/creation_download.svg').ReactComponent} />
        }
        <Text>{t('download')}</Text>
      </Box>
    </Box>
    <Box className={styles.qrCode} ref={QRCodeRef} style={{ display: showQRCode ? 'flex' : 'none' }}>
      <canvas id='qrcode' className={styles.code}></canvas>
      <Text className={styles.tip}>{t('scanCode')}</Text>
      <CButton className={styles.copy} theme='primary' onClick={onCopy}>
        <Icon className={styles.icon} as={require('@/assets/svg/link.svg').ReactComponent} />
        {t('copyLink')}
      </CButton>
    </Box>
  </Box>
}

interface BottomCardProps {
  prompt: string | string[]
  negativePrompt?: string
  sizeScal?: string
  hrLevel?: string
  drawType?: string
  drawAvatar?: string
  imgToImg?: {
    url: string
    weight: number
  }
  cerfToImg?: {
    url: string
    weight: number
  }
  audio?: {
    name: string
    lyrics: string
    style: string
    model_name: string
  }
  time?: Date
}
const BottomCard: FC<BottomCardProps> = (props) => {
  const { prompt, negativePrompt, sizeScal, hrLevel, imgToImg, time, cerfToImg, drawType, drawAvatar, audio } = props;
  const { t } = useTranslation()
  return <Box className={styles.bottomCard}>
    <ScrollBarBox className={styles.bottomScroll}>
      {
        audio ? <>
          <InfoItem title={t('creation.SongName')} content={audio.name || ''} />
          <InfoItem title={t('creation.lyrics')} content={audio.lyrics || ''} />
          <InfoItem title={t('creation.MusicStyle')} content={audio.style || ''} />
          <InfoItem title={t('creation.MusicModel')} content={audio.model_name || ''} />
        </> : <>
          {
            drawType && <InfoItem title={t('creation.style')} content={t(drawType) || ''} img={drawAvatar} imgSize='middle' />
          }
          {
            prompt && <InfoItem title={t('creation.prompt')} content={prompt} />
          }
          {
            negativePrompt && <InfoItem title={t('creation.NegativePrompt')} content={negativePrompt} />
          }
          {
            sizeScal && <InfoItem title={t('creation.imgRatio')} content={sizeScal} />
          }
          {
            hrLevel && <InfoItem title={t('creation.Quality')} content={t(hrLevel)} />
          }
          {
            imgToImg && <InfoItem title={t('creation.StyleReference')} content={`${imgToImg.weight > -1 ? t("creation.Reference") + '：' + imgToImg.weight : ''}`} img={imgToImg.url} imgSize='large' />
          }
          {
            cerfToImg && <InfoItem title={t('creation.CharacterReference')} content={`${t("creation.Reference")}： ${cerfToImg.weight}`} img={cerfToImg.url} imgSize='large' />
          }
        </>
      }
      <InfoItem title={t('creatTime')} content={dayjs(time || new Date()).format('YYYY/MM/DD HH:mm:ss')} />
    </ScrollBarBox>
  </Box>
}

interface InfoItemProps {
  img?: string
  imgSize?: 'small' | 'middle' | 'large'
  title: string
  content: string | string[]
}
const InfoItem: FC<InfoItemProps> = (props) => {
  const { title, content, img, imgSize = 'middle' } = props;
  return <Box className={styles.infoItem}>
    <Text className={styles.title}>{title}</Text>
    <Box className={`${styles.content} ${styles[imgSize]}`}>
      {
        img && <WiseImage src={img} className={styles.img} />
      }
      <ScrollBarBox className={styles.text} barProps={{ style: { flex: 1 } }}>
        {
          Array.isArray(content) ? content.map((item, index) => <Text key={index}>{item}</Text>)
            : <>{content}</>
        }
      </ScrollBarBox>
    </Box>
  </Box>
}