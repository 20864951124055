import { CModal } from "@/components/Modal";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { FC, memo, useState } from "react";
import styles from './index.module.scss'
import { ScrollBarBox } from "@/components/ScrollBox";
import { WiseImage } from "@/components/Image";
import { CButton } from "@/components/Button";
import Loading from "@/components/Loading";
import dayjs from "dayjs";
import { copyDataFunc } from "@/utils/common";
import { useMessage } from "@/hooks/useMessage";
import Markdown from "@/components/Markdown";
import { useTranslation } from "react-i18next";
import { SpeechPlayer } from "../SpeechPlayer";
import { useNavigate } from "react-router-dom";
import { AudioRateEnum, AudioPitchEnum, AudioRoleEnum, AudioStyleEnum, AudioSpeekLangeuageEnum, AudioNameEnum } from "@/@types/audio";
import { usernames } from "../../Tools/TextToSpeech";

function getKeyByValue(type: any, value: string): string {
  const entry = Object.entries(type).find(([key, val]) => val === value);
  return entry ? entry[0] : '默认';
}

function getTime(time: any): string {
  let dateObj = new Date(time);
  let year = dateObj.getUTCFullYear();
  let month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // 月份从0开始
  let day = String(dateObj.getUTCDate()).padStart(2, '0');
  let hours = String(dateObj.getUTCHours()).padStart(2, '0');
  let minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
  let seconds = String(dateObj.getUTCSeconds()).padStart(2, '0');
  let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

interface PreviewProps {
  isOpen: boolean
  extendsType?: string
  username: string,
  onClose: () => void
  userAvatar: string
  userName: string
  negativePrompt?: string
  createTime?: Date
  onDownload?: (url: string, extendsType: any) => Promise<void>
  onSameDraw?: () => void
  showPush?: boolean
  audioSrc: string
  text: string
  audioRate: string;
  audioPitch: string;
  imitates: string;
  speeckstyles: string;
  format: string;
  quality: string;
  langeuage: string;
}
export const SpeechPreview: FC<PreviewProps> = memo((props) => {
  const {
    isOpen,
    onClose,
    userAvatar,
    userName,
    onDownload,
    username,
    createTime,
    onSameDraw,
    showPush = true,
    extendsType,
    audioSrc,
    text,
    audioRate,
    audioPitch,
    imitates,
    speeckstyles,
    format,
    quality,
    langeuage
  } = props;
  const { t } = useTranslation();
  let title = t('TextToSpeech') + '·';
  let extendsTitle = t('Microsoft');
  let user = '';
  let newquality = '';

  if (extendsType === 'microsoft') {
    user = getKeyByValue(AudioNameEnum, username)
    extendsTitle = t('Microsoft');
  } else if (extendsType === 'wisetalker') {
    const data = usernames.find((user: any) => user.key === username);
    user = data ? data.name : usernames[0].name;
    extendsTitle = t('Wisetalker');
    if (quality === 'hd') {
      newquality = '高级版（富有感情）'
    } else {
      newquality = '普通'
    }
  }
  const cardtitle = title + extendsTitle
  return <CModal isOpen={isOpen} onClose={onClose}>
    <Box className={styles.preview}>
      <SpeechPlayer
        audioSrc={audioSrc}
        lyrics={text}
      />
      <Box className={styles.info}>
        <TopCard
          avatar={userAvatar}
          name={userName}
          onClose={onClose}
          onDownload={async () => {
            onDownload && await onDownload(audioSrc, extendsType);
          }}
          onSameDraw={onSameDraw}
          showPush={showPush}
          cardtitle={cardtitle}
        />
        <BottomCard
          username={user}
          time={createTime}
          cardtitle={extendsTitle}
          ismic={extendsType === 'microsoft'}
          format={format}
          audioRate={audioRate}
          audioPitch={audioPitch}
          imitates={imitates}
          speeckstyles={speeckstyles}
          quality={newquality}
          langeuage={langeuage}
        />
      </Box>
    </Box>
  </CModal>
})

interface TopCardProps {
  avatar: string
  name: string
  onClose?: () => void
  onDownload?: () => Promise<void>
  onSameDraw?: () => void
  showPush?: boolean
  cardtitle?: string
}
const TopCard: FC<TopCardProps> = (props) => {
  const { avatar, name, onClose, onDownload, onSameDraw, showPush, cardtitle } = props;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const download = async () => {
    setDownloadLoading(true)
    try {
      onDownload && await onDownload()
    } catch (e) {
      console.error(e)
    } finally {
      setDownloadLoading(false)
    }
  }


  return <Box className={styles.topCard}>
    <Box className={styles.user}>
      <Box className={styles.userInfo}>
        <WiseImage className={styles.avatar} src={avatar} />
        <Text>{name}</Text>
      </Box>
      <Icon as={require('@/assets/svg/close_x.svg').ReactComponent} onClick={onClose} />
    </Box>
    <Text className={styles.type}>{cardtitle}</Text>
    <Box className={styles.btns}>
      <CButton className={`${styles.btn} ${!showPush ? styles.all : ''}`} theme='primary' onClick={onSameDraw}>
        <Icon className={styles.icon} as={require('@/assets/svg/creation_same.svg').ReactComponent} />
        <Text>{t('creation.creationSame')}</Text>
      </CButton>
    </Box>
    <Box className={styles.ops}>
      <Box className={styles.opItem} onClick={download}>
        {
          downloadLoading ? <Loading.Icon className={styles.loading} />
            : <Icon className={styles.icon} as={require('@/assets/svg/creation_download.svg').ReactComponent} />
        }
        <Text>{t('download')}</Text>
      </Box>
      <Box className={styles.opItem} onClick={() => { navigate(`/personal?selectedIndex=${t('report')}`) }}>
        {
          downloadLoading ? <Loading.Icon className={styles.loading} />
            : <Icon className={styles.icon} as={require('@/assets/svg/report.svg').ReactComponent} />
        }
        <Text>{t('report')}</Text>
      </Box>
    </Box>
  </Box>
}

interface BottomCardProps {
  username: string
  time?: Date
  cardtitle: string
  ismic: boolean
  format: string;
  audioRate: string;
  audioPitch: string;
  imitates: string;
  speeckstyles: string;
  quality: string;
  langeuage: string
}
const BottomCard: FC<BottomCardProps> = (props) => {
  const { time, username, cardtitle, ismic, format, quality, langeuage,
    audioRate,
    audioPitch,
    imitates,
    speeckstyles
  } = props;
  return <Box className={styles.bottomCard}>
    <ScrollBarBox className={styles.bottomScroll}>
      <InfoItem title={'模型'} content={cardtitle ? cardtitle : ''} iscopy={false} />
      {ismic && <InfoItem title={'语言'} content={getKeyByValue(AudioSpeekLangeuageEnum, langeuage).replace(/,简体/g, '')} iscopy={false} />}
      <InfoItem title={'语音角色'} content={username ? username : ''} iscopy={false} />
      <InfoItem title={'语速'} content={getKeyByValue(AudioRateEnum, audioRate)} iscopy={false} />
      {ismic && <InfoItem title={'语调'} content={getKeyByValue(AudioPitchEnum, audioPitch)} iscopy={false} />}
      {ismic && <InfoItem title={'风格'} content={getKeyByValue(AudioStyleEnum, speeckstyles)} iscopy={false} />}
      {ismic && <InfoItem title={'声音模仿'} content={getKeyByValue(AudioRoleEnum, imitates)} iscopy={false} />}
      {!ismic && <InfoItem title={'生成质量'} content={quality ? quality : ''} iscopy={false} />}
      <InfoItem title={'生成格式'} content={format ? format : ''} iscopy={false} />
      <InfoItem title={'生成时间'} content={time ? getTime(time) : ''} iscopy={false} />
    </ScrollBarBox>
  </Box>
}

interface InfoItemProps {
  title: string
  content: string | string[]
  iscopy: boolean
}
const InfoItem: FC<InfoItemProps> = (props) => {
  const { title, content, iscopy } = props;
  const { t } = useTranslation();
  const { copyData } = copyDataFunc();
  const message = useMessage();
  const copy = (value: any) => {
    copyData(value);
    message.success({ title: t('CopySuccess') });
  }
  return <Box className={styles.infoItem}>
    <Flex className={styles.titleBox}>
      <Text className={styles.title}>{title}</Text>
      {iscopy &&
        <Icon onClick={() => copy(content)} className={styles.icon} as={require('@/assets/svg/creation_use_prompt.svg').ReactComponent} />
      }
    </Flex>
    <Box className={`${styles.content}`}>
      <ScrollBarBox className={styles.text} barProps={{ style: { flex: 1 } }}>
        {
          Array.isArray(content) ? content.map((item, index) => <Text key={index}>{item}</Text>)
            : <Markdown source={content} />
        }
      </ScrollBarBox>
    </Box>
  </Box>
}