import { AlertProps } from '@/components/Alert';
import { LangValues } from '@/locales/resources';
import i18n from '@/utils/i18n';
import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store';

class UIStore {
  language: LangValues = 'zh';
  theme: string = ''
  alert: AlertProps = { open: false }
  openVip: boolean = false
  updateDownloaded: boolean = false
  releaseNotes: string[] = []
  version: string = ''
  updateIsWindow: boolean = false
  zoom: number = 0.9
  isMustUpdate: boolean = false
  newsetVersion: string = ''
  versionCheckUrl: string = ''
  processTaskIds: string[] = []
  finishTaskInfo: Record<string, TaskStatusResult> = {}
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: 'uiStore',
      properties: ['language', 'theme', 'processTaskIds'],
      storage: window.localStorage
    })
  }

  get getLanguage() {
    return this.language;
  }

  setLanguage(lang: LangValues) {
    this.language = lang;
    i18n.changeLanguage(lang);
  }

  openAlert(props: Omit<AlertProps, 'open'>) {
    this.alert = { open: true, ...props }
  }
  closeAlert() {
    // 释放其他变量
    this.alert = { open: false }
  }

  setOpenVip(isOpen: boolean) {
    this.openVip = isOpen
  }

  setUpdateDownloaded(isDownloaded: boolean) {
    this.updateDownloaded = isDownloaded
  }

  setReleaseNotes(releaseNotes: string[]) {
    this.releaseNotes = releaseNotes;
  }

  setVersion(version: string) {
    this.version = version
  }

  setUpdateIsWindow(isWindow: boolean) {
    this.updateIsWindow = isWindow
  }

  setIsMustUpdate(isMustUpdate: boolean) {
    this.isMustUpdate = isMustUpdate
  }

  setNewsetVersion(version: string) {
    this.newsetVersion = version
  }

  setVersionCheckUrl(url: string) {
    this.versionCheckUrl = url
  }

  setProcessTaskIds(ids: string[]) {
    this.processTaskIds = ids
  }

  setFinishTaskInfo(info: Record<string, TaskStatusResult>) {
    this.finishTaskInfo = info;
  }
}

export const uiStrore = new UIStore()