import { FC, useEffect } from "react";
import { Box, Icon, Text } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { agentsStore } from "@/store/agents";
import { ScrollBarBox } from "@/components/ScrollBox";
import { WiseImage } from "@/components/Image";
import { useTranslation } from "react-i18next";
import { userStore } from "@/store/user";

import styles from './index.module.scss'

export const agent_avatars = {
  avatar_1: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/uploadvideo/450cbdba5b1691bd5f18f7402.png',
  avatar_2: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/uploadvideo/450cbdba5b1691bd5f18f7403.png',
  avatar_3: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/uploadvideo/450cbdba5b1691bd5f18f7404.png',
  avatar_4: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/uploadvideo/450cbdba5b1691bd5f18f7405.png',
  avatar_5: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/uploadvideo/d54c1a7db85a8ebfc0a18d6dfd733618.png',
  avatar_6: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/file/app/images/mine/mine_ztn2.png',
  avatar_7: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/file/app/images/mine/mine_ztn3.png',
  avatar_8: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/file/app/images/mine/mine_ztn4.png',
  avatar_9: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/file/app/images/mine/mine_ztn5.png',
}
const AgentsNav: FC = () => {
  const { fetchList, list, setActiveInfo, activeInfo, showNav } = agentsStore;
  const { userInfo } = userStore
  const { t } = useTranslation();
  let defaultName = 0;

  useEffect(() => {
    userInfo && fetchList();
    // eslint-disable-next-line
  }, [])

  const createAgent = async () => {
    const lenght = list?.myModels?.length || 0;
    const randomAvatar = Math.floor(Math.random() * 5) + 1;
    setActiveInfo({
      _id: '',
      name: t('agent.mine') + ' ' + (lenght < 9 ? '0' + (lenght + 1) : (lenght + 1)),
      avatar: agent_avatars[`avatar_${randomAvatar}` as keyof typeof agent_avatars],
      intro: '',
      systemPrompt: '',
      limitPrompt: '',
      presence_penalty: 0,
      temperature: 1,
      group: '对话聊天'
    })
  }
  return <Box className={`${styles.nav} ${!showNav ? styles.hide : ''}`}>
    <Text className={styles.title}>{t('agent.agent')}</Text>
    <Box className={styles.add} onClick={createAgent}>
      <Icon className={styles.icon} as={require('@/assets/svg/add_circle.svg').ReactComponent} />
      <Text>{t('agent.new_mine')}（{list?.myModels.length}/20）</Text>
    </Box>
    <Box className={styles.list}>
      <ScrollBarBox className={styles.listBox}>
        {list?.myModels.map((item) => {
          let cls = '';
          let name = '';
          Object.keys(agent_avatars).forEach((key) => {
            if (agent_avatars[key as keyof typeof agent_avatars] === item.avatar) {
              cls = styles.defaultAvatar;
            }
          });
          if (item.name === t('agent.mine')) {
            defaultName++;
            name = `${t('agent.mine')} ${defaultName < 10 ? '0' + defaultName : defaultName} `;
          } else {
            name = item.name;
          }
          return <Box key={item._id}
            className={`${styles.item} ${activeInfo?._id === item._id ? styles.active : ''}`}
            onClick={() => setActiveInfo(item)}
          >
            <Box className={`${styles.avatar} ${cls}`} >
              <WiseImage src={item.avatar} />
            </Box>
            <Box className={styles.info}>
              <Text className={styles.name}>{name}</Text>
              <Text className={styles.intro}>{item.intro ? (t('Intro') + ': ' + item.intro) : t('AgentNoIntro')}</Text>
            </Box>
          </Box>
        })}
      </ScrollBarBox>
    </Box>
  </Box>
}

export default observer(AgentsNav)