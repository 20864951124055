import { Box, Flex, Icon, Text, Image } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import styles from './index.module.scss';
import { creationStore, CreationType } from "@/store/creation";
import { useTranslation } from "react-i18next";
import { ScrollBarBox } from "@/components/ScrollBox";
import { getCreationMenu } from "@/api/creation";

let typeCount = 0;
let toolCount = 0;
export const SubNav: FC = observer(() => {
  const { pageType, setPageType, showNav, enableKeys, setEnableKeys } = creationStore.publicStore;
  const [showKeys, setShowKeys] = useState<Record<string, boolean>>({});

  useEffect(() => {
    getMenuConfig()
  }, []);

  const getMenuConfig = async () => {
    const _showKeys: Record<string, boolean> = {};
    const _enableKeys: Record<string, boolean> = {};

    // 默认显示所有
    _ALL_MODELS.forEach(item => {
      _showKeys[item.key] = true;
      _enableKeys[item.key] = true;
    });
    _draw_Tools.forEach(item => {
      _showKeys[item.key] = true;
      _enableKeys[item.key] = true;
    });
    const res = await getCreationMenu();
    console.log(res, 'res----')
    res.forEach((item) => {
      if (!item?.key) return;
      if (item.show) {
        _showKeys[item.key] = true;
      } else {
        _showKeys[item.key] = false;
      }
      if (item.enable) {
        _enableKeys[item.key] = true;
      } else {
        _enableKeys[item.key] = false;
      }
    });
    setShowKeys(_showKeys);
    setEnableKeys(_enableKeys);
  }

  const { t } = useTranslation();
  const _ALL_MODELS = [
    { icon: 'draw_wise', name: t('wise') + 'MJ', desc: t('wise_desc'), active: CreationType.MJ, key: 'wensimj' },
    { icon: 'draw_sd', name: t('wise') + 'SD1.5', desc: t('wise_sd_desc'), active: CreationType.SD, key: 'wensisd1.5' },
    { icon: 'draw_sd', name: t('wise') + 'SD3.0', desc: t('wise_sd3_desc'), active: CreationType.SD3, key: 'wensisd3.0' },
    { icon: 'draw_chat', name: t('wise') + 'E3', desc: t('wise_e3_desc'), active: CreationType.E3, key: 'wensie3' },
    { icon: 'draw_video', name: t('wise') + 'GEN3', desc: t('wise_GEN3_desc'), active: CreationType.GEN3, key: 'wensigen3' },
    { icon: 'draw_suno', name: t('wise') + 'SUNO', desc: t('wise_SUNO_desc'), active: CreationType.SUNO, key: 'wensisuno' },
    { icon: 'draw_clone', name: t('cloneAudio'), desc: t('cloneAudio_desc'), active: CreationType.CloneAudio, key: 'shengyingkeloig' }
  ]
  const _draw_Tools = [
    { name: t('PictureMagify'), icon: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/tools/icon/%E5%9B%BE%E7%89%87%E9%AB%98%E6%B8%85%E6%94%BE%E5%A4%A7.png', synopsis: t('PictureMagifytips'), active: CreationType.ENLARGE, key: 'tupiangaoqingfangda' },
    { name: t('PictureRemoveBG'), icon: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/tools/icon/%E5%9B%BE%E7%89%87%E8%83%8C%E6%99%AF%E7%A7%BB%E9%99%A4.png', synopsis: t('PictureRemoveBGtips'), active: CreationType.BG, key: 'tupianbeijinyichu' },
    { name: t('TextToSpeech'), icon: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/image/upload/video.png', synopsis: t('TextToSpeechtips'), active: CreationType.TextToSpeech, key: 'wenbenzhuanyuyin' },
    { name: t('OldPhotoRestoration'), icon: 'https://wisetalker-gpt-pub-images.oss-cn-guangzhou.aliyuncs.com/video/uploadvideo/4148d750baed6603c55526991d19c2cd.png', synopsis: t('OldPhotoRestorationtips'), active: CreationType.OldPhoto, key: 'oldPhoto' }
  ]

  return <Box className={`${styles.subnav} ${!showNav ? styles.hide : ''}`}>
    <Box className={styles.squareBtn} onClick={() => setPageType(CreationType.Square)}>
      <Icon className={styles.icon} as={require('@/assets/svg/square_orange.svg').ReactComponent} />
      <Text>{t('square')}</Text>
    </Box>
    <Box className={styles.scrollBox}>
      <ScrollBarBox>
        <Box minHeight='300px'>
          {
            _ALL_MODELS.map(item => {
              showKeys[item.key] && typeCount++;
              return showKeys[item.key] ? <Box
                className={`${styles.drawType} ${item.active === pageType ? styles.active : ''} ${enableKeys[item.key] ? '' : styles.disable}`}
                key={item.active}
                onClick={() => {
                  if (pageType !== item.active) {
                    setPageType(item.active);
                  }
                }}
              >
                {
                  item.icon && <Icon className={styles.icon} as={require(`@/assets/svg/${item.icon}${item.active === pageType ? '_active' : ''}.svg`).ReactComponent} />
                }
                <Box className={styles.text}>
                  <Text className={styles.name}>
                    {item.name}
                    {
                      enableKeys[item.key] ? null : <Box className={styles.maintain}>{t('Maintain')}</Box>
                    }
                  </Text>
                  <Text className={styles.desc}>{item.desc}</Text>
                </Box>
              </Box> : null
            })
          }
          {
            typeCount === 0 ? <Box className={styles.noData}>
              <Image src={require('@/assets/png/no_chat.png')} />
              <Text>{t('NoData')}</Text>
            </Box> : null
          }
        </Box>
        <Box>
          <Text className={styles.titleText}>{t('ExploreMore')}</Text>
          {_draw_Tools.map((item) => {
            showKeys[item.key] && toolCount++;
            return showKeys[item.key] ? <Flex key={item.name} className={`${item.active === pageType ? styles.selecttoolBox : styles.toolBox} ${enableKeys[item.key] ? '' : styles.disable}`}
              onClick={() => {
                if (pageType !== item.active) {
                  setPageType(item.active);
                }
              }}>
              <Image src={item.icon} className={styles.avatar} />
              <Box className={styles.contentbox}>
                <Text className={styles.contName}>
                  {item.name}
                  {
                    enableKeys[item.key] ? null : <Box className={styles.maintain}>{t('Maintain')}</Box>
                  }
                </Text>
                <Text className={styles.contSynopsis}>{item.synopsis}</Text>
              </Box>
            </Flex> : null
          }
          )}
        </Box>
        {
          toolCount === 0 ? <Box className={styles.noData}>
            <Image src={require('@/assets/png/no_chat.png')} />
            <Text>{t('NoData')}</Text>
          </Box> : null
        }
      </ScrollBarBox>
    </Box>
  </Box>
})