import { Box, Flex, Icon, Image } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { userStore } from "@/store/user";
import { useMessage } from "@/hooks/useMessage";
import { toolStore } from "@/store/tool";
import { HdrestorationListType, ToolSchema } from "@/@types/tools";
import styles from "./index.module.scss"
import { ScrollBarBox } from "@/components/ScrollBox";
import { TranslationTitle } from "@/pages/tools/components/ComTarnsTitle";
import { ComFile } from "../Components/ComFile";
import { ImgsHistory } from "../Components/history";
import ImageRemovebg from "../Components/imageRemovebg";
import { getHdrestoration } from "@/api/tools";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { creationStore } from "@/store/creation";
import { isDesktop } from "@/utils/electron";
import { useTranslation } from "react-i18next";
import CreateBtn from "../../Components/CreationBtn";
import { uiStrore } from "@/store/ui";
import { cloneDeep } from "lodash";

export const BackGround: FC = observer(() => {
  const [tool, SetTool] = useState<ToolSchema>()
  const { getToolByLink } = toolStore;
  const { enableKeys } = creationStore.publicStore;
  const { t } = useTranslation();
  const { userInfo } = userStore;
  const message = useMessage();
  const [list, setList] = useState<HdrestorationListType[]>([])
  const [chooseFile, setChooseFile] = useState<File>()
  const [loading, setLoading] = useState(false)
  const { processTaskIds, setProcessTaskIds, language } = uiStrore;
  useEffect(() => {
    const fetchData = async () => {
      const data = await getToolByLink({ toollink: 'imageRemovebg' });
      SetTool(data[0]);
      const res = await getHdrestoration('removebg')
      setList(res || [])
    };
    fetchData();
  }, []);
  const [isHovered, setIsHovered] = useState(false);

  const uploadFile = async (file: File) => {

    // 创建FormData对象
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', 'removebg');
    formData.append('model', 'R-ESRGAN 4x+ Anime6B');
    formData.append('toolid', tool ? tool._id : '');
    // formData.append('scale', String(params.scale));
    try {
      // 发送POST请求
      const response = await axios.post(
        (isDesktop() ? (window.proxyApi || '') : '') + '/api/videore/hdrestoration/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent: any) => {
            if (progressEvent && progressEvent.total && progressEvent.total > 0) {
              const perCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              console.log('Upload progress:', perCompleted);
              // 在这里可以更新上传进度的显示
            }
          }
        }
      );
      setChooseFile(undefined)
      setLoading(false)
      document.getElementById('chooseFile')?.setAttribute('src', '')
      if (response.data?.code === 200) {
        await getMyImgs();

        const taskIds = cloneDeep(processTaskIds);
        taskIds.push(response.data.data);
        setProcessTaskIds(taskIds);
      } else if (response.data?.message.indexOf('余额不足') > -1) {
        message.warning({ title: t('creation.RMImgBgFailed') })
      }
    } catch (err) {
      setChooseFile(undefined)
      document.getElementById('chooseFile')?.setAttribute('src', '')
      setLoading(false)
      console.log(err)
    }
  }
  const getMyImgs = async () => {
    try {
      const res = await getHdrestoration('removebg')
      setList(res || [])
    } catch (err) {
      console.log(err)
    }
  }

  const startGenerate = () => {
    if (loading) return
    if (!userInfo?.monVip && (userInfo?.balance && userInfo?.balance < 1)) {
      message.warning({ title: t('PlsRecharge') })
      return;
    }
    if (chooseFile) {
      uploadFile(chooseFile)
    }
  }

  useQuery(['refreshList'], () => { getMyImgs(); return null }, {
    refetchInterval: 10000,
  })

  const reset = () => {
    setChooseFile(undefined)
    document.getElementById('chooseFile')?.setAttribute('src', '')
  }

  const { showNav } = creationStore.publicStore

  return (
    <Flex key={'imageRemovebg'} w={!showNav ? '100%' : 'calc(100% - 340px)'} className={styles.toollistBox}>
      <Flex className={styles.listBox}>
        <TranslationTitle
          icon={tool ? tool.icon.toString() : ''}
          title={language === 'zh' ? tool ? tool.name.toString() : '' : (tool && tool.name_more_language) ? tool.name_more_language.en.toString() : ''}
          historytitle='language'
        />
        <Box className={styles.contentsBox}>
          <ScrollBarBox>
            <Box className={styles.imageBox}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}>
              <Box className={`${styles.fade} ${isHovered ? styles.visible : styles.hidden}`}>
                <ImageRemovebg />
              </Box>
              <Box className={`${styles.fade} ${isHovered ? styles.hidden : styles.visible}`}>
                <Image className={styles.image} src={require('@/assets/png/imageRemovebg.png')} />
              </Box>
            </Box>
            <Flex>
              <ComFile
                title={t('creation.UploadRMBgImg')}
                prompt={t('creation.RMBgDesc')}
                chooseFile={chooseFile}
                setChooseFile={setChooseFile}
                filetitle={t('保证图片质量的同时，去除图片的背景，增加图片的主题感')}
                filelist={[`${t('SupportImages')}：JPG、JPEG、PNG、WEBP、BMP、HEIC、HEIF`, t('imgMax20Size')]}
              />
            </Flex>
          </ScrollBarBox>
        </Box>
        <Flex className={styles.footbutton}>
          <CreateBtn
            loading={loading}
            isMaintain={!enableKeys['tupianbeijinyichu']}
            disabled={!chooseFile}
            onClick={() => { if (chooseFile) { startGenerate() } }}
            price={20}
            isVip={userInfo?.monVip}
            icon={<Icon as={require('@/assets/svg/create_img.svg').ReactComponent} />}
            onReset={reset}
            onVip={() => uiStrore.setOpenVip(true)}
          />
        </Flex>
      </Flex>
      <Flex className={styles.previewBox}>
        <ImgsHistory
          key={tool?.name.toString()}
          history={list}
          type='bg'
        />
      </Flex>
    </Flex>
  )
})