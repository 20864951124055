import { Box, Flex, Icon, Text } from "@chakra-ui/react"
import { CSSProperties, FC } from "react"
import { observer } from "mobx-react-lite"

import styles from './index.module.scss'

interface CreationHeaderProps {
  icon: string
  text: string
  rightNode?: React.ReactNode
  style?: CSSProperties
}

export const CreationHeader: FC<CreationHeaderProps> = observer((props) => {
  const { icon, text, rightNode, style } = props
  return <Flex className={styles.header} style={style}>
    <Box className={styles.left}>
      <Icon className={styles.icon} as={require(`@/assets/svg/${icon}.svg`).ReactComponent} />
      <Text className={styles.title}>{text}</Text>
    </Box>
    {
      rightNode
    }
  </Flex>
})