import { Box, Icon, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import styles from './index.module.scss';
import { CButton } from "@/components/Button";

interface CreationBtnProps {
  icon?: ReactNode
  text?: string
  showReset?: boolean
  showPrice?: boolean
  price?: number
  isVip?: boolean
  disabled?: boolean
  isMaintain?: boolean
  style?: React.CSSProperties
  loading?: boolean
  onClick?: () => void
  onReset?: () => void
  onVip?: () => void
}
const CreateBtn: FC<CreationBtnProps> = (props) => {
  const { t } = useTranslation();
  const {
    icon,
    text = t('generate'),
    showReset = true,
    showPrice = true,
    isMaintain = false,
    price,
    isVip,
    disabled,
    style,
    loading,
    onClick,
    onReset,
    onVip
  } = props;

  return <Box className={`${styles.creationBtn} ${isVip ? styles.vip : ''}`} style={style}>
    {
      showPrice && <Box className={styles.priceBox}>
        <Box className={styles.priceNum}>
          <Text>{t('creation.price')}:</Text>
          <Text className={styles.num}>{price}</Text>
          <Icon as={require('@/assets/svg/personalBalance.svg').ReactComponent} />
        </Box>
        <Box className={styles.vip} onClick={onVip}>
          {t('vipFree')}
          <Icon as={require('@/assets/svg/right.svg').ReactComponent} />
        </Box>
      </Box>
    }
    <Box className={styles.btnInfo}>
      {
        showReset && <Box className={styles.reset} onClick={onReset}>
          <Icon as={require('@/assets/svg/reset.svg').ReactComponent} />
          {t('reset')}
        </Box>
      }
      <CButton
        className={`${styles.btn} ${(disabled || isMaintain) ? styles.disabled : ''}`}
        disabled={disabled || isMaintain}
        isLoading={loading}
        theme="primary"
        onClick={() => {
          if (!disabled && !isMaintain) {
            onClick && onClick()
          }
        }}
      >
        {
          isMaintain ? <Box className={styles.maintain}>
            {t('Maintain')}
          </Box> : <>
            {
              icon && !isVip ? icon : null
            }
            {
              isVip && <Icon as={require('@/assets/svg/vip_mark.svg').ReactComponent} className={styles.vipIcon} />
            }
            {text}
          </>
        }
      </CButton>
    </Box>
  </Box>
}

export default CreateBtn;