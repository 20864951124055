import { Box, Icon } from "@chakra-ui/react";
import { FC } from "react";
import styles from './index.module.scss'
import { useTranslation } from "react-i18next";
import { userStore } from "@/store/user";
import { useNavigate } from "react-router-dom";
import { uiStrore } from "@/store/ui";
import { observer } from "mobx-react-lite";
import { ipcRendererInvoke } from "@/utils/electron";
import { chatStore } from "@/store/chat";
import { useMessage } from "@/hooks/useMessage";
import { creationStore } from "@/store/creation";
import { useStateContext } from "@/pages/im/components";
import { imStore } from "@/store/im";
import { agentsStore } from "@/store/agents";

interface NavigationProps {
  selectedIndex: string;
  setSelectedIndex: (item: string) => void;
}

export const Navigation: FC<NavigationProps> = observer(({ selectedIndex, setSelectedIndex }) => {
  const { t } = useTranslation();
  const message = useMessage();
  const { Logout, userInfo } = userStore;
  if (!userInfo) return null;
  const { openAlert, closeAlert } = uiStrore;
  const navigate = useNavigate();
  const { nim } = useStateContext();
  const titlenavigations = [
    {
      icon: 'personalData',
      title: t('Personal_Data'),
    },
    // {
    //   icon: 'mimn',
    //   title: t('My_agent')
    // },
    {
      icon: 'signin',
      title: t('SignIn_is_courteous')
    },
    {
      icon: 'myinvite',
      title: t('My_invitation')
    }
  ]
  const navigations = [
    // {
    //   icon: 'balance',
    //   title: t('Balance_top_up'),
    // },
    {
      icon: 'recharge',
      title: t('Top_up_history'),
    },
    {
      icon: 'bills',
      title: t('Point_records'),
    },
    // {
    //   icon: 'appearance',
    //   title: t('Appearance_preference'),
    // },
    {
      icon: 'lang',
      title: t('lang'),
    },
    {
      icon: 'contactUs',
      title: t('Contact_us'),
    },
    {
      icon: 'suggestion',
      title: t('Suggestions_Feedback'),
    },
    {
      icon: 'report',
      title: t('report'),
    },
    {
      icon: 'concerning',
      title: t('concerning'),
    },
    {
      icon: 'close',
      title: t('Sign_out'),
    },
  ]

  return <Box className={styles.history}>
    <Box className={styles.title} onClick={() => { setSelectedIndex('') }}>
      {t('Personal_Center')}
    </Box>
    <Box>
      {titlenavigations.map((item) => (
        <Box className={`${styles.titlenavigations}${selectedIndex === item.title ? ` ${styles.navigations_select}` : ''}`}
          onClick={() => setSelectedIndex(item.title)} key={item.title}>
          <Icon className={`${styles.titlesvg} ${styles[item.icon]}`} as={require(`@/assets/svg/${item.icon}.svg`).ReactComponent} />
          <Box className={`${styles.titlecontent}${selectedIndex === item.title ? ` ${styles.select}` : ''}`}>
            {item.title}
          </Box>
          {selectedIndex === item.title && (
            <Icon className={styles.selecticon} as={require(`@/assets/svg/personal_hover.svg`).ReactComponent} />
          )}
        </Box>
      ))}
    </Box>
    <Box className={styles.divider} />
    <Box>
      {navigations.map((item) => (
        <Box
          key={item.title}
          className={`${item.title === t('Sign_out') ? styles.outtions : styles.navigations}${selectedIndex === item.title ? ` ${styles.navigations_select}` : ''}`}
          onClick={() => {
            if (item.title === t('Sign_out')) {
              openAlert({
                title: '提示',
                content: '您确定要退出当前登录账户吗？',
                onOk: async () => {
                  document.cookie = '';
                  ipcRendererInvoke('clearCookie');
                  await Logout();
                  navigate('/chat');
                  closeAlert();
                  agentsStore.reset();
                  chatStore.reset();
                  creationStore.reset();
                  imStore.setUnread(0);
                  await nim?.destroy();
                  await nim?.disconnect();
                  localStorage.clear(); //清除所有缓存
                  message.success({ title: t('退出成功') })
                }
              })
            } else {
              setSelectedIndex(item.title)
            }
          }}>
          <Icon className={styles.svg} as={require(`@/assets/svg/${selectedIndex === item.title ? `${item.icon}Hover` : item.icon}.svg`).ReactComponent} />
          <Box className={styles.content}>
            {item.title}
          </Box>
          {selectedIndex === item.title && (
            <Icon className={styles.selecticon} as={require(`@/assets/svg/personal_hover.svg`).ReactComponent} />
          )}
        </Box>
      ))}
    </Box>
  </Box>
})