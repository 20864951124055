import { Box, Button, Flex, Icon, Image, Input, Text, Tooltip } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { FC } from "react";
import styles from "./index.module.scss"
import { CButton } from "@/components/Button";
import { uiStrore } from "@/store/ui";
import { useTranslation } from "react-i18next";

export const ComTags: FC<any> = observer(({
  title,
  array,
  style,
  SetStyle,
  fathervalue,
  fatherlist,
  father,
  isselectvalue
}: {
  title: string;
  array: any[];
  style: any;
  SetStyle: (e: any) => void;
  fathervalue?: any;
  fatherlist?: any;
  father?: any;
  isselectvalue?: boolean;
}) => {
  const { t } = useTranslation();
  let uniqueParents;
  if (father) {
    uniqueParents = Array.from(new Set(father.map((item: any) => item.parents)));
  }
  useEffect(() => {
    const allContainStyle = array.some(item => item.text === style);
    const test = array.includes(style);
    const zdyContain = zdyselects.includes(style);
    if (test === false && allContainStyle === false && zdyContain === false) {
      SetZdySelects((prevState: any) => ([...prevState, style]));
      handleButtonClick(style);
    }
  }, [array, style])
  useEffect(() => {
    if (array && array.length > 0 && isselectvalue) {
      SetStyle(array[0].text);
      SetZdySelects([]);
    }
  }, [])
  let conttitle = title;
  if (fatherlist) {
    const ctitle = title.replace(/\.?\s*\${(.*?)}/g, (_: any, key: any) => {
      return key in fatherlist ? t(fatherlist[key]) : '无';
    });
    conttitle = ctitle;
  }

  const handleButtonClick = (item: any) => {
    SetStyle(item);
  };

  const parts = conttitle.split('*');

  const [editMode, setEditMode] = useState<null | number>(null);
  const [zdyselects, SetZdySelects] = useState<any>([]);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleClickOutside = (event: any) => {
    if (editMode !== null && inputRefs.current[editMode] && !inputRefs.current[editMode].contains(event.target)) {
      setEditMode(null);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editMode]);
  const { language } = uiStrore;

  return (
    <div>
      {(
        typeof fathervalue === 'undefined' ||
        typeof fatherlist === 'undefined' ||
        uniqueParents && uniqueParents.includes(fatherlist[fathervalue])
      ) && (
          <Flex className={styles.allbox}>
            <Box className={styles.titlebox}>
              {parts.map((part: any, index: any) => (
                <Text key={index} className={styles.title}>
                  {part}{index < parts.length - 1 ? <span className={styles.titletips}>*</span> : null}
                </Text>
              ))}
            </Box>
            <Box>
              {array.map((item) => {
                const shouldDisplayOption = !item.parents || !style || style[item.parents] === item.parents;
                // console.log(item.parents,"判断是否存在父级",shouldDisplayOption)
                if (!item.parents) {
                  return (
                    <CButton
                      key={item.text} className={style === item.text ? styles.selectButton : styles.button}
                      onClick={() => handleButtonClick(item.text)}
                    >
                      {item.value && <Icon mr={1} name={item.value} />}
                      <Text>{language === 'zh' ? item.text : item.text_more_language ? item.text_more_language.en : ''}</Text>
                    </CButton>
                  )
                } else {
                  if (fatherlist && fatherlist[fathervalue] === item.parents) {
                    return (
                      <CButton
                        key={item.text} className={style === item.text ? styles.selectButton : styles.button}
                        onClick={() => handleButtonClick(item.text)}
                      >
                        {item.value && <Icon mr={1} name={item.value} />}
                        <Text>{language === 'zh' ? item.text : item.text_more_language ? item.text_more_language.en : ''}</Text>
                      </CButton>
                    )
                  }
                  return null;
                }
              })}
            </Box>
          </Flex>
        )}
    </div>
  );
})