import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { gen3styles } from "./config";
import { getDrawVideos } from "@/api/creation";

export class CreationGen3 {
  styleConfig?: Gen3StyleConfig;
  styleList: Gen3StyleConfig[] = [];
  showStyles: Gen3StyleConfig[] = []; // 展示的5个style
  sizeScal: string = '1:1';
  cnPrompt: string = '';
  prompt: string = '';
  videoLength: number = 5;
  imgUrl: string = '';
  pageNum = 1;
  pageSize = 10;
  total = 0;
  list: DrawVideo[] = [];
  shouldUpdate = false;

  constructor(pageSize: number = 10) {
    makeAutoObservable(this, {}, { autoBind: true });
    makePersistable(this, {
      name: `creationStore_gen3`,
      properties: ['sizeScal', 'cnPrompt', 'prompt', 'videoLength', 'imgUrl', 'styleConfig',],
      storage: window.localStorage
    })

    this.pageSize = pageSize;
    this.styleList = gen3styles;
    if (!this.styleConfig) {
      this.setStyleConfig(this.styleList[0], 0);
      this.setSizeScal(this.styleList[0].draw_scal[0].name);
    }
  }

  async getCreationHistory() {
    const res = await getDrawVideos({ pageNum: this.pageNum, pageSize: this.pageSize });
    this.list = res.data;
    this.total = res.total || 0;
  }

  setStyleConfig = (styleConfig: Gen3StyleConfig, index?: number) => {
    this.styleConfig = styleConfig;

    if (index !== undefined && index > -1) { // 可能为0
      if (index > this.styleList.length - 3) {
        this.showStyles = this.styleList.slice(-5);
      } else if (index < 3) {
        this.showStyles = this.styleList.slice(0, 5);
      } else {
        this.showStyles = this.styleList.slice(index - 2, index + 3);
      }
    }
  }

  setSizeScal = (sizeScal: string) => {
    this.sizeScal = sizeScal;
  }

  setPrompt = (prompt: string) => {
    this.prompt = prompt;
  }

  setCnPrompt = (cnPrompt: string) => {
    this.cnPrompt = cnPrompt;
  }

  setVideoLength = (videoLength: number) => {
    this.videoLength = videoLength;
  }

  setImgUrl = (imgUrl: string) => {
    this.imgUrl = imgUrl;
  }

  setPageSize = (pageSize: number) => {
    this.pageSize = pageSize;
  }

  setPageNum = (pageNum: number) => {
    this.pageNum = pageNum;
    this.getCreationHistory();
  }

  setTotal = (total: number) => {
    this.total = total;
  }

  setShouldUpdate = (shouldUpdate: boolean) => {
    this.shouldUpdate = shouldUpdate;
  }

  reset = () => {
    this.styleConfig = undefined;
    this.sizeScal = '1:1';
    this.cnPrompt = '';
    this.prompt = '';
    this.videoLength = 5;
    this.imgUrl = '';
    this.pageNum = 1;
    this.pageSize = 10;
    this.total = 0;
  }
}
