import { Box, Icon, Text, useOutsideClick, Flex } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";
import { creationStore, CreationType, DrawImageStatusEnum } from "@/store/creation";
import { observer } from "mobx-react-lite";
import { CreationHeader, CreationPrompt, CreationStyle, CreationReference, CreationInfo, CreationPreview, CreationContent } from "../Components";
import { ScrollBarBox } from "@/components/ScrollBox";
import { getOSSBlobResource, saveBlobToLocal, SHOW_PRICE_SCALE } from "@/utils/common";
import { useMessage } from "@/hooks/useMessage";
import { userStore } from "@/store/user";
import { globalStore } from "@/store/global";
import { deleteDrawVideo, getLangtranslate, postCreateDrawGEN2Image, retryDrawVideo, shareToSquare } from "@/api/creation";
import { useQuery } from "@tanstack/react-query";
import { uiStrore } from "@/store/ui";

import styles from '../SD/index.module.scss';
import { WisePage } from "@/components/Pagination";
import { CreationVideoLength } from "../Components/Quality";
import { RenderVideo } from "../Components/ImageItem";
import { useTranslation } from "react-i18next";
import CreateBtn from "../Components/CreationBtn";
import { getType } from "../Wise";
import { getdate } from "@/pages/tools/components/ContentPreview";
import { cloneDeep } from "lodash";

const pageType = CreationType.GEN3;

export const WiseRunway: FC = observer(() => {
  const {
    cnPrompt, setCnPrompt, prompt, videoLength, imgUrl, setImgUrl, setShouldUpdate, setVideoLength,
    styleList, styleConfig, showStyles, setStyleConfig, sizeScal, getCreationHistory
  } = creationStore[pageType];
  const { enableKeys } = creationStore.publicStore;
  const [loading, setLoading] = useState(false);
  const { processTaskIds, setProcessTaskIds } = uiStrore;
  const { t } = useTranslation();
  const styleTips = {
    title: t('Optional'),
    tips: [
      t('creation.RunwayTips1'),
      t('creation.RunwayTips2')
    ]
  }

  const { userInfo, updateUserBalance } = userStore;
  const { setLoginIsOpen } = globalStore;
  const message = useMessage();

  useEffect(() => {
    // init();
    // eslint-disable-next-line
  }, [])

  const createTask = async () => {
    if (loading || isDisable) return;
    setLoading(true);
    try {
      if (!userInfo) {
        message.warning({ title: t('LoginFirst') });
        setLoginIsOpen(true);
        return;
      }
      if (!userInfo.monVip && (userInfo.balance * SHOW_PRICE_SCALE) < price) {
        message.warning({ title: t('creation.DrawFaildBalance') });
        return;
      }

      let ePrompt = '';
      if (/[\u4e00-\u9fa5]/.test(cnPrompt)) {
        //有中文，则翻译
        ePrompt = (await getLangtranslate(cnPrompt)).toString();
      }

      const { userBalance, ret, message: resMessage, newId } = await postCreateDrawGEN2Image({
        drawType: styleConfig?.name,
        sizeScal,
        prompt: ePrompt.split('#').length > 0 ? (ePrompt.split('#')[0] || cnPrompt) : prompt,
        cnPrompt: cnPrompt,
        video_length: String(videoLength),
        prompt_img: imgUrl,
      })

      if (ret === 'successd') {
        updateUserBalance(userBalance);
        setShouldUpdate(true);
        message.success({ title: t('creation.DrawTaskSuccess') });
        getCreationHistory();
        setImgUrl('');
        const taskIds = cloneDeep(processTaskIds);
        taskIds.push(newId);
        setProcessTaskIds(taskIds);
      } else {
        message.error({ title: resMessage });
      }
    } catch (err: any) {
      console.log(err)
      if (err?.message === 'political_content') {
        message.error({ title: t('creation.InputContainsPolitical') });
      } else {
        message.error({ title: err.message });
      }
    } finally {
      setLoading(false);
    }
  }

  const reset = () => {
    setCnPrompt('');
    setImgUrl('');
    setVideoLength(5);
    setShouldUpdate(true);
  }

  let price = Math.floor(SHOW_PRICE_SCALE * videoLength / 10);

  const isDisable = (!cnPrompt && !imgUrl) || !styleConfig || !videoLength || !sizeScal;

  return <CreationContent.Content>
    <CreationContent.Inputs>
      <CreationHeader icon='draw_gen3_000' text={t('wise') + 'GEN3'} />
      <Box className={styles.container}>
        <ScrollBarBox className={styles.content}>
          <CreationStyle title={t('creation.VideoStyle')} styleList={styleList} selectedStyle={styleConfig} onStyleChange={setStyleConfig} showStyles={showStyles} />
          <CreationPrompt onClear={() => { setCnPrompt('') }}
            cnPrompt={cnPrompt}
            onInput={setCnPrompt}
            type={pageType}
            showOptimization={false}
            empty={!imgUrl}
          />
          <CreationReference
            title={`${t('imageToVideo')}(${t('Optional')})`}
            tips={styleTips}
            showSlider={false}
            url={imgUrl}
            onUrlChange={setImgUrl}
            filetitle={t('基于上传的图片生成动态视频')}
            filelist={[`${t('SupportImages')}：JPG、JPEG、PNG、WEBP、BMP、HEIC、HEIF`, t('imgMax20Size')]}
          />
          {/* <CreationScale scaleList={styleConfig?.draw_scal || []} onScaleChange={setSizeScal} value={sizeScal} /> */}
          <CreationVideoLength length={videoLength} onLengthChange={setVideoLength} />
        </ScrollBarBox>
      </Box>
      <Box className={styles.create}>
        <CreateBtn
          loading={loading}
          isMaintain={!enableKeys['wensigen3']}
          disabled={isDisable}
          onClick={createTask}
          price={price}
          isVip={userInfo?.monVip}
          icon={<Icon as={require('@/assets/svg/create_video.svg').ReactComponent} />}
          onReset={reset}
          onVip={() => uiStrore.setOpenVip(true)}
        />
      </Box>
    </CreationContent.Inputs>
    <WiseSDGenerate />
  </CreationContent.Content>
})

const WiseSDGenerate: FC = observer(() => {
  const {
    list, styleList, pageNum, pageSize, setPageNum, total, shouldUpdate, setShouldUpdate,
    setSizeScal, setPrompt, setCnPrompt, setVideoLength, setImgUrl,
    setStyleConfig, getCreationHistory
  } = creationStore[pageType];
  const { userInfo } = userStore;
  const { openAlert, closeAlert } = uiStrore;
  const { t } = useTranslation();

  const message = useMessage();
  const moreEdit = useRef<DrawVideo>();
  const editRef = useRef<HTMLDivElement>(null);
  const [morePosition, setMorePosition] = useState<undefined | { x: number, y: number }>();
  const [viewSwiper, setViewSwiper] = useState(false);
  const viewInfo = useRef<DrawVideo>();
  const [infoIndex, setInfoIndex] = useState(0);
  const hadCreatingTask = list.some(item => (Number(item.status) === DrawImageStatusEnum.PROCESSING || Number(item.status) === DrawImageStatusEnum.WAIT || Number(item.status) === DrawImageStatusEnum.QUEUE));


  useEffect(() => {
    if (shouldUpdate) {
      setShouldUpdate(false);
    }
    // eslint-disable-next-line
  }, [shouldUpdate])

  useOutsideClick({
    ref: editRef,
    handler: () => {
      setMorePosition(undefined);
    }
  })

  // 存在绘画任务轮询更新列表
  useQuery(
    ['refreshDrawList'],
    () => {
      getCreationHistory();
      return null
    },
    {
      refetchInterval: hadCreatingTask ? 5000 : 30000, //存在绘画任务则5s刷新一次，否则30s刷新一次
      enabled: pageNum === 1 && !!userInfo?._id
    }
  )

  // 复用绘画参数
  const usePrompt = (item: DrawVideo) => {
    let _index = -1;
    const currentStyle = styleList.find((style, index) => {
      _index = index;
      return style.name === item.drawType
    });
    setPrompt(item.prompt_text || '');
    setCnPrompt(item.cnPrompt || '');
    setSizeScal(item.sizeScal || '1:1');
    setVideoLength(Number(item.video_length) || 5);
    setImgUrl(item.prompt_img || '');
    currentStyle && setStyleConfig(currentStyle, _index); // 选中当前风格
    message.success({ title: t('creation.ReuseSuccess') })
  }

  // 下载视频
  const download = async (url: string, extendsType: any) => {
    if (!url) return;
    let extendsTitle = getType(t, extendsType);
    const date = getdate()
    const suggestedName = url.substring(url.lastIndexOf('.') + 1)
    try {
      const res = await getOSSBlobResource(url);
      await saveBlobToLocal(res, `创作_文思GEN3_${extendsTitle}_${date}.${suggestedName}`);
      message.success({ title: t('creation.VideoDownloadSuccess') })
    } catch (err: any) {
      if (err.message !== 'The user aborted a request.') {
        message.warning({ title: t('creation.VideoDownloadCancel') })
      } else {
        message.error({ title: t('creation.VideoDownloadFaild') })
      }
    }
  }

  // const onFavorite = async (id: string, url: string) => {
  //   const isFavorite = faverites.get(id)?.includes(url) || false;
  //   try {
  //     const res = await postCollection(id, url);
  //     setFaverites(id, res[id] || []);
  //     if (isFavorite) {
  //       message.success({ title: '取消收藏成功' })
  //     } else {
  //       message.success({ title: '收藏成功' })
  //     }
  //   } catch (err: any) {
  //     message.error({ title: err.message })
  //   }
  // }

  // 点击显示更多操作
  const onMore = (e: React.MouseEvent<SVGElement, MouseEvent>, url: string, item: DrawVideo) => {
    e.stopPropagation();
    const _BoxHeigh = 150, _BoxWidth = 140;
    //@ts-ignore
    let { x, y } = e.target.getBoundingClientRect();
    if (e.nativeEvent.y + _BoxHeigh > window.innerHeight) {
      y = y - _BoxHeigh;
    } else {
      y = y + 15;
    }
    if (e.nativeEvent.x + _BoxWidth > window.innerWidth) {
      x = x - _BoxWidth;
    } else {
      x = x + 15;
    }
    setMorePosition({ x, y });
    moreEdit.current = item;
  }

  // 预览大图
  const onPreview = (item: DrawVideo, index: number) => {
    viewInfo.current = item;
    setInfoIndex(index);
    setViewSwiper(true);
  }

  const onDelete = async (id: string) => {
    openAlert({
      title: t('creation.IsDeleteVideoRecord'),
      content: t('creation.DeleteCantRecover'),
      status: 'warning',
      onOk: async () => {
        await deleteDrawVideo({ _id: id, type: pageType });
        closeAlert();
        message.success({ title: t('deleteSuccess') });
        getCreationHistory();
      }
    })
  }

  const onRetry = async (item: DrawVideo) => {
    try {
      await retryDrawVideo(item._id);
      getCreationHistory();
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  const onShare = async () => {
    if (!viewInfo.current) return
    try {
      await shareToSquare({
        _id: viewInfo.current._id || '',
        url: viewInfo.current.result_url || '',
        type: CreationType.GEN3,
        mediaType: 'video'
      });
      message.success({ title: '分享成功' });
    } catch (err: any) {
      message.error({ title: err.message })
    }
  }

  return <CreationContent.History>
    <Box className={styles.historyTitle}>
      <Text className={styles.text}>{t('history')}</Text>
    </Box>
    <ScrollBarBox className={styles.listBox}>
      {
        list.map((item, _index) => {
          return <Box key={item._id} className={`${styles.historyItem} ${styles.historyItemGen3}`}>
            <CreationInfo.CreationTitle
              type={pageType}
              extendsType={item.prompt_img ? 'imgToVideo' : 'textToVideo'}
            />
            <RenderVideo
              item={item}
              onMore={(e, url) => onMore(e, url, item)}
              onView={(index) => onPreview(item, index)}
              onReDraw={() => onRetry(item)}
              onDelete={() => onDelete(item._id)}
            />
            <Box className={styles.bottomInfo}>
              <Box className={styles.infoBox}>
                <CreationInfo.Calendar time={item.task_creation_time} />
                <CreationInfo.DrawType drawType={item.drawType || ''} />
              </Box>
              <Box className={styles.infoBox}>
                <CreationInfo.VideoLength length={item.video_length || '5'} />
                <CreationInfo.Price isVip={item.isVip} price={Math.floor(Number(item.video_length) * 10)} />
              </Box>
            </Box>
          </Box>
        })
      }
      {
        list.length % 3 !== 0 && <Box w='160px' visibility='hidden'></Box>
      }
      {
        total > pageSize && <WisePage pageNum={pageNum} pageSize={pageSize} total={total} onPageChange={setPageNum} />
      }
      {
        !list.length ? <Flex className={styles.fullbox}>
          <Icon as={require(`@/assets/svg/toolsicon/nofound.svg`).ReactComponent} />
          <Text className={styles.prompt}>{t('NoHistory')}</Text>
        </Flex> : null
      }
    </ScrollBarBox>
    {
      morePosition && <Box ref={editRef} className={styles.moreEdit} style={{ left: morePosition.x, top: morePosition.y }}>
        <Box
          className={styles.moreItem}
          onClick={() => moreEdit.current?.result_url && download(moreEdit.current?.result_url, viewInfo.current?.prompt_img ? 'imgToVideo' : 'textToVideo')}>
          <Icon className={styles.icon} as={require('@/assets/svg/creation_download.svg').ReactComponent} />
          {t('download')}
        </Box>
        <Box className={styles.moreItem} onClick={() => {
          if (moreEdit.current) {
            usePrompt(moreEdit.current);
          }
        }}>
          <Icon className={styles.icon} as={require('@/assets/svg/creation_redraw.svg').ReactComponent} />
          {t('creation.Redraw')}
        </Box>
        <Box className={`${styles.moreItem} ${styles.del}`} onClick={() => onDelete(moreEdit.current?._id || '')}>
          <Icon className={styles.icon} as={require('@/assets/svg/creation_delete.svg').ReactComponent} />
          {t('delete')}
        </Box>
      </Box>
    }
    <CreationPreview
      imgList={[viewInfo.current?.result_url || '']}
      imgIndex={infoIndex || 0}
      isOpen={viewSwiper}
      onClose={() => setViewSwiper(false)}
      userAvatar={userInfo?.avatar || ''}
      userName={userInfo?.nickName || userInfo?.wiseNickName || ''}
      drayInfo={{
        id: viewInfo.current?._id || '',
        type: viewInfo.current?.drawType || '',
        avatar: styleList.find(style => style.name === viewInfo.current?.drawType)?.view_image || '',
      }}
      isFavorite={false}
      prompt={viewInfo.current?.prompt_text || viewInfo.current?.cnPrompt || ""}
      onDownload={download}
      sizeScal={viewInfo.current?.sizeScal || ''}
      imgToImg={{ url: viewInfo.current?.prompt_img || '', weight: -1 }}
      createTime={viewInfo.current?.task_creation_time}
      onChangeIndex={setInfoIndex}
      onSameDraw={() => {
        viewInfo.current && usePrompt(viewInfo.current);
        setViewSwiper(false);
      }}
      onShare={onShare}
      type={pageType}
      extendsType={viewInfo.current?.prompt_img ? 'imgToVideo' : 'textToVideo'}
      isVideo
    />
  </CreationContent.History>
})