import { Box, Flex, Grid, GridItem, Icon, Image, Table, Tbody, Td, Text, Textarea, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from './index.module.scss'
import { chatStore, ChatType } from "@/store/chat";
import { observer } from "mobx-react-lite";
import { ScrollBarBox } from "@/components/ScrollBox";
import { useMessage } from "@/hooks/useMessage";
import { personalStore } from "@/store/personal";
import { CModal } from "@/components/Modal";
import DatePicker from "@/components/DatePicker";
import dayjs from "dayjs";
import { userStore } from "@/store/user";
import { WiseImage } from "@/components/Image";
import { CButton } from "@/components/Button";
import { getAndroidSystemVipConfig, getRechargeData } from "@/api/personal";
import { useQuery } from "@tanstack/react-query";
import { getPayCode } from "@/api/user";

function transformData(data: any) {
  return data.map((categoryObj: any) => {
    const category = Object.keys(categoryObj)[0]; // 获取类别名
    const models = categoryObj[category]; // 获取模型数组
    const transformedModels = models.map((modelEntry: any) => {
      const modelName = Object.keys(modelEntry)[0];
      const memberStatus = modelEntry[modelName];
      return { name: modelName, member: memberStatus === "免费" ? "免费" : "消耗点数", nonMember: '消耗点数' };
    });
    return { category: category, models: transformedModels };
  });
}

const VipContent: FC = () => {
  const { currentType } = chatStore
  const { userInfo } = userStore;
  const { t } = useTranslation();
  const { payVipOrders, getVipPayOrders, checkPayResult } = personalStore;
  const message = useMessage();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState<[Date | null, Date | null]>([null, null]);
  const [select, setSelect] = useState<any>();
  const [selectid, setSelectid] = useState<any>();
  const [recharges, setrecharges] = useState<any>({});
  const [transformedData, setTransformedData] = useState<any[]>([]);
  const [contentData, setContentData] = useState<any[]>([]);
  const [inputOrderType, setInputOrderType] = useState('Vip');
  const [payopen, setPayOpen] = useState(false);
  const [openRecharge, setOpenRecharge] = useState<boolean>(false)
  // const [selectedIndex, setSelectedIndex] = useState(recharges[0]);
  const [selectedIndex, setSelectedIndex] = useState();
  const [isChecked, setIsChecked] = useState(true);
  const [czfwxyOpen, setCzfwxyOpen] = useState(false); // 用户协议
  const [payId, setPayId] = useState('');
  const [ewmloading, setEwmLoading] = useState(false);
  const isWap = /mobile/i.test(navigator.userAgent)
    || /android/i.test(navigator.userAgent)
    || /iphone/i.test(navigator.userAgent)
    || /ipad/i.test(navigator.userAgent);
  const handleCheckboxChange = (event: any) => {
    setIsChecked(event);
    // setIsChecked(event.target.checked);
  };
  useEffect(() => {
    const fetchSignInDates = async () => {
      try {
        const data = await getAndroidSystemVipConfig();
        setContentData(data.content);
        setTransformedData(transformData(data.rights));
        const recharge = await getRechargeData();
        setrecharges(recharge.expand.price);
        if (recharge) {
          setSelectid(recharge._id)
          setSelect(recharge.expand.price[0] || null);
        }
      } catch (error) {
        console.error('Failed to fetch recharge data:', error);
      }
    };

    fetchSignInDates();
  }, []);
  const formatDate = (date: Date) => {
    return dayjs(date).format('YYYY-MM-DD');
  };
  useEffect(() => {
    const fetchData = async () => {
      if (range[0] !== null && range[1] !== null) {
        const time1 = formatDate(range[0])
        const time2 = formatDate(range[1])
        await getVipPayOrders(time1, time2);
      } else {
        await getVipPayOrders();
      }
    }
    fetchData()
  }, [range]);
  const newPayOrders = payVipOrders.map(order => ({
    ...order,
    points: order.price * 100,
    formattedCreateTime: dayjs(order.createTime).format('YYYY-MM-DD HH:mm:ss')
  }));
  const handleRefreshPayOrder = useCallback(
    async (payId: string) => {
      setLoading(true);
      try {
        const data = await checkPayResult(payId);
        message.warning({ title: data === null ? "订单已关闭" : data })
      } catch (error: any) {
        message.warning({ title: error?.message })
        console.log(error);
      }
      setLoading(false);
    },
    [setLoading, message]
  );
  useEffect(() => {
    if (openRecharge) {
      setPayId('');
      setLoading(false);
      let pay = document.getElementById('payQRCode');
      if (pay) {
        pay.innerHTML = ''
      }
      handleClickPay(select.payPrice, select.type)
    }
  }, [select])
  const onclickCopy = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      message.success({ title: t('CopySuccess') })
    } catch (err) {
      message.error({ title: t('CopyFaild') })
    }
  };
  const handleClickPay = useCallback(async (price: any, type: any) => {
    if (loading) {
      return;
    }
    if (!isChecked) {
      message.warning({ title: '请先勾选充值协议！' });
      setLoading(false);
      return;
    }
    setOpenRecharge(true);
    setEwmLoading(true)
    try {
      // @ts-ignore
      const res = await getPayCode(price, inputOrderType, selectid, type);
      setPayId(res.payId);
      await new Promise(resolve => setTimeout(resolve, 500));
      const qrCodeContainer = document.getElementById('payQRCode');
      if (!qrCodeContainer) return;
      qrCodeContainer.innerHTML = '';
      new QRCode(qrCodeContainer, {
        text: res.codeUrl,
        width: 128,
        height: 128,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: 2
      });
    } catch (err: any) {
      console.log(err);
      message.error({ title: err?.message || '生成错误' });
    }
    setEwmLoading(false)
  }, [select, isWap, inputOrderType, payId]);
  useQuery(
    [payId, setLoading],
    () => {
      if (!payId) return null;
      return checkPayResult(payId);
    },
    {
      enabled: !!payId,
      refetchInterval: 3000,
      onSuccess(res) {
        if (!res) return;
        if (res.toString() === '订单已结算') {
          setLoading(false);
          message.success({ title: '充值成功' });
          setPayOpen(false);
          setPayId('');
        }
      }
    }
  );
  return (
    <Box className={styles.content}>
      {currentType === ChatType.AI ?
        <Box className={styles.allvipBox}>
          <ScrollBarBox >
            <Box className={userInfo?.monVip ? styles.vipinfo : styles.novip}>
              <Box className={styles.vipbox}>
                <WiseImage className={styles.avatar} src={userInfo?.avatar} />
                <Box>
                  <Text className={styles.titletext}>
                    {userInfo && userInfo.wiseNickName ? userInfo.wiseNickName : '文思用户'}
                  </Text>
                  <Text className={styles.desctext}>
                    {userInfo?.monVip ?
                      `${new Date(userInfo?.limit.vipPayList[userInfo?.limit.vipPayList.length - 1]?.endTime).toLocaleDateString()}日到期`
                      : '会员权益尚未开通'}
                  </Text>
                </Box>
              </Box>
              <CButton className={styles.openbutton} onClick={() => setPayOpen(true)}>{userInfo?.monVip ? '续费会员' : '开通会员'}</CButton>
            </Box>
            <Flex className={styles.fatherbox}>
              <Text className={styles.boxtext}>{t('benefits')}</Text>
              <Flex className={styles.listbox}>
                {contentData.map((item, index) => {
                  return (
                    <Flex key={index} className={styles.rightbox}>
                      <Flex className={styles.iconbox}>
                        <Image src={item.icon} />
                      </Flex>
                      <Flex flexDir={'column'} className={styles.rightContentBox}>
                        <Text className={styles.rightTitles}>{item.title}</Text>
                        <Text className={styles.rightcontents}>{item.desc}</Text>
                      </Flex>
                    </Flex>
                  )
                })}
              </Flex>
            </Flex>
            <Flex className={styles.fatherbox}>
              <Text className={styles.boxtext}>会员权益说明</Text>
              <Flex>
                <Table className={styles.table}>
                  <Thead>
                    <Tr>
                      <Th colSpan={2}>模型/详情</Th>
                      <Th className={styles.viptext}>
                        会员
                        <Icon className={styles.vipBadge} as={userInfo?.monVip ? require('@/assets/svg/viptags.svg').ReactComponent : require('@/assets/svg/notviptags.svg').ReactComponent} />
                      </Th>
                      <Th>非会员</Th>
                    </Tr>
                  </Thead>
                  {transformedData && transformedData.map((category: any, index: any) => (
                    <Tbody key={index} className={styles.categoryRow}>
                      {category.models && category.models.map((model: any, index: any) => (
                        <Tr key={model.member + index}>
                          {index === 0 && <Td className={styles.boldtext} rowSpan={category.models.length + 1}>{category.category}</Td>}
                          <Td className={styles.notviptext}>{model.name}</Td>
                          <Td className={styles.viptext}>{model.member}</Td>
                          <Td className={styles.notviptext}>{model.nonMember}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  ))}
                </Table>
              </Flex>
            </Flex>
          </ScrollBarBox >
        </Box>
        :
        <Box className={styles.parent}>
          <Box className={styles.child}>
            <Flex className={styles.selectDate} onClick={() => setOpen(true)}>
              <Text>{range[0] ? formatDate(range[0]) : '开始时间'}</Text>
              <Text mx={2}> —— </Text>
              <Text>{range[1] ? formatDate(range[1]) : '结束时间'}</Text>
            </Flex>
            <ScrollBarBox className={styles.listbox}>
              {newPayOrders.length > 0 ? newPayOrders.map((item, index) => {
                const icon = (() => {
                  switch (item.status.toString()) {
                    case 'NOTPAY':
                      return 'wait_for_payment';
                    case 'CLOSED':
                      return 'closed';
                    case 'SUCCESS':
                      return 'points_increase';
                    default:
                      return 'wait_for_payment';
                  }
                })();
                const statusDesc = (() => {
                  switch (item.status.toString()) {
                    case 'NOTPAY':
                      return t('Wait_for_payment');
                    case 'CLOSED':
                      return t('Closed');
                    case 'SUCCESS':
                      return t('Points_increase');
                    default:
                      return t('Unknown_status');
                  }
                })();
                const statusColor = (() => {
                  switch (item.status.toString()) {
                    case 'NOTPAY':
                      return 'billsboxawait'
                    case 'CLOSED':
                      return 'billsboxfail'
                    case 'SUCCESS':
                      return 'billsboxsucceed'
                    default:
                      return 'billsboxfail';
                  }
                })();
                const paysInfo = (() => {
                  switch (item.vipType) {
                    case 'month':
                      return '文思逸言会员*单月'
                    case 'season':
                      return '文思逸言会员*季月'
                    case 'year':
                      return '文思逸言会员*全年'
                    default:
                      return '文思逸言会员*单月';
                  }
                })();
                return (
                  <Box className={styles.itmebox} key={index}>
                    <Flex justify={'space-between'} pos={'relative'}>
                      <Flex className={styles.billbox}>
                        {/* <Icon as={require(`@/assets/svg/${icon}.svg`).ReactComponent} className={styles.billsboxicon} /> */}
                        <Icon as={require(`@/assets/svg/viphistory.svg`).ReactComponent} className={styles.billsboxicon} />
                        <Text className={styles.billstitle}>会员充值</Text>
                      </Flex>
                      <Flex>
                        <Text color={item.status === 'SUCCESS' ? '#333' : 'rgba(153, 153, 153, 0.5)'} className={styles.billsboxprices}>￥{item.price}</Text>
                        <Text className={styles[statusColor]}>{statusDesc}</Text>
                        {item.status === 'NOTPAY' && (
                          <Flex className={styles.paysbutton}
                            onClick={() => handleRefreshPayOrder(item._id)}
                          >更新</Flex>
                        )}
                      </Flex>
                    </Flex>
                    <Flex className={styles.textbox}>
                      {/* <Text className={styles.fasttext}>{t('Points_increase')}：{item.points}</Text> */}
                      <Text className={styles.fasttext}>{t('Time')}：{item.formattedCreateTime}</Text>
                      <Text className={styles.endtext}>详情：{paysInfo}</Text>
                    </Flex>
                    <Flex className={styles.textbox}>
                      <Text className={styles.endtext}>{t('Order_number')}：{item.orderId}</Text>
                      <Icon as={require(`@/assets/svg/vipOrderCopy.svg`).ReactComponent} onClick={() => onclickCopy(item.orderId)} />
                    </Flex>
                  </Box>
                )
              }) :
                <Box className={styles.noChat}>
                  <Image src={require('@/assets/png/no_chat.png')} />
                  <Text>暂无记录</Text>
                </Box>
              }
            </ScrollBarBox>
          </Box>
          <CModal isOpen={open} onClose={() => setOpen(false)}>
            <DatePicker range={range} setRange={setRange} onClose={() => setOpen(false)} />
          </CModal>
        </Box>
      }
      <CModal isOpen={payopen} onClose={() => { setPayOpen(false); setOpenRecharge(false) }}>
        <Flex className={styles.cmodelbox}>
          <Flex className={styles.cmodel_closebox}>
            <Text>会员套餐</Text>
            <Icon className={styles.cmodel_close} onClick={() => { setPayOpen(false); setOpenRecharge(false) }} as={require(`@/assets/svg/cModelClose.svg`).ReactComponent} />
          </Flex>
          <Flex className={styles.rechargesBox}>
            {recharges.length > 0 && recharges.map((recharge: any, index: any) => {
              return (
                <Flex mr={'20px'} key={index} className={`${styles.rechargebox} ${select && select.payPrice === recharge.payPrice ? styles.selected : ''}`}
                  onClick={() => setSelect(recharge)}>
                  <Text className={styles.rechargeTitle}>{recharge.name}</Text>
                  <Text className={styles.rechargeNewprice}>{recharge.payPrice}<span>元</span></Text>
                  <Text className={styles.rechargePrice}>￥{recharge.price}元</Text>
                  {recharge.tag && (
                    <Flex className={styles.rechargeTagBox}>
                      <Text>{recharge.tag}</Text>
                    </Flex>
                  )}
                </Flex>
              )
            })}
          </Flex>
          {!openRecharge ?
            <Flex className={styles.modelbuttonbox}>
              <CButton className={styles.Savebutton}
                onClick={() => { handleClickPay(select.payPrice, select.type) }}
              >
                <Icon as={require('@/assets/svg/personalWechat.svg').ReactComponent} />
                获取微信支付二维码
              </CButton>
              <Box className={styles.checkbox_custom}>
                <Box onClick={() => handleCheckboxChange(!isChecked)} className={styles.checkbox_container}>
                  <Box className={`${styles.checkbox_customs} ${isChecked ? styles.checked : ''}`}>
                    <Icon as={require('@/assets/svg/confirm.svg').ReactComponent} />
                  </Box>
                </Box>
                <Text>我已阅读并同意</Text>
                <Text className={styles.agreement_text} onClick={() => { setCzfwxyOpen(true); setPayOpen(false) }}>
                  《文思逸言会员服务协议》
                </Text>
                <Text>我已阅读并同意</Text>
                {/* <Text className={styles.agreement_text} onClick={() => { setCzfwxyOpen(true); setPayOpen(false) }}>
                      《文思逸言会员自动续费服务协议》
                    </Text> */}
              </Box>
            </Flex>
            :
            <Flex alignItems={'center'} pos={'relative'}>
              {ewmloading && (
                <Icon className={styles.loadicon} as={require('@/assets/svg/creation_loading.svg').ReactComponent} />
              )}
              <Box id="payQRCode" className={styles.payimage} display="inline-block"></Box>
              <Box>
                <Text className={styles.titletext}>{select.payPrice}.00<span>元</span></Text>
                <Box className={styles.payimgbox}>
                  <Icon as={require('@/assets/svg/wx_circle.svg').ReactComponent} />
                  <Text>请使用微信扫码支付</Text>
                </Box>
              </Box>
            </Flex>
          }
        </Flex>
      </CModal>
    </Box>
  );
};


export default observer(VipContent);