import { CreationType } from "@/store/creation";
import { DELETE, GET, POST } from "./request"

export const getCreationStyleConfig = (subType: CreationType.MJ | CreationType.SD | CreationType) => {
  return GET<StyleConfig[]>('/config/drawConfiglist?subType=' + subType)
}

export const getCreationHistory = (data: GetCreationListParams) => {
  return POST<HistoryListResult>('/user/draw/list', { data })
}

/* 获取视频列表， 单次获取全部视频 */
export const getDrawVideos = (page: RequestPaging) =>
  POST<PagingData<DrawVideo> & { favorites: DrawFavoriteType[] }>(`/user/draw/videolist`, page);

export const getLangtranslate = (lang: string) => POST<String>('/user/draw/translate', { lang })

/** 请求创建一个画图任务 */
export const postCreateDrawImage = (data: CreateCreationTaskParams) => {
  return POST<{ ret: string; message: string; userBalance: number; newId: string }>('/user/draw/create', data);
}

/** 请求创建一个视频任务 */
export const postCreateDrawGEN2Image = (data: CreateGen3TaskParams) =>
  POST<{ ret: string; message: string; userBalance: number; newId: string }>('/user/draw/createGEN2', data);


export const deleteCreation = (data: { type: CreationType, _id: string }) => POST<String>('/user/draw/delete', data)

/* 删除某一个视频 */
export const deleteDrawVideo = ({ _id, type }: { _id: string; type: string }) =>
  POST<DrawVideo[]>(`/user/draw/deleteVideo`, {
    _id,
    type
  });

/** 收藏绘画图片 */
export const collectionImg = (id: string, imgUrl: string, type: CreationType) => POST<Record<string, string[]>>('/user/draw/favorite', {
  id, imgUrl, type
});


/** 获取图片广场列表 */
export const getShareImgs = (data: RequestPaging & { filter: SquareFilterType, mediaType?: MediaType | 'all' }) => {
  return POST<PagingData<CreationShareType>>(`/user/draw/square`, { data });
}

/** 删除广场列表图片 */
export const deleteShareImg = (_id: string) => {
  return POST<PagingData<CreationShareType>>(`/user/draw/deleteShare`, { _id });
}

export const getDallE3History = (data: RequestPaging) => {
  return GET<{ list: DrawDallE3Type[], total: number }>('/user/draw/getDallE3', data);
}

export const getDallE3Info = (id: string) => {
  return GET<{ list: DrawDallE3Type, favorites: DrawFavoriteType[] }>('/user/draw/dalle3Info?id=' + id,);
}

export const drawAsDallE3 = (data: { id?: string, prompt: string }) =>
  POST<{
    _id: string, drawId: string,
    contentCheck?: {
      status: boolean; riskLevel: 'high' | 'medium' | 'low' | ''; riskTips: string; riskWords: string; labels: string; // 风险标签
    },
    result: { revised_prompt: string; url: string, status: 'failed' | 'success' | 'delete' }[]
  }>('/user/draw/dalle3', data);

export const deleteDallE3Record = (id: string) => DELETE('/user/draw/deleteDallE3?id=' + id);

/** 失败重试 */
export const retryDraw = ({ _id, type }: { _id: string; type: string }) => {
  return POST<{ message: string; data: HistoryItem[] }>(`/user/draw/retry`, {
    _id,
    type
  });
}

/** 重试生成视频 */
export const retryDrawVideo = (_id: string) =>
  POST<{ message: string; data: DrawVideo[] }>(`/user/draw/retryVideo`, {
    _id
  });

/** 发布到广场 */
export const shareToSquare = (data: { _id: string, url: string, type: CreationType, mediaType: MediaType }) => {
  return POST<HistoryItem>(`/user/draw/share`, data);
}

/** 获取所有suno列表 */
export const getDrawSunoList = () => {
  return GET<DrawSunoSchema[]>(`/user/suno/getsunolist`);
}

/** 获取所有suno风格列表 */
export const getDrawSunoStyleList = () => {
  return GET<any>(`/user/suno/getsunostylelist`);
}

export const creatSuno = (
  {
    draw_type,
    instrumental_music,
    music_style,
    music_model,
    title,
    text,
    prompt,
  }: {
    draw_type: string;
    instrumental_music: boolean;
    music_style: string;
    music_model: string;
    title: string;
    text: string;
    prompt: string;
  }) =>
  POST(`/user/suno/creat`, {
    draw_type, instrumental_music, music_style, music_model, title, text, prompt
  });

export const creatSunoLyric = (
  {
    title,
  }: {
    title: string;
  }) =>
  POST(`/user/suno/creatlyric`, {
    type: 'gen_lyrics', title
  });

/** 获取当前生成的歌词 */
export const getDrawSunoLyric = ({
  _id,
}: {
  _id: string;
}) => {
  return POST<any>(`/user/suno/getsunolyric`, { _id });
}

/** 删除suno任务 */
export const deleteDrawSuno = ({
  _id,
}: {
  _id: string;
}) => {
  return POST<any>(`/user/suno/delete`, { _id });
}

/** 获取公开声音 */
export const getPublicVoiceList = () => {
  return GET<any>(`/user/userVoice/publicList`);
}

/** 获取个人声音 */
export const getUserVoiceList = () => {
  return GET<any>(`/user/userVoice/list`);
}

/** 生成新的个人声音 */
export const creatUserVoice = (
  {
    voiceCover,
    voiceText,
    voiceName,
    description,
    auditionFile
  }: {
    voiceCover: string;
    voiceText: string;
    voiceName: boolean;
    description: string;
    auditionFile: string;
  }) =>
  POST(`/user/userVoice/create`, {
    voiceCover, voiceText, voiceName, description, auditionFile
  });

/** 删除用户创建的声音 */
export const deleteUserVoice = ({
  _id,
}: {
  _id: string;
}) => {
  return POST<any>(`/user/userVoice/delete`, { _id });
}

/** 获取所有声音的历史记录 */
export const getCloneAudiosList = (page: RequestPaging) => {
  return POST<any>(`/user/cloneAudios/list`, page);
}

/** 生成声音克隆 */
export const creatCloneAudios = (
  {
    audiosUrl,
    audioPrompt,
    audioName,
    audioText,
    speed,
    audioId,
    audioCover,
    voiceText,
    notpublic
  }: {
    audiosUrl: string;
    audioPrompt: boolean;
    audioName: string;
    audioText: string;
    speed: number;
    audioId: string;
    audioCover: string;
    voiceText: string;
    notpublic: boolean
  }) =>
  POST(`/user/cloneAudios/create`, {
    audiosUrl, audioPrompt, audioName, audioText, speed, audioId, audioCover, voiceText, notpublic
  });

/** 删除克隆任务历史记录 */
export const deleteCloneAudio = ({
  _id,
}: {
  _id: string;
}) => {
  return POST<any>(`/user/cloneAudios/delete`, { _id });
}

/** 获取个人声音的试听音频 */
export const getexampleAudio = ({
  audioId,
}: {
  audioId: string;
}) => {
  return POST<any>(`/user/cloneAudios/exampleAudio`, { audioId });
}

/** 修改声音参数 */
export const updateCloneAudios = (
  {
    voiceCover,
    description,
    voiceName,
    _id,
  }: {
    voiceCover: string;
    description: boolean;
    voiceName: string;
    _id: string;
  }) =>
  POST(`/user/userVoice/update`, {
    _id, voiceCover, description, voiceName
  });

/** 克隆任务生成失败返还点数 */
export const failCloneAudio = () => {
  return POST<any>(`/user/cloneAudios/fail`);
}

/** 已读新声音 */
export const readAlreadyAudio = (
  {
    audiotype,
    _id,
  }: {
    audiotype: string;
    _id: string;
  }) => {
  return POST<any>(`/user/audio/readAlready`, { audiotype, _id });
}

export const getShareDetail = (id: string, type: 'image' | 'music' | 'video') => {
  return GET<ShareDetailResult>(`/user/share/detail?id=${id}&type=${type}`);
}

export const getCreationMenu = () => {
  return GET<CreationMenuConfig[]>(`/config/creationModuleslist`);
}

export const getCreationTask = (ids: string) => {
  return GET<Record<string, TaskStatusResult>>(`/user/draw/taskStatus`, { ids });
}