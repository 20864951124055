import { Box, Flex, Text, Textarea, Icon } from "@chakra-ui/react";
import { FC, useState } from "react";
import styles from './index.module.scss';
import { CButton } from "@/components/Button";
import { useTranslation } from "react-i18next";
import { personalStore } from "@/store/personal";
import { observer } from "mobx-react-lite";
import { useMessage } from "@/hooks/useMessage";
import { uploadFiles } from "@/utils/common";

export interface ImageItem {
  id: number;
  src: string | ArrayBuffer | null;
}

export const Suggestions: FC = observer(() => {
  const { t } = useTranslation();
  const { creatFeedback } = personalStore;
  const message = useMessage();
  const [images, setImages] = useState<ImageItem[]>([]);
  const [text, setText] = useState('');
  const [textPhone, setTextPhone] = useState('');
  const texthandleChange = (event: any) => {
    setText(event);
  };
  const textPhoenhandleChange = (event: any) => {
    setTextPhone(event);
  };
  const deleteImage = (id: any) => {
    setImages((images) => images.filter((image) => image.id !== id));
  };
  const handleFileChange = async (event: any) => {
    if (images.length >= 3) {
      // toast({
      //   title: '提示！最多只能上传3张图片',
      //   status: 'warning'
      // });
      return;
    }
    // 通过File API获取到所有选择的图片
    const files: File[] = Array.from(event.target.files);
    for (let file of files) {
      if (file) {
        const res = await uploadFiles(file);
        if (res) {
          setImages((prevState) => [
            ...prevState,
            { id: Date.now(), src: res[0] }
          ]);
        }
      }
    }
  };
  const confirm = async () => {
    if (text === '') {
      message.warning({ title: '请输入建议内容' })
      return;
    }
    try {
      const res = await creatFeedback(text, images, textPhone)
      if (res) {
        message.success({ title: '提交成功，感谢您的建议' })
      }
    } catch (err: unknown) {
      message.error({ title: (err as ErrorResponse).message })
    }
  }
  return (
    <Box className={styles.parent}>
      <Box className={styles.child}>
        <Text className={styles.tips}>
          {t('User_Suggestions')}
        </Text>
        <Flex className={styles.suggestions}>
          <Text className={styles.text}>{t('Suggestions')}*</Text>
          <Textarea className={styles.sugtextarea} value={text} onChange={(e) => texthandleChange(e.target.value)} />
        </Flex>
        <Flex className={styles.imgsbox}>
          <Text className={styles.text}>{t('Upload_image')}</Text>
          <Flex className={styles.imagesbox}>
            {images.length > 0 && (
              images.map((file) => (
                <Box className={styles.uploadBox} key={file.id}>
                  <img src={file.src as string} className={styles.previewImage} />
                  {/* <Icon onClick={() => deleteImage(file.id)} as={require(`@/assets/svg/personalClosed.svg`).ReactComponent} className={styles.uploadBoxicon} /> */}
                </Box>
              ))
            )}
            <Box className={styles.uploadBox} onClick={() => document.getElementById('file-input')!.click()}>
              <input
                type="file"
                accept=".jpeg,.jpg,.png,.heic,.heif,.webp,.bmp" // 只接受图片类型的文件
                onChange={handleFileChange}
                className={styles.fileUploadInput}
                id="file-input"
              />
              <Text className={styles.plusIcon} >+</Text>
            </Box>
          </Flex>
        </Flex>
        <Flex className={styles.phonebox}>
          <Text className={styles.text}>{t('Contact')}</Text>
          <Textarea className={styles.phonetextarea} value={textPhone} onChange={(e) => textPhoenhandleChange(e.target.value)} />
        </Flex>
        <CButton className={styles.cbutton} onClick={confirm}>
          {t('submit')}
        </CButton>
      </Box>
    </Box>
  )
})