import { Box, Flex, Icon } from "@chakra-ui/react"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import styles from './index.module.scss'
import { observer } from "mobx-react-lite";
import { Home } from './Home';
import { ToolList } from "./ToolList";
import { ToolSchema } from "@/@types/tools";
import { toolStore } from "@/store/tool";
import { ComTitle } from "./components/ComTitle";
import { ComButton } from "./components/ComButton";
import { ComComboBox } from "./components/ComBoBox";
import { Contentpreview } from "./components/ContentPreview";
import { ComTextarea } from "./components/ComTextarea";
// import { ComImgSelect } from "./components/ComImgSelect";
import { ComTags } from "./components/ComTags";
import { ComDoubleTags } from "./components/ComDoubleTags";
import { ComImprove } from "./components/ComImprove";
import { userStore } from "@/store/user";
import { useMessage } from "@/hooks/useMessage";
import { streamFetch } from "@/utils/stream";
import { throttle } from "lodash";
import { Filmtranslations } from "./Filmtranslations";
import { Langtranslations } from "./Langtranslations";
import { VideoSummary } from "./VideoSummary";
import { MacScrollbar } from "mac-scrollbar";
import { personalStore } from "@/store/personal";
import { WechatRobot } from "./WechatRobot";
import WritingAssistant from "./WritingAssistant";
import { ComImageSelect } from "./components/ComImageSelect";
import { MarkmapClass } from "./MarkmapClass";
import { Tarot } from "./Tarot";
import { gettoolhis } from "@/api/tools";
import { WiseImage } from "@/components/Image";
import { NaMing } from "./NaMing";
import i18n from "@/utils/i18n";
import { useTranslation } from "react-i18next";
import { AIppts } from "./AIppt";
import { uiStrore } from "@/store/ui";

type ValuesType = {
  [key: string]: any; // 这允许values对象具有任何类型的属性
};
export const _Models = [
  { name: '文思GPT 4o-mini', key: 'gpt-4o-mini', tips: '(理解能力强、回答简洁)', tag: '限时免费' },
  { name: '文思GPT 4o-128k', key: 'gpt-4o', tips: '(生成质量好、理解能力优秀)', price: '100' }
]

const Tools: FC = () => {
  const [toolslist, SetToolsList] = useState<ToolSchema[]>()
  const [caseStop, SetcaseStop] = useState(true);
  const [isContent, setIsContent] = useState(false);
  const bindGenPrompt = useRef<HTMLTextAreaElement>(null);
  const textareabindGenPrompt = useRef<HTMLTextAreaElement>(null);
  const [toolInputs, setToolInputs] = useState<{ [toolId: string]: { [inputKey: string]: string } }>({});
  const [toolSelects, setToolSelects] = useState<{ [toolId: string]: { [selectKey: string]: string } }>({});
  const [toolImgSelects, setToolImgSelects] = useState<{ [toolId: string]: { [selectKey: string]: string } }>({});
  const [toolSelectsnums, setToolSelectsNums] = useState<{ [toolId: string]: { [selectKey: string]: string[] } }>({});
  const [textareavalue, setTextareaValue] = useState<{ [toolId: string]: { [selectKey: string]: string[] } }>({});
  const [comboBox, setComboBox] = useState<{ [toolId: string]: { [selectKey: string]: string } }>({});
  const { getToollist, tools, allToolsHistory, createToolHistory, getAllToolsHistory, deleteToolsHistory, getToolModel,
    toolShowNav, setToolShowNav, setCurrentTool, selectTool, SetSelectTool } = toolStore;
  const textareaRef = useRef<any>(null);
  const previousCursorPosition = useRef<number | null>(null);
  const [isselectvalue, SetIsSelectValue] = useState(true);
  const { userInfo } = userStore;
  const { language } = uiStrore;
  const { handleSuccess } = personalStore;
  const [choosesModel, setChoosesModel] = useState(false);
  const message = useMessage();
  const [loading, setLoading] = useState(false);
  const [modelkey, setModelsKey] = useState(_Models[1])
  const [buttonpd, setButtonpd] = useState(true);
  const [selectedTag, setSelectedTag] = useState('热门');
  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      await getToollist();
    }
    fetchData();
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      SetRiskInfo({})
      // await getToollist();
      tools.map((item) => {
        if (item.assembly.improve.length > 0) {
          item.assembly.improve.map((it) => {
            setTextareaValue((prevInputs: any) => ({
              ...prevInputs,
              [item._id]: {
                ...prevInputs[item._id], // 拷贝该工具先前的输入对象，保留其他键值对
                [it.textvalue]: language === 'zh' ? it.improvevalues : it.improvevalues_more_language ? it.improvevalues_more_language.en : '' // 更新传入的键的值
              }
            }));
          })
        }
      })
      SetToolsList(tools);
    };
    fetchData();
  }, [tools]);
  const handleFocus = () => {
    if (previousCursorPosition.current !== null) {
      textareaRef.current.selectionStart = previousCursorPosition.current;
      textareaRef.current.selectionEnd = previousCursorPosition.current;
    }
  };
  const handleBlur = () => {
    previousCursorPosition.current = textareaRef.current.selectionStart;
  };
  useEffect(() => {
    if (textareaRef.current && previousCursorPosition.current !== null) {
      textareaRef.current.focus();
    }
  }, []);
  const handleValueChange = (toolId: any, improveKey: any, value: any) => {
    SetcaseStop(true);
    setTextareaValue(prevInputs => ({
      ...prevInputs,
      [toolId]: {
        ...prevInputs[toolId], // 拷贝该工具先前的输入对象，保留其他键值对
        [improveKey]: value ? value.target ? value.target.value : value : value // 更新传入的键的值
      }
    }));
  };
  // 当文本输入发生变化
  const handleTextInputChange = (toolId: any, inputKey: any, value: any) => {
    SetcaseStop(true);
    setToolInputs(prevInputs => ({
      ...prevInputs,
      [toolId]: {
        ...prevInputs[toolId], // 拷贝该工具先前的输入对象，保留其他键值对
        [inputKey]: value // 更新传入的键的值
      }
    }));
  };
  // 当选择变化
  const handleSelectChange = (toolId: any, selectKey: any, value: any, select?: any, allSelects?: any[]) => {
    SetcaseStop(true);
    setToolSelects(prevState => ({
      ...prevState,
      [toolId]: {
        ...(prevState[toolId] || {}),
        [selectKey]: value
      }
    }));

    allSelects?.map((item: any) => {
      if (item.texttitle.includes(selectKey)) {
        const parentSelect = item.selects.find((s: any) => s.parents === value);
        if (parentSelect) {
          setToolSelects(prevState => ({
            ...prevState,
            [toolId]: {
              ...(prevState[toolId] || {}),
              [item.textvalue]: parentSelect.text
            }
          }));
        }
      }
    })
  };
  // 当选择变化
  const handleImgSelectChange = (toolId: any, selectKey: any, value: any) => {
    SetcaseStop(true);
    setToolImgSelects(prevState => ({
      ...prevState,
      [toolId]: {
        ...(prevState[toolId] || {}),
        [selectKey]: value
      }
    }));
  };
  // 当选择变化
  const handleComboBoxChange = (toolId: any, selectKey: any, value: any) => {
    SetcaseStop(true);
    setComboBox(prevState => ({
      ...prevState,
      [toolId]: {
        ...(prevState[toolId] || {}),
        [selectKey]: value
      }
    }));
  };

  const handleSelectsNumChange = (toolId: any, textvalue: any, selectedItem: any, clear?: any) => {
    SetcaseStop(true);
    setToolSelectsNums((prevSelects) => {
      const newSelects = { ...prevSelects };
      if (!newSelects[toolId]) {
        newSelects[toolId] = {};
      }
      if (!newSelects[toolId][textvalue]) {
        newSelects[toolId][textvalue] = [];
      }
      if (clear === 'clear') {
        newSelects[toolId][textvalue] = []
      }
      if (!Array.isArray(newSelects[toolId][textvalue])) {
        newSelects[toolId][textvalue] = []
      }
      if (selectedItem && selectedItem.includes(',')) {
        const newItems = selectedItem.split(',');
        newItems.forEach((item: any) => {
          const index = newSelects[toolId][textvalue].indexOf(item);
          if (index > -1) {
            newSelects[toolId][textvalue].splice(index, 1);
          } else {
            newSelects[toolId][textvalue].push(item);
          }
        });
      } else {
        const index = newSelects[toolId][textvalue].indexOf(selectedItem);
        if (index > -1) {
          newSelects[toolId][textvalue].splice(index, 1);
        } else {
          newSelects[toolId][textvalue].push(selectedItem);
        }
      }
      return newSelects;
    });
  };

  useEffect(() => {
    //设置用户最后使用的工具
    // onCloseSlider();
    // setLastselectToolTag(selectTool);
    SetRiskInfo({});
    setChoosesModel(false);
    toolslist?.map((item) => {
      if (item.assembly.link === selectTool) {
        if (item.assembly.inputs.length > 0) {
          item.assembly.inputs.map((input) => {
            handleTextInputChange(item._id, input.textvalue, '');
          })
        }
        if (item.assembly.selects.length > 0) {
          item.assembly.selects.map((select) => {
            handleSelectChange(item._id, select.textvalue, select.selects[0].text);
          })
        }
        if (item.assembly.selectsnums.length > 0) {
          item.assembly.selectsnums.map((selectsnum) => {
            handleSelectsNumChange(item._id, selectsnum.textvalue, ['']);
          })
        }
        if (item.assembly.imgselect.length > 0) {
          item.assembly.imgselect.map((imgselect) => {
            handleImgSelectChange(item._id, imgselect.textvalue, imgselect.selects[0].value);
          })
        }
        if (item.assembly.comboBox.length > 0) {
          item.assembly.comboBox.map((comboBox) => {
            handleComboBoxChange(item._id, comboBox.textvalue, language === 'zh' ? [comboBox.selects[0].text] : [comboBox.selects[0].text_more_language ? comboBox.selects[0].text_more_language.en : '']);
          })
        }
        if (item.assembly.improve.length > 0) {
          item.assembly.improve.map((improve) => {
            handleValueChange(item._id, improve.textvalue, language === 'zh' ? improve.improvevalues : improve.improvevalues_more_language ? improve.improvevalues_more_language.en : '');
          })
        }
      }
    })
    setCurrentTool(selectTool);
    return () => {
      setCurrentTool('');
    }
  }, [selectTool]);
  const scrollToBottom = useCallback((behavior: 'smooth' | 'auto' = 'smooth') => {
    if (!bindGenPrompt.current) return;
    bindGenPrompt.current.scrollTo({
      top: bindGenPrompt.current.scrollHeight,
      behavior
    });
  }, []);
  const generatingMessage = useCallback(
    throttle(() => {
      if (!bindGenPrompt.current) return;
      const isBottom =
        bindGenPrompt.current.scrollTop + bindGenPrompt.current.clientHeight + 150 >=
        bindGenPrompt.current.scrollHeight;
      isBottom && scrollToBottom('auto');
    }, 100),
    []
  );
  const delay = (ms: number) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve('');
      }, ms);
    });
  // 中断请求
  const controller = useRef(new AbortController());
  const [isnullcontent, SetIsNullContent] = useState(true);
  const [riskInfo, SetRiskInfo] = useState<any>();
  const [nullcontent, SetNullContent] = useState('');
  useEffect(() => {
    const checkRequiredFields = async () => {
      if (!selectTool) { return }
      const datafindToolBySelectTool = () => {
        if (!toolslist) { return }
        return toolslist.find((tool: any) => tool.assembly.link === selectTool);
      }
      const tool = datafindToolBySelectTool()
      if (!tool) { return }
      const inputs = toolInputs[tool._id];
      const tool_inputs = tool.assembly.inputs;
      const isrequired_tools = tool_inputs.map((m) => {
        if (m.isrequired) return m;
      });
      const Required_isNull = isrequired_tools.find((m) => {
        if (m && (!inputs || !inputs[m.textvalue] || inputs[m.textvalue] === '')) {
          return m;
        }
      });

      if (Required_isNull) {
        SetIsNullContent(true);
      } else {
        SetIsNullContent(false);
      }
    };

    checkRequiredFields();
  }, [toolInputs, selectTool])

  const collectAndProcessValues = async (tool: any) => {
    setButtonpd(false);
    setIsContent(true);
    if (bindGenPrompt.current) {
      bindGenPrompt.current.value = '';
    }
    if (userInfo && !userInfo.monVip && userInfo.balance <= 0 && modelkey.name === _Models[1].name) {
      message.warning({ title: t('ToolinsufficientBalance') })
      return;
    }
    const inputs = toolInputs[tool._id];
    const selects = toolSelects[tool._id];
    const selecteds = toolSelectsnums[tool._id];
    const imgselects = toolImgSelects[tool._id];
    const textareavalues = textareavalue[tool._id];
    const combobox = comboBox[tool._id];
    let template = tool.assembly.usercontent;
    let systemcontent = tool.assembly.systemcontent;
    let values: ValuesType;
    if (template.includes('typecontent')) {
      values = { ...inputs, ...selects, ...selecteds, ...combobox, ...imgselects, typecontent: textareaRef.current.value };
    } else {
      values = { ...inputs, ...selects, ...selecteds, ...textareavalues, ...combobox, ...imgselects };
    }
    const tool_inputs = tool.assembly.inputs;
    const isrequired_tools = tool_inputs.map((m: any) => { if (m.isrequired) return m })
    const Required_isNull = isrequired_tools.find((m: any) => {
      if (m && (!inputs || !inputs[m.textvalue] || inputs[m.textvalue] == '')) {
        return m
      }
    })
    if (Required_isNull) {
      SetIsNullContent(true);
      SetNullContent(Required_isNull.texttitle)
      message.warning({ title: t('Fillin') + Required_isNull.texttitle + t('Tryagain') })
      return;
    }

    template = template.replace(/\.?\s*\${(.*?)}/g, (_: any, key: any) => {
      return key in values ? values[key] : '无';
    });
    systemcontent = systemcontent.replace(/\.?\s*\${(.*?)}/g, (_: any, key: any) => {
      return key in values ? values[key] : '无';
    });
    const model = modelkey.key;
    const abortSignal = new AbortController();
    controller.current = abortSignal;
    let concatenatedString = '';
    for (let key in inputs) {
      concatenatedString += key + ':' + inputs[key] + '/';
    }
    for (let key in selects) {
      concatenatedString += key + ':' + selects[key] + '/';
    }
    for (let key in selecteds) {
      concatenatedString += key + ':' + selecteds[key] + '/';
    }
    for (let key in imgselects) {
      concatenatedString += key + ':' + imgselects[key] + '/';
    }
    for (let key in combobox) {
      concatenatedString += key + ':' + combobox[key] + '/';
    }
    for (let key in textareavalues) {
      concatenatedString += key + ':' + textareavalues[key] + '/';
    }

    concatenatedString = concatenatedString.slice(0, -1);
    setLoading(true);
    // 流请求，获取数据
    try {
      const ret = await streamFetch({
        data: {
          messages: [
            {
              content: systemcontent,
              role: 'system'
            },
            {
              content: template,
              role: 'user'
            }
          ] as any[],
          chatId: '',
          stream: true,
          appId: await getToolModel('AIWrite'),
          model: model,
          toolParams: {
            toolsid: tool._id,
            toolsname: tool.name,
            userinput: concatenatedString,
            improve: "",
            model: model,
            template: template,
          }
        },
        onMessage: (text: string) => {
          if (!bindGenPrompt.current) return;
          let newText = text.replace(/```__PRICE__:.*?__PRICE__END__```/g, "");
          bindGenPrompt.current.value = bindGenPrompt.current.value + text;
          setIsContent(false);
          generatingMessage();
          if (!textareabindGenPrompt.current) return;
          textareabindGenPrompt.current.value = bindGenPrompt.current.value;
        },
        abortSignal
      });
      // 等待输出内容结束
      abortSignal.signal.aborted && (await delay(500));
      const res = await gettoolhis({ _id: ret.newChatId })
      if (res) {
        SetRiskInfo(res.contentCheck)
        if (bindGenPrompt.current && bindGenPrompt.current.value.length === 0) {
          const riskValue = res[0].contentCheck?.status !== false ? res[0].askCheck?.status !== false ? res[0].contents : (`${t("creation.TextMayContain")}${res[0].askCheck.labels?.split(',').map((str: any) => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')}${t('creation.NoConsume')} ` || t('NoConsume'))
            : (`${t("ContentMayContains")}:${res[0].contentCheck.labels?.split(',').map((str: any) => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')} ` || t('ContentRiskBlocked'))
          bindGenPrompt.current.value = riskValue;
        }
      }
      await getAllToolsHistory({ id: tool._id });
      handleSuccess();
      // setHistoryCache(allToolsHistory);
    } catch (err: any) {
      if (!(err == 'The user aborted a request.'
        || err == 'BodyStreamBuffer was aborted'
        || err == 'Fetch is aborted')) {
        message.warning({ title: typeof err === 'string' ? err : err?.message || '聊天出错了~', })
      } else {
        let concatenatedString = '';
        for (let key in inputs) {
          concatenatedString += key + ':' + inputs[key] + '/';
        }
        for (let key in selects) {
          concatenatedString += key + ':' + selects[key] + '/';
        }
        for (let key in selecteds) {
          concatenatedString += key + ':' + selecteds[key] + '/';
        }
        concatenatedString = concatenatedString.slice(0, -1);
        await createToolHistory({
          toolsid: tool._id,
          newChatId: '',
          toolsnanme: tool.name,
          userinput: concatenatedString,
          improve: "",
          contents: bindGenPrompt.current!.value,
          model: model,
          template: template
        })
      }
      if (err.message && err.message.includes('不足')) {
        // onOpenPayModal()
      }
      setLoading(false);
    }
    handleChange();
    setLoading(false);
  };
  const parseInput = (userinput: string) => {
    const pairs = userinput.split('/');
    const result: { [key: string]: string } = {};
    pairs.forEach(pair => {
      const [key, value] = pair.split(':');
      result[key] = value;
    });
    return result;
  };
  const handleChange = () => {
    if (!bindGenPrompt.current || loading) return;
  };
  //返回历史记录
  const setViewhistory = (item: any) => {
    setIsContent(!isContent)
    SetIsSelectValue(false);
    const data = parseInput(item.userinput);
    Object.keys(data).forEach((key) => {
      if (Object.keys(toolInputs).length !== 0 && toolInputs.hasOwnProperty(item.toolsid) && toolInputs[item.toolsid].hasOwnProperty(key)) {
        handleTextInputChange(item.toolsid, key, data[key]);
      }
      if (Object.keys(toolSelects).length !== 0 && toolSelects.hasOwnProperty(item.toolsid) && toolSelects[item.toolsid].hasOwnProperty(key)) {
        handleSelectChange(item.toolsid, key, data[key]);
      }
      if (Object.keys(toolSelectsnums).length !== 0 && toolSelectsnums.hasOwnProperty(item.toolsid) && toolSelectsnums[item.toolsid].hasOwnProperty(key)) {
        handleSelectsNumChange(item.toolsid, key, data[key], 'clear');
      }
      if (Object.keys(comboBox).length !== 0 && comboBox.hasOwnProperty(item.toolsid) && comboBox[item.toolsid].hasOwnProperty(key)) {
        handleComboBoxChange(item.toolsid, key, data[key]);
      }
      if (Object.keys(toolImgSelects).length !== 0 && toolImgSelects.hasOwnProperty(item.toolsid) && toolImgSelects[item.toolsid].hasOwnProperty(key)) {
        handleImgSelectChange(item.toolsid, key, data[key]);
      }
      if (Object.keys(textareavalue).length !== 0 && textareavalue.hasOwnProperty(item.toolsid) && textareavalue[item.toolsid].hasOwnProperty(key)) {
        handleValueChange(item.toolsid, key, data[key]);
      }
    });
    if (bindGenPrompt.current) {
      const riskValue = item.contentCheck?.status !== false ? item.askCheck?.status !== false ? item.contents : (`${t("creation.TextMayContain")}:${item.askCheck.labels?.split(',').map((str: any) => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')}${t('creation.NoConsume')} ` || t('NoConsume'))
        : (`${t("ContentMayContains")}:${item.contentCheck.labels?.split(',').map((str: any) => i18n.t('riskContent.' + str)).join(',')} ${t('creation.TemporarilyUnavailable')} ` || t('ContentRiskBlocked'))
      bindGenPrompt.current.value = riskValue;
      setButtonpd(bindGenPrompt.current.value.length < 1);
    }
    if (item.contentCheck) {
      SetRiskInfo(item.contentCheck);
    }
  }
  type ComponentType = 'inputs' | 'selects' | 'imgselect' | 'selectsnums' | 'textareavalues' | 'comboBox' | 'improve';
  const [currentExampleIndex, setCurrentExampleIndex] = useState(1);
  const typeSetters: { [K in ComponentType]: React.Dispatch<React.SetStateAction<any>> } = {
    inputs: setToolInputs,
    selects: setToolSelects,
    imgselect: setToolImgSelects,
    selectsnums: setToolSelectsNums,
    textareavalues: setTextareaValue,
    comboBox: setComboBox,
    improve: setTextareaValue
  };
  const caseButtonClick = (tool: any) => {
    setIsContent(!isContent)
    setButtonpd(false);
    SetIsSelectValue(false);
    for (const type in tool.assembly) {
      const compsArray = tool.assembly[type];
      if (Array.isArray(compsArray)) {
        compsArray.forEach(comp => {
          if (comp.example && comp.example[`example${currentExampleIndex}`]) {
            const updateFn = typeSetters[type as ComponentType];
            if (updateFn) {
              updateFn((prevValues: any) => ({
                ...prevValues,
                [tool._id]: {
                  ...prevValues[tool._id],
                  [comp.textvalue]: comp.example[`example${currentExampleIndex}`]
                }
              }));
            }
          }
        });
      }
    }

    const currentExampleValue = tool.assembly.contentpreview?.example[`example${currentExampleIndex}`] ?? '';
    let updateCurrent = '';
    for (let i = 0; i < currentExampleValue.length; i++) {
      if (/\s/.test(currentExampleValue[i]) && i > 0 && i < currentExampleValue.length - 1) {
        // if (!(/[a-zA-Z]/.test(currentExampleValue[i - 1]) || /[a-zA-Z]/.test(currentExampleValue[i + 1]) || currentExampleValue[i - 1] === '.')) {
        if ((/[\u4e00-\u9fa5]/.test(currentExampleValue[i - 1]) || currentExampleValue[i - 1] === '。' || currentExampleValue[i - 1] === '；' || currentExampleValue[i - 1] === '：' || currentExampleValue[i - 1] === '）' || currentExampleValue[i - 1] === '”' || currentExampleValue[i - 1] === '！')) {
          updateCurrent += '\n';
        } else {
          updateCurrent += currentExampleValue[i];
        }
      } else {
        updateCurrent += currentExampleValue[i];
      }
    }
    if (bindGenPrompt.current) {
      bindGenPrompt.current.value = updateCurrent;
    }

    setCurrentExampleIndex(prevIndex => (prevIndex >= 3 ? 1 : prevIndex + 1));
    SetcaseStop(false);
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowToggle, setShouldShowToggle] = useState(false);
  // useEffect(() => {
  //   const currentTool = toolslist?.find(tool => tool.assembly.link === selectTool);

  //   if (currentTool) {
  //     const nonRequiredCount = currentTool.assembly.inputs.filter(input => !input.isrequired).length
  //       + currentTool.assembly.selects.filter(select => !select.isrequired).length;
  //     setShouldShowToggle(nonRequiredCount >= 2);
  //   } else {
  //     setShouldShowToggle(false);
  //   }
  // }, [toolslist, selectTool]);
  useEffect(() => {
    setLoading(false)
    controller.current?.abort();
    const fetchData = async (toolid: any) => {
      try {
        await getAllToolsHistory({ id: toolid });
        controller.current?.abort();
        if (bindGenPrompt.current) {
          bindGenPrompt.current.value = '';
          setButtonpd(bindGenPrompt.current.value.length < 1);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (selectTool) {
      const currentTool = toolslist?.find((tool) => tool.assembly.link === selectTool);
      if (currentTool) {
        fetchData(currentTool._id);
      }
    }
  }, [selectTool]);

  const Closebutton = () => {
    return (
      <Box className={`${styles.nav}  ${toolShowNav ? styles.hide : ''}`} onClick={() => setToolShowNav(!toolShowNav)}>
        <Box className={styles.navBox}>
          <WiseImage className={styles.navOp} src={require('@/assets/png/nav_op_bg.png')} />
          <Icon className={styles.navOpIcon} as={require('@/assets/svg/right.svg').ReactComponent} />
        </Box>
      </Box>
    )
  }

  return <Box className={styles.tool}>
    {selectTool === '' ? (
      <Home SetSelectTool={SetSelectTool} selectedTag={selectedTag} setSelectedTag={setSelectedTag}></Home>
    ) : selectTool === 'filmtranslations' ? (
      <Flex className={styles.contentBox}>
        <ToolList selectTool={selectTool} SetSelectTool={SetSelectTool}
          selectedTag={selectedTag} setSelectedTag={setSelectedTag}></ToolList>
        <Flex className={styles.homecontent}>
          <Closebutton />
          <Filmtranslations />
        </Flex>
      </Flex>
    ) : selectTool === 'langtranslations' ? (
      <Flex className={styles.contentBox}>
        <ToolList selectTool={selectTool} SetSelectTool={SetSelectTool}
          selectedTag={selectedTag} setSelectedTag={setSelectedTag}></ToolList>
        <Flex className={styles.homecontent}>
          <Closebutton />
          <Langtranslations />
        </Flex>
      </Flex>
    ) : selectTool === 'videoSummary' ? (
      <Flex className={styles.contentBox}>
        <ToolList selectTool={selectTool} SetSelectTool={SetSelectTool}
          selectedTag={selectedTag} setSelectedTag={setSelectedTag}></ToolList>
        <Flex className={styles.homecontent}>
          <Closebutton />
          <VideoSummary />
        </Flex>
      </Flex>
    ) : selectTool === 'wechatRobot' ? (
      <Flex className={styles.contentBox}>
        <ToolList selectTool={selectTool} SetSelectTool={SetSelectTool}
          selectedTag={selectedTag} setSelectedTag={setSelectedTag}></ToolList>
        <Flex className={styles.homecontent}>
          <Closebutton />
          <WechatRobot />
        </Flex>
      </Flex>
    ) : selectTool === 'writingAssistant' ? (
      <Flex className={styles.contentBox}>
        <ToolList selectTool={selectTool} SetSelectTool={SetSelectTool}
          selectedTag={selectedTag} setSelectedTag={setSelectedTag}></ToolList>
        <Flex className={styles.homecontent}>
          <Closebutton />
          <WritingAssistant />
        </Flex>
      </Flex>
    ) : selectTool === 'swdt' ? (
      <Flex className={styles.contentBox}>
        <ToolList selectTool={selectTool} SetSelectTool={SetSelectTool}
          selectedTag={selectedTag} setSelectedTag={setSelectedTag}></ToolList>
        <Flex className={styles.homecontent}>
          <Closebutton />
          <MarkmapClass />
        </Flex>
      </Flex>
    ) : selectTool === 'tarotDivination' ? (
      <Flex className={styles.contentBox}>
        <ToolList selectTool={selectTool} SetSelectTool={SetSelectTool}
          selectedTag={selectedTag} setSelectedTag={setSelectedTag}></ToolList>
        <Flex className={styles.homecontent}>
          <Closebutton />
          <Tarot />
        </Flex>
      </Flex>
    ) : selectTool === 'naMing' ? (
      <Flex className={styles.contentBox}>
        <ToolList selectTool={selectTool} SetSelectTool={SetSelectTool}
          selectedTag={selectedTag} setSelectedTag={setSelectedTag}></ToolList>
        <Flex className={styles.homecontent}>
          <Closebutton />
          <NaMing />
        </Flex>
      </Flex>
    ) : selectTool === 'GeneratePPT' ? (
      <Flex className={styles.contentBox}>
        <ToolList selectTool={selectTool} SetSelectTool={SetSelectTool}
          selectedTag={selectedTag} setSelectedTag={setSelectedTag}></ToolList>
        <Flex className={styles.homecontent}>
          <Closebutton />
          <AIppts />
        </Flex>
      </Flex>
    ) : (
      <Flex className={styles.contentBox}>
        <ToolList selectTool={selectTool} SetSelectTool={SetSelectTool}
          selectedTag={selectedTag} setSelectedTag={setSelectedTag}></ToolList>
        <Flex className={styles.homecontent}>
          <Closebutton />
          {toolslist?.map((tool) => {
            if (tool.assembly.link === selectTool) {
              let parentsValue: string[] = [];
              const selectvalue: string[] = [];
              tool.assembly.selects.map((select) => {
                let uniqueParentsStrings = Array.from(new Set(select.selects
                  .map((item: any) => item.parents)
                  .filter((parent) => parent !== null && parent !== undefined && parent !== '')
                ))
                  .join(', ');
                if (uniqueParentsStrings.length > 0) {
                  parentsValue.push(uniqueParentsStrings);//获取所有父级元素的内容
                }
                let uniquevalue = toolSelects[tool._id] ? toolSelects[tool._id][select.textvalue] : select.selects[0]
                selectvalue.push(uniquevalue);//获取当前所有选中的内容
              });
              if (parentsValue.length === 1) {//针对小红书进行分割
                let valuesList = parentsValue[0].split(',');
                let tempValues: string[] = [];
                valuesList.forEach((value: any) => {
                  tempValues.push(value.trim());
                });
                parentsValue = tempValues;
              }
              return (
                <Flex className={styles.toollistBox} key={tool.assembly.link}>
                  <Flex className={styles.listBox}>
                    <ComTitle
                      key={tool.name.toString()}
                      setViewhistory={setViewhistory}
                      icon={tool.icon.toString()}
                      title={language === 'zh' ? tool.name.toString() : tool.name_more_language ? tool.name_more_language.en.toString() : ''}
                      historyCache={allToolsHistory}
                      bindprompt={bindGenPrompt}
                      onDelete={async (id: string) => {
                        await deleteToolsHistory({ id: id });
                        await getAllToolsHistory({ id: tool._id });
                        // setHistoryCache(allToolsHistory);
                        if (bindGenPrompt.current !== null) {
                          bindGenPrompt.current.value = '';
                        }
                      }}></ComTitle>
                    <Box className={styles.comsBox}>
                      <MacScrollbar thumbStyle={(horizontal: any) => { return horizontal ? {} : ({ width: '4px' }) }}>
                        <Flex className={styles.comBox}>
                          {tool.assembly.imgselect.map((imgselect, index) => (
                            (imgselect.isrequired || isExpanded || !shouldShowToggle) && (
                              <Box order={parseInt(imgselect.componentSort)} key={tool.assembly.link + 'imgselect' + index}>
                                <ComImageSelect
                                  title={language === 'zh' ? imgselect.texttitle : imgselect.texttitle_more_language ? imgselect.texttitle_more_language.en : ''}
                                  isPc={''}
                                  combos={imgselect.selects.map(s => s)}
                                  selectvalue={toolImgSelects[tool._id] ? toolImgSelects[tool._id][imgselect.textvalue] : imgselect.selects[0]}
                                  SetSelectvalue={(value: any) => handleImgSelectChange(tool._id, imgselect.textvalue, value)}
                                />
                              </Box>
                            )
                          ))}
                          {tool.assembly.comboBox.map((combo, index) => (
                            (combo.isrequired || isExpanded || !shouldShowToggle) && (
                              <Box order={parseInt(combo.componentSort)} key={tool.assembly.link + 'comboBox' + index}>
                                <ComComboBox
                                  selectLanguage={comboBox[tool._id] ? comboBox[tool._id][combo.textvalue] : language === 'zh' ? combo.selects[0].text : combo.selects[0].text_more_language ? combo.selects[0].text_more_language.en : ''} //选中的语言
                                  title={language === 'zh' ? combo.texttitle : combo.texttitle_more_language ? combo.texttitle_more_language.en : ''}  //标题
                                  langs={combo.selects.map(s => s)}  //下拉列表
                                  SetSelectLanguage={(value: any) => handleComboBoxChange(tool._id, combo.textvalue, value)} //切换的方法
                                ></ComComboBox>
                              </Box>
                            )
                          ))}
                          {tool.assembly.inputs.map((input, index) => (
                            (input.isrequired || isExpanded || !shouldShowToggle) && (
                              <Box order={parseInt(input.componentSort)} key={tool.assembly.link + 'input' + index}>
                                <ComTextarea
                                  title={language === 'zh' ? input.texttitle : input.texttitle_more_language ? input.texttitle_more_language.en : ""}
                                  prompt={language === 'zh' ? input.textvalueEnter : input.textvalueEnter_more_language ? input.textvalueEnter_more_language.en : ''}
                                  textvalue={toolInputs[tool._id] && toolInputs[tool._id][input.textvalue] ? toolInputs[tool._id][input.textvalue] : ''}
                                  textchange={(e: any) =>
                                    handleTextInputChange(
                                      tool._id,
                                      input.textvalue,
                                      e
                                    )
                                  }
                                  maxCount={Number(input.maxcontent)}
                                  rows={Number(input.maxrows)}
                                  required={input.isrequired.valueOf()}
                                />
                              </Box>
                            )
                          ))}
                          {tool.assembly.selects.map((select, index) => (
                            (select.isrequired || isExpanded || !shouldShowToggle) &&
                            (!select.fathervalue || (parentsValue && parentsValue.includes(selectvalue[0]))) && (//增加判断，没有父级元素直接展示，存在父级元素的则需要判断最高级的父级元素是否对应
                              <Box order={parseInt(select.componentSort)} key={tool.assembly.link + 'selects' + index}>
                                <ComTags
                                  title={language === 'zh' ? select.texttitle : select.texttitle_more_language ? select.texttitle_more_language.en : ''}
                                  array={select.selects.map(s => s)} // 假设这里需要的是一个字符串数组
                                  fathervalue={select.fathervalue ? select.fathervalue : undefined}
                                  fatherlist={toolSelects[tool._id] ? toolSelects[tool._id] : undefined}
                                  style={toolSelects[tool._id] ? toolSelects[tool._id][select.textvalue] : select.selects[0]} // 这里假设选择的第一项为默认值
                                  SetStyle={(value: any) => handleSelectChange(tool._id, select.textvalue, value, select.selects, tool.assembly.selects)}
                                  father={select.selects}
                                  isselectvalue={isselectvalue}
                                />
                              </Box>
                            )
                          ))}
                          {tool.assembly.selectsnums.map((select, index) => (
                            (select.isrequired || isExpanded || !shouldShowToggle) && (
                              <Box order={parseInt(select.componentSort)} key={tool.assembly.link + 'selectsnumsbox' + index}>
                                <ComDoubleTags
                                  title={language === 'zh' ? select.texttitle : select.texttitle_more_language ? select.texttitle_more_language.en : ''}
                                  array={select.selects.map(s => s)}
                                  emoji_content={toolSelectsnums[tool._id] ? toolSelectsnums[tool._id][select.textvalue] : []}
                                  SetEmoji_content={(selectedItems: any) => handleSelectsNumChange(tool._id, select.textvalue, selectedItems)}
                                />
                              </Box>
                            )
                          ))}
                          {tool.assembly.improve.map((improve, index) => (
                            (improve.isrequired || isExpanded || !shouldShowToggle) && (
                              <Box order={parseInt(improve.componentSort)} key={tool.assembly.link + 'improve' + index} w={['100%']}>
                                <ComImprove
                                  title={language === 'zh' ? improve.texttitle : improve.texttitle_more_language ? improve.texttitle_more_language.en : ''}
                                  typecontent={textareavalue[tool._id][improve.textvalue]}
                                  handleValueChange={(improveItems: any) => handleValueChange(tool._id, improve.textvalue, improveItems)}
                                  handleFocus={handleFocus}
                                  handleBlur={handleBlur}
                                  textareaRef={textareaRef}
                                  fathervalue={improve.fathervalue ? improve.fathervalue : ''}
                                  parents={improve.parents ? improve.parents : ''}
                                  fatherlist={toolSelects[tool._id]}
                                  tool={tool.assembly}
                                />
                              </Box>
                            )
                          ))}
                        </Flex>
                      </MacScrollbar>
                    </Box>
                    <Flex className={styles.footbutton}>
                      <ComButton
                        isnull={isnullcontent}
                        loading={loading}
                        setLoading={setLoading}
                        casebut={language === 'zh' ? tool.assembly.buttons.casebut.text : tool.assembly.buttons.casebut.text_more_language ? tool.assembly.buttons.casebut.text_more_language.en : ''}
                        startbut={language === 'zh' ? tool.assembly.buttons.startbut.text : tool.assembly.buttons.startbut.text_more_language ? tool.assembly.buttons.startbut.text_more_language.en : ''}
                        stopbut={language === 'zh' ? tool.assembly.buttons.stopbut.text : tool.assembly.buttons.stopbut.text_more_language ? tool.assembly.buttons.stopbut.text_more_language.en : ''}
                        stopClick={() => { controller.current?.abort() }}
                        startClick={() => collectAndProcessValues(tool)}
                        caseButtonClick={() => caseButtonClick(tool)}
                        caseStop={caseStop}
                        chooseModel={choosesModel}
                        setChooseModel={setChoosesModel}
                        modelkey={modelkey}
                        setModelsKey={setModelsKey}
                        _Models={_Models}
                      ></ComButton>
                    </Flex>
                  </Flex>
                  <Flex className={styles.previewBox}>
                    <Contentpreview
                      title={language === 'zh' ? tool.assembly.contentpreview.title.toString() : tool.assembly.contentpreview.title_more_language ? tool.assembly.contentpreview.title_more_language.en.toString() : ''}
                      titlePrompt={language === 'zh' ? tool.assembly.contentpreview.titlePrompt.toString() : tool.assembly.contentpreview.titlePrompt_more_language ? tool.assembly.contentpreview.titlePrompt_more_language.en.toString() : ''}
                      bindprompt={bindGenPrompt}
                      loading={loading}
                      download={tool.assembly.contentpreview.buttons.download}
                      downloadtitle={tool.name}
                      buttonpd={buttonpd}
                      isContent={isContent}
                      riskInfo={riskInfo}
                      model={modelkey.key}
                    />
                  </Flex>
                </Flex>
              )
            }
          })}
        </Flex>
      </Flex>
    )}
  </Box>
}

export default observer(Tools)