export enum AudioFormatEnum {
  MP3 = 'MP3',
  WAV = 'WAV'
}

export enum AudioSpeekLangeuageEnum {
  '中文（普通话,简体）' = 'zh-CN',
  '中文（粤语,简体）' = 'yue-CN',
  '英语（美国）' = 'en-US',
  '中文（中原官话河南,简体）' = 'zh-CN-henan',
  '中文（东北官话,简体）' = 'zh-CN-liaoning',
  '中文（中原官话陕西,简体）' = 'zh-CN-shaanxi',
  '中文（台湾普通话）' = 'zh-TW',
  '泰语（泰国）' = 'th-TH',
  '俄语（俄国）' = 'ru-RU',
  '韩语（韩国）' = 'ko-KR',
  '日语（日本）' = 'ja-JP',
  '法语（法国）' = 'fr-FR',
  '德语（德国）' = 'de-DE'
}

//检测中文
export function containsChinese(text: string): boolean {
  const regex = /[\u4e00-\u9fa5]/;
  return regex.test(text);
}
//检测韩语
export function containsKorean(text: string): boolean {
  const regex = /[\u3131-\uD79D]/g;
  return regex.test(text);
}
//检测日语
export function containsJapanese(text: string): boolean {
  const regex = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uF900-\uFAFF\uFF66-\uFF9F]/g;
  return regex.test(text);
}
//检测俄语
export function containsRussian(text: string): boolean {
  const regex = /[\u0400-\u04FF]/;
  return regex.test(text);
}
//检测泰语
export function containsThai(text: string): boolean {
  const regex = /[\u0E00-\u0E7F]/;
  return regex.test(text);
}

export enum AudioNameEnum {
  //中文普通话
  '晓晓' = 'XiaoxiaoNeural',
  '云希' = 'YunxiNeural',
  '云健' = 'YunjianNeural',
  '晓伊' = 'XiaoyiNeural',
  '云扬' = 'YunyangNeural',
  '晓辰' = 'XiaochenNeural',
  '晓涵' = 'XiaohanNeural',
  '晓梦' = 'XiaomengNeural',
  '晓墨' = 'XiaomoNeural',
  '晓秋' = 'XiaoqiuNeural',
  '晓睿' = 'XiaoruiNeural',
  '晓双' = 'XiaoshuangNeural',
  '小轩' = 'XiaoxuanNeural',
  '晓颜' = 'XiaoyanNeural',
  '晓悠' = 'XiaoyouNeural',
  '晓甄' = 'XiaozhenNeural',
  '云枫' = 'YunfengNeural',
  '云皓' = 'YunhaoNeural',
  '云夏' = 'YunxiaNeural',
  '云野' = 'YunyeNeural',
  '云泽' = 'YunzeNeural',
  //中文粤语
  'XiaoMin' = 'XiaoMinNeural',
  'YunSong' = 'YunSongNeural',
  "晓晓 方言" = 'XiaoxiaoDialectsNeural',
  //英文美式
  'JennyMultilingual' = 'JennyMultilingualNeural',
  'Jenny' = 'JennyNeural',
  'Guy' = 'GuyNeural',
  'Aria' = 'AriaNeural',
  'Davis' = 'DavisNeural',
  'Amber' = 'AmberNeural',
  'Ana' = 'AnaNeural',
  'Ashley' = 'AshleyNeural',
  'Brandon' = 'BrandonNeural',
  'Christopher' = 'ChristopherNeural',
  'Cora' = 'CoraNeural',
  'Elizabeth' = 'ElizabethNeural',
  'Eric' = 'EricNeural',
  'Jacob' = 'JacobNeural',
  'Jane' = 'JaneNeural',
  'Jason' = 'JasonNeural',
  'Michelle' = 'MichelleNeural',
  'Monica' = 'MonicaNeural',
  'Nancy' = 'NancyNeural',
  'Roger' = 'RogerNeural',
  'Sara' = 'SaraNeural',
  'Steffan' = 'SteffanNeural',
  'Tony' = 'TonyNeural',
  'Blue' = 'BlueNeural',
  'RyanMultilingual' = 'RyanMultilingualNeural',

  //中文（中原官话河南,简体）
  '云登' = 'YundengNeural',
  //中文（东北官话,简体）
  '晓北 辽宁' = 'XiaobeiNeural',
  '云彪 辽宁' = 'YunbiaoNeural',
  //中文（中原官话陕西，简体）
  '晓妮' = 'XiaoniNeural',
  //中文台湾话
  '曉臻' = 'HsiaoChenNeural',
  '雲哲' = 'YunJheNeural',
  '曉雨' = 'HsiaoYuNeural',
  //泰语
  'Premwadee' = 'PremwadeeNeural',
  'Niwat' = 'NiwatNeural',
  'Achara' = 'AcharaNeural',
  //俄语
  'Svetlana' = 'SvetlanaNeural',
  'Dmitry' = 'DmitryNeural',
  'Dariya' = 'DariyaNeural',
  //韩语
  'SunHi' = 'SunHiNeural',
  'InJoon' = 'InJoonNeural',
  'BongJin' = 'BongJinNeural',
  'GookMin' = 'GookMinNeural',
  'JiMin' = 'JiMinNeural',
  'SeoHyeon' = 'SeoHyeonNeural',
  'SoonBok' = 'SoonBokNeural',
  'YuJin' = 'YuJinNeural',
  //日语
  'Nanami' = 'NanamiNeural',
  'Keita' = 'KeitaNeural',
  'Aoi' = 'AoiNeural',
  'Daichi' = 'DaichiNeural',
  'Mayu' = 'MayuNeural',
  'Naoki' = 'NaokiNeural',
  'Shiori' = 'ShioriNeural',
  //法语
  'Denise' = 'DeniseNeural',
  'Henri' = 'HenriNeural',
  'Alain' = 'AlainNeural',
  'Brigitte' = 'BrigitteNeural',
  'Celeste' = 'CelesteNeural',
  'Claude' = 'ClaudeNeural',
  'Coralie' = 'CoralieNeural',
  'Eloise' = 'EloiseNeural',
  'Jacqueline' = 'JacquelineNeural',
  'Jerome' = 'JeromeNeural',
  'Josephine' = 'JosephineNeural',
  'Maurice' = 'MauriceNeural',
  'Yves' = 'YvesNeural',
  'Yvette' = 'YvetteNeural',
  //德语
  'Katja' = 'KatjaNeural',
  'Conrad' = 'ConradNeural',
  'Amala' = 'AmalaNeural',
  'Bernd' = 'BerndNeural',
  'Christoph' = 'ChristophNeural',
  'Elke' = 'ElkeNeural',
  'Gisela' = 'GiselaNeural',
  'Kasper' = 'KasperNeural',
  'Killian' = 'KillianNeural',
  'Klarissa' = 'KlarissaNeural',
  'Klaus' = 'KlausNeural',
  'Louisa' = 'LouisaNeural',
  'Maja' = 'MajaNeural',
  'Ralf' = 'RalfNeural',
  'Tanja' = 'TanjaNeural'
}

export enum AudioStatusEnum {
  WAIT = 0,
  PROCESSING = 1,
  DELETE = 9,
  COMPLETED = 2,
  FAIL = 3,
  uploadToOss = 8
}

export enum PPTGenerationStatusEnum {
  SELECTTHEME = 1,  //已选择主题
  SELECTTITLE = 2,  //已选择标题
  SELECTOUTLINE = 3,  //已选择大纲
  SELECTCONTENT = 4,  //已选择ppt内容
  STEP_5 = 5,
  STEP_6 = 6,
  STEP_7 = 7,
  STEP_8 = 8,
  STEP_9 = 9,
  STEP_10 = 10,
  WAIT = 11,  //全部添加完成，准备生成
  SUCCESS = 200,  //生成成功
  FAILURE = 201,  //生成失败
  DELETE = 300,  //删除
}

export enum ApiTypeEnum {
  Wisetalker = 'wisetalker',
  Microsoft = 'microsoft'
}

export enum AudioStyleEnum {
  '默认' = '',
  '聊天' = 'chat',
  '客户服务' = 'customerservice',
  '愉悦' = 'cheerful',
  '新闻' = 'newscast',
  '愤怒' = 'angry',
  '悲伤' = 'sad',
  '兴奋' = 'excited',
  '友好' = 'friendly',
  '恐惧' = 'fearful',
  '喊叫' = 'shouting',
  '不友好' = 'unfriendly',
  '低语' = 'whispering',
  '期待' = 'hopeful',
  '旁白-专业' = 'narration-professional',
  '新闻-休闲' = 'newscast-casual',
  '新闻-正式' = 'newscast-formal',
  '同理心' = 'empathetic',
  '助理' = 'assistant',
  '对话' = 'dialogue',
  '旁白-放松' = 'narration-relaxed',
  '尴尬' = 'embarrassed',
  '害怕' = 'terrified',
  '不满' = 'disgruntled',
  '严厉' = 'serious',
  '沮丧' = 'depressed',
  '撒娇' = 'coquettish',
  '平静' = 'calm',
  '温柔' = 'gentle',
  '抒情' = 'lyrical',
  '诗歌朗诵' = 'poetry-reading',
  '聊天-休闲' = 'chat_leisure',
  '抱歉' = 'sorry',
  '体育解说' = 'sports_commentary',
  '体育解说-兴奋' = 'sports_commentary_excited',
  '纪录片-旁白' = 'documentary-narration',
  '实时广告' = 'advertising',
  '广告-欢快' = 'advertising_cheerful'
}

export enum AudioRoleEnum {
  '不模仿' = '',
  '女孩' = 'Girl',
  '男孩' = 'Boy',
  '女青年' = 'YoungAdultFemale',
  '男青年' = 'YoungAdultMale',
  '女中年' = 'OlderAdultFemale',
  '男中年' = 'OlderAdultMale',
  '女老年' = 'SeniorFemale',
  '男老年' = 'SeniorMale'
}

// 音调
export enum AudioPitchEnum {
  '默认(1倍语调)' = '',
  '0.5倍语调' = '-5st',
  '0.6倍语调' = '-4st',
  '0.7倍语调' = '-3st',
  '0.8倍语调' = '-2st',
  '0.9倍语调' = '-1st',
  '1.1倍语调' = '+1st',
  '1.2倍语调' = '+2st',
  '1.3倍语调' = '+3st',
  '1.4倍语调' = '+4st',
  '1.5倍语调' = '+5st'
}

// 速度
export enum AudioRateEnum {
  '默认(1倍语速)' = '',
  '0.5倍语速' = '-50%',
  '0.6倍语速' = '-40%',
  '0.7倍语速' = '-30%',
  '0.8倍语速' = '-20%',
  '0.9倍语速' = '-10%',
  '1.1倍语速' = '10%',
  '1.2倍语速' = '20%',
  '1.3倍语速' = '30%',
  '1.4倍语速' = '40%',
  '1.5倍语速' = '50%'
}
